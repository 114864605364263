import { computed, getCurrentInstance, onMounted, onUnmounted, ref, watch, provide } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useSnapping } from '@/components/Takeoff/composables/layers/useSnapping.js'
import { useSegmentPaths } from '@/components/Takeoff/composables/layers/useSegmentPaths.js'

export function useAreaLayer({
  updateLayer,
  layers,
  activeTool,
  zoom,
  selectLayer,
  selectedLayers
}) {
  const $this = getCurrentInstance().proxy

  const editingLayer = ref(null)
  const selectedPointIndex = ref(null)
  const draggingPointIndex = ref(null)
  const previewPoint = ref(null)

  // Create edit mode state if this is the provider instance
  const editMode = ref('edit')

  const { updatePointToSnap, snapping } = useSnapping({
    zoom,
    layers
  })

  const setEditMode = (mode) => {
    editMode.value = mode === editMode.value ? 'edit' : mode
  }

  provide('areaLayerState', {
    editMode: editMode,
    setEditMode
  })

  watch(
    selectedLayers,
    (sel) => {
      if (editingLayer.value && !sel.find((l) => l.id === editingLayer.value)) {
        editingLayer.value = null
        selectedPointIndex.value = null
        draggingPointIndex.value = null
        editMode.value = 'edit' // Reset to default mode when selection changes
      }
    },
    { deep: true }
  )

  // Find the closest point and segment on a path
  const findClosestPointOnPath = (point, layer) => {
    if (!layer.points || layer.points.length < 2) return null

    let closestDistance = Infinity
    let closestSegmentIndex = -1
    let closestPoint = null

    for (let i = 0; i < layer.points.length; i++) {
      const p1 = layer.points[i]
      const p2 = layer.points[(i + 1) % layer.points.length]

      // Calculate the closest point on this segment
      const dx = p2.x - p1.x
      const dy = p2.y - p1.y
      const segmentLength = Math.sqrt(dx * dx + dy * dy)

      if (segmentLength === 0) continue

      const t = Math.max(
        0,
        Math.min(
          1,
          ((point.x - p1.x) * dx + (point.y - p1.y) * dy) / (segmentLength * segmentLength)
        )
      )
      const projectedPoint = {
        x: p1.x + t * dx,
        y: p1.y + t * dy
      }

      const distance = Math.sqrt(
        Math.pow(point.x - projectedPoint.x, 2) + Math.pow(point.y - projectedPoint.y, 2)
      )

      if (distance < closestDistance) {
        closestDistance = distance
        closestSegmentIndex = i
        closestPoint = projectedPoint
      }
    }

    return {
      segmentIndex: closestSegmentIndex,
      point: closestPoint,
      distance: closestDistance
    }
  }

  const addAreaLayer = (data = {}) => {
    const layer = {
      id: uuidv4(),
      type: 'area',
      points: data.points || [],
      position: {
        points: data.points || [],
        ...data.position
      },
      metadata: {
        isComplete: data.metadata?.isComplete || false,
        area: data.metadata?.area || 0,
        centroid: data.metadata?.centroid || null,
        ...data.metadata
      },
      zIndex: layers.value.length
    }

    layers.value = [...layers.value, layer]
    updateLayer(layer.id)
    return layer
  }

  const updateAreaPoints = (layerId, points) => {
    const layer = layers.value.find((l) => l.id === layerId)
    if (!layer) return

    layer.points = points
    layer.metadata ??= {}

    updateLayer(layer.id, layer)
  }

  const completeArea = (layerId) => {
    const layer = layers.value.find((l) => l.id === layerId)
    if (!layer) return

    layer.metadata.isComplete = true

    updateLayer(layer.id, layer)
  }

  const areaLayers = computed(() => layers.value.filter((layer) => layer.type === 'area'))

  const proximityRange = computed(() => 5 / (zoom.value || 1))

  const getLayer = () => layers.value.find((l) => l.id === editingLayer.value)
  const currentLayer = computed(() => getLayer())

  const isPointEvent = ({ svgPoint, element, layer: providedLayer = null }) => {
    return (
      activeTool.value !== 'navigate' &&
      element &&
      element.hasAttribute('data-point-index') &&
      providedLayer &&
      selectedLayers.value.find((l) => l.id === providedLayer.id)
    )
  }

  const handleClick = (event) => {
    const { svgPoint, element, layer: providedLayer = null } = event
    if (draggingPointIndex.value !== null) handleDragend(event)

    const pointEvent = isPointEvent(event)
    // Handle normal point selection/editing

    if (
      pointEvent &&
      activeTool.value === 'area' &&
      editMode.value === 'edit' &&
      +element.getAttribute('data-point-index') === 0 &&
      editingLayer.value &&
      getLayer().points?.length > 2
    ) {
      completeArea(getLayer().id)
      editingLayer.value = null
      return
    }

    // Handle point removal mode
    if (editMode.value === 'remove' && providedLayer?.type === 'area') {
      if (isPointEvent(event)) {
        const pointIndex = +element.getAttribute('data-point-index')
        const layer = providedLayer
        if (layer.points.length > 3) {
          // Prevent removing points if it would make the area invalid
          const newPoints = layer.points.filter((_, i) => i !== pointIndex)
          updateAreaPoints(layer.id, newPoints)
        }
        return
      }
    }

    // Handle point addition mode
    if (editMode.value === 'add' && providedLayer?.type === 'area') {
      const closest = findClosestPointOnPath(svgPoint, providedLayer)
      if (closest && closest.distance < proximityRange.value) {
        const newPoints = [...providedLayer.points]
        newPoints.splice(closest.segmentIndex + 1, 0, closest.point)
        updateAreaPoints(providedLayer.id, newPoints)
        return
      }
    }

    // Handle normal point selection/editing
    else if (pointEvent) {
      editingLayer.value = providedLayer.id
      selectLayer(editingLayer.value)
      selectPoint(+element.getAttribute('data-point-index'))
      return
    }

    if (activeTool.value !== 'area' || editMode.value !== 'edit') return

    let layer
    if (!editingLayer.value) {
      layer = addAreaLayer({
        points: []
      })
      editingLayer.value = layer.id
      selectLayer(layer.id)
    }
    layer = getLayer()

    const firstPoint = layer.points?.[0] ?? null

    const isNearFirstPoint =
      firstPoint &&
      Math.abs(svgPoint.x - firstPoint.x) < proximityRange.value &&
      Math.abs(svgPoint.y - firstPoint.y) < proximityRange.value

    if (isNearFirstPoint && layer.points?.length > 2) {
      // Complete the area
      completeArea(layer.id)
      editingLayer.value = null
    } else {
      const lastPointIndex = draggingPointIndex.value || layer.points?.length - 1
      const newPoint = updatePointToSnap(
        svgPoint,
        layer.segments?.[lastPointIndex],
        layer.points?.[lastPointIndex]
      )
      const newPoints = [...(layer.points || []), newPoint]
      selectedPointIndex.value = newPoints.length - 1
      // editingLayer.value = layer.id
      updateAreaPoints(layer.id, newPoints)
    }
  }

  const resetPointDrag = () => {
    selectedPointIndex.value = null
    draggingPointIndex.value = null
  }

  const handleDragstart = (event) => {
    resetPointDrag()
    if (!isPointEvent(event)) return

    editingLayer.value = event.layer.id
    const { element, isMousedown } = event
    // const unwatch = watch(isMousedown, (is) => {
    //   if (!is) {
    //     unwatch()
    //     handleDragend(event)
    //   }
    // })
    selectedPointIndex.value = +element.getAttribute('data-point-index')
    draggingPointIndex.value = +element.getAttribute('data-point-index')
  }

  const handleDragmove = ({ svgPoint, isMousedown }) => {
    if (
      draggingPointIndex.value === null ||
      !isMousedown.value ||
      !editingLayer.value ||
      selectedPointIndex.value == null
    ) {
      resetPointDrag()
      return
    }

    if (
      !isMousedown.value &&
      draggingPointIndex.value === null &&
      editingLayer.value &&
      selectedPointIndex.value
    ) {
      previewPoint.value = svgPoint
      return
    }

    const points = [...(currentLayer.value.points ?? [])]

    const lastPointIndex = draggingPointIndex.value || layer.points?.length - 1
    const newPoint = updatePointToSnap(
      svgPoint,
      layer.segments?.[lastPointIndex],
      layer.points?.[lastPointIndex]
    )
    points[draggingPointIndex.value] = newPoint
    updateAreaPoints(currentLayer.value.id, points)
  }

  const handleDragend = ({ isMousedown }) => {
    if (
      draggingPointIndex.value === null ||
      !isMousedown.value ||
      !editingLayer.value ||
      selectedPointIndex.value === null
    ) {
      resetPointDrag()
      return
    }
    draggingPointIndex.value = null
  }

  const selectPoint = (pointIndex) => {
    selectedPointIndex.value = pointIndex
  }

  onMounted(() => {
    $this.$on('clickCanvas', handleClick)
    $this.$on('mousedownCanvas', handleDragstart)
    $this.$on('mousemoveCanvas', handleDragmove)
    $this.$on('mouseupCanvas', handleDragend)
  })
  onUnmounted(() => {
    $this.$off('clickCanvas', handleClick)
    $this.$off('mousedownCanvas', handleDragstart)
    $this.$off('mousemoveCanvas', handleDragmove)
    $this.$off('mouseupCanvas', handleDragend)
  })

  // const getPathData = (layer) => {
  //   if (!layer?.points?.length) return ''
  //
  //   return (
  //     layer.points.reduce((path, point, index) => {
  //       return path + `${index === 0 ? 'M' : 'L'} ${point.x} ${point.y} `
  //     }, '') + (layer.points.length > 2 ? 'Z' : '')
  //   )
  // }

  const { getPointPathData } = useSegmentPaths({ zoom })

  const previewSegment = computed(() => {
    if (editingLayer.value === null || editMode.value !== 'edit' || !previewPoint.value) return null

    return [currentLayer.value.points[selectedPointIndex.value], previewPoint.value]
  })

  const getPathData = (layer) => getPointPathData(layer, layers.value, previewSegment.value)

  return {
    snapping,
    selectPoint,
    addAreaLayer,
    updateAreaPoints,
    completeArea,
    areaLayers,
    getPathData,
    selectedPointIndex,
    editMode,
    setEditMode
  }
}
