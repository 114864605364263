import { toRaw } from 'vue'
import { v4 } from 'uuid'

export function useCalculations({ zoom }) {
  const tolerance = 0.99
  const toleranceSq = tolerance * tolerance

  const pointKey = (p) => `${p.x},${p.y}`

  // Convex Hull algorithm: Graham Scan
  const convexHull = (points) => {
    if (points.length <= 3) return points

    const angleDiff = (a, b, center) => {
      const angleA = Math.atan2(a.y - center.y, a.x - center.x)
      const angleB = Math.atan2(b.y - center.y, b.x - center.x)
      return angleA - angleB
    }

    // find bottom-most y point (and left-most)
    const startPt = points.reduce((lowest, p) =>
      p.y < lowest.y || (p.y === lowest.y && p.x < lowest.x) ? p : lowest
    )

    // sort points by polar-angle to startPt
    const sorted = points.slice().sort((a, b) => {
      return angleDiff(a, b, startPt) || distanceSq(startPt, a) - distanceSq(startPt, b)
    })

    function cross(p1, p2, p3) {
      return (p2.x - p1.x) * (p3.y - p1.y) - (p2.y - p1.y) * (p3.x - p1.x)
    }

    function distanceSq(a, b) {
      return (b.x - a.x) ** 2 + (b.y - a.y) ** 2
    }

    const hull = [startPt]

    for (let p of sorted.slice(1)) {
      while (hull.length > 1 && cross(hull[hull.length - 2], hull[hull.length - 1], p) <= 0) {
        hull.pop() //remove last point if it's "right-turn"
      }
      hull.push(p)
    }

    return hull
  }

  // const buildGraph = (segments) => {
  //   const graph = new Map()
  //
  //   for (let [p1, p2] of segments) {
  //     p1 = _.imm(p1)
  //     p2 = _.imm(p2)
  //     const key1 = pointKey(p1)
  //     const key2 = pointKey(p2)
  //
  //     if (!graph.has(key1)) graph.set(key1, { point: p1, neighbors: [] })
  //     if (!graph.has(key2)) graph.set(key2, { point: p2, neighbors: [] })
  //
  //     graph.get(key1).neighbors.push(p2)
  //     graph.get(key2).neighbors.push(p1)
  //   }
  //
  //   return graph
  // }

  const angleFrom = (origin, pt) => Math.atan2(pt.y - origin.y, pt.x - origin.x)

  const isPointOnLine = (point, segment) => {
    const [p1, p2] = segment
    const dx = p2.x - p1.x
    const dy = p2.y - p1.y
    const lenSq = dx * dx + dy * dy

    if (lenSq === 0) {
      // segment is a single point
      const px = point.x - p1.x
      const py = point.y - p1.y
      const distSq = px * px + py * py
      return distSq <= toleranceSq
        ? { point: { x: p1.x, y: p1.y }, distance: Math.sqrt(distSq) }
        : null
    }

    const t = ((point.x - p1.x) * dx + (point.y - p1.y) * dy) / lenSq

    if (t < 0 || t > 1) return null

    const closestX = p1.x + t * dx
    const closestY = p1.y + t * dy
    const px = point.x - closestX
    const py = point.y - closestY
    const distSq = px * px + py * py

    return distSq <= toleranceSq
      ? { point: { x: closestX, y: closestY }, distance: Math.sqrt(distSq) }
      : null
  }

  const isSamePoint = (p1, p2) => {
    const dx = p1.x - p2.x
    const dy = p1.y - p2.y
    return dx * dx + dy * dy <= toleranceSq
  }
  const pointEq = isSamePoint

  const isValidSegment = (segment) => {
    if (!Array.isArray(segment) || segment.length !== 2) return false
    const [p1, p2] = segment
    return !isSamePoint(p1, p2)
  }

  const doSegmentsIntersect = (seg1, seg2) => {
    // Ensure segments are arrays with two points each
    if (!Array.isArray(seg1) || !Array.isArray(seg2) || seg1.length !== 2 || seg2.length !== 2) {
      console.warn('Invalid segment format in doSegmentsIntersect:', { seg1, seg2 })
      return null
    }

    const [p1, p2] = seg1
    const [p3, p4] = seg2

    // Ensure points have x and y properties
    if (
      !p1 ||
      !p2 ||
      !p3 ||
      !p4 ||
      typeof p1.x !== 'number' ||
      typeof p1.y !== 'number' ||
      typeof p2.x !== 'number' ||
      typeof p2.y !== 'number' ||
      typeof p3.x !== 'number' ||
      typeof p3.y !== 'number' ||
      typeof p4.x !== 'number' ||
      typeof p4.y !== 'number'
    ) {
      console.warn('Invalid point format in doSegmentsIntersect:', { p1, p2, p3, p4 })
      return null
    }

    const denominator = (p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x)
    if (denominator === 0) return null

    const t = ((p1.x - p3.x) * (p3.y - p4.y) - (p1.y - p3.y) * (p3.x - p4.x)) / denominator
    const u = -((p1.x - p2.x) * (p1.y - p3.y) - (p1.y - p2.y) * (p1.x - p3.x)) / denominator

    if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
      return {
        x: p1.x + t * (p2.x - p1.x),
        y: p1.y + t * (p2.y - p1.y)
      }
    }
    return null
  }

  const areSegmentsTouching = (segmentA, segmentB) => {
    const [p1a, p2a] = segmentA
    const [p1b, p2b] = segmentB

    if (
      isSamePoint(p1a, p1b) ||
      isSamePoint(p1a, p2b) ||
      isSamePoint(p2a, p1b) ||
      isSamePoint(p2a, p2b)
    ) {
      return true
    }

    return (
      (isPointOnLine(p1a, segmentB) ||
        isPointOnLine(p2a, segmentB) ||
        isPointOnLine(p1b, segmentA) ||
        isPointOnLine(p2b, segmentA)) !== null
    )
  }

  // Return true if point is strictly inside the polygon (ray casting algorithm)
  const isPointInsidePolygon = (point, polygon) => {
    let inside = false
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      const xi = polygon[i].x,
        yi = polygon[i].y
      const xj = polygon[j].x,
        yj = polygon[j].y

      const intersect =
        yi > point.y !== yj > point.y &&
        point.x < ((xj - xi) * (point.y - yi)) / (yj - yi || 1e-10) + xi

      if (intersect) inside = !inside
    }
    return inside
  }

  const polygonCenter = (polygon) => {
    const pt = polygon.reduce((a, c) => ({ x: a.x + c.x, y: a.y + c.y }), { x: 0, y: 0 })
    return { x: pt.x / polygon.length, y: pt.y / polygon.length }
  }

  // Finds a reliable interior point
  const reliableInsidePoint = (polygon) => {
    const center = polygonCenter(polygon)

    for (let i = 0; i < polygon.length - 1; i++) {
      const p1 = polygon[i]
      const p2 = polygon[i + 1]
      const midpoint = { x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2 }

      // Move slightly towards polygon center
      const testPoint = {
        x: midpoint.x + (center.x - midpoint.x) * 0.1,
        y: midpoint.y + (center.y - midpoint.y) * 0.1
      }

      if (isPointInsidePolygon(testPoint, polygon)) {
        return testPoint // returns the first reliable inside point found
      }
    }

    return center // fallback (less safe, but avoid total failure)
  }

  const splitSegmentsAtIntersections = (segments, targetSegment = null) => {
    if (!Array.isArray(segments)) return []

    const distanceSq = (p1, p2) => (p1.x - p2.x) ** 2 + (p1.y - p2.y) ** 2

    const sortPointsAlongSegment = (points, seg) =>
      points.slice().sort((a, b) => distanceSq(seg[0], a) - distanceSq(seg[0], b))

    // Identify intersections
    const getIntersectionPoints = (seg, others) => {
      const pts = []
      others.forEach((otherSeg) => {
        const intersection = doSegmentsIntersect(seg, otherSeg)
        if (intersection) pts.push(intersection)

        otherSeg.forEach((pt) => {
          if (isPointOnLine(pt, seg)) pts.push(pt)
        })

        seg.forEach((pt) => {
          if (isPointOnLine(pt, otherSeg)) pts.push(pt)
        })
      })

      return pts
    }

    // Process a single segment with provided intersection points
    const splitSegment = (seg, intersectionPoints) => {
      const points = [seg[0], seg[1], ...intersectionPoints]
      const uniquePoints = points.filter(
        (p, i, arr) => i === arr.findIndex((q) => isSamePoint(p, q))
      )
      const sorted = sortPointsAlongSegment(uniquePoints, seg)
      const result = []
      for (let i = 0; i < sorted.length - 1; i++) {
        const newSeg = [
          {
            ...sorted[i],
            sid: v4()
          },
          {
            ...sorted[i + 1],
            sid: v4()
          }
        ]
        if (isValidSegment(newSeg)) result.push(newSeg)
      }
      return result
    }

    const outputSegments = []

    if (targetSegment) {
      // keep original segments in correct order
      segments.forEach((seg) => {
        if (seg === targetSegment) {
          const others = segments.filter((s) => s !== seg)
          const intersections = getIntersectionPoints(seg, others)
          const splits = splitSegment(seg, intersections)
          outputSegments.push(...splits)
        } else {
          const intersectionsWithNewSeg = getIntersectionPoints(seg, [targetSegment])
          if (intersectionsWithNewSeg.length) {
            const splits = splitSegment(seg, intersectionsWithNewSeg)
            outputSegments.push(...splits)
          } else {
            outputSegments.push(seg)
          }
        }
      })
    } else {
      // No incremental segment, process all fully
      segments.forEach((seg) => {
        const others = segments.filter((s) => s !== seg)
        const intersections = getIntersectionPoints(seg, others)
        const splits = splitSegment(seg, intersections)
        outputSegments.push(...splits)
      })
    }

    // Deduplicate while keeping original order
    const finalSegments = []
    outputSegments.forEach((seg) => {
      if (
        !finalSegments.some(
          (s) =>
            (isSamePoint(s[0], seg[0]) && isSamePoint(s[1], seg[1])) ||
            (isSamePoint(s[0], seg[1]) && isSamePoint(s[1], seg[0]))
        )
      ) {
        finalSegments.push(seg)
      }
    })

    return finalSegments
  }

  const groupConnectedSegments = (segments) => {
    const groups = []
    const queue = segments ? [...segments] : []

    let segmentIndex = 0
    while (queue.length) {
      const segment = queue.shift()

      let matchedGroupIndex = -1

      for (let g = 0; g < groups.length; g++) {
        if (groups[g].some((gs) => areSegmentsTouching(segment, gs))) {
          if (matchedGroupIndex === -1) {
            groups[g].push(segment)
            matchedGroupIndex = g
          } else {
            // merge groups if multiple matches found
            groups[matchedGroupIndex] = groups[matchedGroupIndex].concat(groups[g])
            groups.splice(g, 1)
            g-- // important, as we just removed current group
          }
        }
      }

      if (matchedGroupIndex === -1) {
        groups.push([segment])
      }

      segmentIndex++
    }

    return groups
  }

  /**

   angleBetweenThreePoints(p0, p1, p2):
   Computes the TURN angle (0..2π) from the line p0→p1 to the line p1→p2.
   p0: lastPoint
   p1: currentPoint
   p2: candidate neighbor */
  const angleBetweenThreePoints = (p0, p1, p2) => {
    // Vector from p0 to p1
    const x1 = p1.x - p0.x
    const y1 = p1.y - p0.y

    // Vector from p1 to p2
    const x2 = p2.x - p1.x
    const y2 = p2.y - p1.y

    // Dot product (v1 · v2) = (x1*x2 + y1*y2)
    const dot = x1 * x2 + y1 * y2

    // 2D Cross product / determinant: det = (x1*y2 - y1*x2)
    const det = x1 * y2 - y1 * x2

    // Angle from –π..π
    let angle = Math.atan2(det, dot)

    // Convert angle relative to 180°
    angle = (angle + Math.PI) % (2 * Math.PI)

    return angle
  }

  const buildGraph = (segments) => {
    const graph = new Map()

    // Create vertices and links accurately
    segments.forEach((segment) => {
      const [p1, p2] = _.imm(segment)
      const key1 = pointKey(p1)
      const key2 = pointKey(p2)

      if (!graph.has(key1)) {
        graph.set(key1, { point: p1, neighbors: new Set() })
      }

      if (!graph.has(key2)) {
        graph.set(key2, { point: p2, neighbors: new Set() })
      }

      let vertex1 = graph.get(key1)
      let vertex2 = graph.get(key2)

      // add each as neighbors explicitly
      vertex1.neighbors.add(vertex2.point)
      vertex2.neighbors.add(vertex1.point)
    })

    return graph
  }

  function segmentsToPolygon(segments) {
    if (!segments?.length) {
      return { polygon: [], segments: [], isExplicitlyClosed: false }
    }

    // 1) Build the graph
    const graph = buildGraph(segments)
    // console.log('graph', graph)

    // 2) Find the bottom-leftmost point as our start
    const startVertexKey = [...graph.keys()].reduce((accKey, curKey) => {
      const accPt = graph.get(accKey).point
      const curPt = graph.get(curKey).point
      if (curPt.y < accPt.y || (curPt.y === accPt.y && curPt.x < accPt.x)) {
        return curKey
      }
      return accKey
    }, graph.keys().next().value)

    const startPoint = graph.get(startVertexKey).point

    // 3) We track the polygon path
    const polygon = [startPoint]
    let isExplicitlyClosed = false

    // visitedEdges to avoid re-traversing the same line
    const visitedEdges = new Set()

    // 4) We'll define lastPoint initially as if we came from directly below startPoint
    //    so that the initial direction is "straight up"
    let lastPoint = { x: startPoint.x, y: startPoint.y - 1 }
    let currentPoint = startPoint
    const segmentIds = []

    while (true) {
      // All neighbors for the current vertex
      const neighbors = [...graph.get(pointKey(currentPoint)).neighbors]

      // Filter out neighbors if we've visited that edge in both directions
      const availableNeighbors = neighbors.filter((nPt) => {
        const e1 = `${pointKey(currentPoint)}-${pointKey(nPt)}`
        const e2 = `${pointKey(nPt)}-${pointKey(currentPoint)}`
        return !visitedEdges.has(e1) && !visitedEdges.has(e2)
      })

      if (!availableNeighbors.length) {
        // No further moves
        break
      }

      // 5) For each neighbor, find "turn angle" from line lastPoint->currentPoint -> currentPoint->neighbor
      const candidates = availableNeighbors
        .map((nPt) => {
          const turnAngle = angleBetweenThreePoints(lastPoint, currentPoint, nPt)
          // get turnAngle in degrees
          return { currentPoint, lastPoint, nPt, turnAngle, degrees: (turnAngle * 180) / Math.PI }
        })
        // sort by smallest turn angle => leftmost direction
        .sort((a, b) => a.turnAngle - b.turnAngle)

      // console.log(
      //   'current point, neighbor candidates',
      //   [currentPoint.x, currentPoint.y],
      //   candidates
      // )
      // 6) Pick the neighbor with the smallest left-turn angle
      const { nPt: nextPoint } = candidates[0]

      // find the segment that has both currentPoint and nextPoint, and add it to
      // the list of outside segments
      const outsideSegment = segments.find(
        (seg) =>
          (isSamePoint(seg[0], currentPoint) && isSamePoint(seg[1], nextPoint)) ||
          (isSamePoint(seg[0], nextPoint) && isSamePoint(seg[1], currentPoint))
      )
      if (outsideSegment) {
        nextPoint.sid = outsideSegment[0].sid
        segmentIds.push(outsideSegment[0].sid)
      }

      // Mark the edge visited in both directions
      const forwardEdge = `${pointKey(currentPoint)}-${pointKey(nextPoint)}`
      const backwardEdge = `${pointKey(nextPoint)}-${pointKey(currentPoint)}`
      visitedEdges.add(forwardEdge)
      visitedEdges.add(backwardEdge)

      // Check if we’ve looped back to the start
      if (polygon.length > 2 && pointKey(nextPoint) === pointKey(startPoint)) {
        polygon.push(nextPoint)
        isExplicitlyClosed = true
        break
      }

      // 7) Advance:
      polygon.push(nextPoint)
      lastPoint = currentPoint // shift forward
      currentPoint = nextPoint
    }

    // 8) If fewer than 3 unique points => not a valid polygon
    if (polygon.length < 3) {
      return { polygon: [], segments: [], isExplicitlyClosed: false }
    }

    // 9) Build polygon edges from the final loop
    const polygonSegments = polygon.slice(0, -1).map((p, i) => [p, polygon[i + 1]])

    // 10) Collect segLayerIds for reference
    const segLayerIds = polygon.map((pt) => pt.sid).filter(Boolean)

    return {
      polygon,
      segments: polygonSegments,
      outsideSegmentIds: segmentIds,
      isExplicitlyClosed
    }
  }

  const filterContainedPolygons = (groups) => {
    const polygonObjects = groups.map(segmentsToPolygon)

    const nonContainedGroups = []

    for (let i = 0; i < polygonObjects.length; i++) {
      const polyObjA = polygonObjects[i]
      const testPoint = reliableInsidePoint(polyObjA.polygon)
      let contained = false

      for (let j = 0; j < polygonObjects.length; j++) {
        if (i === j) continue
        const polyObjB = polygonObjects[j]

        if (isPointInsidePolygon(testPoint, polyObjB.polygon)) {
          contained = true
          break
        }
      }

      if (!contained) {
        nonContainedGroups.push(polyObjA)
      }
    }

    return nonContainedGroups
  }

  const getCentroid = (layer) => {
    if (!layer.points?.length) return { x: 0, y: 0 }

    // Find the bounding box
    const bounds = layer.points.reduce(
      (box, point) => {
        return {
          minX: Math.min(box.minX, point.x),
          maxX: Math.max(box.maxX, point.x),
          minY: Math.min(box.minY, point.y),
          maxY: Math.max(box.maxY, point.y)
        }
      },
      {
        minX: layer.points[0].x,
        maxX: layer.points[0].x,
        minY: layer.points[0].y,
        maxY: layer.points[0].y
      }
    )

    // Calculate the center of the bounding box
    return {
      x: (bounds.minX + bounds.maxX) / 2,
      y: (bounds.minY + bounds.maxY) / 2
    }
  }

  const getGroupedOuterPolygons = (segments) => {
    const groups = groupConnectedSegments(segments)
    const filteredGroups = filterContainedPolygons(groups)

    return filteredGroups
  }

  const calculatePixelArea = (polygons) => {
    let totalArea = 0

    polygons.forEach((group) => {
      if (!group?.polygon) return

      const points = group.polygon
      let area = 0

      // Calculate area using the Shoelace formula
      for (let i = 0; i < points.length; i++) {
        const j = (i + 1) % points.length
        area += points[i].x * points[j].y - points[j].x * points[i].y
      }

      // Finalize area calculation (area is half of the sum)
      area = Math.abs(area) / 2
      totalArea += area
    })

    return totalArea
  }

  const calculatePixelOuterPerimeterLength = (polygons) => {
    if (!polygons || !polygons.length) return 0

    let perimeter = 0

    polygons.forEach((polyObj) => {
      if (!polyObj.isExplicitlyClosed) return

      const polygonPoints = polyObj.polygon
      if (!polygonPoints || polygonPoints.length < 2) return

      for (let i = 0; i < polygonPoints.length; i++) {
        const currentPoint = polygonPoints[i]
        const nextPoint = polygonPoints[(i + 1) % polygonPoints.length]
        perimeter += Math.hypot(nextPoint.x - currentPoint.x, nextPoint.y - currentPoint.y)
      }
    })

    return perimeter
  }

  const calculatePixelSegmentLength = (segments) => {
    let perimeter = 0

    segments.forEach(([p1, p2]) => {
      const length = Math.hypot(p2.x - p1.x, p2.y - p1.y)
      perimeter += length
    })

    return perimeter
  }

  return {
    calculatePixelSegmentLength,
    calculatePixelOuterPerimeterLength,
    calculatePixelArea,
    getCentroid,
    isPointInsidePolygon,
    isPointOnLine,
    areSegmentsTouching,
    segmentsToPolygon,
    isSamePoint,
    groupConnectedSegments,
    filterContainedPolygons,
    splitSegmentsAtIntersections,
    getGroupedOuterPolygons
  }
}
