import { ref, onMounted, onBeforeUnmount } from 'vue'

export const useWheelEvents = ({
  zoom,
  updateZoom,
  screenToSVGPoint,
  updatePanOffset,
  viewportRef
}) => {
  const isWheeling = ref(false)
  let wheelTimeout

  const handler = (event) => {
    event.preventDefault()
    event.stopPropagation()

    // Get the point under the cursor in SVG coordinates
    const point = screenToSVGPoint(event.clientX, event.clientY)
    if (!point) return

    // Zoom
    const delta = -event.deltaY
    const factor = delta > 0 ? 1.05 : 0.95
    updateZoom(zoom.value * factor, point, { x: event.clientX, y: event.clientY })

    // Set wheeling state
    isWheeling.value = true
    clearTimeout(wheelTimeout)
    wheelTimeout = setTimeout(() => {
      isWheeling.value = false
    }, 150)
  }

  onMounted(() => {
    viewportRef.value.addEventListener('wheel', handler)
  })
  onBeforeUnmount(() => {
    viewportRef.value.removeEventListener('wheel', handler)
  })

  return {
    handler,
    isWheeling
  }
}
