import { ref, onMounted, onUnmounted, getCurrentInstance, computed } from 'vue'

export const usePan = ({ takeoff, activeTool, refGridBackdrop, currentElement, viewport }) => {
  const $this = getCurrentInstance().proxy

  const isPanning = ref(false)

  const shouldPan = () => {
    if (activeTool.value === 'navigate') return true
    if (activeTool.value === 'select' && currentElement.value === refGridBackdrop.value) return true

    return false
  }

  const dragstartHandler = (event) => {
    if (!shouldPan(event)) return false

    isPanning.value = true
  }

  const dragmoveHandler = (event) => {
    if (!isPanning.value) return false

    viewport.pan(-1 * event.dxScreen, -1 * event.dyScreen)
  }

  const dragendHandler = () => {
    isPanning.value = false
  }

  onMounted(() => {
    $this.$on('dragstartCanvas', dragstartHandler)
    $this.$on('dragendCanvas', dragendHandler)
    $this.$on('dragmoveCanvas', dragmoveHandler)
  })
  onUnmounted(() => {
    $this.$off('dragstartCanvas', dragstartHandler)
    $this.$off('dragendCanvas', dragendHandler)
    $this.$off('dragmoveCanvas', dragmoveHandler)
  })

  return {
    isPanning
  }
}
