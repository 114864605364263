<template>
  <div class="modals-gutter" :class="{ dip }">
    <slot></slot>
    <component
      v-for="(modal, index) in allModals"
      :key="`${modal.name}-${modal.timeAdded}`"
      :is="modal.name"
      :zIndex="2000 + (index + 1) * 10"
      :style="{
        'z-index': 2000 + (index + 1) * 10
      }"
      :startOpen="true"
      :modal="modal"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Modals',
  components: {
    Accounting: defineAsyncComponent(() => import('../modals/AccountingSync.vue')),
    AddedAssemblyPreferences: defineAsyncComponent(
      () => import('../modals/AddedAssemblyPreferences.vue')
    ),
    AddedItemPreferences: defineAsyncComponent(() => import('../modals/AddedItemPreferences.vue')),
    AiAssistant: defineAsyncComponent(() => import('@/components/modals/AiAssistant.vue')),
    Assembly: defineAsyncComponent(() => import('../modals/Assembly.vue')),
    AssemblyConfirmDimensions: defineAsyncComponent(
      () => import('../modals/AssemblyConfirmDimensions.vue')
    ),
    ProgressTutorial: defineAsyncComponent(
      () => import('../../components/progress/ProgressTutorial.vue')
    ),
    AssemblyNew: defineAsyncComponent(() => import('../modals/AssemblyNew.vue')),
    AssigneeNew: defineAsyncComponent(() => import('../modals/AssigneeNew.vue')),
    BudgetCode: defineAsyncComponent(() => import('../modals/BudgetCode.vue')),
    Client: defineAsyncComponent(() => import('../modals/Client.vue')),
    ClientNew: defineAsyncComponent(() => import('../modals/ClientNew.vue')),
    Company: defineAsyncComponent(() => import('../modals/Company.vue')),
    CompanyNew: defineAsyncComponent(() => import('../modals/CompanyNew.vue')),
    Confirm: defineAsyncComponent(() => import('../modals/Confirm.vue')),
    CostItem: defineAsyncComponent(() => import('../modals/CostItem.vue')),
    CostType: defineAsyncComponent(() => import('../modals/CostType.vue')),
    CostTypeNew: defineAsyncComponent(() => import('../modals/CostTypeNew.vue')),
    Dimension: defineAsyncComponent(() => import('../modals/Dimension.vue')),
    DimensionNew: defineAsyncComponent(() => import('../modals/DimensionNew.vue')),
    File: defineAsyncComponent(() => import('../modals/File.vue')),
    Files: defineAsyncComponent(() => import('../modals/Files.vue')),
    Form: defineAsyncComponent(() => import('../modals/Form.vue')),
    Invoice: defineAsyncComponent(() => import('../modals/Invoice.vue')),
    InvoiceNew: defineAsyncComponent(() => import('../modals/InvoiceNew.vue')),
    LaborType: defineAsyncComponent(() => import('../modals/LaborType.vue')),
    LaborTypeNew: defineAsyncComponent(() => import('../modals/LaborTypeNew.vue')),
    LeadRotation: defineAsyncComponent(() => import('../modals/LeadRotation.vue')),
    LeadRotationNew: defineAsyncComponent(() => import('../modals/LeadRotationNew.vue')),
    LeadSource: defineAsyncComponent(() => import('../modals/LeadSource.vue')),
    LeadSourceNew: defineAsyncComponent(() => import('../modals/LeadSourceNew.vue')),
    Location: defineAsyncComponent(() => import('../modals/Location.vue')),
    LocationNew: defineAsyncComponent(() => import('../modals/LocationNew.vue')),
    PaymentMethods: defineAsyncComponent(() => import('../modals/PaymentMethods.vue')),
    PaymentMethodNew: defineAsyncComponent(() => import('../modals/PaymentMethodNew.vue')),
    PaymentNew: defineAsyncComponent(() => import('../modals/PaymentNew.vue')),
    Prompt: defineAsyncComponent(() => import('../modals/Prompt.vue')),
    Quote: defineAsyncComponent(() => import('../modals/Quote.vue')),
    QuoteDocumentViewer: defineAsyncComponent(() => import('../modals/QuoteDocumentViewer.vue')),
    QuoteNew: defineAsyncComponent(() => import('../modals/QuoteNew.vue')),
    QuoteSend: defineAsyncComponent(() => import('../modals/QuoteSend.vue')),
    QuoteWizard: defineAsyncComponent(() => import('../modals/QuoteWizard.vue')),
    RatingForm: defineAsyncComponent(() => import('../modals/RatingForm.vue')),
    Receipt: defineAsyncComponent(() => import('../modals/Receipt.vue')),
    Role: defineAsyncComponent(() => import('../modals/Role.vue')),
    RoleNew: defineAsyncComponent(() => import('../modals/RoleNew.vue')),
    Selector: defineAsyncComponent(() => import('../modals/Selector.vue')),
    Stage: defineAsyncComponent(() => import('../modals/Stage.vue')),
    Tax: defineAsyncComponent(() => import('../modals/Tax.vue')),
    TaxNew: defineAsyncComponent(() => import('../modals/TaxNew.vue')),
    TradeType: defineAsyncComponent(() => import('../modals/TradeType.vue')),
    UpgradeDetails: defineAsyncComponent(() => import('../modals/UpgradeDetails.vue')),
    OptionalDetails: defineAsyncComponent(() => import('../modals/OptionalDetails.vue')),
    PresentationOnboarding: defineAsyncComponent(
      () => import('../modals/PresentationOnboarding.vue')
    ),
    // Grrrr 🤬@todo Find where we invoke Template modals and rename these
    // eslint-disable-next-line vue/no-reserved-component-names
    Template: defineAsyncComponent(() => import('../modals/Template.vue')),
    TemplateNew: defineAsyncComponent(() => import('../modals/TemplateNew.vue')),
    TermsAndConditions: defineAsyncComponent(() => import('../modals/TermsAndConditions.vue')),
    Transactions: defineAsyncComponent(() => import('../modals/Transactions.vue')),
    TwoFactorAuth: defineAsyncComponent(() => import('../modals/TwoFactorAuth.vue')),
    User: defineAsyncComponent(() => import('../modals/User.vue')),
    Vendor: defineAsyncComponent(() => import('../modals/Vendor.vue')),
    VendorNew: defineAsyncComponent(() => import('../modals/VendorNew.vue')),
    Webhook: defineAsyncComponent(() => import('../modals/Webhook.vue')),
    UserNew: defineAsyncComponent(() => import('../modals/UserNew.vue')),
    ContractorRatingNew: defineAsyncComponent(() => import('../modals/ContractorRatingNew.vue')),
    ClientRatingNew: defineAsyncComponent(() => import('../modals/ClientRatingNew.vue')),
    SuperCreateFeatureProduct: defineAsyncComponent(
      () => import('../modals/SuperCreateFeatureProduct.vue')
    ),
    ExpenseDetailsForm: defineAsyncComponent(() => import('../modals/ExpenseDetails.vue'))
  },
  methods: {
    previewUnderlyingPage() {
      if (this.allModals.length) {
        setTimeout(() => {
          this.dipModals()
          setTimeout(() => {
            this.undipModals()
          }, 3000)
        }, 2000)
      }
    },
    dipModals() {
      this.dip = 1
    },
    undipModals() {
      this.dip = 0
    }
  },
  data() {
    return {
      dip: 0
    }
  },
  computed: {
    allModals() {
      return this.$store.state.modal.visible
    }
  },
  mounted() {
    this.$router.afterEach((to, from) => {
      // Filter out query changes, only dip when an actual path has changed
      if (to.path !== from.path) {
        this.previewUnderlyingPage()
      }
    })
  }
}
</script>
