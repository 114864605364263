import _ from './Helpers'

const suppliers = ['hdus', 'hdca', 'rona', 'lowe', 'abc']

/**
 * Returns if the given company has an active AutoCost subscription.
 */
const hasAutoCostSubscription = (company) => {
  return company && company?.aoModules?.autocost !== '0'
}

/**
 * Determines if a labor type is AutoCost from its id.
 */
const isAutoCostLaborTypeId = (laborTypeId) => {
  return /^(ac)-/.test(String(laborTypeId))
}

/**
 * Determines if an item is AutoCost from its id.
 */
const isAutoCostObjectId = (itemId) => {
  const prefixes = [...suppliers, 'ac', 'autocost', 'crft']
  return prefixes.filter((prefix) => `${itemId ?? ''}`.startsWith(prefix)).length > 0
}

/**
 * Determines if an item object is AutoCost.
 */
const isAutoCostObject = (object) => {
  return (
    object.live_price_reference ||
    (object.labor_id && isAutoCostLaborTypeId(object.labor_id)) ||
    (object?.parent_cost_type_id?.includes('autocost > '))
  )
}

/**
 * Determines if an item object is global.
 * Global items are defined as items in an autocost category with no specific country.
 */
const isGlobalObject = (object) => {
  return object?.parent_cost_type_id?.includes('autocost > ') && object?.live_price_country === null
}

/**
 * Determines if an object is an abc object.
 */
const isABCSupplyObject = (object) => {
  const id = object.autocost_id ?? object.live_price_reference ?? object.livePriceRef
  return `${id ?? ''}`.startsWith('abc')
}

/**
 * Determines if an object is an abc id.
 */
const isABCSupplyId = (id) => {
  return `${id ?? ''}`.startsWith('abc')
}

/**
 * Determines if an object is a craftsman object.
 */
const isCraftsmanObject = (object) => {
  return `${object?.autocost_id ?? ''}`.startsWith('crft')
}

/**
 * Determines if an object is a craftsman id.
 */
const isCraftsmanId = (id) => {
  return `${id ?? ''}`.startsWith('crft')
}

/**
 * Determines if an object is a craftsman material object.
 */
const isCraftsmanMaterialObject = (object) => {
  return isCraftsmanObject(object) && object?.autocost_id?.includes('mat')
}

/**
 * Determines if an object is a craftsman labor object.
 */
const isCraftsmanLaborObject = (object) => {
  return isCraftsmanObject(object) && object?.autocost_id?.includes('lab')
}

/**
 * Determines if an object is a craftsman labor object.
 */
const isCraftsmanLaborId = (id) => {
  return isCraftsmanId(id) && id?.includes('lab')
}

/**
 * Determines if an object is a supplier object
 * See suppliers array at top of file for possible suppliers
 */
const isSupplierObject = (object) => {
  const matches = suppliers.filter((supplier) => object?.autocost_id?.startsWith(supplier))
  return matches.length === 1
}

/**
 * Checks if a list of autocost objects contains supplier items
 */
const hasAutoCostSupplierObjects = (list) => {
  const supplierItems = list.filter((item) => isSupplierObject(item))
  return supplierItems.length > 0
}

/**
 * Returns if a given catalog category is an autocost one.
 */
const isAutoCostCategory = (category) => {
  return `${category ?? ''}`.startsWith('autocost')
}

/**
 * Returns if a given catalog category is an autocost one.
 */
const isAutoCostRootCategory = (category) => {
  return category && category === 'autocost'
}

/**
 * Returns the zipcode to use for AutoCost queries.
 */
const getAutoCostZipcode = (company = null, quote = null) => {
  // In quote
  if (company && quote) {
    switch (quote.live_price_zipcode) {
      // Use quote zipcode
      case 'project':
        if (!quote.quote_postal) break
        return quote.quote_postal
      // Use company zipcode
      case 'company':
        if (!company.company_postal) break
        return company.company_postal
      // Use custom zipcode
      default:
        if (!quote.live_price_zipcode) break
        return quote.live_price_zipcode
    }
  }

  // Not in quote
  if (company && company.company_postal) {
    return company.company_postal
  }

  // No zipcode set
  return ''
}

/**
 * Converts a company's country abbreviation to an array of AutoCost allowed country codes
 */
const getAutoCostCountries = (company) => {
  if (!company || !Object.keys(company).length) return [1, 2, 'NULL']

  const countryMap = {
    ca: [1, 'NULL'],
    us: [2, 'NULL']
  }

  return countryMap[company.company_country] || []
}

/**
 * Returns an array of AutoCost categories
 * Searches recursively if recursive = true
 */
const getAutoCostCategories = async (store, path = 'autocost', filter = true) => {
  // Update this if new categories are added!
  // Invalidates old cached categories if theres a mismatch between current and stored versions
  const currentVersion = 2

  // Whether to return internal categories or not
  const showInternal = store.state.session.user.user_is_super_user

  let categories = _.getStorage('bolsterCategories')
  let storedVersion = JSON.parse(_.getStorage('bolsterCategoriesVersion'))

  // Has cached categories that are up to date
  if (categories && categories.length && storedVersion === currentVersion) {
    categories = typeof categories === 'string' ? JSON.parse(categories) : categories
  }

  // Doesn't have cached categories
  else {
    categories = await store.dispatch('ajax', {
      path: 'live_price/searchLivePriceCategories',
      data: {
        category_path: 'autocost',
        offset: null,
        limit: null,
        recursive: true
      }
    })
    categories = categories.payload
    _.setStorage('bolsterCategories', JSON.stringify(categories))
    _.setStorage('bolsterCategoriesVersion', JSON.stringify(currentVersion))
  }

  // Filter out categories not inside path
  categories = categories.filter((category) => {
    if (!showInternal && category.internal) return false
    if (filter) {
      let categoryPath = category.cost_type_id.split(' > ').slice(0, -1)
      let parentPath = path.split(' > ')
      return JSON.stringify(parentPath) === JSON.stringify(categoryPath)
    }
  })

  // Sort alphabetically
  categories = categories.sort((a, b) =>
    a.cost_type_name.toLowerCase() < b.cost_type_name.toLowerCase() ? -1 : 1
  )

  return categories
}

/**
 * Returns the url to the image corresponding to an AutoCost item.
 */
const getAutoCostImageUrl = (object, bucket = '') => {
  if (!object) return null

  if (object?.aoImages?.length) {
    if (isABCSupplyObject(object)) {
      return `/assets/abc/${object.aoImages[0]}`
    }
    return `${bucket}/${object.aoImages[0]}`
  }

  if (object?.image_external) {
    if (isABCSupplyObject(object)) {
      return `/assets/abc/${object.image_external}`
    }
    return `${bucket}/${object.image_external}`
  }

  return null
}

const getAutoCostImageUrlByName = (name, bucket = '') => {
  const prefixes = [...suppliers.filter((sup) => sup !== 'abc'), 'ac', 'autocost', 'crft']
  if (prefixes.filter((prefix) => `${name ?? ''}`.startsWith(prefix)).length > 0) {
    return `${bucket}/${name}`
  }
  return `/assets/abc/${name}`
}

export default {
  suppliers,
  hasAutoCostSubscription,
  isAutoCostLaborTypeId,
  isAutoCostObjectId,
  isAutoCostObject,
  isGlobalObject,
  isABCSupplyObject,
  isABCSupplyId,
  isCraftsmanObject,
  isCraftsmanMaterialObject,
  isCraftsmanLaborObject,
  isCraftsmanId,
  isCraftsmanLaborId,
  isSupplierObject,
  hasAutoCostSupplierObjects,
  isAutoCostCategory,
  isAutoCostRootCategory,
  getAutoCostZipcode,
  getAutoCostCountries,
  getAutoCostCategories,
  getAutoCostImageUrl,
  getAutoCostImageUrlByName
}
