import { ref } from 'vue'

export const usePinchEvents = ({ zoom, updateZoom, screenToSVGPoint }) => {
  const isPinching = ref(false)
  let initialDistance = 0
  let initialZoom = 1

  const handler = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const touches = event.touches
    if (touches.length !== 2) return

    // Calculate center point
    const center = {
      x: (touches[0].clientX + touches[1].clientX) / 2,
      y: (touches[0].clientY + touches[1].clientY) / 2
    }

    // Convert center to SVG coordinates
    const svgCenter = screenToSVGPoint(center.x, center.y)
    if (!svgCenter) return

    // Calculate distance between touch points
    const dx = touches[0].clientX - touches[1].clientX
    const dy = touches[0].clientY - touches[1].clientY
    const distance = Math.sqrt(dx * dx + dy * dy)

    if (!isPinching.value) {
      isPinching.value = true
      initialDistance = distance
      initialZoom = zoom.value
    } else {
      const scale = distance / initialDistance
      updateZoom(initialZoom * scale, svgCenter)
    }
  }

  const reset = () => {
    isPinching.value = false
    initialDistance = 0
    initialZoom = 1
  }

  return {
    handler,
    isPinching,
    reset
  }
}
