  export default {
    'Amount': 'Montant',
    'Amounts include any taxes.': 'Les montants incluent toutes les taxes de vente.',
    'Anonymous': 'Anonyme',
    'Cancel loan': 'Annuler le prêt',
    'Category': 'Catégorie',
    'Click to toggle expand': 'Cliquez pour déplier',
    'Combined taxes': 'Impôts combinés',
    'Convenience fee': 'Frais de commodité',
    'Credit Card': 'Carte de drédit',
    'Current Invoice': 'Facture courante',
    'default invoice description': 'Sa c\'est la description de la facture.Ce texte ne sera pas traduit automatiquement car il est généré par les utilisateurs.',
    'derived from': 'dérivé de',
    'Download Invoice': 'Télécharger la facture',
    'Due date': 'Date d\'échéance',
    'each': 'chaque',
    'From': 'De',
    'How would you rate your recent experience with': 'Comment évalueriez-vous votre expérience récente avec',
    'If disabled, only the total price of the proposal will be shown.': 'Si désactivé, seul le prix total de la proposition sera affiché',
    'Including any sales or value-added taxes': 'Inclut toutee les ventes ou taxe sur la valeur ajoutée',
    'Invoice': 'Facture',
    'Invoice breakdown': 'Détail de la facture',
    'Invoiced': 'Facturés',
    'Invoices payable': 'Factures à payer',
    'labor': 'travaux de construction',
    'loan': 'prêt',
    'Loan payment': 'Paiement du prêt',
    'Management fee': 'Frais de gestion',
    'manual': 'manuel',
    'materials': 'matériaux',
    'month': 'mois',
    'Name': 'Nom',
    'No fee': 'Pas de Frais',
    'No item name': 'Aucun nom d\'article',
    'not included': 'non inclus',
    'Not Yet Due': 'Pas encore dû',
    'of project total': 'total du projet',
    'Offline Payment': 'Paiement hors ligne',
    'on': 'sur',
    'Paid on': 'Payé le',
    'Payment received': 'Paiement reçus',
    'Payments received': 'Paiements reçus',
    'Please make a selection above before continuing': 'Veuillez sélectionner un élément ci-dessus avant de continuer',
    'powered by': 'Alimenté par',
    'Price': 'Prix',
    'Product': 'Produit',
    'Project summary': 'Résumé du projet',
    'Quantity': 'Quantité',
    'Rate your experience': 'Évaluez votre expérience',
    'Receipt': 'Reçu',
    'Request alternative payment method': 'Demander une autre mode de paiement',
    'Review all': 'Revoir tout',
    'Review all items': 'Passer en revue tous les articles',
    'Review submitted': 'Critique soumise',
    'Review the details of this quote': 'Vérifiez les détails de cette soumission',
    'Secure Bank Transfer': 'Transfer bancaire sécurisé',
    'Secure payment methods': 'Modes de paiement sécurisés',
    'See projects': 'Voir les projets',
    'Select': 'Sélectionnez',
    'Selections': 'Choisir',
    'Sent': 'Date envoyée',
    'Show itemized pricing': 'Afficher le détail des prix',
    'subcontracting': 'Sous-traitance',
    'Submit review': 'Soumettre une critique',
    'Subtotal': 'Sous-Total',
    'subtotal': 'sous-Total',
    'Tax': 'Impôts',
    'tax': 'impôts',
    'tax on {taxOn} derived from {taxType}': 'impôt sur {taxOn} dérivé de {taxType}',
    'Taxes Total': 'Impots Total',
    'Thank you for booking with us! We will get in touch as soon as possible to confirm details.': 'Merci d\'avoir réservé avec nous! Nous vous contacterons dès que possible pour confirmer les détails.',
    'This invoice includes the following items': 'Cette facture comprend les éléments suivants',
    'This is a required selection': 'Ceci est une sélection requise',
    'Total due now': 'Montant total dû maintenant',
    'Total paid': 'Total payé',
    'Total project value': 'Total valeur du project',
    'Uninvoiced': 'Pas facturés',
    'Upon Request': 'À la demande',
    'Item name': 'Nom de l\'article',
    'Itemized invoicing' : 'Facturation détaillée',
    'Invoice date' : 'Date de facture',
    'Amount (% of balance)': 'Montant (% de balance)',
    'Due': 'A payer',
    'Due on': 'Dû le',
    'New Invoice': 'Nouvelle Facture',
    'Project invoice summary': 'Résumé de la facture de projet',
    'To create an invoice,': 'Pour créer une facture,',
    'Date when invoice created or when project completed.  Defaults to today.': 'Date à laquelle la facture a été créée ou à laquelle le projet a été terminé. Par défaut, il est réglé sur aujourd\'hui.',
    'you must have a booked project first!': 'Vous devez d\'abord avoir un projet réservé!',
    'If enabled, invoice per item.': 'Si activée, facturer par article.',
    'Select a percentage of the remaining uninvoiced amount of the project.': 'Sélectionnez un pourcentage du montant restant à facturer pour le projet',
    'Select an amount based on the percentage of the total job value.': 'Sélectionnez un montant basé sur le pourcentage de la valeur totale du travail.',
    "Select a project to create an invoice for.  Project must be marked 'booked' or 'in progress' before it will appear in this list.": 'Choisissez un projet pour créer une facture. Le projet doit être marqué comme \'réservé\' ou \'en cours\' avant de pouvoir apparaître dans cette liste.',
    'Your project is fully booked. Come back to Bolster for updates or to request and approve change-orders.': 'Votre Projet est réservé. Revenez a la plateforme Bolster pour suivre les mises à jour ou pour approuver ou faire de nouvelles demandes',
    'Cover the credit card transaction fee?': 'Couvrez  les frais de transaction de la carte de crédit?',
    'Cover the Bolster transaction fee?': 'Couvrez les frais de transaction de Bolster?',
    "A 2.9% credit card transaction fee will be added to the client's invoice if they choose to pay with credit card.": 'Des frais de transaction de carte de crédit de 2,9 % seront ajoutés à la facture du client s\'il choisit de payer par carte de crédit.',
    "A 2.9% credit card transaction fee will be subtracted from your payout if the client chooses to pay with credit card.": 'Des frais de transaction de carte de crédit de 2,9 % seront déduits de votre paiement si le client choisit de payer par carte de crédit.',
    "Within each invoice settings you can opt in or out to pay for the credit card transaction fee. Otherwise this global setting will be used.": 'Dans chaque paramètre de facture, vous pouvez choisir d\'accepter ou de refuser de payer les frais de transaction de la carte de crédit. Sinon, ce paramètre global sera utilisé.',
    "A transaction fee will be added to the client's invoice.":'Des frais de transaction de seront ajoutés à la facture du client.',
    "A transaction fee will be subtracted from your payout. ":'Des frais de transaction de seront déduits de votre paiement.',
    "Within each invoice settings you can opt in or out to pay for the Bolster transaction fee. Otherwise this global setting will be used.": 'Dans chaque paramètre de facture, vous pouvez choisir d\'accepter ou de refuser de payer les frais de transaction de Bolster. Sinon, ce paramètre global sera utilisé." translates to French as ',
    'You are a read-only reviewer': 'Vous êtes un réviseur en lecture seule',
    'This proposal has expired and is no longer valid': 'Cette proposition a expiré et n\'est plus valide',
    'Request a new proposal': 'Demander une nouvelle proposition',
    'No selection required by customer': 'Aucune sélection requise par le client',
    "Thank you for your submission! We will get back to you as soon as possible": "Merci pour votre soumission! Nous vous répondrons dès que possible.",
    "Submission Successful": "Soumission réussie",
    "Could not find form": "Impossible de trouver le formulaire",
    "This form does not exist. Please double check the url": "Ce formulaire n'existe pas. Veuillez vérifier l'URL.",
    "Saving form submission": "Enregistrement de la soumission du formulaire",
    "Submit": "Soumettre",
    "To get started creating an estimate, please select the options provided below": "Pour commencer à créer un devis, veuillez sélectionner les options ci-dessous.",
    "About us": "À propos de nous",
    "Get a quote": "Obtenez un devis",
    "Company rating": "Évaluation de l'entreprise",
    "One of our dedicated agents will review your requirements to provide a personalized response. Check your inbox for confirmation and details. We’re excited to help make your dream home a reality": "Un de nos agents dévoués examinera vos exigences pour vous fournir une réponse personnalisée. Consultez votre boîte de réception pour la confirmation et les détails. Nous sommes ravis de vous aider à réaliser la maison de vos rêves.",
    "You will receive a copy of the estimate by email for your records": "Vous recevrez une copie de l'estimation par courriel pour vos dossiers.",
    "Thank you for your submission! We've received your details and are already working on creating an estimate for your project. We will follow up with you shortly to discuss the estimate and the next steps": "Merci pour votre soumission! Nous avons reçu vos informations et travaillons déjà sur l'élaboration d'une estimation pour votre projet. Nous vous contacterons sous peu pour discuter de l'estimation et des prochaines étapes.",
    "First Name": "Prénom",
    "Last Name": "Nom de famille",
    "Email": "Email",
    "Phone": "Téléphone",
    "Suite": "Appartement",
    "Address": "Adresse",
    "City": "Ville",
    "Zip Code / Postal Code": "Code postal",
    "Project Details": "Détails du projet",
    "Project Type": "Type de projet",
    "Project Budget": "Budget du projet",
    "Project Timeline": "Calendrier du projet",
    "Tell us about your project.": "Parlez-nous de votre projet.",
    "Tell us the type of project you need.": "Dites-nous quel type de projet vous avez besoin.",
    "Tell us your budget for the project.": "Indiquez-nous votre budget pour le projet.",
    "Tell us when you want the project to start and any other important dates.": "Dites-nous quand vous souhaitez commencer le projet et toute autre date importante.",
    "Add to estimate": "Ajouter à l'estimation",
    "Remove from estimate": "Retirer de l'estimation",
    "Add": "Ajouter",
    "Save": "Économiser",
    "Included": "Inclus",
    "Not included": "Non inclus",
    "Same price": "Même prix",
    "Learn more": "En savoir plus",
    "Optional item details": "Détails de l'article optionnel",
    "No description available": "Aucune description disponible",
    "Description": "Description",
    "Features": "Caractéristiques",
    "Upgrade availability may vary according to individual project circumstances. Pictures are examples and may not exactly represent how it appears on your project.": "La disponibilité des améliorations peut varier en fonction des circonstances spécifiques du projet. Les images sont des exemples et peuvent ne pas représenter exactement l'apparence sur votre projet.",
    "Cancel": "Annuler",
    "Upgrade details": "Détails de l'amélioration",
    "Select this": "Sélectionnez ceci",
    "Please complete the form below, and we'll instantly generate a shoppable estimate. You can make upgrades, refine your selections, and submit a request for proposal.": "Veuillez remplir le formulaire ci-dessous, et nous générerons instantanément un devis personnalisable. Vous pourrez effectuer des améliorations, affiner vos choix et soumettre une demande de proposition.",
    "View options": "Voir les options",
    "Welcome to your interactive estimate": "Bienvenue dans votre estimation interactive",
    "In this proposal, your contractor or builder has specified some items which require a selection to be made by you before your project can start.": "Dans cette proposition, votre entrepreneur ou constructeur a spécifié certains éléments qui nécessitent une sélection de votre part avant que votre projet puisse commencer.",
    "There are also items marked 'Optional', which you can choose to add to your project if you wish.": "Il y a également des éléments marqués 'Optionnel', que vous pouvez choisir d'ajouter à votre projet si vous le souhaitez.",
    "Thanks, and enjoy your experience using Bolster's Interactive Estimates!": "Merci et profitez de votre expérience avec les estimations interactives de Bolster !",
    "Dismiss": "Ignorer",
    "Download relevant files to enhance your project or documentation such as contracts, invoices, receipts, images, or any other supporting documents.": "Téléchargez les fichiers pertinents pour enrichir votre projet ou votre documentation, tels que des contrats, factures, reçus, images ou tout autre document justificatif.",
    "The achievements and recognitions demonstrate our expertise and commitment, helping you feel confident in your decision.": "Les réalisations et les reconnaissances témoignent de notre expertise et de notre engagement, vous aidant à avoir confiance dans votre décision.",
    "Awards and badges": "Récompenses et badges",
    "Attached files": "Fichiers joints",
    "Rolling Basis Payments": "Paiements au fil de l'avancement",
    "Pay as work is completed. You'll receive individual payment requests for each finished and quality-approved item. An itemized receipt summarizing all completed items will be provided.": 
      "Payez au fur et à mesure de l'avancement des travaux. Vous recevrez des demandes de paiement individuelles pour chaque élément terminé et approuvé. Un reçu détaillant tous les éléments complétés sera fourni.",
    "Transparent Costs": "Coûts transparents",
    "Gain clear insight into costs and track progress in real time.": 
      "Obtenez une visibilité claire des coûts et suivez la progression en temps réel.",
    "Flexible Payments": "Paiements flexibles",
    "Pay for items as they're completed, reducing large upfront expenses and improving budget management.": 
      "Payez pour les éléments au fur et à mesure de leur achèvement, réduisant ainsi les dépenses initiales importantes et améliorant la gestion du budget.",
    "Aligned Expectations": "Attentes alignées",
    "Payments tied to progress ensure quality and scope meet expectations.": 
      "Des paiements liés à l'avancement garantissent que la qualité et la portée répondent aux attentes.",
      'Total items from the estimate': 'Total des articles du devis',
      'Installment': 'Versement',
      'Total': 'Total',
      'Payments are required': 'Les paiements sont requis',
      'Project payment terms': 'Conditions de paiement du projet',
      "Rolling Basis Payments offer flexibility and transparency by allowing you to pay as work is completed. After approving the estimate, you’ll be prompted to add a payment method, ensuring you can quickly approve and pay for items as they’re completed. Access your project’s Progress Dashboard within the portal to track ongoing work, review completed items, and approve them for payment using a simple checkout process. Easily add or remove payment methods directly from the portal, giving you control over how payments are made. All approval files and receipts are available within the portal for easy reference and record-keeping, ensuring payments align with project progress while helping maintain budget control.": "Les paiements en continu offrent flexibilité et transparence en vous permettant de payer au fur et à mesure que le travail est terminé. Après avoir approuvé le devis, vous serez invité à ajouter un mode de paiement, garantissant ainsi que vous pouvez rapidement approuver et payer les éléments au fur et à mesure de leur réalisation. Accédez au tableau de bord de progression de votre projet dans le portail pour suivre les travaux en cours, examiner les éléments terminés et les approuver pour le paiement via un processus de paiement simple. Ajoutez ou supprimez facilement des modes de paiement directement depuis le portail, vous offrant ainsi un contrôle total sur la manière dont les paiements sont effectués. Tous les fichiers d'approbation et les reçus sont disponibles dans le portail pour une référence facile et une tenue de dossiers, garantissant que les paiements sont alignés sur l'avancement du projet tout en aidant à maintenir le contrôle du budget.",
      "Flexible Payments, also known as Percentage-Based Payments, provide a structured yet adaptable approach by aligning payments with project milestones. After approving the estimate, you’ll add a payment method, enabling you to make payments as each milestone is reached. Use the Progress Dashboard in the portal to monitor completion percentages, review milestone details, and approve payments through a straightforward checkout process. The portal allows you to manage your payment methods, ensuring flexibility and convenience. Approval files and receipts are readily available within the portal, offering clear documentation of each milestone payment and ensuring transparency throughout the project.": "Les paiements flexibles, également appelés paiements basés sur un pourcentage, offrent une approche structurée mais adaptable en alignant les paiements sur les étapes du projet. Après avoir approuvé le devis, vous ajouterez un mode de paiement, vous permettant ainsi d'effectuer des paiements à mesure que chaque étape est atteinte. Utilisez le tableau de bord de progression dans le portail pour surveiller les pourcentages d'achèvement, examiner les détails des étapes et approuver les paiements via un processus de paiement simple. Le portail vous permet de gérer vos modes de paiement, garantissant flexibilité et commodité. Les fichiers d'approbation et les reçus sont facilement accessibles dans le portail, offrant une documentation claire de chaque paiement lié aux étapes du projet et assurant une transparence totale.",
      "Phase-Based Payments allow you to make payments as your project reaches key milestones. Once you approve the estimate, you'll be prompted to add a payment method and gain access to your project portal. From there, you can navigate to the Progress dashboard to review and approve completed project phases. After approving the work for a phase, you’ll receive a notification that it’s time to pay, and the approved items will be added to a checkout process where you can securely submit payment. The portal also lets you manage your payment methods, giving you the flexibility to add or remove them as needed. Additionally, all approval files and receipts are stored in the portal for easy access, ensuring you have complete visibility into your payments and project progress.": "Les paiements basés sur des phases vous permettent d'effectuer des paiements à mesure que votre projet atteint des étapes clés. Une fois le devis approuvé, vous serez invité à ajouter un mode de paiement et à accéder à votre portail de projet. De là, vous pouvez naviguer dans le tableau de bord de progression pour examiner et approuver les phases terminées du projet. Après avoir approuvé le travail d'une phase, vous recevrez une notification indiquant qu'il est temps de payer, et les éléments approuvés seront ajoutés à un processus de paiement sécurisé. Le portail vous permet également de gérer vos modes de paiement, vous offrant la flexibilité d'en ajouter ou d'en supprimer selon vos besoins. De plus, tous les fichiers d'approbation et les reçus sont stockés dans le portail pour un accès facile, garantissant une transparence totale sur vos paiements et l'avancement du projet.",
      "Stage-Based Payments allow you to make payments as your project progresses. Once you approve the estimate, you'll be prompted to add a payment method and gain access to your project portal. From there, you can navigate to the Progress dashboard to review and approve completed project stages. When all items within a stage are approved, they will be added to a checkout process where you can securely submit payment. You’ll receive notifications when it’s time to pay, keeping you informed every step of the way. The portal also allows you to manage your payment methods, giving you the flexibility to add or remove them as needed. Additionally, all approval files and receipts are stored in the portal for easy access, ensuring you have full visibility into your payments and project milestones.": "Les paiements basés sur des étapes vous permettent d'effectuer des paiements à mesure que votre projet progresse. Une fois le devis approuvé, vous serez invité à ajouter un mode de paiement et à accéder à votre portail de projet. De là, vous pouvez naviguer dans le tableau de bord de progression pour examiner et approuver les étapes terminées du projet. Lorsque tous les éléments d'une étape sont approuvés, ils seront ajoutés à un processus de paiement sécurisé. Vous recevrez des notifications lorsqu'il sera temps de payer, vous tenant informé à chaque étape. Le portail vous permet également de gérer vos modes de paiement, vous offrant la flexibilité d'en ajouter ou d'en supprimer selon vos besoins. De plus, tous les fichiers d'approbation et les reçus sont stockés dans le portail pour un accès facile, garantissant une visibilité totale sur vos paiements et les étapes du projet."
};
