// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'item',

  possibleStatuses: ['p', 'd', 'f', 'x', 'q', 'r', 'c'],

  cachedTypes: ['client', 'quote', 'cost_item'],

  fields: {
    item_id: {
      type: 'string',
      reload: true,
      trackChanges: false
    },
    assignee_ids: {
      type: 'array',
      reload: true,
      trackChanges: true,
      save: true
    },
    item_count_bids: {
      type: 'int',
      reload: true,
      save: false,
      trackChanges: false
    },
    aoBids: {
      type: 'array',
      reload: true,
      save: false,
      trackChanges: false,
      mapTo: 'bid'
    },
    client_item_id: {
      type: 'string',
      reload: true,
      trackChanges: false
    },
    item_name: {
      type: 'string',
      reload: true,
      trackChanges: true
    },
    item_type: {
      type: 'string',
      reload: true,
      trackChanges: false
    },
    prime_quote_id: {
      type: 'string',
      reload: true,
      trackChanges: false
    },
    prime_company_id: {
      type: 'string',
      reload: true,
      trackChanges: false
    },
    parent_item_id: {
      type: 'string',
      reload: true,
      trackChanges: false
    },
    children_item_ids: {
      type: 'array',
      reload: true,
      trackChanges: false
    },
    item_status: {
      type: 'string',
      format: 'status',
      reload: true,
      trackChanges: false, // tracked individually
      save: false
    },
    item_company_status: {
      type: 'string',
      format: 'status',
      reload: true,
      trackChanges: false, // tracked individually
      save: false
    },
    item_vendor_status: {
      type: 'string',
      format: 'status',
      reload: true,
      trackChanges: false, // tracked individually
      save: false
    },
    item_client_status: {
      type: 'string',
      format: 'status',
      reload: true,
      trackChanges: false, // tracked individually
      save: false
    },
    item_vendor_is_fully_paid: {
      type: 'int',
      title: 'Vendor has paid?'
    },
    item_client_is_fully_paid: {
      type: 'int',
      title: 'Client has paid?'
    },
    item_company_time_completed: {
      type: 'int',
      format: 'datetime',
      reload: true,
      trackChanges: false
    },
    item_vendor_time_completed: {
      type: 'int',
      format: 'datetime',
      reload: true,
      trackChanges: false, // tracked individually
      save: false
    },
    item_client_time_completed: {
      type: 'int',
      format: 'datetime',
      reload: true,
      trackChanges: false, // tracked individually
      save: false
    },
    item_company_completed_by: {
      type: 'string',
      reload: true,
      trackChanges: false, // tracked individually
      save: false
    },
    item_vendor_completed_by: {
      type: 'string',
      reload: true,
      trackChanges: false, // tracked individually
      save: false
    },
    item_client_completed_by: {
      type: 'string',
      reload: true,
      trackChanges: false, // tracked individually
      save: false
    },
    vendor_id: {
      type: 'string',
      reload: true,
      trackChanges: false
    },
    item_is_contents_visible: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    item_is_vendor_provided: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    company_id: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    quote_id: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    change_order_id: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    cost_type_id: {
      type: 'string',
      reload: true,
      trackChanges: false
    },
    assembly_id: {
      type: 'string',
      reload: true,
      trackChanges: false
    },
    stage_id: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    trade_type_id: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    unit_of_measure_id: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    item_show_itemized_prices: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    item_time_created: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    item_creator: {
      type: 'string',
      reload: true,
      trackChanges: false
    },
    item_qty_net: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_total_cost_net: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_price_net: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_is_optional: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    item_total_hours: {
      type: 'float',
      reload: true,
      format: 'hours',
      trackChanges: false
    },
    oMore: {
      type: 'object',
      reload: true,
      trackChanges: false
    },
    item_prepayment_is_required: {
      type: 'int',
      reload: true,
      trackChanges: false
    },
    item_time_last_modified: {
      type: 'int',
      format: 'datetime',
      reload: true,
      trackChanges: false
    },
    keep_tax_id: {
      type: 'int',
      mapTo: 'tax',
      reload: true,
      trackChanges: false
    },
    tax_id: {
      type: 'string',
      reload: true,
      trackChanges: false
    },
    keep_tax_percentage: {
      type: 'percentage',
      reload: true,
      trackChanges: false
    },
    item_keep_net: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_tax: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_keep_tax: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_keep_gross: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    cost_tax_percentage: {
      type: 'percentage',
      reload: true,
      trackChanges: false
    },
    item_cost_net: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_cost_tax: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_cost_gross: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_actual_total_cost_gross: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_actual_total_cost_net: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_actual_total_cost_tax: {
      type: 'float',
      format: 'currency',
      reload: true,
      trackChanges: false
    },
    item_company_approved_time: {
      type: 'int',
      format: false,
      reload: true,
      trackChanges: false,
      save: false
    },
    item_client_approved_time: {
      type: 'int',
      format: false,
      reload: true,
      trackChanges: false,
      save: false
    },
    item_company_has_approved: {
      type: 'int',
      format: false,
      reload: true,
      trackChanges: false,
      save: false
    },
    item_client_has_approved: {
      type: 'int',
      format: false,
      reload: true,
      trackChanges: false,
      save: false
    },
    item_is_booked: {
      type: 'int',
      format: false,
      reload: true,
      trackChanges: false,
      save: false
    },

    item_company_approved_by: {
      type: 'int',
      format: false,
      reload: true,
      trackChanges: false,
      save: false
    },
    item_client_approved_by: {
      type: 'int',
      format: false,
      reload: true,
      trackChanges: false,
      save: false
    },

    item_approved_terms: {
      type: 'int',
      format: false,
      reload: true,
      trackChanges: false,
      save: false
    },

    item_declined_reason: {
      type: 'int',
      format: false,
      reload: true,
      trackChanges: false,
      save: false
    },
    item_vendor_is_assigned: {
      type: 'int',
      format: false,
      reload: true,
      trackChanges: false,
      save: false,
      default: 0
    },
    aoAssignees: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: 'itemAssignee',
      deep: false,
      normalize: false,
      default: () => []
    },
    item_priority: {
      type: 'int',
      reload: true,
      trackChanges: true,
      default: 0
    }
  },

  getComputedDependants() {
    return {}
  },

  getFieldDependencies() {
    return {}
  },

  getParentDependencies() {
    return {}
  },

  generateVueActions() {
    return {
      async saveActualCost({ dispatch }, payload) {
        const { materials, labor, id, object } = payload

        return dispatch('ajax', {
          path: `/item/saveActualCost/${id}/${labor || 0}/${materials || 0}`,
          data: {
            object
          }
        })
      },

      async markVendorFullyPaid({ dispatch }, payload) {
        const { store = 'Item' } = payload

        const { set: resolvedSet } = await dispatch(`${store}/resolveObject`, payload, {
          root: true
        })
        const selected = resolvedSet.map((resolved) => resolved.item_id)
        return dispatch('ajax', {
          path: `${store}/markVendorFullyPaid`,
          data: selected
        })
      },

      async forceSave({ dispatch }, payload) {
        const { id, changes } = payload
        if (!id) throw new Error('Must provide an item id')
        if (!changes) throw new Error('Must provide a change object')
        const { object } = await dispatch(
          'Item/resolveObject',
          {
            id
          },
          {
            root: true
          }
        )
        if (!object || !object.quote_id) throw new Error('Could not resolve object')
        return dispatch('ajax', {
          path: 'Item/forceSave',
          data: {
            item_id: object.item_id,
            quote_id: object.quote_id,
            changes
          }
        })
      }
    }
  }
}
