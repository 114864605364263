import { ref, onMounted, onUnmounted, getCurrentInstance, computed, watch } from 'vue'

export const useLayerSelect = ({ takeoff, activeTool }) => {
  const $this = getCurrentInstance().proxy

  const selectedLayerIds = ref([])

  const selectLayer = (layerId, add = false) => {
    if (add) return addSelectLayer(layerId)
    selectedLayerIds.value = [layerId]
  }

  const addSelectLayer = (layerId) => {
    selectedLayerIds.value = [...selectedLayerIds.value, layerId]
  }

  const layerIndexById = computed(() => {
    return takeoff.layers.reduce((acc, layer, index) => {
      acc[layer.id] = index
      return acc
    }, {})
  })

  const selectedLayers = computed(() => {
    const ls = _.imm(takeoff.layers)
    return selectedLayerIds.value.map((id) => ls[layerIndexById.value[id]])
  })

  watch(
    selectedLayers,
    (ls = []) => {
      // check if every layer as the same type
      if (ls.length && _.uniq(ls.map((l) => l?.type)).length === 1 && ls[0]) {
        activeTool.value = ls[0].type
      }
    },
    { deep: true }
  )

  const selectedLayersById = computed(() => {
    const ids = {}
    selectedLayers.value.forEach((l, index) => {
      ids[l.id] = l
    })
    return ids
  })

  const deselectAll = () => {
    selectedLayerIds.value = []
  }

  const canSelect = computed(
    () => !activeTool.value || activeTool.value === 'navigate' || activeTool.value === 'select'
  )
  const handleClick = ({ layer, event }) => {
    if (!canSelect.value) return
    if (!layer) {
      activeTool.value = 'navigate'
      deselectAll()
      return
    }

    if (event.shiftKey) {
      addSelectLayer(layer.id)
    } else {
      selectLayer(layer.id)
    }
    activeTool.value = null
  }

  onMounted(() => {
    $this.$on('clickCanvas', handleClick)
  })
  onUnmounted(() => {
    $this.$off('clickCanvas', handleClick)
  })

  return {
    selectedLayers,
    selectedLayersById,
    selectedLayerIds,
    selectLayer,
    deselectAll
  }
}
