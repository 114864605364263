<template>
  <div
    v-if="position"
    class="absolute z-50 pointer-events-auto"
    :style="{
      right: `20px`,
      top: `20px`
    }"
  >
    <!-- wrapper to save mouse over movement -->
    <div
      class="p-2 bg-transparent -m-2 w-fit h-fit"
      @mouseover.stop.capture="handleMouseover"
      @mouseout.stop.capture="handleMouseout"
    >
      <div
        class="ring-2 ring-flame-white bg-surface-800 flex flex-col rounded overflow-hidden cursor-pointer w-8 shadow-lg pointer-events-auto"
      >
        <template v-for="(action, index) in actions">
          <div
            :key="index"
            v-if="selectedLayers.every(action.ifFn)"
            v-tooltip="action.tooltip"
            @mousedown.stop.capture.prevent="() => {}"
            @mouseup.stop.capture.prevent="() => {}"
            @click.stop.capture.prevent="() => handleButtonClick(action)"
            :class="btnClass(action)"
          >
            <font-awesome-icon :icon="action.icon" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, ref, watch, onMounted, onBeforeUnmount, inject, toRefs } from 'vue'
import { useStore } from 'vuex'

// Configure for Vue 3
defineOptions({
  compatConfig: { MODE: 3 }
})

const { selectedLayers, viewportRef } = inject('takeoff')

const takeoff = defineModel('takeoff')

const emit = defineEmits(['edit', 'hovering'])

const selectedIds = computed(() => selectedLayers.value.map((l) => l.id))

const hasMultiplyBlend = computed(() => {
  return selectedLayers.value.some((layer) => layer.image?.blend === 'multiply')
})

const handleButtonClick = (action) => {
  if (action.modifyFn) return modifyLayers(action.modifyFn, action.ifFn)
  if (action.action) return action.action(selectedLayers.value)
}

const modifyLayers = (changeFn, ifFn = (l) => true) => {
  const lyrs = [...takeoff.value.layers]
  for (let i = 0; i < lyrs.length; i++) {
    const layer = lyrs[i]
    if (!selectedIds.value.includes(layer.id)) continue
    if (!ifFn(layer)) continue
    lyrs[i] = { ...layer, ...changeFn(layer) }
  }
  takeoff.value.layers = lyrs
}

const { editMode: areaEditMode, setEditMode: setAreaEditMode } = inject('areaLayerState')
const { editMode: lineEditMode, setEditMode: setLineEditMode } = inject('lineLayerState')
const { scaleLayer } = inject('layerState')

const actions = {
  notClosed: {
    icon: 'bone-break',
    ifFn: (layer) => layer.type === 'line' && !layer.isExplicitlyClosed,
    tooltip: `Path not closed, cannot calculate area or outside perimeter.`,
    class: () => 'bg-deep-red-400'
  },
  markup: {
    icon: 'ghost',
    ifFn: (layer) => layer.type === 'markup',
    modifyFn: (layer) => ({
      image: {
        ...(layer.image || {}),
        blend: layer.image?.blend === 'multiply' ? 'normal' : 'multiply'
      }
    }),
    tooltip: `Toggle multiply blend mode`
  },
  showHideBg: {
    icon: 'ghost',
    ifFn: (layer) => layer.type === 'image',
    modifyFn: (layer) => ({
      image: {
        ...(layer.image || {}),
        blend: layer.image?.blend === 'multiply' ? 'normal' : 'multiply'
      }
    }),
    tooltip: `Toggle see-through mode`,
    class: (layer) => (layer.image?.blend === 'multiply' ? 'bg-blue-print-400' : '')
  },
  scale: {
    icon: 'arrow-up-right-and-arrow-down-left-from-center',
    ifFn: (layer) => layer.type === 'image',
    action: ([layer]) => scaleLayer(layer),
    tooltip: `Re-scale this image`,
    class: (layer) => (layer.scaledTo ? 'bg-blue-print-400' : 'bg-deep-red-400')
  },
  addDot: {
    icon: 'location-plus',
    ifFn: (layer) => layer.type === 'area',
    action: () => setAreaEditMode('add'),
    tooltip: `Add points to the area`,
    class: () => (areaEditMode.value === 'add' ? 'bg-blue-print-500' : '')
  },
  minusDot: {
    icon: 'location-minus',
    ifFn: (layer) => layer.type === 'area',
    action: () => setAreaEditMode('remove'),
    tooltip: `Remove points from the area`,
    class: () => (areaEditMode.value === 'remove' ? 'bg-blue-print-500' : '')
  },

  addSegment: {
    icon: 'location-plus',
    ifFn: (layer) => layer.type === 'line',
    action: () => setLineEditMode('edit'),
    tooltip: `Add segments to this line`,
    class: () => (lineEditMode.value === 'add' ? 'bg-blue-print-500' : '')
  },
  minusSegment: {
    icon: 'location-minus',
    ifFn: (layer) => layer.type === 'line',
    action: () => setLineEditMode('remove'),
    tooltip: `Remove segments from the line`,
    class: () => (lineEditMode.value === 'remove' ? 'bg-blue-print-500' : '')
  }
}

const btnClass = computed(
  () => (action) =>
    [
      'p-1 text-white text-sm w-8 h-8 border-b border-surface-300 flex items-center justify-center hover:bg-blue-print-400 transition-colors',
      typeof action.class === 'function' ? action.class(selectedLayers.value[0]) : action.class
    ].filter(Boolean)
)

const hovering = ref(false)
const handleMouseover = () => {
  hovering.value = true
  emit('hovering', true)
}
const handleMouseout = () => {
  hovering.value = false
  emit('hovering', false)
}

// Position calculation
const position = ref(null)

const updatePosition = () => {
  if (!selectedLayers.value.length || !viewportRef.value) return

  // Calculate bounding box of all selected layers
  const bbox = selectedLayers.value.reduce((box, layer) => {
    let layerBBox = null

    if (layer.type === 'image') {
      // For image layers, use the position directly
      const { x, y, width, height } = layer.position
      layerBBox = { x, y, width, height }
    } else if (layer.points?.length || layer.segments?.length) {
      // For area layers, calculate from points
      layerBBox = layer.position
    } else if (layer.paths?.length) {
      // For markup layers, parse SVG paths to find bounds
      const bounds = layer.paths.reduce(
        (pathBox, pathData) => {
          // Split path into commands
          const commands = pathData.split(/(?=[MLCz])/i)
          const points = []

          commands.forEach((cmd) => {
            // Extract numbers from command
            const nums = cmd
              .slice(1)
              .trim()
              .split(/[\s,]+/)
              .map(Number)

            if (cmd[0] === 'M' || cmd[0] === 'L') {
              // Move or Line commands have x,y pairs
              for (let i = 0; i < nums.length; i += 2) {
                points.push({ x: nums[i], y: nums[i + 1] })
              }
            } else if (cmd[0] === 'C') {
              // Cubic bezier has three points: two control points and end point
              // We only need the end point for bounding box
              points.push({ x: nums[4], y: nums[5] })
            }
          })

          // Calculate bounds from points
          if (points.length) {
            const xs = points.map((p) => p.x)
            const ys = points.map((p) => p.y)
            return {
              x: Math.min(pathBox.x, Math.min(...xs)),
              y: Math.min(pathBox.y, Math.min(...ys)),
              width: Math.max(pathBox.width, Math.max(...xs) - Math.min(...xs)),
              height: Math.max(pathBox.height, Math.max(...ys) - Math.min(...ys))
            }
          }
          return pathBox
        },
        { x: Infinity, y: Infinity, width: -Infinity, height: -Infinity }
      )

      if (bounds.x !== Infinity) {
        layerBBox = bounds
      }
    }

    if (!layerBBox) return box

    if (!box) return layerBBox

    // Combine bounding boxes
    const right = Math.max(box.x + box.width, layerBBox.x + layerBBox.width)
    const bottom = Math.max(box.y + box.height, layerBBox.y + layerBBox.height)
    return {
      x: Math.min(box.x, layerBBox.x),
      y: Math.min(box.y, layerBBox.y),
      width: right - Math.min(box.x, layerBBox.x),
      height: bottom - Math.min(box.y, layerBBox.y)
    }
  }, null)

  if (!bbox) return

  // Get viewport element's bounding rect
  const viewportRect = viewportRef.value.getBoundingClientRect()

  // Convert SVG coordinates to screen coordinates
  const svgPoint = viewportRef.value.querySelector('svg').createSVGPoint()
  const matrix = viewportRef.value.querySelector('svg').getScreenCTM()

  svgPoint.x = bbox.x + bbox.width
  svgPoint.y = bbox.y
  const screenPoint = svgPoint.matrixTransform(matrix)

  // Position the controls at the top right of the bounding box
  position.value = {
    x: Math.min(viewportRect.right - 50, screenPoint.x + 35),
    y: Math.max(viewportRect.top + 8, screenPoint.y + 80)
  }
}

// Update position when selection changes
watch(() => [takeoff, takeoff], updatePosition, { deep: true })

// Update position on scroll/resize
let rafId = null
const handleViewportChange = () => {
  if (rafId) return
  rafId = requestAnimationFrame(() => {
    updatePosition()
    rafId = null
  })
}

onMounted(() => {
  window.addEventListener('resize', handleViewportChange)
  window.addEventListener('scroll', handleViewportChange)
  updatePosition()
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleViewportChange)
  window.removeEventListener('scroll', handleViewportChange)
  if (rafId) cancelAnimationFrame(rafId)
})
</script>
