import { computed, getCurrentInstance, onMounted, onUnmounted, ref, watch, provide } from 'vue'
import { v4, v4 as uuidv4 } from 'uuid'
import { useSnapping } from '@/components/Takeoff/composables/layers/useSnapping.js'
import { useSegmentPaths } from '@/components/Takeoff/composables/layers/useSegmentPaths.js'
import { useCalculations } from '@/components/Takeoff/composables/useCalculations.js'

export function useLineLayer({
  updateLayer,
  layers,
  activeTool,
  zoom,
  selectLayer,
  selectedLayers,
  viewport,
  addLayer,
  takeoff
}) {
  const $this = getCurrentInstance().proxy

  const editingLayer = ref(null)
  const selectedPointIndex = ref(null)
  const draggingPointIndex = ref(null)
  const editingSegment = ref(null)
  const isDrawingWalls = ref(false)
  const lastPoint = ref(null)
  const previewSegment = ref(null)
  const hoverSegment = ref(null)

  const getSegmentIndexById = (id) => {
    return currentLayer.value?.segments?.findIndex((l) => l?.[0]?.sid === id) ?? -1
  }

  watch(selectedLayers, () => {
    const sel = selectedLayers.value?.[0]
    if (selectedLayers.value?.length === 1 && sel?.type === 'line' && activeTool.value === 'line') {
      editingLayer.value = sel.id
    }
  })

  // Create edit mode state if this is the provider instance
  const editMode = ref('edit')

  const setEditMode = (mode) => {
    editMode.value = mode === editMode.value ? 'edit' : mode
  }

  provide('lineLayerState', {
    editMode: editMode,
    setEditMode
  })

  watch(
    selectedLayers,
    (sel) => {
      if (editingLayer.value && !sel.find((l) => l.id === editingLayer.value)) {
        editingLayer.value = null
        selectedPointIndex.value = null
        draggingPointIndex.value = null
        editMode.value = 'edit' // Reset to default mode when selection changes
        editingSegment.value = null
      }
    },
    { deep: true }
  )

  watch(activeTool, (newTool) => {
    if (newTool !== 'line') {
      cancelWallDrawing()
      editingLayer.value = null
    }
  })

  watch(editMode, (newMode) => {
    if (newMode !== 'edit') {
      cancelWallDrawing()
    }
  })

  const addLineLayer = (data = {}) => {
    const lyr = addLayer('line', {
      id: uuidv4(),
      type: 'line',
      segments: data.segments || [],
      position: {
        ...data.position
      },
      calcs: {},
      dims: {},
      metadata: {
        isComplete: data.metadata?.isComplete || false,
        length: data.metadata?.length || 0,
        centroid: data.metadata?.centroid || null,
        ...data.metadata
      },
      zIndex: layers.value.length,
      ...data
    })

    return lyr
  }

  const { getPathData: getSegmentPathData } = useSegmentPaths({ zoom })

  const { splitSegmentsAtIntersections } = useCalculations({ zoom })

  const updateLineSegments = (layerId, segments) => {
    const layer = layers.value.find((l) => l.id === layerId)
    if (!layer) return

    // Ensure segments is an array
    if (!Array.isArray(segments)) {
      console.warn('updateLineSegments received non-array input:', segments)
      return
    }

    layer.segments = segments.map((s, i) => {
      const sidx = i
      const lid = layerId

      s[0].sid = s[0].sid ?? uuidv4()
      s[1].sid = s[0].sid
      s[0].sidx = sidx
      s[0].lid = lid
      s[1].sidx = sidx
      s[1].lid = lid
      return [s[0], s[1]]
    })
    layer.metadata ??= {}

    updateLayer(layer.id, layer)
  }

  const completeLine = (layerId) => {
    const layer = layers.value.find((l) => l.id === layerId)
    if (!layer) return

    layer.metadata.isComplete = true

    updateLayer(layer.id, layer)
  }

  const lineLayers = computed(() => layers.value.filter((layer) => layer.type === 'line'))

  const proximityRange = computed(() => 5 / (zoom.value || 1))

  const getLayer = () => layers.value.find((l) => l.id === editingLayer.value)
  const currentLayer = computed(() => getLayer())

  const isPointEvent = ({ svgPoint, element, layer: providedLayer = null }) => {
    return (
      editingSegment.value === null &&
      activeTool.value !== 'navigate' &&
      element &&
      element.hasAttribute('data-segment-index') &&
      providedLayer &&
      selectedLayers.value.find((l) => l.id === providedLayer.id)
    )
  }

  const { updatePointToSnap, snapping } = useSnapping({
    layers,
    zoom
  })

  const resetPointDrag = () => {
    selectedPointIndex.value = null
    draggingPointIndex.value = null
  }

  const addSegment = (startPoint, endPoint) => {
    const layer = getLayer()
    if (!layer) return

    const newSegment = [startPoint, endPoint]
    const newSegments = [...layer.segments, newSegment]
    // Split segments at intersections before updating
    const splitSegments =
      newSegments.length > 1 ? splitSegmentsAtIntersections(newSegments, newSegment) : newSegments
    updateLineSegments(layer.id, splitSegments)
    return splitSegments.length - 1
  }

  const removeSegment = (segmentIndex) => {
    const layer = getLayer()
    if (!layer) return

    if (segmentIndex === -1 || !layer.segments.length || !layer.segments[segmentIndex]) return

    const newSegments = [...layer.segments]
    newSegments.splice(segmentIndex, 1)
    updateLineSegments(layer.id, newSegments)
    return newSegments.length - 1
  }

  const startWallDrawing = (point) => {
    let layer
    if (!editingLayer.value) {
      layer = addLineLayer({
        segments: []
      })
      editingLayer.value = layer.id
      selectLayer(layer.id)
    }

    isDrawingWalls.value = true

    const newPoint = updatePointToSnap(point)

    lastPoint.value = newPoint
    previewSegment.value = [newPoint, newPoint]
  }

  const updatePreview = (point) => {
    // Create a list of possible snap positions
    const newPoint = updatePointToSnap(
      point,
      currentLayer.value?.segments[editingSegment.value] ?? null,
      lastPoint.value
    )

    previewSegment.value = [lastPoint.value ?? newPoint, newPoint]

    return point
  }

  // Add computed preview length
  const previewLength = computed(() => {
    if (!previewSegment.value || !previewSegment.value[0] || !previewSegment.value[1]) return null

    const [start, end] = previewSegment.value
    const pixelLength = Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2))

    // Convert to physical length using scale
    if (!takeoff.scale?.pixels || !takeoff.scale?.length) return null
    return c.format((pixelLength / takeoff.scale.pixels) * takeoff.scale.length, 'number')
  })

  const continueWallDrawing = (point) => {
    if (!lastPoint.value) return

    // Get the snapped point from updatePreview
    updatePreview(point)
    const newPoint = updatePointToSnap(
      point,
      currentLayer.value?.segments?.[editingSegment.value - 1] ?? null,
      lastPoint.value
    )

    // Add the completed segment using the snapped point
    addSegment(lastPoint.value, newPoint)

    // Set up for the next segment
    lastPoint.value = newPoint
    previewSegment.value = [newPoint, newPoint]
  }

  const cancelWallDrawing = () => {
    // If we have an empty layer, remove it
    isDrawingWalls.value = false
    lastPoint.value = null
    previewSegment.value = null
    editingSegment.value = null

    // const layer = getLayer()
    // if (layer?.segments?.length) removeSegment(layer.segments.length - 1)
    //
    // // If we have an empty layer, remove it
    // // const layer = getLayer()
    // if (layer && (!layer.segments || layer.segments.length === 0)) {
    //   layers.value = layers.value.filter((l) => l.id !== layer.id)
    // }
  }

  const handleMouseDown = (event) => {
    if (activeTool.value !== 'line') return

    const { svgPoint, element, layer: eventLayer } = event

    const newPoint = updatePointToSnap(svgPoint)
    let snapElement = viewport.findElementAtPoint(svgPoint)
    let newElement = snapElement || element
    const snapLayer =
      newPoint.snapLayerId && layers.value.find((l) => l.id === newPoint.snapLayerId)

    if (selectedLayers.value?.[0]?.type !== 'line' && snapLayer?.type === 'line') {
      selectLayer(snapLayer.id)
      editingLayer.value = snapLayer.id
      return
    }

    if (event?.event?.button === 0 && editMode.value !== 'remove') {
      if (!isDrawingWalls.value) {
        startWallDrawing(svgPoint)
      } else {
        continueWallDrawing(svgPoint)
      }
    } else if (
      svgPoint &&
      ((event?.event?.button === 2 && !isDrawingWalls.value) || editMode.value === 'remove') &&
      newElement?.hasAttribute('data-segment-index')
    ) {
      const newPoint = updatePointToSnap(svgPoint)
      updatePreview(newPoint)
      lastPoint.value = newPoint
      const sid = newElement.getAttribute('data-segment-id')
      const lid = newElement.getAttribute('data-layer-id')

      if (lid !== currentLayer.value.id) return
      const sidx = getSegmentIndexById(sid)

      removeSegment(sidx)
    } else if (event?.event?.button === 2 && editMode.value === 'edit') {
      cancelWallDrawing()
    }
  }

  const handleMouseoverRemove = (event) => {
    const { element } = event

    if (element?.hasAttribute('data-segment-index')) {
      hoverSegment.value = +element.getAttribute('data-segment-index')
    }
  }

  const handleMouseMove = (event) => {
    if (activeTool.value !== 'line') return
    if (editMode.value === 'remove') handleMouseoverRemove(event)

    const { svgPoint } = event
    updatePreview(svgPoint)
  }

  const selectPoint = (pointIndex) => {
    selectedPointIndex.value = pointIndex
  }

  onMounted(() => {
    $this.$on('mousedownCanvas', handleMouseDown)
    $this.$on('mousemoveCanvas', handleMouseMove)
  })

  onUnmounted(() => {
    $this.$off('mousedownCanvas', handleMouseDown)
    $this.$off('mousemoveCanvas', handleMouseMove)
  })

  const getPathData = (layer) => {
    return getSegmentPathData(
      layer,
      layers.value,
      editingLayer.value && isDrawingWalls.value && previewSegment.value
        ? previewSegment.value
        : false
    )
  }

  const getOutsideLength = (layer) => {
    return 0
  }

  const getInsideLength = (layer) => {}
  const getInsidePerimeter = (layer) => {}
  const getArea = (layer) => {}

  return {
    getOutsideLength,
    snapping,
    selectPoint,
    addLineLayer,
    updateLineSegments,
    completeLine,
    lineLayers,
    getPathData,
    selectedPointIndex,
    editMode,
    setEditMode,
    isDrawingWalls,
    previewSegment,
    previewLength
  }
}
