import { ref, computed, onMounted, onUnmounted, watch, provide, reactive, toRaw } from 'vue'
import { useStore } from 'vuex'
import { useCanvasEvents } from './composables/useCanvasEvents'
import { usePinchEvents } from './composables/usePinchEvents'
import { useResizeEvents } from './composables/useResizeEvents'
import { useWheelEvents } from './composables/useWheelEvents'
import { useViewport } from './composables/useViewport'
import { v4 } from 'uuid'
import { usePan } from '@/components/Takeoff/composables/usePan.js'
import { useLayerSelect } from '@/components/Takeoff/composables/useLayerSelect.js'
import { useLayerDrag } from '@/components/Takeoff/composables/useLayerDrag.js'
import { useMeasure } from '@/components/Takeoff/composables/useMeasure.js'
import { useLayers } from '@/components/Takeoff/composables/useLayers.js'
import { useHistory } from '@/components/Takeoff/composables/useHistory.js'
import { useFileSaving } from '@/components/Takeoff/composables/useFileSaving.js'

export const useTakeoff = ({ fileId, refId, store, emit }) => {
  const vuexStore = useStore()

  // State
  const isAnalyzing = ref(false)
  const error = ref(null)
  const activeTool = ref('navigate')

  const viewportRef = ref(null)
  const svgRef = ref(null)
  const refGridBackdrop = ref(null)
  const refScaler = ref(null)

  // const layers = ref([])
  // const rooms = ref([])
  const takeoff = reactive({
    layers: [],
    ignoreLinks: {},
    rooms: [],
    scale: {
      pixels: 20,
      length: 1,
      unit: 'ft'
    }
  })

  // Initialize viewport composable
  const viewport = useViewport({ viewportRef, svgRef })
  const { zoom, panOffset, viewBox, zoomIn, zoomOut, canvasSize, zoomToFit, getContentBounds } =
    viewport

  const showViewer = computed(() => takeoff.layers.length > 0 || takeoff.rooms.length > 0)

  // Local state for event handlers
  const handlers = ref({
    pinchHandler: null,
    wheelHandler: null,
    updateContainerSize: null
  })

  const setActiveTool = (tool) => {
    deselectAll()
    activeTool.value = tool
  }
  const contentBounds = computed(() => {
    return getContentBounds(takeoff)
  })

  const getExportTakeoff = () => {
    const raw = toRaw(takeoff)
    return JSON.stringify(raw)
  }

  let triggerAudit = () => {}
  // Import/Export methods
  const exportData = () => {
    const raw = getExportTakeoff()
    // Copy to clipboard
    navigator.clipboard
      .writeText(raw)
      .then(() => {
        alert('Takeoff data copied to clipboard!')
      })
      .catch((err) => {
        console.error('Failed to copy to clipboard:', err)
        // Fallback: show the data in the textarea
        return raw
      })
  }

  const importData = (json) => {
    try {
      const data = JSON.parse(json)
      // TODO: Implement import in useTakeoff composable
      Object.assign(takeoff, data)
      zoomToFit(takeoff)
      c.throttle(() => zoomToFit(takeoff), { delay: 50 })
      triggerAudit()
    } catch (error) {
      console.error('Import error:', error)
      alert('Invalid takeoff data format or error during import')
    }
  }

  const { selectLayer, deselectAll, selectedLayers, selectedLayersById } = useLayerSelect({
    takeoff,
    activeTool
  })
  const {
    redo,
    undo,
    history,
    initialized: historyInitialized,
    addToHistory
  } = useHistory({
    takeoff,
    importData,
    exportData,
    getExportTakeoff,
    deselectAll,
    emit,
    triggerAudit
  })

  const { transforms: dragTransforms, isDragging: isDraggingLayersOnCanvas } = useLayerDrag({
    takeoff,
    activeTool,
    selectedLayers,
    zoom,
    selectedLayersById
  })
  const {
    getScale,
    isScalingMode,
    isMeasuring,
    startPoint,
    previewPoint,
    linePath,
    textPosition,
    distance
  } = useMeasure({
    activeTool,
    takeoff,
    zoom,
    refScaler
  })
  const {
    addImageLayer,
    updateImageAnalysis,
    imageLayers,
    loadFiles,
    addImageLayerByFileId,
    addAreaLayer,
    getAreaPathData,
    getLinePathData,
    selectedPointIndex,
    textEditingLayer,
    isEditingText,
    textLayer,
    editingText,
    snapping,
    getOutsideLength,
    isDrawingWalls,
    lineEditMode,
    triggerAudit: ta,
    addLineLayer,
    addCountLayer,
    updateLayer,
    previewLength
  } = useLayers({
    getScale,
    selectLayer,
    selectedLayers,
    takeoff,
    activeTool,
    isMeasuring,
    viewBox,
    zoom,
    svgRef,
    viewport,
    refScaler,
    emit,
    historyInitialized,
    addToHistory
  })
  triggerAudit = ta

  const blueprint400 = c.getCssColor('blue-print-400')
  const blueprint400alpha = c.hexWithOpacity(c.rgbToHex(blueprint400), 0.4)
  const blueprint700 = c.getCssColor('blue-print-700')

  // Computed
  const sortedLayers = computed(() => {
    return _.imm(takeoff.layers).map((l) => {
      l.selected = l.id in selectedLayersById.value
      const dims = l.dims ?? {}
      l.showAreaPath = !Object.keys(dims).length || 'area_total' in dims
      l.showOutsideLengths =
        !Object.keys(dims).length ||
        'segment_length_total' in dims ||
        'segment_length_outside' in dims ||
        'perimeter_inside' in dims
      l.showInsideLengths =
        !Object.keys(dims).length ||
        'segment_length_total' in dims ||
        'segment_length_inside' in dims ||
        'perimeter_inside' in dims

      const getColor = (calcKeys) => {
        let links = []
        for (const calcKey of calcKeys) {
          links = [...links, ...(dims[calcKey]?.links ?? [])]
        }
        const colors = _.flatMap(links, 'color').filter(Boolean)
        if (colors.length > 2) {
          return c.blendColors(...colors.slice(0, 2))
        }
        if (colors.length) {
          return colors[0]
        }

        return null
      }

      const acol = getColor(['area_total'])
      l.areaColor = (acol && c.hexWithOpacity(acol, 0.2)) ?? blueprint400alpha
      l.insideLineColor =
        getColor(['perimeter_inside', 'segment_length_inside', 'segment_length_total']) ??
        blueprint400
      l.outsideLineColor =
        getColor(['segment_length_outside', 'perimeter_inside', 'segment_length_total']) ??
        blueprint700
      l.countColor = getColor(['count_total']) ?? blueprint400

      return l
    })
  })

  const updateLayerPosition = (layerId, newPosition) => {
    const layer = takeoff.layers.find((l) => l.id === layerId)
    if (layer) {
      layer.position = newPosition
    }
    addToHistory(takeoff)
  }

  const removeLayer = async (layerId) => {
    const layer = takeoff.layers.find((l) => l.id === layerId)
    if (
      (layer.segments?.length ||
        layer.points?.length ||
        layer.lines?.length ||
        Object.keys(layer.dims ?? {}).length) &&
      !(await vuexStore.dispatch('modal/asyncConfirm', {
        message: `Are you sure you want to delete the layer "${layer.name}" and its takeoff drawings? It will not delete the dimension or the value associated to it in the quote, only the drawings and markups made here.`
      }))
    )
      return

    if (layerId in selectedLayersById.value) deselectAll()
    emit('layer-deleted', layer)
    takeoff.layers = takeoff.layers.filter((l) => l.id !== layerId)
    addToHistory(takeoff)
  }

  const updateLayerName = (layerId, newName) => {
    const layer = takeoff.layers.find((l) => l.id === layerId)
    if (layer) {
      layer.name = newName
    }
    addToHistory(takeoff)
  }

  const analyzeLayer = async (layer) => {
    if (!layer.file?.file_id) return

    try {
      isAnalyzing.value = true
      error.value = null

      const response = await vuexStore.dispatch('Takeoff/analyze', {
        file_id: layer.file.file_id
      })

      if (response.rooms) {
        takeoff.rooms = response.rooms
      }
    } catch (err) {
      console.error('Analysis failed:', err)
      error.value = 'Failed to analyze drawing. Please try again.'
    } finally {
      isAnalyzing.value = false
    }
  }

  const exportLayers = () => {
    // Implement export functionality
    console.log('Exporting layers:', takeoff.layers)
  }

  const reorderLayers = (newLayers) => {
    // Update zIndex based on new order
    takeoff.layers = newLayers.map((layer, index) => ({
      ...layer,
      zIndex: index
    }))
  }

  // Initialize all composables and event handlers
  const { handler: pinchHandler } = usePinchEvents({
    zoom,
    updateZoom: viewport.updateZoom,
    screenToSVGPoint: viewport.screenToSVGPoint
  })

  const { updateContainerSize } = useResizeEvents({
    viewportRef,
    containerSize: ref({ width: 1000, height: 1000 }),
    updateViewportSize: viewport.updateViewportSize
  })

  useWheelEvents({
    viewportRef,
    zoom,
    updateZoom: viewport.updateZoom,
    screenToSVGPoint: viewport.screenToSVGPoint,
    updatePanOffset: viewport.updatePanOffset
  })

  const {
    currentLayer,
    currentPoint,
    currentElement,

    hoverElement,
    hoverLayer,

    mousedownPoint,
    lastMousePosition,

    isMousedown
  } = useCanvasEvents({
    takeoff,
    svgRef,
    viewportRef,
    viewport
  })

  const {} = usePan({
    takeoff,
    activeTool,
    refGridBackdrop,
    currentElement,
    viewport
  })

  // Reset state
  const reset = () => {
    takeoff.layers = []
    takeoff.rooms = []
    selectedLayers.value = []
    error.value = null
    isAnalyzing.value = false
    activeTool.value = 'navigate'
    viewport.resetViewport()
  }

  const changeTakeoffUnit = (newUnit) => {
    const oldUnit = takeoff.scale.unit
    const oldLength = takeoff.scale.length
    const oldPixels = takeoff.scale.pixels

    // Convert the length to the new unit
    const newLength = c.convertMeasure(oldLength, oldUnit, newUnit)

    // Calculate new pixels needed to maintain the same physical length
    // oldPixels/oldLength = newPixels/newLength
    // therefore newPixels = (oldPixels * newLength) / oldLength
    const newPixels = oldPixels

    // Update the scale with new values
    takeoff.scale = {
      ...takeoff.scale,
      unit: newUnit,
      pixels: newPixels,
      length: newLength
    }

    // Update any layers that were explicitly scaled
    // if (takeoff.layers) {
    //   for (const layer of takeoff.layers) {
    //     if (layer.scaledTo) {
    //       const scaledLength = c.convertMeasure(layer.scaledTo.length, layer.scaledTo.unit, newUnit)
    //       layer.scaledTo = {
    //         ...layer.scaledTo,
    //         unit: newUnit,
    //         length: scaledLength,
    //         pixels: (layer.scaledTo.pixels * scaledLength) / layer.scaledTo.length
    //       }
    //     }
    //   }
    // }
  }

  const changeTakeoffLength = (rlength) => {
    const newLength = c.toNum(rlength)
    const oldLength = takeoff.scale.length || 1
    const oldPixels = takeoff.scale.pixels || 20

    const newPixels = (newLength / oldLength) * (oldPixels || 20)

    // Update the scale with new values
    takeoff.scale = {
      ...takeoff.scale,
      length: newLength,
      pixels: newPixels
    }

    // Update any layers that were explicitly scaled
    // if (takeoff.layers) {
    //   for (const layer of takeoff.layers) {
    //     if (layer.scaledTo) {
    //       const scaledLength = c.convertMeasure(layer.scaledTo.length, layer.scaledTo.unit, newUnit)
    //       layer.scaledTo = {
    //         ...layer.scaledTo,
    //         unit: newUnit,
    //         length: scaledLength,
    //         pixels: (layer.scaledTo.pixels * scaledLength) / layer.scaledTo.length
    //       }
    //     }
    //   }
    // }
  }

  // Provide takeoff context to child components
  provide('takeoff', {
    // State
    takeoff,
    isAnalyzing,
    error,
    activeTool,
    selectedLayers,
    sortedLayers,
    showViewer,

    // Viewport state
    zoom,
    panOffset,
    viewBox,

    // Methods
    addImageLayer,
    updateImageAnalysis,
    imageLayers,
    loadFiles,
    addImageLayerByFileId,
    selectLayer,
    deselectAll,
    removeLayer,
    analyzeLayer,
    exportLayers,
    setActiveTool: (tool) => (activeTool.value = tool),
    updateLayerPosition,
    updateLayerName,
    screenToSVGPoint: viewport.screenToSVGPoint,
    updateZoom: viewport.updateZoom,
    updatePanOffset: viewport.updatePanOffset,
    reorderLayers,

    // refs
    viewportRef
  })

  const { loaded } = useFileSaving({
    getExportTakeoff,
    importData,
    takeoff,
    refId,
    fileId,
    store,
    autoSave: true
  })

  return {
    refScaler,
    // State
    isAnalyzing,
    error,
    takeoff,
    activeTool,
    selectedLayers,
    sortedLayers,
    handlers,
    showViewer,
    isMousedown,
    dragTransforms,

    isMeasuring,
    startPoint,
    previewPoint,
    linePath,
    textPosition,
    distance,
    isScalingMode,
    getScale,

    isDraggingLayersOnCanvas,
    hoverLayer,
    hoverElement,
    currentLayer,
    currentElement,

    textEditingLayer,
    isEditingText,
    textLayer,
    editingText,

    getAreaPathData,
    selectedPointIndex,

    getLinePathData,

    // Viewport state and methods
    ...viewport,
    zoomIn,
    zoomOut,

    // Methods
    addImageLayer,

    updateImageAnalysis,
    imageLayers,
    loadFiles,
    addImageLayerByFileId,
    selectLayer,
    deselectAll,
    removeLayer,
    analyzeLayer,
    exportLayers,
    reset,
    handleNewTakeoff: reset,
    handleSaveTakeoff: exportLayers,
    setActiveTool,
    updateLayerName,
    reorderLayers,

    // refs
    refGridBackdrop,
    viewportRef,
    svgRef,

    redo,
    undo,
    history,

    importData,
    exportData,

    getOutsideLength,

    snapping,
    isDrawingWalls,
    lineEditMode,
    canvasSize,
    zoomToFit,
    getContentBounds,
    contentBounds,
    addLineLayer,
    addCountLayer,
    updateLayer,
    getExportTakeoff,
    loaded,
    historyInitialized,
    addToHistory,
    changeTakeoffUnit,
    changeTakeoffLength,
    previewLength
  }
}
