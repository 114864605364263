export default {
  root: ({ props, context, parent }) => ({
    class: [
      'relative',

      // Alignments
      'items-center inline-flex text-center align-bottom justify-center shrink-0',

      // Sizes & Spacing
      'leading-none font-medium',

      ...(props.unstyled
        ? []
        : [
            // Sizes & Spacing
            !props.rounded
              ? {
                  'px-1 py-0.5 text-xs': props.size === 'xs' || props.size === 'xsmall',
                  'px-2 py-1.5 text-xs': props.size === 'sm' || props.size === 'small',
                  'py-2 px-3 text-sm':
                    props.size === 'md' || props.size === 'medium' || props.size === 'none',
                  'py-2 px-4 text-base h-12': props.size === 'lg' || props.size === 'large',
                  'py-4 px-6 text-xl': props.size === 'xl' || props.size === 'xlarge',
                  'py-6 px-8 text-2xl': props.size === '2xl',
                  'py-2 md:px-4 md:h-8 md:text-sm h-6 text-xs': props.size === 'item',
                  'py-2 px-4 min-h-10 h-full text-sm': props.size === 'item-price'
                }
              : {},
            // rounded sizing
            props.rounded
              ? {
                  'p-0.5 text-xs size-3 max-w-3 max-h-3 min-w-3 min-h-3':
                    props.size === 'xs' || props.size === 'xsmall',
                  'p-1 text-xs size-5 max-w-5 max-h-5 min-w-5 min-h-5':
                    props.size === 'sm' || props.size === 'small',
                  'p-1.5 text-sm size-8 max-w-8 max-h-8 min-w-8 min-h-8':
                    props.size === 'md' || props.size === 'medium' || props.size === 'none',
                  'p-2 text-base w-10 h-10 max-w-10 max-h-10 min-w-10 min-h-10':
                    props.size === 'lg' || props.size === 'large',
                  'p-4 text-xl w-10 h-10 max-w-10 max-h-10 min-w-10 min-h-10':
                    props.size === 'xl' || props.size === 'xlarge'
                }
              : {},

            // Link Button
            {
              'text-pitch-black bg-transparent border-transparent hover:bg-surface-200': props.link,
              'text-pitch-black bg-transparent border-transparent hover:bg-transparent':
                props.link & context.disabled
            },

            {
              'opacity-75': context.disabled
            },

            // {
            //   'text-xs p-0.5 px-1.5 h-fit': props.size === 'xs' || props.size === 'xsmall',
            //   'text-xs py-1.5 px-3.5 h-fit': props.size === 'sm' || props.size === 'small',
            //   'px-4 py-1.5 h-fit': (props.size === 'md' || props.size === 'medium' || props.size === null),
            //   'text-xl py-3 px-4 h-fit': props.size === 'lg' || props.size === 'large',
            // },
            // Color
            {
              'text-surface-100 bg-pitch-black hover:bg-cool-gray-900 active:bg-pitch-black':
                props.severity === 'primary' && !props.link && !context.disabled,
              'text-blue-print-400 bg-surface-0 border-blue-print-400 border-2 hover:bg-blue-print-100 active:bg-blue-print-100 rounded-sm':
                props.severity === 'info-outline' && !props.link && !context.disabled,
              'text-flame-white dark:text-purple-500/20 ring-purple-500/5 bg-purple-600 dark:bg-purple-300 hover:bg-purple-800':
                props.severity === 'automation' && !props.link && !context.disabled,
              'text-surface-100 bg-blue-print-500 hover:bg-blue-print-500/80 active:bg-pitch-black':
                props.severity === 'blue' && !context.disabled,
              'text-level-yellow bg-pitch-black hover:bg-cool-gray-900 active:bg-pitch-black':
                (props.severity === 'primary-black' || props.severity === 'bolster') &&
                !context.disabled,
              'text-pitch-black bg-level-yellow hover:text-level-yellow hover:bg-pitch-black active:bg-cool-gray-900':
                props.severity === 'primary-yellow' && !context.disabled,
              'text-pitch-black bg-level-yellow hover:text-level-yellow hover:bg-cool-gray-800 active:bg-cool-gray-900':
                props.severity === 'primary-yellow-login' && !context.disabled,
              'text-surface-600 bg-cool-gray-200 hover:text-pitch-black hover:bg-cool-gray-300/80 active:bg-cool-gray-300':
                props.severity === 'secondary' && !context.disabled,
              'text-pitch-black bg-transparent border border-pitch-black hover:text-pitch-black hover:bg-cool-gray-200 active:bg-cool-gray-300':
                props.severity === 'tertiary' && !context.disabled,
              'text-pitch-black bg-transparent hover:text-pitch-black hover:bg-cool-gray-200 active:bg-cool-gray-300':
                props.severity === 'tertiary-borderless' && !context.disabled,
              'text-surface-100 bg-blue-print-400 border border-print-400 hover:bg-blue-print-400/80 active:bg-pitch-black':
                props.severity === 'secondary-blue' && !context.disabled,
              'text-pitch-black bg-white border border-pitch-black hover:bg-cool-gray-100':
                props.severity === 'light' && !context.disabled,
              'text-white bg-pitch-black border border-pitch-black hover:bg-cool-gray-900':
                props.severity === 'dark' && !context.disabled
            },
            {
              'w-12 p-0 py-3': props.label == null && props.icon !== null
            },

            // Shapes
            { 'shadow-lg': props.raised },
            {
              'rounded-sm':
                !(
                  parent.instance?.$name === 'InputGroup' ||
                  parent.instance?.$name === 'ButtonGroup'
                ) && !props.rounded,
              'rounded-full':
                !(
                  parent.instance?.$name === 'InputGroup' ||
                  parent.instance?.$name === 'ButtonGroup'
                ) && props.rounded
            },
            {
              'rounded-none first:rounded-l-sm last:rounded-r-sm':
                parent.instance?.$name === 'InputGroup' || parent.instance?.$name === 'ButtonGroup'
            },

            {
              'text-xs p-0.5 h-fit size-3 max-w-3 max-h-3 min-w-3 min-h-3':
                props.size === 'xs' && props.rounded,
              'text-xs p-1.5 h-fit size-5 max-w-5 max-h-5 min-w-5 min-h-5':
                props.size === 'small' && props.rounded,
              'p-2 h-fit size-8 max-w-8 max-h-8 min-w-8 min-h-8':
                (props.size === 'md' || props.size === null) && !props.unstyled && props.rounded,
              'text-xl p-3 h-fit w-10 h-10 max-w-10 max-h-10 min-w-10 min-h-10':
                props.size === 'large' && props.rounded
            },

            // Link Button
            { 'text-pitch-black bg-transparent border-transparent': props.link },

            // Plain Button
            {
              'text-white bg-gray-500 border border-gray-500':
                props.plain && !props.outlined && !props.text
            },
            // Plain Text Button
            {
              'text-surface-700 p-1 hover:bg-surface-400 hover:text-surface-900 focus:bg-surface-400 focus:text-surface-900 ':
                props.plain && props.text
            },
            // Plain Outlined Button
            { 'text-surface-700 border border-gray-700': props.plain && props.outlined },

            // Text Button
            { 'bg-transparent border-transparent': props.text && !props.plain },

            // Outlined Button
            { 'bg-transparent border': props.outlined && !props.plain },

            // --- Severity Buttons ---

            // Primary Button
            {
              'dark:text-white text-level-yellow':
                !props.link &&
                props.severity === 'bolster' &&
                !props.text &&
                !props.outlined &&
                !props.plain &&
                !props.unstyled,
              'bg-pitch-black dark:bg-primary-400':
                !props.link &&
                props.severity === 'bolster' &&
                !props.text &&
                !props.outlined &&
                !props.plain &&
                !props.unstyled,
              'border border-pitch-black dark:border-primary-400':
                !props.link &&
                props.severity === 'bolster' &&
                !props.text &&
                !props.outlined &&
                !props.plain &&
                !props.unstyled
            },
            // Primary Text Button
            {
              'text-level-yellow dark:text-primary-400':
                props.text && props.severity === 'bolster' && !props.plain
            },
            // Primary Outlined Button
            {
              'text-pitch-black border border-pitch-black hover:bg-pitch-black hover:text-level-yellow':
                props.outlined && props.severity === 'bolster' && !props.plain && !props.unstyled
            },

            // Primary Button
            // {
            //     'dark:text-white text-primary-50': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled,
            //     'bg-primary-900 dark:bg-primary-400': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled,
            //     'border border-primary-900 dark:border-primary-400': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled,
            //     'hover:bg-primary-700': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain && !props.unstyled
            // },
            // Primary Text Button
            {
              'text-primary-500 dark:text-primary-400':
                props.text && props.severity === null && !props.plain
            },
            // Primary Outlined Button
            {
              'text-primary-500 border border-primary-500 hover:bg-primary-300/20':
                props.outlined && props.severity === null && !props.plain && !props.unstyled
            },

            // Secondary Button
            // {
            //     'text-surface-100 dark:text-surface-900 hover:bg-secondary/90 hover:border-secondary/90 ': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
            //     'bg-secondary dark:bg-surface-400': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain,
            //     'border border-secondary dark:bg-secondary': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain
            // },
            // Secondary Text Button
            // { 'text-surface-500 dark:text-surface-300': props.text && props.severity === 'secondary' && !props.plain },
            // Secondary Outlined Button
            // { 'text-secondary dark:text-secondary border border-secondary hover:bg-secondary/20': props.outlined && props.severity === 'secondary' && !props.plain },

            // Success Button
            {
              'text-white dark:text-green-900':
                props.severity === 'success' && !props.text && !props.outlined && !props.plain,
              'bg-green-500 dark:bg-green-400':
                props.severity === 'success' && !props.text && !props.outlined && !props.plain,
              'border border-green-500 dark:border-green-400':
                props.severity === 'success' && !props.text && !props.outlined && !props.plain
            },
            // Success Text Button
            {
              'text-green-500 dark:text-green-400':
                props.text && props.severity === 'success' && !props.plain
            },
            // Success Outlined Button
            {
              'text-green-500 border border-green-500 hover:bg-green-300/20':
                props.outlined && props.severity === 'success' && !props.plain
            },

            // Info Button
            {
              'text-white dark:text-surface-900':
                props.severity === 'info' && !props.text && !props.outlined && !props.plain,
              'bg-blue-500 dark:bg-blue-400':
                props.severity === 'info' && !props.text && !props.outlined && !props.plain,
              'border border-blue-500 dark:border-blue-400':
                props.severity === 'info' && !props.text && !props.outlined && !props.plain
            },
            // Info Text Button
            {
              'text-blue-500 dark:text-blue-400':
                props.text && props.severity === 'info' && !props.plain
            },
            // Info Outlined Button
            {
              'text-blue-500 border border-blue-500 hover:bg-blue-300/20 ':
                props.outlined && props.severity === 'info' && !props.plain
            },

            // Warning Button
            {
              'text-white dark:text-surface-900':
                props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
              'bg-orange-500 dark:bg-orange-400':
                props.severity === 'warning' && !props.text && !props.outlined && !props.plain,
              'border border-orange-500 dark:border-orange-400':
                props.severity === 'warning' && !props.text && !props.outlined && !props.plain
            },
            // Warning Text Button
            {
              'text-orange-500 dark:text-orange-400':
                props.text && props.severity === 'warning' && !props.plain
            },
            // Warning Outlined Button
            {
              'text-orange-500 border border-orange-500 hover:bg-orange-300/20':
                props.outlined && props.severity === 'warning' && !props.plain
            },

            // Help Button
            {
              'text-white dark:text-surface-900':
                props.severity === 'help' && !props.text && !props.outlined && !props.plain,
              'bg-purple-500 dark:bg-purple-400':
                props.severity === 'help' && !props.text && !props.outlined && !props.plain,
              'border border-purple-500 dark:border-purple-400':
                props.severity === 'help' && !props.text && !props.outlined && !props.plain
            },
            // Help Text Button
            {
              'text-purple-500 dark:text-purple-400':
                props.text && props.severity === 'help' && !props.plain
            },
            // Help Outlined Button
            {
              'text-purple-500 border border-purple-500 hover:bg-purple-300/20':
                props.outlined && props.severity === 'help' && !props.plain
            },

            // Danger Button
            {
              'text-white dark:text-surface-900':
                props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
              'bg-red-500 dark:bg-red-400':
                props.severity === 'danger' && !props.text && !props.outlined && !props.plain,
              'border border-red-500 dark:border-red-400':
                props.severity === 'danger' && !props.text && !props.outlined && !props.plain
            },
            // Danger Text Button
            {
              'text-red-500 dark:text-red-400':
                props.text && props.severity === 'danger' && !props.plain
            },
            // Danger Outlined Button
            {
              'text-red-500 border border-red-500 hover:bg-red-300/20':
                props.outlined && props.severity === 'danger' && !props.plain
            },

            // --- Severity Button States ---
            'focus:outline-none focus:outline-offset-0',

            // Link
            // { 'focus:ring-primary-400/50 dark:focus:ring-primary-300/50': props.link },

            // Plain
            {
              'hover:bg-gray-600 hover:border-gray-600':
                props.plain && !props.outlined && !props.text
            },
            // Text & Outlined Button
            { 'hover:bg-surface-300/20': props.plain && (props.text || props.outlined) },

            // Primary
            // { 'hover:bg-primary-600 dark:hover:bg-primary-300 hover:border-primary-600 dark:hover:border-primary-300 hover:text-surface-100': !props.link && props.severity === null && !props.text && !props.outlined && !props.plain },
            // { 'focus:ring-primary-400/50 dark:focus:ring-primary-300/50': props.severity === null },
            // Text & Outlined Button
            // { 'hover:bg-primary-300/20': (props.text || props.outlined) && props.severity === null && !props.plain },

            // Secondary
            // { 'hover:bg-surface-600 dark:hover:bg-surface-300 hover:border-surface-600 dark:hover:border-surface-300': props.severity === 'secondary' && !props.text && !props.outlined && !props.plain },
            // { 'focus:ring-surface-400/50 dark:focus:ring-surface-300/50': props.severity === 'secondary' },
            // Text & Outlined Button
            // { 'hover:bg-surface-300/20': (props.text || props.outlined) && props.severity === 'secondary' && !props.plain },

            // Success
            {
              'hover:bg-green-600 dark:hover:bg-green-300 hover:border-green-600 dark:hover:border-green-300':
                props.severity === 'success' && !props.text && !props.outlined && !props.plain
            },
            // { 'focus:ring-green-400/50 dark:focus:ring-green-300/50': props.severity === 'success' },
            // Text & Outlined Button
            {
              'hover:bg-green-300/20':
                (props.text || props.outlined) && props.severity === 'success' && !props.plain
            },

            // Info
            {
              'hover:bg-blue-600 dark:hover:bg-blue-300 hover:border-blue-600 dark:hover:border-blue-300':
                props.severity === 'info' && !props.text && !props.outlined && !props.plain
            },
            // { 'focus:ring-blue-400/50 dark:focus:ring-blue-300/50': props.severity === 'info' },
            // Text & Outlined Button
            {
              'hover:bg-blue-300/20':
                (props.text || props.outlined) && props.severity === 'info' && !props.plain
            },

            // Warning
            {
              'hover:bg-orange-600 dark:hover:bg-orange-300 hover:border-orange-600 dark:hover:border-orange-300':
                props.severity === 'warning' && !props.text && !props.outlined && !props.plain
            },
            // { 'focus:ring-orange-400/50 dark:focus:ring-orange-300/50': props.severity === 'warning' },
            // Text & Outlined Button
            {
              'hover:bg-orange-300/20':
                (props.text || props.outlined) && props.severity === 'warning' && !props.plain
            },

            // Help
            {
              'hover:bg-purple-600 dark:hover:bg-purple-300 hover:border-purple-600 dark:hover:border-purple-300':
                props.severity === 'help' && !props.text && !props.outlined && !props.plain
            },
            // { 'focus:ring-purple-400/50 dark:focus:ring-purple-300/50': props.severity === 'help' },
            // Text & Outlined Button
            {
              'hover:bg-purple-300/20':
                (props.text || props.outlined) && props.severity === 'help' && !props.plain
            },

            // Danger
            {
              'hover:bg-red-600 dark:hover:bg-red-300 hover:border-red-600 dark:hover:border-red-300':
                props.severity === 'danger' && !props.text && !props.outlined && !props.plain
            },
            // { 'focus:ring-red-400/50 dark:focus:ring-red-300/50': props.severity === 'danger' },
            // Text & Outlined Button
            {
              'hover:bg-red-300/20':
                (props.text || props.outlined) && props.severity === 'danger' && !props.plain
            },

            // Disabled
            {
              'bg-cool-gray-300 border-surface-200 text-cool-gray-600 pointer-events-none cursor-default':
                context.disabled && !props.link,
              'border border-pitch-black': context.disabled && props.severity === 'tertiary',
              'text-surface-400 bg-transparent':
                context.disabled && props.severity === 'tertiary-borderless',
              'text-surface-500 bg-surface-300':
                (props.severity === 'primary-black' || props.severity === 'bolster') &&
                context.disabled
            },

            // Transitions
            'transition duration-200 ease-in-out',

            // Misc
            'cursor-pointer overflow-hidden select-none'
          ])
    ]
  }),
  label: ({ props }) => ({
    class: [
      'duration-200',
      'font-medium',
      {
        'hover:underline': props.link
      },
      { 'flex-1': props.label !== null, 'invisible w-0': props.label == null }
    ]
  }),
  icon: ({ props }) => ({
    class: [
      'mx-0',
      {
        [props.iconPos]: props.iconPos,
        'mr-2': props.iconPos == 'left' && props.label != null,
        'ml-2 order-1': props.iconPos == 'right' && props.label != null,
        'mb-2': props.iconPos == 'top' && props.label != null,
        'mt-2': props.iconPos == 'bottom' && props.label != null
      }
    ]
  }),
  loading: ({ props }) => ({
    class: [
      'w-full h-full flex items-center justify-center',
      'mx-0',
      'absolute',
      {
        'mr-2': props.iconPos == 'left' && props.label != null,
        'ml-2 order-1': props.iconPos == 'right' && props.label != null,
        'mb-2': props.iconPos == 'top' && props.label != null,
        'mt-2': props.iconPos == 'bottom' && props.label != null
      },
      'animate-spin'
    ]
  }),
  badge: ({ props }) => ({
    class: [{ 'ml-2 w-4 h-4 leading-none flex items-center justify-center': props.badge }]
  })
}
