import { ref, onMounted, onUnmounted, getCurrentInstance, computed } from 'vue'

export const useCanvasEvents = ({ takeoff, svgRef, viewportRef, viewport }) => {
  const isDragging = ref(false)

  const lastMousePosition = ref(null)
  const lastMousePositionScreen = ref(null)

  const isMousedown = ref(false)
  const mousedownPoint = ref(null)
  const mousedownPointScreen = ref(null)

  const currentPoint = ref(null)
  const currentPointScreen = ref(null)
  const currentElement = ref(null)
  const currentLayer = ref(null)

  const hoverElement = ref(null)
  const hoverLayer = ref(null)

  const $this = getCurrentInstance().proxy

  const emitEvent = (eventName, data) => {
    return $this.$emit(`${eventName}Canvas`, data)
  }

  const enrichEventData = (event) => {
    const svgPoint = viewport.screenToSVGPoint(event.clientX, event.clientY) ?? { x: 0, y: 0 }
    const screenPoint = {
      x: event.clientX ?? 0,
      y: event.clientY ?? 0
    }
    const element = viewport.findElementAtPoint(svgPoint)
    const layerElement = element?.closest('[data-layer-id]') ?? element ?? null
    const layerId = layerElement?.getAttribute('data-layer-id') ?? null
    const layer = (layerId && takeoff.layers?.find((l) => l.id === layerId)) ?? null

    const dx = (svgPoint?.x ?? 0) - (lastMousePosition.value?.x ?? svgPoint?.x ?? 0)
    const dy = (svgPoint?.y ?? 0) - (lastMousePosition.value?.y ?? svgPoint?.y ?? 0)

    const dxScreen =
      (screenPoint?.x ?? 0) - (lastMousePositionScreen.value?.x ?? screenPoint?.x ?? 0)
    const dyScreen =
      (screenPoint?.y ?? 0) - (lastMousePositionScreen.value?.y ?? screenPoint?.y ?? 0)

    return {
      isMousedown,
      event,
      svgPoint,
      screenPoint,
      element,
      layer,
      dx,
      dy,
      dxScreen,
      dyScreen
    }
  }

  const handleMouseDown = (event) => {
    isMousedown.value = true

    const newEvent = enrichEventData(event)

    mousedownPoint.value = newEvent.svgPoint
    mousedownPointScreen.value = newEvent.screenPoint
    currentPoint.value = newEvent.svgPoint
    currentPointScreen.value = newEvent.screenPoint
    currentElement.value = newEvent.element
    currentLayer.value = newEvent.layer

    emitEvent('mousedown', newEvent)
  }

  const hasMoved = computed(() => {
    if (!currentPointScreen.value) return false
    if (!mousedownPointScreen.value) return false

    return (
      Math.abs(mousedownPointScreen.value.x - currentPointScreen.value.x) >= 1 ||
      Math.abs(mousedownPointScreen.value.y - currentPointScreen.value.y) >= 1
    )
  })

  const happenedInsideViewport = (event) => {
    const bounds = viewportRef.value?.getBoundingClientRect()
    if (!bounds) return false

    return (
      bounds.left <= event.clientX &&
      bounds.right >= event.clientX &&
      bounds.top <= event.clientY &&
      bounds.bottom >= event.clientY
    )
  }

  const handleMouseMove = (event) => {
    if (!happenedInsideViewport(event)) return

    const newEvent = enrichEventData(event)

    emitEvent('mousemove', newEvent)

    if (!isMousedown.value) {
      if (hoverElement.value && newEvent.element !== hoverElement.value) {
        emitEvent('mouseout', {
          ...newEvent,
          element: hoverElement.value
        })
      }

      hoverElement.value = newEvent.element
      hoverLayer.value = newEvent.layer

      if (newEvent.element && newEvent.element !== hoverElement.value) {
        emitEvent('mouseover', newEvent)
      }

      return
    }

    currentPoint.value = newEvent.svgPoint
    currentPointScreen.value = newEvent.screenPoint

    if (!hasMoved.value && !isDragging.value) return

    if (!isDragging.value) {
      isDragging.value = true
      emitEvent('dragstart', newEvent)
    }

    emitEvent('dragmove', newEvent)

    lastMousePosition.value = currentPoint.value
    lastMousePositionScreen.value = currentPointScreen.value
  }

  let lastClick = 0
  let lastElement = 0
  const handleClick = (enrichedEvent) => {
    if (Date.now() - lastClick < 200 && lastElement === enrichedEvent.element) {
      emitEvent('dblclick', enrichedEvent)
      lastClick = 0
      lastElement = null
    } else {
      emitEvent('click', enrichedEvent)
    }

    lastClick = Date.now()
    lastElement = enrichedEvent.element
  }

  const handleMouseUp = (event) => {
    const triggerClick = !hasMoved.value && happenedInsideViewport(event)
    const enriched = enrichEventData(event)
    resetEventState()

    if (triggerClick) handleClick(enriched)
    else {
      emitEvent('mouseup', enriched)
      emitEvent('dragend', enriched)
    }
  }

  const handleContextmenu = (event) => {
    const enriched = enrichEventData(event)
    emitEvent('contextmenu', enriched)
    return event.preventDefault()
  }

  const handleMouseLeave = (event) => {
    resetEventState()

    const enriched = enrichEventData(event)
    emitEvent('mouseup', enriched)
    emitEvent('dragend', enriched)
  }

  const resetEventState = () => {
    currentElement.value = null
    currentLayer.value = null
    currentPoint.value = null
    currentPointScreen.value = null
    mousedownPoint.value = null
    mousedownPointScreen.value = null
    isMousedown.value = false
    isDragging.value = false
    lastMousePosition.value = null
    lastMousePositionScreen.value = null
  }

  onMounted(() => {
    if (viewportRef.value) {
      viewportRef.value.addEventListener('contextmenu', handleContextmenu)
      viewportRef.value.addEventListener('mousedown', handleMouseDown)
      viewportRef.value.addEventListener('mouseleave', handleMouseLeave, { passive: true })
      window.addEventListener('mousemove', handleMouseMove, { passive: true })
      window.addEventListener('mouseup', handleMouseUp)
    }
  })

  onUnmounted(() => {
    if (viewportRef.value) {
      viewportRef.value.removeEventListener('contextmenu', handleContextmenu)
      viewportRef.value.removeEventListener('mousedown', handleMouseDown)
      viewportRef.value.removeEventListener('mouseleave', handleMouseLeave)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  })

  return {
    currentLayer,
    currentPoint,
    currentPointScreen,
    currentElement,

    hoverElement,
    hoverLayer,

    mousedownPoint,
    mousedownPointScreen,
    lastMousePosition,
    lastMousePositionScreen,

    isMousedown
  }
}
