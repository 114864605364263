import { toValue, toRefs, ref } from 'vue'

export function useSnapping(props) {
  const { layers: providedLayers, zoom: providedZoom } = toRefs(props)

  const snapping = ref(false)

  const getLayerSnapPositions = (point, layers = providedLayers.value) => {
    const snapPositions = []

    layers.forEach((lineLayer) => {
      lineLayer.segments?.forEach(([start, end]) => {
        // Your existing segment vector logic
        // Calculate segment vector
        const segDx = end.x - start.x
        const segDy = end.y - start.y
        const segLengthSquared = segDx * segDx + segDy * segDy

        // Project point onto segment line
        const t = ((point.x - start.x) * segDx + (point.y - start.y) * segDy) / segLengthSquared
        const projectedX = start.x + t * segDx
        const projectedY = start.y + t * segDy

        // Calculate distance to projected point
        const distToLine = Math.hypot(point.x - projectedX, point.y - projectedY)

        // Determine whether the snap is "real" or "imagined"
        const snapType = t >= 0 && t <= 1 ? 'line' : 'imaginary-line'

        // Snap directly to start & end points (weighted for priority)
        snapPositions.push({
          x: start.x,
          y: start.y,
          type: 'endpoint',
          segment: [start, end],
          preference: 0.1,
          distanceModify: 0.5,
          distance: Math.hypot(point.x - start.x, point.y - start.y),
          snapLayerId: lineLayer.id
        })
        snapPositions.push({
          x: end.x,
          y: end.y,
          type: 'endpoint',
          segment: [start, end],
          preference: 0.1,
          distanceModify: 0.5,
          distance: Math.hypot(point.x - end.x, point.y - end.y),
          snapLayerId: lineLayer.id
        })

        // Push with differentiated snapping types
        snapPositions.push({
          x: projectedX,
          y: projectedY,
          type: snapType, // either 'line' or 'imaginary-line'
          segment: [start, end],
          distanceModify: snapType === 'imaginary-line' ? 1.1 : 0.7,
          preference: snapType === 'imaginary-line' ? 3 : 0.2,
          distance: distToLine,
          snapLayerId: lineLayer.id
        })

        // 🔥 Additional snapping onto perpendicular horizontal and vertical "imaginary" axes at start and end points

        // Horizontal snapping lines (attributes y equals start/end)
        ;[start, end].forEach((pt) => {
          const snapDistance = Math.abs(point.y - pt.y)
          snapPositions.push({
            x: point.x, // keep current mouse x
            y: pt.y, // snap vertically to endpoint y
            type: 'perpendicular-horizontal',
            segment: [start, end],
            preference: 2,
            distance: snapDistance,
            snapLayerId: lineLayer.id
          })
        })

        // Vertical snapping lines (attributes x equals start/end)
        ;[start, end].forEach((pt) => {
          const snapDistance = Math.abs(point.x - pt.x)
          snapPositions.push({
            x: pt.x, // snap horizontally to endpoint x
            y: point.y, // keep current mouse y
            type: 'perpendicular-vertical',
            segment: [start, end],
            preference: 2,
            distance: snapDistance,
            snapLayerId: lineLayer.id
          })
        })
      })
    })

    return snapPositions
  }

  const getSnapPositions = (
    point,
    lastSegment = null,
    lastPoint = null,
    layers = providedLayers.value
  ) => {
    const layerSnapPositions = getLayerSnapPositions(point, layers)

    if (!lastPoint) return layerSnapPositions

    // Calculate angle between last point and current point
    const dx = point.x - lastPoint.x
    const dy = point.y - lastPoint.y
    const currentDistance = Math.sqrt(dx * dx + dy * dy)

    // Get the previous segment's angle if it exists
    let previousSegmentAngle = null
    if (lastSegment) {
      const [prevStart, prevEnd] = lastSegment
      const prevDx = prevEnd.x - prevStart.x
      const prevDy = prevEnd.y - prevStart.y
      previousSegmentAngle = Math.atan2(prevDy, prevDx) * (180 / Math.PI)
    }

    // Create a list of possible snap positions
    const snapPositions = []

    // Add screen-aligned snap positions
    const screenAngles = [0, 45, 90, 180, -90, -45]
    screenAngles.forEach((angle) => {
      const angleRadians = angle * (Math.PI / 180)

      const x = lastPoint.x + currentDistance * Math.cos(angleRadians)
      const y = lastPoint.y + currentDistance * Math.sin(angleRadians)

      snapPositions.push({
        x,
        y,
        type: 'screen',
        angle: angle,
        preference: 2,
        distance: Math.sqrt(Math.pow(point.x - x, 2) + Math.pow(point.y - y, 2))
      })
    })

    // Add relative angle snap positions
    if (previousSegmentAngle !== null) {
      const relativeAngles = [45, 90, 180, 225, 270]
      relativeAngles.forEach((angle) => {
        const snappedAngle = previousSegmentAngle + angle
        const angleRadians = snappedAngle * (Math.PI / 180)

        const x = lastPoint.x + currentDistance * Math.cos(angleRadians)
        const y = lastPoint.y + currentDistance * Math.sin(angleRadians)

        snapPositions.push({
          x,
          y,
          type: 'relative',
          angle: snappedAngle,
          preference: 1,
          distance: Math.sqrt(Math.pow(point.x - x, 2) + Math.pow(point.y - y, 2))
        })
      })
    }

    return [...layerSnapPositions, ...snapPositions]
  }

  const getClosestSnap = (point, snapPositions) => {
    let closestSnap = null
    let minDistance = Infinity

    snapPositions.forEach((snap) => {
      // Add a small bias to prefer endpoint snaps over path snaps
      const prefDistance = snap.distance * (snap.preference || 1)
      if (prefDistance < minDistance) {
        minDistance = prefDistance
        closestSnap = snap

        if (closestSnap.distanceModify) {
          closestSnap.distance = closestSnap.distance * closestSnap.distanceModify
        }
      }
    })

    return closestSnap
  }

  const updatePointToSnap = (
    point,
    lastSegment = null,
    lastPoint = null,
    threshold = 10,
    layers = providedLayers.value,
    zoom = providedZoom.value,
    snapPositions = getSnapPositions(point, lastSegment, lastPoint, layers)
  ) => {
    const snapThreshold = threshold / (zoom || 1) // Distance threshold for snapping
    const closest = getClosestSnap(point, snapPositions)

    // Only snap if we're within the threshold distance
    if (closest && closest.distance < snapThreshold) {
      snapping.value = closest
      return {
        x: closest.x,
        y: closest.y,
        snapping: closest.type,
        snapLayerId: closest.snapLayerId
      }
    }

    snapping.value = {
      ...point,
      snapping: false
    }
    return snapping.value
  }

  return {
    snapping,
    getSnapPositions,
    getClosestSnap,
    updatePointToSnap
  }
}
