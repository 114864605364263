import {
  onBeforeMount,
  onBeforeUnmount,
  toRefs,
  ref,
  watch,
  computed,
  toValue,
  getCurrentInstance
} from 'vue'
import { useStore } from 'vuex'

export function useFileSaving(payload) {
  const { getExportTakeoff, importData, takeoff, refId, fileId } = payload
  const { autoSave, store } = toRefs(payload)
  const loaded = ref(false)
  const $store = useStore()
  const $this = getCurrentInstance().proxy

  const handleChange = () => c.throttle(() => save(), { delay: 5000 })
  onBeforeMount(async () => {
    if (fileId.value && !loaded.value) await load()
    if (autoSave.value) $this.$on('takeoff-changed', handleChange)

    loaded.value = true
  })

  onBeforeUnmount(() => {
    if (autoSave.value) $this.$off('takeoff-changed', handleChange)
    save()
  })

  const load = async () => {
    let {
      object: { content }
    } = await $store.dispatch('ajax', {
      path: `file/getContent/${fileId.value}`,
      store: store.value
    })
    if (!content) {
      // retry since sometime while saving at the same time, it returns blank
      ;({
        object: { content }
      } = await $store.dispatch('ajax', {
        path: `file/getContent/${fileId.value}`,
        store: store.value
      }))
    }

    if (content) importData(content)
  }

  const create = async () => {
    const quote = $store.state[store.value]?.normalized?.[refId.value] ?? null

    const { object: file } = await $store.dispatch('File/save', {
      object: {
        file_name: `Takeoff_${quote?.quote_name ?? Date.now()}.blstr`,
        file_type: 'bolster/takeoff',
        parent_file_id: quote ? `quote-${quote.quote_id}` : null
      },
      go: false,
      alert: false
    })
    fileId.value = file.file_id

    if (store.value === 'Quote' && quote)
      await $store.dispatch('Quote/partialUpdate', {
        selected: [
          {
            type: 'quote',
            quote_id: quote.quote_id,
            takeoff_file_ids: file.file_id
          }
        ],
        go: false,
        alert: false
      })

    if (quote)
      await $store.dispatch(`${store.value}/field`, {
        changes: {
          [refId.value]: {
            takeoff_file_ids: file.file_id
          }
        },
        explicit: false
      })
  }

  const save = async () => {
    if (!loaded.value) return

    if (!fileId.value) await create()

    const content = getExportTakeoff()
    return update(fileId.value, content)
  }

  const update = (id, content) => {
    if (!id) return
    return $store.dispatch('File/updateFileContents', { id, content })
  }

  return {
    loaded
  }
}
