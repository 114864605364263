export default {
  type: 'custom_field_option',

  skipAudit: true,

  fields: {
    custom_field_option_id: {
      type: 'int'
    },
    custom_field_option_creator: {
      type: 'int',
      filter: true,
      format: false
    },
    custom_field_option_time_created: {
      type: 'int'
    },
    custom_field_option_time_last_modified: {
      type: 'int'
    },
    custom_field_option_status: {
      type: 'int'
    },
    custom_field_option_value: {
      type: 'string',
      required: true
    },
    custom_field_option_label: {
      type: 'string',
      required: true
    },
    custom_field_option_order: {
      type: 'int',
      default: 0
    },
    custom_field_id: {
      type: 'string'
    },
    company_id: {
      type: 'int'
    }
  }
}
