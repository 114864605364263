/**
 * Common logic between FE and appsync for payment terms
 */

export const paymentTerms = {
  rolling_basis: 'rolling_basis',
  stage_based: 'stage_based',
  phase_based: 'phase_based',
  segmented: 'segmented'
}

export const defaultPaymentTerm = paymentTerms.rolling_basis

/**
 * Extracts the type from the `context.term` by splitting it on `_` and returning the first part.
 * @returns {string} The extracted type.
 */
export const getType = (term) => {
  const [type] = term.split('_')
  return type
}

/**
 * Checks if a payment request has been sent by verifying its status is 'p'.
 * @param {Object} paymentRequest - The payment request object.
 * @returns {boolean} `true` if the payment request status is 'p', otherwise `false`.
 */
export const hasPaymentRequestBeenSent = (paymentRequestStatus) => {
  return paymentRequestStatus === 'p'
}

export default {
  paymentTerms,
  defaultPaymentTerm,
  getType,
  hasPaymentRequestBeenSent
}
