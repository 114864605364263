// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'payment_request',

  possibleStatuses: ['o', 'p', 'e', 'x', 'n'],

  skipAudit: true,

  fields: {
    payment_request_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    payment_segment_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'payment_segment'
    },
    phase_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'phase'
    },
    stage_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'stage'
    },
    quote_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'quote'
    },
    payment_request_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
      default: 'o'
    },
    payment_request_amount: {
      type: 'float',
      filter: true,
      format: 'number',
      mapTo: false
    },
    payment_request_time_sent: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    payment_request_time_due: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    payment_request_time_created: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    payment_request_time_last_modified: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    }
  }
}
