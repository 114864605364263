import { computed, getCurrentInstance, onMounted, onUnmounted, ref, watch, provide } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export function useCountLayer({
  updateLayer,
  layers,
  activeTool,
  zoom,
  selectLayer,
  selectedLayers,
  addLayer
}) {
  const $this = getCurrentInstance().proxy

  const editingLayer = ref(null)
  const selectedPointIndex = ref(null)
  const draggingPointIndex = ref(null)

  // Create edit mode state if this is the provider instance
  const editMode = ref('edit')

  const setEditMode = (mode) => {
    editMode.value = mode === editMode.value ? 'edit' : mode
  }

  provide('countLayerState', {
    editMode: editMode,
    setEditMode
  })

  watch(
    selectedLayers,
    (sel) => {
      if (editingLayer.value && !sel.find((l) => l.id === editingLayer.value)) {
        editingLayer.value = null
        selectedPointIndex.value = null
        draggingPointIndex.value = null
        editMode.value = 'edit' // Reset to default mode when selection changes
      }
    },
    { deep: true }
  )

  const addCountLayer = (data = {}) => {
    const layer = {
      id: uuidv4(),
      type: 'count',
      points: data.points || [],
      calcs: {},
      dims: {},
      position: {
        ...data.position
      },
      metadata: {
        ...data.metadata
      },
      ...data
    }

    return addLayer('count', layer)
  }

  const updatePoints = (layerId, points) => {
    const layer = layers.value.find((l) => l.id === layerId)
    if (!layer) return

    layer.points = points
    layer.metadata ??= {}

    updateLayer(layer.id, layer)
  }

  const completeArea = (layerId) => {
    const layer = layers.value.find((l) => l.id === layerId)
    if (!layer) return

    layer.metadata.isComplete = true

    updateLayer(layer.id, layer)
  }

  const countLayers = computed(() => layers.value.filter((layer) => layer.type === 'count'))

  const proximityRange = computed(() => 5 / (zoom.value || 1))

  const getLayer = () => layers.value.find((l) => l.id === editingLayer.value)
  const currentLayer = computed(() => getLayer())

  const isPointEvent = ({ svgPoint, element, layer: providedLayer = null }) => {
    return (
      activeTool.value !== 'navigate' &&
      element &&
      element.hasAttribute('data-point-index') &&
      providedLayer &&
      selectedLayers.value.find((l) => l.id === providedLayer.id)
    )
  }

  const handleClick = (event) => {
    if (activeTool.value !== 'count') return

    const { svgPoint, element, layer: eventLayer } = event

    if (
      selectedLayers.value.length === 1 &&
      selectedLayers.value[0].type === 'count' &&
      !editingLayer.value
    ) {
      editingLayer.value = selectedLayers.value[0].id
    }

    if (
      eventLayer?.type === 'count' &&
      selectedLayers.value.every((sl) => sl.id !== eventLayer?.id)
    ) {
      selectLayer(eventLayer.id)
      editingLayer.value = eventLayer.id
      return
    }

    if (draggingPointIndex.value !== null) handleDragend(event)

    const pointEvent = isPointEvent(event)
    // Handle normal point selection/editing

    // Handle point removal mode
    if (editMode.value === 'remove' && providedLayer?.type === 'count') {
      if (isPointEvent(event)) {
        const pointIndex = +element.getAttribute('data-point-index')
        const layer = providedLayer
        if (layer.points.length > 3) {
          // Prevent removing points if it would make the area invalid
          const newPoints = layer.points.filter((_, i) => i !== pointIndex)
          updatePoints(layer.id, newPoints)
        }
        return
      }
    }

    // Handle point addition mode
    if (editMode.value === 'add' && providedLayer?.type === 'count') {
      const layer = providedLayer
      const newPoints = [...(layer.points || []), svgPoint]
      selectedPointIndex.value = newPoints.length - 1
      updatePoints(layer.id, newPoints)
      return
    }

    // Handle normal point selection/editing
    else if (pointEvent) {
      editingLayer.value = providedLayer.id
      selectLayer(editingLayer.value)
      selectPoint(+element.getAttribute('data-point-index'))
      return
    }

    if (activeTool.value !== 'count' || editMode.value !== 'edit') return

    let layer
    if (!editingLayer.value) {
      layer = addCountLayer({
        points: []
      })
      editingLayer.value = layer.id
      selectLayer(layer.id)
    }
    layer = getLayer()

    const newPoints = [...(layer.points || []), svgPoint]
    selectedPointIndex.value = newPoints.length - 1
    // editingLayer.value = layer.id
    updatePoints(layer.id, newPoints)
  }

  const resetPointDrag = () => {
    selectedPointIndex.value = null
    draggingPointIndex.value = null
  }

  const handleDragstart = (event) => {
    resetPointDrag()
    if (!isPointEvent(event)) {
      return handleClick(event)
    }

    editingLayer.value = event.layer.id
    const { element, isMousedown } = event
    // const unwatch = watch(isMousedown, (is) => {
    //   if (!is) {
    //     unwatch()
    //     handleDragend(event)
    //   }
    // })
    selectedPointIndex.value = +element.getAttribute('data-point-index')
    draggingPointIndex.value = +element.getAttribute('data-point-index')
  }

  const handleDragmove = ({ svgPoint, isMousedown }) => {
    if (
      draggingPointIndex.value === null ||
      !isMousedown.value ||
      !editingLayer.value ||
      selectedPointIndex.value == null
    ) {
      resetPointDrag()
      return
    }

    const points = [...currentLayer.value.points]
    points[draggingPointIndex.value] = svgPoint
    updatePoints(currentLayer.value.id, points)
  }

  const handleDragend = ({ isMousedown }) => {
    if (
      draggingPointIndex.value === null ||
      !isMousedown.value ||
      !editingLayer.value ||
      selectedPointIndex.value === null
    ) {
      resetPointDrag()
      return
    }
    draggingPointIndex.value = null
  }

  const selectPoint = (pointIndex) => {
    selectedPointIndex.value = pointIndex
  }

  onMounted(() => {
    $this.$on('mousedownCanvas', handleDragstart)
    $this.$on('mousemoveCanvas', handleDragmove)
    $this.$on('mouseupCanvas', handleDragend)
  })
  onUnmounted(() => {
    $this.$off('mousedownCanvas', handleDragstart)
    $this.$off('mousemoveCanvas', handleDragmove)
    $this.$off('mouseupCanvas', handleDragend)
  })

  return {
    selectPoint,
    addCountLayer,
    updatePoints,
    completeArea,
    countLayers,
    selectedPointIndex,
    editMode,
    setEditMode
  }
}
