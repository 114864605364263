import _ from '../Helpers'

export default {
  type: 'dimension',

  skipAudit: true,

  fields: {
    dimension_id: {
      filter: true,
      component: 'PreviewDimension',
      title: 'Dimension Code',
      name: 'Dimension Code',
      type: 'string',
      format: false,
      mapTo: false,
      required: true
    },
    oOptions: {
      type: 'object'
    },
    dimension_name: {
      filter: true,
      type: 'string',
      format: false,
      mapTo: false,
      required: true
    },
    dimension_measure_type: {
      type: 'string',
      required: true,
      visible: true,
      filter: true
    },
    dimension_desc: {
      filter: true,
      type: 'string',
      format: false,
      mapTo: false,
      visible: true
    },
    dimension_default_equation: {
      type: 'string'
    },
    dimension_time_created: {
      type: 'int',
      visible: true
    },
    dimension_creator: {
      type: 'int',
      visible: true
    },
    dimension_color: {
      type: 'string',
      default: '#279EFF'
    },
    dimension_keywords: {
      type: 'string',
      visible: true,
      filter: true
    },
    dimension_icon: {
      type: 'string',
      default: 'hash'
    },
    company_id: {
      type: 'int',
      save: false,
      reload: true,
      trackChanges: false,
      filter: true
    },
    company_name: {
      type: 'string',
      save: false,
      reload: true,
      trackChanges: false,
      filter: true
    }
  },

  getDimParent(norm, refId, dimAbbr) {
    const abbr = dimAbbr

    if (!norm[refId]?.oDimensions?.[abbr]?.inherit) return refId

    const up = (from) => {
      const dim = norm[from]?.oDimensions?.[abbr]
      const parentRef = norm[from]?.parentRefId

      if (!dim?.inherit || !parentRef) return from

      return up(parentRef)
    }

    return up(refId)
  },

  generateVueActions() {
    return {
      /**
       * Get a shortened, smarter list of typical dimensions for a given item
       * @param dispatch
       * @param payload
       *  @param payload.itemName required
       *  @param payload.parentName required default ''
       *  @param payload.itemDesc required default ''
       *  @param payload.object required
       *  @param payload.limit required
       *  @see params for getSuggestedDimension as well
       * @returns {Promise<*[]>}
       */
      async getSuggestedDimensions({ dispatch }, payload) {
        const dims = await dispatch('getPossibleDimensions', { full: true })
        const { sorted, suggested: sugg, rank } = await dispatch('getSuggestedDimension', payload)

        const { object, limit = 10, onlySuggested = true } = payload

        let suggested = sorted
        if (onlySuggested && sugg.length) {
          suggested = sorted.filter((d) => sugg.includes(d.dimension_id))
        }

        suggested = suggested.slice(0, limit)
        const chosenDimension = object.cost_type_qty_equation || object.assembly_qty_equation
        const otherselected =
          chosenDimension &&
          chosenDimension in dims &&
          dims[chosenDimension] &&
          !suggested.find((s) => s.dimension_id === chosenDimension)
            ? [dims[chosenDimension]]
            : []

        const combined = [...(otherselected || []), ...(suggested || [])]

        const combinedLength = combined.length

        if (
          combinedLength < 1 &&
          (!combinedLength || !combined.find((s) => s.dimension_id === 'fa'))
        ) {
          combined.push(dims.fa)
        }
        // if (combinedLength < 2
        //   && (!combinedLength || !combined.find(s => s.dimension_id === 'twp'))) {
        //   combined.push(dims.twp);
        // }
        // if (combinedLength < 1
        //   && (!combinedLength || !combined.find(s => s.dimension_id === 'iwa'))) {
        //   combined.push(dims.iwa);
        // }

        return {
          dimensions: await dispatch('mapDimensions', combined),
          sorted,
          rank
        }
      },

      /**
       * Get a suggested dimension from information provided
       * by an item.
       * @param dispatch
       * @param payload
       * @returns {Promise<void>}
       */
      async getSuggestedDimension({ dispatch }, payload = {}) {
        const {
          itemName,
          parentName,
          itemDesc,
          preferMeasureType = true,
          modifiedWeights = {},
          modifierWeight = 15,
          dimensionTypeWeight = 10,
          dimensions: dims = Object.values(await dispatch('getPossibleDimensions', { full: true }))
        } = payload

        const regxs = dims.reduce((acc, dim) => {
          const dimTokens = [
            ..._.tokenize(dim.dimension_name, 6),
            ..._.tokenize(dim.dimension_keywords, 5),
            ..._.tokenize(dim.dimension_desc, 1)
          ]

          if (dim.dimension_measure_type === 'length') {
            dimTokens.push('length')
            dimTokens.push('height')
            dimTokens.push('thick')
            dimTokens.push('perimeter')
            dimTokens.push('contour')
            dimTokens.push('distance')
            dimTokens.push('width')
          } else if (dim.dimension_measure_type === 'area') {
            dimTokens.push('area')
            dimTokens.push('coverage')
            dimTokens.push('surface')
            dimTokens.push('square')
          } else if (dim.dimension_measure_type === 'volume') {
            dimTokens.push('volume')
            dimTokens.push('cubic')
            dimTokens.push('cube')
            dimTokens.push('contain')
          } else if (dim.dimension_measure_type === 'count') {
            dimTokens.push('single')
          } else if (dim.dimension_measure_type === 'weight') {
            dimTokens.push('weight')
            dimTokens.push('oz')
            dimTokens.push('ounce')
            dimTokens.push('pound')
            dimTokens.push('kilo')
            dimTokens.push('gram')
            dimTokens.push('mass')
          }

          return {
            ...acc,
            [dim.dimension_id]: dimTokens
          }
        }, {})

        const nameTokens = _.tokenize(itemName, 4)
        const parentNameTokens = _.tokenize(parentName, 4)
        const descTokens = _.tokenize(itemDesc, 1)
        const itemTokens = [...nameTokens, ...parentNameTokens, ...descTokens]

        const getRankedMeasureTypeValue = (mt, weight = 1) => {
          if (mt === preferMeasureType) return 1 * weight
          if (mt === 'weight') return 0.15 * weight
          if (mt === 'volume') return 0.17 * weight
          if (mt === 'area') return 0.15 * weight
          if (mt === 'length') return 0.13 * weight
          if (mt === 'count') return -0.05 * weight
          return 0
        }

        const getModifiedWeight = (abbr, weight = 1) => {
          if (abbr in modifiedWeights) return modifiedWeights[abbr] * weight
          return 0
        }

        const rank = {}
        const addRank = (abbr, matches, measureTypeRank, total, other = {}) => {
          if (!(abbr in rank)) rank[abbr] = {}

          rank[abbr] = {
            ...rank[abbr],
            ...other
          }
          rank[abbr].measureTypeRank = measureTypeRank
          rank[abbr].total = total
          rank[abbr].preferMeasureType = preferMeasureType
        }

        dims.forEach((dim) => {
          const all = nameTokens.every((p) => regxs[dim.dimension_id].includes(p)) ? 10 : 0
          const allParent = parentNameTokens.every((p) => regxs[dim.dimension_id].includes(p))
            ? 5
            : 0
          const amt = getRankedMeasureTypeValue(dim.dimension_measure_type, dimensionTypeWeight)
          const mod = getModifiedWeight(dim.abbr, modifierWeight)
          const matchl = _.termFrequency(itemTokens, regxs[dim.dimension_id]) * 4
          const matchi = _.termFrequency(regxs[dim.dimension_id], itemTokens)
          const general = dim.dimension_name.includes('general') ? -5 : 0
          const total = matchi + matchl + amt + all + allParent + mod + general

          if (total - amt > 0.01)
            addRank(dim.abbr, [], amt, total, {
              termFrequencyReversed: matchi,
              termFrequency: matchl,
              getRankedMeasureTypeValue: amt,
              getModifiedWeight: mod,
              general,
              allParent,
              all
            })
        })

        const ranked = Object.keys(rank).sort((ra, rb) => rank[rb].total - rank[ra].total)

        const list = ranked.map((rid) => dims.find((st) => String(st.abbr) === String(rid)))
        // console.log('returnign', _.immutable({
        //   list, ranked, rank
        // }));

        return {
          suggested: ranked,
          sorted: list,
          rank
        }
      },

      /**
       * Turn an array of dimensions into an object with
       * required dimension fields
       * @param dimensions
       * @returns {Promise<*[]>}
       */
      async mapDimensions({ dispatch }, dimensions = []) {
        const defaultAreaMeasure = await dispatch('getMeta', 'defaultAreaMeasure', { root: true })
        const defaultLengthMeasure = await dispatch('getMeta', 'defaultLengthMeasure', {
          root: true
        })
        const defaultVolumeMeasure = await dispatch('getMeta', 'defaultVolumeMeasure', {
          root: true
        })
        const defaultWeightMeasure = await dispatch('getMeta', 'defaultWeightMeasure', {
          root: true
        })

        const getMeasure = (dim) => {
          const mt = dim.dimension_measure_type
          if (mt === 'length') {
            return defaultLengthMeasure || dim.measure || 'ft'
          } else if (mt === 'area') {
            return defaultAreaMeasure || dim.measure || 'ft2'
          } else if (mt === 'volume') {
            return defaultVolumeMeasure || dim.measure || 'yd3'
          } else if (mt === 'weight') {
            return defaultWeightMeasure || dim.measure || 'lbs'
          }

          return 'count'
        }

        const mapped = dimensions.reduce(
          (acc, dim) => ({
            ...acc,
            [dim.dimension_id]: {
              ..._.defaultDimension,
              dimension_id: dim.dimension_id,
              defaultValue: dim.dimension_default_equation,
              equation: dim.dimension_default_equation,
              measureType: dim.dimension_measure_type,
              dimension_measure_type: dim.dimension_measure_type,
              measure: getMeasure(dim),
              value: 0,
              name: dim.dimension_name,
              abbr: dim.dimension_id,
              desc: dim.dimension_desc,
              options: dim.oOptions ?? {},
              color: _.ensureContrastWithWhite(dim.dimension_color),
              ...dim
            }
          }),
          {}
        )

        return mapped
      },

      /**
       * Get list of all possible dimensions, sorted by id/key/abbr
       * which serves as the dimensions variable
       * @returns {{}}
       */
      async getPossibleDimensions({ dispatch, rootState }, { force = false } = {}) {
        const cacheKey = `PossibleDimensions`
        let possibleDimensions = await dispatch('getCacheItem', {
          key: cacheKey
        })

        if (!force && possibleDimensions && Object.keys(possibleDimensions).length) {
          return possibleDimensions
        }

        let { set: dimensionList } = await dispatch('search', {
          limit: 0,
          offset: 0,
          saveSet: true,
          filters: {
            company_id: rootState.session.scope.company
              ? `${rootState.session.scope.company}||NULL`
              : 'NULL'
          }
        })

        // Inject recommended measure
        dimensionList = await Promise.all(
          dimensionList.map(async (dim) => {
            const uomid = await dispatch(
              'UnitOfMeasure/getSuggestedUnitOfMeasureFromDimension',
              dim,
              { root: true }
            )
            dim.measure = uomid

            return dim
          })
        )

        // Sort dimensions from least to most dependants, with
        // always the dependant before the computed dimension, ie
        // if dimension A is calculated based on dimension B and dimension
        // B is computed based on dimension C and dimension C is inherited
        // or explicitly set, then it should be ordered C, B, A.
        // Makes dimensions computing, sorting and auditing more efficient later
        dimensionList.sort((a, b) => {
          const dera = !!a.dimension_default_equation
          const derb = !!b.dimension_default_equation

          if (!dera && !derb) return 0
          if (dera && !derb) return 1
          if (!dera && derb) return -1

          const eqa = a.dimension_default_equation
          const eqb = b.dimension_default_equation
          const rega = new RegExp(a.dimension_id)
          const regb = new RegExp(b.dimension_id)

          // both derived, see if one requires the other

          // b requires a, make a before b
          if (rega.test(eqb)) {
            return -1
          }

          // a requires b, make b before a
          if (regb.test(eqa)) {
            return 1
          }

          // Neither requires the other
          return 0
        })

        possibleDimensions = await dispatch('mapDimensions', dimensionList)

        dispatch('setCacheItem', {
          key: cacheKey,
          value: possibleDimensions
        })

        return possibleDimensions
      },
      async verifyDimensions({ dispatch }, { set }) {
        const possibleDimensions = await dispatch('getPossibleDimensions', {})
        let missing = []
        const itemsRemovedNames = []
        const removedItems = {}
        set.forEach((item) => {
          if (item.oDimensions && Object.keys(item.oDimensions).length > 0) {
            const itemDimensions = Object.keys(item.oDimensions)
            const possibleDimensionsKeys = Object.keys(possibleDimensions)
            const missingDimensions = _.difference(itemDimensions, possibleDimensionsKeys)
            if (missingDimensions) {
              missing = [...missing, ...missingDimensions]
              itemsRemovedNames.push(item[`${item.type}_name`])
              removedItems[item[`${item.type}_id`]] = item
            }
          }
        })
        if (missing.length > 0) {
          await dispatch(
            'modal/confirm',
            {
              message: `
            The item(s) were not added because the following dimensions could not be found in the companies dimensions:\n
            ${missing.join(', ')}\n
            Create the dimensions with their respective code in the custom dimensions page before proceeding.`,
              actions: {
                confirm: {
                  title: 'Ok'
                },
                cancel: {
                  visible: () => false
                }
              }
            },
            { root: true }
          )
          return set.filter((i) => !removedItems[i[`${i.type}_id`]])
        }

        return set
      }
    }
  }
}
