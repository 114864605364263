import { ref, computed, getCurrentInstance, onMounted, onUnmounted, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export function useMarkupLayer({
  updateLayer,
  layers,
  activeTool,
  zoom,
  selectLayer,
  selectedLayers,
  addLayer
}) {
  const $this = getCurrentInstance().proxy
  const editingLayer = ref(null)
  const currentPath = ref([])
  const isDrawing = ref(false)

  watch(selectedLayers, (sel) => {
    if (editingLayer.value && !sel.find((l) => l.id === editingLayer.value)) {
      editingLayer.value = null
      isDrawing.value = false // Reset to default mode when selection changes
      currentPath.value = []
    }
  })

  const smoothPath = computed(() => {
    if (currentPath.value.length < 2) return ''

    // Create a smooth curve through the points using cubic bezier
    let path = `M ${currentPath.value[0].x} ${currentPath.value[0].y}`

    for (let i = 1; i < currentPath.value.length - 1; i++) {
      const p0 = currentPath.value[i - 1]
      const p1 = currentPath.value[i]
      const p2 = currentPath.value[i + 1]

      // Calculate control points for smooth curve
      const cp1x = p1.x - (p2.x - p0.x) * 0.2
      const cp1y = p1.y - (p2.y - p0.y) * 0.2
      const cp2x = p1.x + (p2.x - p0.x) * 0.2
      const cp2y = p1.y + (p2.y - p0.y) * 0.2

      path += ` C ${cp1x} ${cp1y}, ${cp2x} ${cp2y}, ${p1.x} ${p1.y}`
    }

    // Add the last point
    if (currentPath.value.length > 1) {
      const last = currentPath.value[currentPath.value.length - 1]
      path += ` L ${last.x} ${last.y}`
    }

    return path
  })

  const addMarkupLayer = () => {
    const layer = {
      id: uuidv4(),
      type: 'markup',
      paths: [],
      strokeWidth: 2,
      strokeColor: '#FF0000',
      position: {},
      metadata: {
        isComplete: false
      },
      zIndex: layers.value.length
    }

    return addLayer('markup', layer)
  }

  const handleMouseDown = (event) => {
    if (activeTool.value !== 'markup') return

    const { svgPoint } = event

    let layer = layers.value.find((l) => l.id === editingLayer.value)
    // Start a new layer if not editing one
    if (!editingLayer.value || !layer) {
      layer = addMarkupLayer()
      editingLayer.value = layer.id
      selectLayer(layer.id)
    }

    isDrawing.value = true
    currentPath.value = [svgPoint]
  }

  const handleMouseMove = (event) => {
    if (!isDrawing.value || activeTool.value !== 'markup') return

    const { svgPoint } = event

    // Add point to current path with some smoothing
    if (currentPath.value.length > 0) {
      const lastPoint = currentPath.value[currentPath.value.length - 1]
      const dx = svgPoint.x - lastPoint.x
      const dy = svgPoint.y - lastPoint.y
      const distance = Math.sqrt(dx * dx + dy * dy)

      // Only add points if they're far enough apart (prevents too many points)
      if (distance > 2 / zoom.value) {
        currentPath.value.push(svgPoint)

        // Update the layer with the current path
        const layer = layers.value.find((l) => l.id === editingLayer.value)
        if (layer) {
          layer.paths = [...layer.paths.slice(0, -1), smoothPath.value]
          updateLayer(layer.id, layer, true)
        }
      }
    }
  }

  const handleMouseUp = () => {
    if (!isDrawing.value || activeTool.value !== 'markup') return

    // Finalize the current path
    const layer = layers.value.find((l) => l.id === editingLayer.value)
    if (layer && currentPath.value.length > 1) {
      layer.paths.push(smoothPath.value)
      updateLayer(layer.id, layer, true)
    }

    isDrawing.value = false
    currentPath.value = []
  }

  onMounted(() => {
    $this.$on('mousedownCanvas', handleMouseDown)
    $this.$on('mousemoveCanvas', handleMouseMove)
    $this.$on('mouseupCanvas', handleMouseUp)
  })

  onUnmounted(() => {
    $this.$off('mousedownCanvas', handleMouseDown)
    $this.$off('mousemoveCanvas', handleMouseMove)
    $this.$off('mouseupCanvas', handleMouseUp)
  })

  return {
    editingLayer,
    currentPath,
    isDrawing,
    smoothPath,
    addMarkupLayer
  }
}
