<template>
  <g class="measure-tool">
    <!-- Start point dot -->
    <template v-if="isMeasuring || startPoint">
      <circle
        v-if="startPoint"
        :cx="startPoint.x"
        :cy="startPoint.y"
        :r="7 / zoom"
        class="fill-blue-print-400 stroke-2 stroke-white"
        :stroke-width="2 / zoom"
      />

      <!-- Preview/End point dot -->
      <circle
        v-if="previewPoint"
        :cx="previewPoint.x"
        :cy="previewPoint.y"
        :r="7 / zoom"
        class="fill-blue-print-400 stroke-white"
        :stroke-width="2 / zoom"
      />

      <!-- Measurement line -->
      <path
        v-if="linePath"
        :d="linePath"
        class="stroke-blue-print-400"
        fill="none"
        :stroke-width="2 / zoom"
        :stroke-dasharray="`${4 / zoom} ${4 / zoom}`"
      />

      <!-- Distance text -->
      <g
        v-if="textPosition && distance !== null && !isScalingMode"
        :transform="`translate(${textPosition.x},${textPosition.y}) scale(${1 / zoom})`"
      >
        <rect x="-30" y="-12" width="60" height="24" rx="3" class="fill-blue-print-400" />
        <text
          y="1"
          class="text-sm fill-white font-medium"
          text-anchor="middle"
          dominant-baseline="middle"
        >
          {{ formatDistance(distance) }}
        </text>
      </g>
    </template>
  </g>
</template>

<script setup>
import { watch, toRefs, computed } from 'vue'

const props = defineProps({
  isMeasuring: Boolean,
  startPoint: Object,
  previewPoint: Object,
  linePath: String,
  textPosition: Object,
  distance: Number,
  takeoff: Object,
  zoom: Number,
  isScalingMode: Boolean
})

const zoom = computed(() => props.zoom || 1)

const { isMeasuring, startPoint, previewPoint, linePath, textPosition, distance, takeoff } =
  toRefs(props)

const formatDistance = (distance) => {
  const value = Number(distance) || 0
  return `${c.format(value, 'number')} ${takeoff.value.scale.unit}`
}
</script>
