import { ref, computed, watch, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'

export const useMeasure = ({ takeoff, activeTool, zoom, refScaler }) => {
  const $this = getCurrentInstance().proxy
  const $store = useStore()

  const isMeasuring = ref(false)
  const startPoint = ref(null)
  const endPoint = ref(null)
  const previewPoint = ref(null)

  const isScalingMode = ref(false)
  const showScaleInput = ref(false)
  const scaleInputValue = ref('')
  const scaleInputUnit = ref('in')

  const disabled = ref(false)

  const reset = () => {
    isMeasuring.value = false
    startPoint.value = null
    endPoint.value = null
    previewPoint.value = null
  }

  const scale = computed(() => takeoff.scale?.length / takeoff.scale?.pixels || 1)

  const handleClick = ({ svgPoint }) => {
    if (activeTool.value !== 'measurement' || disabled.value) return

    if (!isMeasuring.value) {
      // Start measuring
      isMeasuring.value = true
      startPoint.value = svgPoint
      previewPoint.value = svgPoint
    } else {
      // Finish measuring
      endPoint.value = svgPoint
      isMeasuring.value = false
    }
  }

  const handleMouseMove = ({ svgPoint }) => {
    if (activeTool.value !== 'measurement' || !isMeasuring.value) return

    previewPoint.value = svgPoint
  }

  const handleKeyDown = (event) => {
    if (activeTool.value !== 'measurement' || !isMeasuring.value || event.key !== 'Escape') return
    reset()
  }

  const pixelDistance = computed(() => {
    if (!startPoint.value || !previewPoint.value || !scale.value) return 0

    const dx = previewPoint.value.x - startPoint.value.x
    const dy = previewPoint.value.y - startPoint.value.y
    return Math.sqrt(dx * dx + dy * dy)
  })

  const distance = computed(() => {
    if (!startPoint.value || !previewPoint.value || !scale.value) return 0

    // Convert pixel distance to real-world units using scale
    return Number(pixelDistance.value * scale.value)
  })

  const getPixels = async () => {
    reset()
    const { promise, resolve } = c.getDeferredPromise()

    activeTool.value = 'measurement'

    const stopWatch = watch(endPoint, async (ep) => {
      if (!ep) return
      resolve(pixelDistance.value)
      // Stop watching after the first change
      stopWatch()
      reset()
      activeTool.value = null
    })

    return promise
  }

  const getScale = async () => {
    reset()
    const { promise, resolve } = c.getDeferredPromise()

    activeTool.value = 'measurement'
    isScalingMode.value = true

    const stopWatch = watch(endPoint, async (ep) => {
      if (!ep) return

      disabled.value = true
      const pixels = pixelDistance.value

      const cancel = () => {
        stopWatch()
        reset()
        activeTool.value = null
        isScalingMode.value = false
      }

      const val = (await refScaler.value?.requestScale()) ?? false

      if (val) {
        resolve({
          pixels,
          distance: val.distance,
          unit: val.unit
        })
      } else {
        resolve(false)
      }

      disabled.value = false
      cancel()
    })

    return promise
  }

  // SVG path for the measurement line
  const linePath = computed(() => {
    if (!startPoint.value || !previewPoint.value) return ''

    const x1 = Number(startPoint.value.x)
    const y1 = Number(startPoint.value.y)
    const x2 = Number(previewPoint.value.x)
    const y2 = Number(previewPoint.value.y)

    if (isNaN(x1) || isNaN(y1) || isNaN(x2) || isNaN(y2)) return ''

    return `M ${x1} ${y1} L ${x2} ${y2}`
  })

  // Position for the measurement text
  const textPosition = computed(() => {
    if (!startPoint.value || !previewPoint.value) return null

    return {
      x: (Number(startPoint.value.x) + Number(previewPoint.value.x)) / 2,
      y: (Number(startPoint.value.y) + Number(previewPoint.value.y)) / 2 - 10
    }
  })

  // Add event handlers for the measure tool
  watch(activeTool, (newTool) => {
    if (newTool !== 'measurement') {
      reset()
    }
  })

  onMounted(() => {
    window.addEventListener('keydown', handleKeyDown)
    $this.$on('clickCanvas', handleClick)
    $this.$on('mousemoveCanvas', handleMouseMove)
  })

  onBeforeUnmount(() => {
    window.removeEventListener('keydown', handleKeyDown)
    $this.$off('clickCanvas', handleClick)
    $this.$off('mousemoveCanvas', handleMouseMove)
  })

  return {
    isMeasuring,
    startPoint,
    endPoint,
    previewPoint,
    distance,
    linePath,
    textPosition,
    handleClick,
    handleMouseMove,
    handleKeyDown,
    reset,
    isScalingMode,
    showScaleInput,
    scaleInputValue,
    scaleInputUnit,
    pixelDistance,
    getScale
  }
}
