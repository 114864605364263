// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import _ from '../Helpers'

export default {
  type: 'cost_matrix',

  fields: {
    cost_matrix_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'cost_type'
    },
    cost_matrix_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    unit_of_measure_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'unit_of_measure',
      title: 'Unit'
    },
    cost_matrix_labor_cost_net_explicit: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      save: false,
      default: null
    },
    cost_matrix_labor_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      save: false,
      title: 'Per-unit labor cost'
    },
    cost_matrix_materials_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      save: false,
      title: 'Per-unit materials cost'
    },
    labor_cost_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    material_cost_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_matrix_aggregate_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      save: false
    },
    cost_matrix_time_created: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      reload: true,
      save: false
    },
    cost_matrix_use_company_markup: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_matrix_markup_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 1
    },
    cost_matrix_time_last_modified: {
      type: 'float',
      filter: false,
      format: 'datetime',
      mapTo: false
    },
    cost_matrix_is_custom: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    cost_matrix_is_override: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    cost_matrix_rate_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      title: 'Per-unit price'
    },
    cost_matrix_setup_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      title: 'Static setup costs'
    }
  },

  getComputedDependants() {
    const n = _.notNaN
    return {
      cost_matrix_materials_cost_net(state) {
        if (
          state.oMeta?.itemType !== undefined &&
          state.oMeta?.itemType !== null &&
          state.oMeta?.itemType !== 'costItem'
        )
          return 0

        if (state.cost_type_is_fee && state.cost_type_has_materials) {
          return 1
        }

        if (!state.cost_type_has_materials && !state.cost_type_is_subcontracted) return 0

        return state.cost_matrix_materials_cost_net
      },
      cost_matrix_labor_cost_net(state) {
        if (
          state.oMeta?.itemType !== undefined &&
          state.oMeta?.itemType !== null &&
          state.oMeta?.itemType !== 'costItem'
        )
          return 0

        if (state.cost_type_is_fee && !state.cost_type_has_materials) {
          return 1
        }

        if (!state.cost_type_has_labor) return 0
        if (state.cost_type_is_subcontracted) {
          return n(state.labor_type_callout_rate_net)
            ? n(state.labor_type_callout_rate_net) * n(state.cost_type_hours_per_unit)
            : 0
        }

        if (
          state.cost_matrix_labor_cost_net_explicit &&
          state.cost_matrix_labor_cost_net_explicit !== null
        ) {
          return state.cost_matrix_labor_cost_net_explicit
        }
        return n(state.labor_type_rate_net) * n(state.cost_type_hours_per_unit)
      },
      cost_matrix_aggregate_cost_net(state) {
        return n(state.cost_matrix_labor_cost_net) + n(state.cost_matrix_materials_cost_net)
      },
      cost_matrix_markup_net(state) {
        if (state.type === 'cost_type' && state.cost_type_is_parent) {
          return null
        }
        return n(state.cost_matrix_markup_net) || n(state.company_default_markup) || 1.43
      },
      cost_matrix_rate_net(state) {
        const markupAdjusted =
          n(state.cost_matrix_markup_net) +
          _.n(state.type === 'cost_item' ? state.cost_item_markup_net_adjustment || 0 : 0)
        return n(state.cost_matrix_aggregate_cost_net) * markupAdjusted
      }
    }
  }
}
