// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import _ from '../Helpers'
import User from './User'
// import Stripe from '../../../client/components/billing/Stripe';

export default {
  type: 'company',

  possibleStatuses: ['a', 'i'],

  listPresets: [
    {
      title: 'Subscribed companies',
      description: 'Companies that have an active subscription',
      filters: {
        stripe_subscription_id: '!NULL',
        company_status: 'a'
      },
      filterText: {
        stripe_subscription_id: 'Not empty',
        company_status: 'Active'
      }
    },
    {
      title: 'Have payment method',
      description: 'Active companies, with a payment method on file.',
      filters: {
        company_status: 'a',
        stripe_payment_method: '!NULL'
      },
      filterText: {
        company_status: 'Active',
        stripe_payment_method: 'Not empty'
      }
    },
    {
      title: 'No payment method',
      description: 'Active companies, without a payment method on file.',
      filters: {
        stripe_customer_id: '!NULL',
        stripe_payment_method: 'NULL'
      },
      filterText: {
        stripe_customer_id: 'Not empty',
        stripe_payment_method: 'Empty'
      }
    },
    {
      title: 'Active registered',
      description: 'Companies that signed up for Bolster and are still active.',
      filters: {
        company_status: 'a',
        stripe_customer_id: '!NULL'
      },
      filterText: {
        company_status: 'Active',
        stripe_customer_id: 'Not empty'
      }
    },
    {
      title: 'Companies in trial',
      description: 'Companies that are still inside their cancel period',
      filters: {
        company_status: 'a',
        company_is_in_trial: '>0'
      },
      filterText: {
        company_status: 'Active',
        company_is_in_trial: 'True'
      }
    }
  ],

  skipAudit: true,

  fields: {
    ...User.fields,
    company_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewCompany'
    },
    trade_type_ids: {
      type: 'string',
      save: true,
      mapTo: 'trade_type'
    },
    company_is_verified: {
      type: 'int',
      default: 0
    },
    company_is_locked: {
      type: 'int',
      default: 1
    },
    company_is_internal: {
      type: 'int',
      default: 0
    },
    company_user_ids: {
      type: 'array'
    },
    user_id: {
      type: 'int',
      mapTo: 'user'
    },
    company_creator: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    company_owner: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    company_business_number: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_name_short: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    timezone_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'timezone'
    },
    company_suite: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_address: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_email: {
      type: 'string',
      filter: true,
      mapTo: false,
      format: 'email'
    },
    company_accounting_email: {
      type: 'string',
      filter: true,
      format: 'email'
    },
    company_postal: {
      type: 'string',
      filter: true,
      mapTo: false
    },
    company_prov: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_city: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_website: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_subdir: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false
    },
    company_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    company_dist: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_average_hourly_rate: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_burden: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_default_markup: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_minimum_quote_margin: {
      type: 'string',
      filter: true,
      format: 'percentWhole',
      mapTo: false
    },
    company_logo_file_id: {
      type: 'string',
      mapTo: 'file',
      save: true
    },
    company_phone: {
      type: 'string',
      filter: true,
      format: 'phone',
      mapTo: false
    },
    province_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'province',
      defaultSetting: true
    },
    aoMeta: {
      type: 'array',
      filter: true,
      save: true,
      format: false,
      mapTo: false
    },
    oMeta: {
      type: 'object',
      filter: true,
      format: false,
      save: true,
      trackChanges: true,
      mapTo: false,
      default: () => ({})
    },
    aoModules: {
      type: 'object',
      filter: true,
      format: false,
      mapTo: 'module',
      save: false,
      trackChanges: false,
      reload: true
    },

    country_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'country'
    },
    country_abbr: {
      type: 'string',
      filter: false,
      format: false,
      save: false,
      trackChanges: false
    },
    country_name: {
      type: 'string',
      filter: false,
      format: false,
      save: false,
      trackChanges: false
    },
    company_insurance_number: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_wcb_number: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    mod_id: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: 'mod'
    },
    currency_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'currency'
    },
    tax_id: {
      type: 'int',
      filter: false,
      mapTo: 'tax'
    },
    item_tax_id: {
      type: 'int',
      filter: false,
      mapTo: 'tax'
    },
    live_price_tax_id: {
      type: 'int',
      filter: false,
      mapTo: 'tax'
    },
    mod_labor_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false
    },
    mod_materials_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false
    },
    mod_equipment_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false
    },
    company_country: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    company_stage_tasks_is_strict: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      title: 'Required stage tasks must be completed'
    },
    company_has_instant_upgrade: {
      type: 'int'
    },
    company_project_signature_is_required: {
      type: 'int',
      save: true
    },
    company_terms_and_conditions: {
      type: 'string'
    },
    stripe_customer_id: {
      type: 'string',
      save: true,
      trackChanges: true
    },
    stripe_payment_method: {
      type: 'string',
      save: true,
      trackChanges: true,
      filter: true
    },
    stripe_subscription_id: {
      type: 'string',
      save: true,
      trackChanges: true,
      filter: true
    },
    stripe_price_id: {
      type: 'string',
      save: true,
      trackChanges: true,
      filter: true
    },
    company_coaching_hours_available: {
      type: 'float',
      save: false,
      reload: true
    },
    /**
     * Details for payment method billing details
     */
    oBillingDetails: {
      type: 'object',
      save: false,
      reload: true
    },
    stripe_access_payment_ref: {
      type: 'string',
      save: true,
      trackChanges: true,
      filter: true
    },
    stripe_access_payment_time_paid: {
      type: 'int',
      format: 'datetime',
      save: true,
      trackChanges: true,
      filter: true
    },
    stripe_access_payment_amount: {
      type: 'float',
      format: 'currency',
      save: true,
      trackChanges: true,
      filter: true
    },
    company_time_trial_start: {
      type: 'int',
      format: 'datetime',
      save: true,
      trackChanges: true,
      filter: true
    },
    oRegistrationTerms: {
      type: 'object',
      save: false,
      trackChanges: false
    },
    oTermsAcceptance: {
      type: 'object',
      save: false,
      trackChanges: false
    },
    company_is_in_trial: {
      type: 'int',
      save: false,
      reload: true,
      trackChanges: false
    },
    aoUsers: {
      type: 'array',
      save: false,
      reload: true,
      trackChanges: false,
      mapTo: 'user'
    },
    company_count_users: {
      type: 'int',
      save: false,
      reload: true,
      trackChanges: false
    },
    company_is_vendor_count: {
      type: 'int',
      save: false,
      reload: true,
      trackChanges: false
    },
    company_is_client_count: {
      type: 'int',
      save: false,
      reload: true,
      trackChanges: false
    },
    oPaymentPlan: {
      type: 'object',
      save: false,
      trackChanges: false,
      reload: true
    },
    company_has_email: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true
    },
    company_has_phone: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true
    },
    company_has_contact: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true
    },
    company_has_users: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true
    },
    franchisor_id: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true
    },
    company_bio: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    company_is_listed_publicly: {
      type: 'int',
      save: true,
      trackChanges: true,
      default: 1
    },
    company_is_ratings_public: {
      type: 'int',
      save: true,
      trackChanges: true,
      default: 0
    },
    asWorkdays: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: false,
      save: true,
      trackChanges: true
    },
    oWorkingHours: {
      type: 'object',
      filter: true,
      format: false,
      mapTo: false,
      save: true,
      trackChanges: true,
      default: () => ({
        start: 9,
        end: 17
      })
    },
    counterparty_status: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    oAccountManagers: {
      type: 'object',
      filter: true,
      format: false,
      mapTo: false,
      save: true
    },
    oPaymentTerms: {
      type: 'object',
      filter: true,
      format: false,
      mapTo: false,
      save: false
    },
    company_has_onboarded: {
      type: 'int',
      default: 0
    },
    company_onboarded_on_bolster_time_completed: {
      type: 'int',
      format: 'datetime',
      mapTo: false
    },
    aoFranchisees: {
      type: 'array'
    },
    company_is_franchisor: {
      type: 'int',
      default: 0,
      filter: true
    },
    default_live_price_zipcode: {
      type: 'string',
      default: 'company',
      format: false
    },
    asCompanyStripeCustomers: {
      type: 'array',
      filter: true,
      trackChanges: false,
      format: false,
      mapTo: false,
      save: false
    },
    lead_rotation_id: {
      type: 'int',
      save: true,
      saveChanges: true,
      trackChanges: true
    },
    presentation_file_ids: {
      type: 'array',
      mapTo: 'file'
    }
  },

  generateVueActions() {
    return {
      async inviteUsers({ dispatch }, payload) {
        const { id, users } = payload

        if (!users.length) return

        const { set } = await dispatch('ajax', {
          path: `company/inviteUsers/${id}`,
          data: { users }
        })

        dispatch(
          'alert',
          {
            message:
              'Each member of your team will receive a welcome email with a secure link allowing them to login automatically.',
            timeout: 10000
          },
          {
            root: true
          }
        )

        return {
          set
        }
      },
      async cancelSubscription({ dispatch }, payload) {
        const { id } = payload

        if (
          !(await dispatch(
            'modal/asyncConfirm',
            {
              message: 'Are you sure you want to cancel?',
              subMessage: 'Cancelling means all your data will be marked for deletion.'
            },
            { root: true }
          ))
        ) {
          return false
        }

        const reason = await dispatch(
          'modal/prompt',
          {
            message:
              'Before cancelling, can you please let us know why you want to cancel and most importantly how we can improve?',
            inputType: 'textarea',
            confirmText: 'Continue'
          },
          { root: true }
        )

        if (!reason) {
          throw new Error('Please enter a reason before proceeding.')
        }

        await dispatch('ajax', {
          path: `company/cancelSubscription/${id}`,
          data: {
            reason
          }
        })

        dispatch(
          'alert',
          {
            message:
              'Your subscription was succesfully canceled, and will take effect at the end of the billing cycle. Thank you for trying Bolster!',
            timeout: 10000
          },
          { root: true }
        )

        return true
      },

      async getSubscriptionDetails({ dispatch }, payload) {
        const { id } = payload

        const { object } = await dispatch('ajax', {
          path: `company/getSubscriptionDetails/${id}`
        })

        return object
      },
      async getPaymentMethod({ dispatch }, payload) {
        const { id } = payload

        const { object: paymentMethod } = await dispatch('ajax', {
          path: `company/getPaymentMethod/${id}`
        })

        return paymentMethod.stripe_payment_method
      },

      // async createSubscription({ dispatch }, payload) {
      //   const { id } = payload;

      //   // Stripe price_id for the price object, which relates to a product_id
      //   // which is the payment terms for that product ie: subscription value etc
      //   const { priceId, freeTrial = false, paymentMethodId = null, couponCode = null } = payload;

      //   const { object } = await dispatch('ajax', {
      //     path: `company/createSubscription/${id}`,
      //     data: {
      //       company_id: id,
      //       price_id: priceId,
      //       free_trial: freeTrial,
      //       payment_method_id: paymentMethodId,
      //       coupon_code: couponCode,
      //     },
      //   });

      //   if (object.status === 'incomplete'
      //     && (object.latest_invoice.payment_intent.status === 'requires_source_action'
      //       || object.latest_invoice.payment_intent.status === 'requires_action')) {
      //     const paymentIntent = object.latest_invoice.payment_intent;
      //     await dispatch('secureCardProcess', {
      //       paymentMethodId,
      //       paymentIntent,
      //       id,
      //     });
      //   }

      //   await dispatch('getBaseValues', { cloak: false }, { root: true });

      //   // Return the PaymentIntent's client_secret to reference the PaymentIntent via token
      //   return object.client_secret;
      // },

      // async secureCardProcess({ dispatch }, { id, paymentIntent, paymentMethodId }) {
      //   const stripe = await Stripe.getStripe();
      //   const returned = await stripe.confirmCardPayment(paymentIntent.client_secret, {
      //     payment_method: paymentMethodId,
      //   });

      //   if (returned.error) {
      //     dispatch('alert', {
      //       message: 'That card was declined. Please try another card.',
      //       error: true,
      //     }, { root: true });
      //     return returned;
      //   }

      //   const { object: subscription } = await dispatch('ajax', {
      //     path: `company/completeSubscription/${id}`,
      //   });

      //   await dispatch('getBaseValues', { cloak: true }, { root: true });

      //   return subscription;
      // },

      // async saveSubscription({ dispatch }, payload) {
      //   const { id } = payload;

      //   // Stripe price_id for the price object, which relates to a product_id
      //   // which is the payment terms for that product ie: subscription value etc
      //   const { subscriptionId } = payload;

      //   const { object } = await dispatch('ajax', {
      //     path: 'company/saveSubscription',
      //     data: {
      //       company_id: id,
      //       subscription_id: subscriptionId,
      //     },
      //   });

      //   // Return the PaymentIntent's client_secret to reference the PaymentIntent via token
      //   return subscriptionId;
      // },

      // async getPaymentIntent({ dispatch }, payload) {
      //   const { id } = payload;

      //   const { amount, currency = 'usd', off_session = false } = payload;

      //   const { object } = await dispatch('ajax', {
      //     path: 'company/getPaymentIntent',
      //     data: {
      //       amount,
      //       currency,
      //       company_id: id,
      //       off_session,
      //     },
      //   });

      //   // Return the PaymentIntent's client_secret to reference the PaymentIntent via token
      //   return object.client_secret;
      // },

      // async savePaymentMethod({ dispatch, rootState }, payload) {
      //   const { id } = payload;

      //   const { paymentMethod } = payload;

      //   const returned = await dispatch('ajax', {
      //     path: 'company/saveStripePaymentMethod',
      //     data: {
      //       payment_method: paymentMethod,
      //       company_id: id,
      //     },
      //   });

      //   if (String(id)
      //     === String(rootState.session.company && rootState.session.company.company_id)) {
      //     dispatch('getBaseValues', { cloak: false }, { root: true });
      //   }

      //   return returned;
      // },

      // async purchaseCoachingHours({ dispatch }, payload) {
      //   const { hours = 0 } = payload;

      //   if (hours <= 1) {
      //     throw new UserError({
      //       userMessage: 'Minimum purchase quantity is 1 hour',
      //     });
      //   }

      //   const { object } = await dispatch('resolveObject', payload);

      //   await dispatch('ajax', {
      //     path: `company/purchaseCoachingHours/${object.company_id}/${hours}`,
      //   });

      //   await ispatch('getBaseValues', {}, { root: true });

      //   dispatch('alert', {
      //     message: `Successfully purchased ${hours} hours`,
      //   }, { root: true });

      //   return true;
      // },

      async addUser({ dispatch }, payload) {
        const { user } = payload

        const { id } = await dispatch('resolveObject', payload)

        await dispatch('ajax', {
          path: `/company/addUser/${id}/${user}`
        })

        return true
      },
      async removeUser({ dispatch }, payload) {
        const { user } = payload

        const { id } = await dispatch('resolveObject', payload)

        await dispatch('ajax', {
          path: `/company/removeUser/${id}/${user}`
        })

        return true
      },
      async toggleModule({ dispatch, rootState }, { moduleName, onOrOff = null }) {
        const toggle =
          (typeof onOrOff === 'string' && _.ucfirst(onOrOff)) ||
          (rootState.session.company.aoModules[moduleName] ? 'Off' : 'On')
        await dispatch('ajax', {
          path: `/company/turnModule${toggle}/${moduleName}`
        })

        return dispatch('getBaseValues', {}, { root: true })
      },
      async turnModuleOn({ dispatch }, { moduleName }) {
        return await dispatch('toggleModule', {
          moduleName,
          onOrOff: 'On'
        })
      },
      async turnModuleOff({ dispatch }, { moduleName }) {
        return await dispatch('toggleModule', {
          moduleName,
          onOrOff: 'Off'
        })
      },
      async setModules({ dispatch }, { set }) {
        await dispatch('ajax', {
          path: '/company/setModules',
          data: {
            set
          }
        })
        return true
      },
      async getEnabledModuleIds({ dispatch }, payload) {
        const { object } = await dispatch('ajax', {
          path: 'module/getEnabledModuleIds',
          data: payload
        })
        return object.message
      },
      async setEnabledModuleIds({ dispatch }, payload) {
        const { object } = await dispatch('ajax', {
          path: 'module/setEnabledModuleIds',
          data: payload
        })
        return object.message
      },
      async getChosenAccountManagerIds({ dispatch }, payload) {
        const { object } = await dispatch('ajax', {
          path: 'company/getAccountManagerIds',
          data: payload
        })
        return JSON.parse(object[0].oAccountManagers)
      },
      async setChosenAccountManagerIds({ dispatch }, payload) {
        await dispatch('ajax', {
          path: 'company/setAccountManagerIds',
          data: payload
        })
      },
      async submitOnboardingData({ dispatch }, { companyId, data }) {
        await dispatch('ajax', {
          path: 'company/saveChanges',
          data: [
            {
              company_id: companyId,
              ...data
            }
          ]
        })
      },
      async createFranchisor({ dispatch }, payload) {
        await dispatch('ajax', {
          path: 'company/createFranchisor',
          data: payload
        })
      },
      async setFranchisees({ dispatch }, payload) {
        await dispatch('ajax', {
          path: 'company/setFranchisees',
          data: payload
        })
      },
      async getFranchiseeIds({ dispatch }, payload) {
        const { object } = await dispatch('ajax', {
          path: 'company/getFranchiseeIds',
          data: payload
        })
        return object
      },
      async linkStripeCustomer({ dispatch }, { companyId, stripeCustomerId }) {
        const { payload } = await dispatch('ajax', {
          path: 'company/linkStripeCustomer',
          data: {
            company_id: companyId,
            stripe_customer_id: stripeCustomerId
          }
        })

        return payload
      },
      async unlinkStripeCustomer({ dispatch }, { companyId, stripeCustomerId }) {
        const { payload } = await dispatch('ajax', {
          path: 'company/unlinkStripeCustomer',
          data: {
            company_id: companyId,
            stripe_customer_id: stripeCustomerId
          }
        })

        return payload
      }
    }
  }
}
