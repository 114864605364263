import ld from 'lodash'
import Pako from 'pako'
import DeepEqual from 'deep-equal'
import Fraction from 'fraction.js'
import cloneDeep from './DeepClone'
import { baseFileUrl, isDev } from './moduleUtil.js'

import { parsePhoneNumberFromString } from 'libphonenumber-js/max'
import Throttle from './Throttle'
import Statuses from './Statuses'
import Shorten from './Shorten'
import FieldDetection from './FieldDetection'
import moment from 'moment'

const { statuses } = Statuses

const stackTrace = () => {
  const err = new Error()
  return err.stack
}

const btoa = (str) => {
  let buffer

  if (str instanceof Buffer) {
    buffer = str
  } else {
    buffer = Buffer.from(str.toString(), 'binary')
  }

  return buffer.toString('base64')
}

const immutable = (object) =>
  cloneDeep(object, (value) => {
    if (ld.isNaN(value)) {
      return null
    }
  })
const clone = immutable
const imm = immutable

const coalesce = (object1, object2) => {
  const merged = {}
  const keys = Object.keys({ ...object1, ...object2 })
  keys.forEach((key) => {
    merged[key] =
      typeof object2[key] === 'undefined' || object2[key] === null ? object1[key] : object2[key]
  })
  return merged
}

const encode = (string) => Pako.deflate(string, { to: 'string' })
const compress = encode
const decode = (string) => Pako.inflate(string, { to: 'string' })
const decompress = decode
const encodeURI = (string) => window && window.encodeURIComponent(window.btoa(encode(string)))
const decodeURI = (string) => window && decode(window.atob(window.decodeURIComponent(string)))

const idFromText = (text) =>
  String(text || '')
    .trim()
    .replace('&nbsp;', '')
    .replace(/\s|\\|\/|\+/g, '_')
    .replace(/__/g, '_')
    .replace(/[^a-zA-Z_]/g, '')
    .replace(/(^_|_$)/g, '')
    .toLowerCase()

const conversionTables = {
  in: {
    baseMeasure: 'in',
    measureType: 'length',
    unit_of_measure_ids: ['in'],
    area: 'in2',
    volume: 'in3',
    conversions: {
      ft: 'from / 12',
      lisq: 'from / 120',
      m: '((from / 12) / 3.280839895013123)',
      mm: '((from / 12) / 3.280839895013123) * 1000',
      yd: '(from / 12) / 3',
      km: '((from / 12) / 3.280839895013123)/1000',
      mi: 'from/(5280*12)',
      cm: 'from * 2.54' // Added conversion: inches to centimeters
    }
  },
  lisq: {
    // hidden to calculate square
    baseMeasure: 'lisq',
    measureType: 'length',
    area: 'sqr',
    volume: null,
    unit_of_measure_ids: ['lisq'],
    conversions: {
      m: '(from/3.280839895013123) * 10',
      mm: '((from/3.280839895013123)*1000) * 10',
      yd: '(from/3) * 10',
      in: '(from * 10) * 12',
      ft: 'from * 10',
      mi: 'from/528', // 10ft to miles
      km: 'from/3280.8399' // 10ft to kilometers
    }
  },
  ft: {
    baseMeasure: 'ft',
    measureType: 'length',
    area: 'ft2',
    volume: 'ft3',
    unit_of_measure_ids: ['ft', 10, 605, 24, 601, 602],
    conversions: {
      m: 'from/3.280839895013123',
      mm: '(from/3.280839895013123)*1000',
      yd: 'from/3',
      in: 'from * 12',
      lisq: 'from / 10',
      mi: 'from/5280',
      km: 'from/3280.8399',
      cm: 'from * 30.48' // Added conversion: feet to centimeters
    }
  },
  mi: {
    baseMeasure: 'mi',
    measureType: 'length',
    unit_of_measure_ids: ['mi'],
    conversions: {
      m: 'from*1609.344', // Miles to meters
      mm: 'from*1609344', // Miles to millimeters
      ft: 'from*5280', // Miles to feet
      yd: 'from*1760', // Miles to yards
      in: 'from*63360', // Miles to inches
      cm: 'from*160934.4', // Miles to centimeters
      km: 'from*1.609344', // Miles to kilometers
      nmi: 'from*0.869' // Miles to nautical miles
    }
  },
  ft2: {
    baseMeasure: 'ft',
    measureType: 'area',
    unit_of_measure_ids: ['ft2', 603, 607, 604, 600, 23, 9],
    conversions: {
      m2: 'from/10.763910416709722', // Square feet to sqr meters
      mm2: '(from/10.763910416709722)*1000000', // Square feet to sqr millimeters
      yd2: 'from/9', // Square feet to sqr yards
      sqr: 'from/100', // Square feet to sqr
      in2: 'from*144', // Square feet to sqr inches
      cm2: 'from*929.0304', // Square feet to sqr centimeters
      acre: 'from/43560', // Square feet to acres
      ha: 'from/107639.104', // Square feet to hectares
      mi2: 'from/27878400' // Square feet to sqr miles
    }
  },
  in2: {
    baseMeasure: 'in',
    measureType: 'area',
    unit_of_measure_ids: ['in2'],
    conversions: {
      m2: 'from/1550.0031', // Square inches to square meters
      mm2: 'from*645.16', // Square inches to square millimeters
      yd2: 'from/1296', // Square inches to square yards
      sqr: 'from/100', // Square inches to square
      cm2: 'from*6.4516', // Square inches to square centimeters
      ft2: 'from/144', // Square inches to square feet
      acre: 'from/6272640', // Square inches to acres
      ha: 'from/15500031' // Square inches to hectares
    }
  },
  ha: {
    baseMeasure: 'm',
    measureType: 'area',
    unit_of_measure_ids: ['ha'],
    conversions: {
      m2: 'from*10000',
      mm2: 'from*100000000',
      ft2: 'from*107639.10416709722',
      yd2: 'from*11959.900463',
      sqr: 'from*1.0',
      in2: 'from*15500031',
      acre: 'from*2.4710538147',
      mi2: 'from*0.003861',
      cm2: 'from * 100000000' // Added conversion: hectares to square centimeters
    }
  },

  acre: {
    baseMeasure: 'ft',
    measureType: 'area',
    unit_of_measure_ids: ['acre'],
    conversions: {
      m2: 'from*4046.8564224',
      mm2: 'from*4046856422.4',
      ft2: 'from*43560',
      yd2: 'from*4840',
      sqr: 'from*0.4046856422',
      in2: 'from*6272640',
      ha: 'from/2.4710538147',
      mi2: 'from/640',
      cm2: 'from*40468564.224' // Added conversion: acres to square centimeters
    }
  },
  sqr: {
    baseMeasure: 'lisq',
    measureType: 'area',
    unit_of_measure_ids: ['sqr'],
    conversions: {
      ft2: 'from*100', // Square to square feet
      m2: 'from/0.10763910416709722', // Square to square meters
      mm2: '(from/0.10763910416709722)*1000000', // Square to square millimeters
      yd2: 'from/0.09', // Square to square yards
      in2: 'from*14400', // Square to square inches
      cm2: 'from*92903.04', // Square to square centimeters
      acre: 'from/0.00229568', // Square to acres
      ha: 'from/0.0107639104', // Square to hectares
      mi2: 'from/0.0000386102' // Square to square miles
    }
  },
  ft3: {
    baseMeasure: 'ft',
    measureType: 'volume',
    unit_of_measure_ids: ['ft3'],
    conversions: {
      m3: 'from/35.31466672148858',
      mm3: '(from/35.31466672148858)*1000000000',
      yd3: 'from/27',
      cm3: 'from * 28316.846592' // Added conversion: cubic feet to cubic centimeters
    }
  },
  m: {
    baseMeasure: 'm',
    measureType: 'length',
    area: 'm2',
    volume: 'm3',
    unit_of_measure_ids: ['m', 29, 610, 612, 613, 617],
    conversions: {
      ft: 'from*3.280839895013123',
      mm: 'from*1000',
      yd: 'from*1.09361329834',
      in: 'from * 3.280839895013123 * 12',
      lisq: '(from*3.280839895013123) / 10',
      mi: 'from/1609.344',
      km: 'from/1000',
      cm: 'from * 100' // Added conversion: meters to centimeters
    }
  },
  m2: {
    baseMeasure: 'm',
    measureType: 'area',
    unit_of_measure_ids: ['m2', 608, 609, 611, 614, 615, 616],
    conversions: {
      ft2: 'from*10.763910416709722', // Square meters to square feet
      mm2: 'from*1000000', // Square meters to square millimeters
      yd2: 'from*1.1959900463', // Square meters to square yards
      sqr: 'from*0.10763910416709722', // Square meters to square
      in2: 'from*1550.0031', // Square meters to square inches
      cm2: 'from*10000', // Square meters to square centimeters
      acre: 'from/4046.8564224', // Square meters to acres
      ha: 'from/10000', // Square meters to hectares
      mi2: 'from/2589988.1103' // Square meters to square miles
    }
  },
  m3: {
    baseMeasure: 'm',
    measureType: 'volume',
    unit_of_measure_ids: ['m3'],
    conversions: {
      ft3: 'from*35.31466672148858',
      mm3: 'from*1000000000',
      yd3: 'from*1.3079506193143917',
      cm3: 'from * 1000000' // Added conversion: cubic meters to cubic centimeters
    }
  },
  mm: {
    baseMeasure: 'mm',
    measureType: 'length',
    area: 'mm2',
    volume: 'mm3',
    unit_of_measure_ids: ['mm'],
    conversions: {
      ft: '(from/1000)*3.280839895013123',
      m: 'from/1000',
      yd: '(from/1000)*1.09361329834',
      in: '((from/1000)*3.280839895013123)*12',
      lisq: '((from/1000)*3.280839895013123)/10',
      mi: 'from/1609344',
      km: 'from/1000000',
      cm: 'from / 10' // Added conversion: millimeters to centimeters
    }
  },
  mm2: {
    baseMeasure: 'mm',
    measureType: 'area',
    unit_of_measure_ids: ['mm2'],
    conversions: {
      ft2: '(from/1000000)*10.763910416709722', // Square millimeters to square feet
      m2: 'from/1000000', // Square millimeters to square meters
      yd2: '(from/1000000)*1.1959900463', // Square millimeters to square yards
      sqr: '(from/1000000)*0.10763910416709722', // Square millimeters to square
      in2: 'from/645.16', // Square millimeters to square inches
      cm2: 'from/100', // Square millimeters to square centimeters
      acre: 'from/4046856422.4', // Square millimeters to acres
      ha: 'from/10000000000', // Square millimeters to hectares
      mi2: 'from/2589988110336' // Square millimeters to square miles
    }
  },
  mm3: {
    baseMeasure: 'mm',
    measureType: 'volume',
    unit_of_measure_ids: ['mm3'],
    conversions: {
      ft3: '(from/1000000000)*35.31466672148858',
      m3: 'from/1000000000',
      yd3: '(from/1000000000)*1.3079506193143917',
      cm3: 'from / 1000' // Added conversion: cubic millimeters to cubic centimeters
    }
  },
  yd: {
    baseMeasure: 'yd',
    measureType: 'length',
    area: 'yd2',
    volume: 'yd3',
    unit_of_measure_ids: ['yd'],
    conversions: {
      m: 'from/1.09361329834',
      mm: '(from/1.09361329834)*1000',
      ft: 'from*3',
      in: 'from * 3 * 12',
      lisq: '(from*3)/10',
      mi: 'from/1760',
      km: 'from/1093.61329834',
      cm: 'from * 91.44' // Added conversion: yards to centimeters
    }
  },
  yd2: {
    baseMeasure: 'yd',
    measureType: 'area',
    unit_of_measure_ids: ['yd2'],
    conversions: {
      m2: 'from/1.1959900463', // Square yards to square meters
      mm2: '(from/1.1959900463)*1000000', // Square yards to square millimeters
      ft2: 'from*9', // Square yards to square feet
      sqr: 'from*0.09', // Square yards to square
      in2: 'from*1296', // Square yards to square inches
      cm2: 'from*8361.2736', // Square yards to square centimeters
      acre: 'from/4840', // Square yards to acres
      ha: 'from/11959.900463', // Square yards to hectares
      mi2: 'from/3097600' // Square yards to square miles
    }
  },
  yd3: {
    baseMeasure: 'yd',
    measureType: 'volume',
    unit_of_measure_ids: ['yd3'],
    conversions: {
      m3: 'from/1.3079506193143917',
      mm3: '(from/1.3079506193143917)*1000000000',
      ft3: 'from*27',
      cm3: 'from * 764554.857984' // Added conversion: cubic yards to cubic centimeters
    }
  },
  kg: {
    baseMeasure: 'kg',
    measureType: 'weight',
    unit_of_measure_ids: ['kg'],
    conversions: {
      lbs: 'from*2.20462',

      ton: 'from/907.18474', // Kilograms to ton
      t: 'from/1000' // Kilograms to metric ton
    }
  },
  lbs: {
    baseMeasure: 'lbs',
    measureType: 'weight',
    unit_of_measure_ids: ['lbs'],
    conversions: {
      kg: 'from/2.20462',
      ton: 'from/2000', // Pounds to ton
      t: 'from/2204.62262' // Pounds to metric ton
    }
  },
  ton: {
    baseMeasure: 'ton',
    measureType: 'weight',
    unit_of_measure_ids: ['ton'],
    conversions: {
      kg: 'from*907.18474', // Ton to kilograms
      lbs: 'from*2000', // Ton to pounds
      t: 'from/1.102311' // Ton to metric ton
    }
  },

  t: {
    baseMeasure: 't',
    measureType: 'weight',
    unit_of_measure_ids: ['t'],
    conversions: {
      kg: 'from*1000', // Metric ton to kilograms
      lbs: 'from*2204.62262', // Metric ton to pounds
      ton: 'from*1.102311' // Metric ton to ton
    }
  },
  cm: {
    baseMeasure: 'cm',
    measureType: 'length',
    area: 'cm2',
    volume: 'cm3',
    unit_of_measure_ids: ['cm'],
    conversions: {
      m: 'from/100', // centimeters to meters
      mm: 'from*10', // centimeters to millimeters
      in: 'from/2.54', // centimeters to inches
      ft: 'from/30.48', // centimeters to feet
      yd: 'from/91.44', // centimeters to yards
      mi: 'from/160934.4', // centimeters to miles
      km: 'from/100000' // centimeters to kilometers
    }
  },
  cm2: {
    baseMeasure: 'cm',
    measureType: 'area',
    unit_of_measure_ids: ['cm2'],
    conversions: {
      m2: 'from/10000', // square centimeters to square meters
      mm2: 'from*100', // square centimeters to square millimeters
      ft2: 'from/929.0304', // square centimeters to square feet
      yd2: 'from/8361.2736', // square centimeters to square yards
      in2: 'from/6.4516', // square centimeters to square inches
      acre: 'from/40468564.224', // square centimeters to acres
      ha: 'from/100000000', // square centimeters to hectares
      mi2: 'from/25899881103.36' // square centimeters to square miles
    }
  },
  cm3: {
    baseMeasure: 'cm',
    measureType: 'volume',
    unit_of_measure_ids: ['cm3'],
    conversions: {
      m3: 'from/1000000', // cubic centimeters to cubic meters
      mm3: 'from*1000', // cubic centimeters to cubic millimeters
      ft3: 'from/28316.846592', // cubic centimeters to cubic feet
      yd3: 'from/764554.857984' // cubic centimeters to cubic yards
    }
  }
}

/**
 * Tells us if we can link a given unit of measure
 * to an assembly/quote dimension
 * @param id    unit of measure id
 * @returns {boolean}
 */
const isUnitOfMeasureLinkable = (id) =>
  !!Object.keys(conversionTables).find(
    (abbr) =>
      conversionTables[abbr].unit_of_measure_ids.includes(+id) ||
      conversionTables[abbr].unit_of_measure_ids.includes(String(id))
  )

/**
 * Gets the basic measure for a unit of measure
 * for exmple, m2, or ft (linear foot), or mm2 etc,
 * so that it can be used to find a conversion table above.
 * @param id    unit of measure id
 * @returns {string | undefined}
 */
const getMeasureForUnitOfMeasure = (id) => {
  if (id in conversionTables) return id

  // Old
  return Object.keys(conversionTables).find(
    (m) =>
      conversionTables[m].unit_of_measure_ids.includes(String(id)) ||
      conversionTables[m].unit_of_measure_ids.includes(+id)
  )
}

const getMeasureTypeForUnitOfMeasure = (id) => {
  const m = getMeasureForUnitOfMeasure(id)
  if (m in conversionTables) {
    return conversionTables[m].measureType
  }

  return 'count'
}

const isImageFileType = (fileType) => /png|jpg|gif|jpeg|tif|bmp|ico/.test(fileType)

const defaultAddon = {
  name: 'Option',

  // object type
  type: 'assembly',

  // object id
  id: null,
  uid: null,

  // replace or option
  // replace replaces a different item
  // option adds to parent
  // for cost_items/_types, it is always
  // a replace!
  addonType: 'replace',

  // sibling or anywhere
  // for sibling, this option will show
  // if this item does not exist in
  // the immediate siblings, or for an assembly
  // also within its children
  // For a 'anywhere' option, it cannot exist anywhere
  // in the active quote tree.
  preventIf: 'sibling',

  // Picture file id
  file_id: null,
  image_external: null,

  bulk: null,

  original: null,

  // array of dimension keys required for each addon
  // ie: iwa,ewa,ca,fa etc
  asDimensionsRequired: [],

  embue: {},

  // Cache
  price: null,
  qty: null,
  equation: null,
  revision: null,
  isgrp: 0,
  usages: 0,
  desc: '',
  tag: '',
  rating: null, // out of 3
  unit: null,
  markup: null,
  targetKey: null, // [0.0000, 0.0000, 0.0000], // price, cost, qty
  errors: []
}

/**
 * A default dimension with all minimum required key value pairs
 * @type {object}
 */
const defaultDimension = {
  // Whether it is linked to its parent equiv of the dimension
  inherit: 0,
  // Value of dimension
  value: null,
  // The unit of measure of the value ^^
  measure: null, // 'm', 'ft, 'm2', 'mm' etc
  measureType: null, // area/length
  // The dimension type this corresponds to
  dimension_id: null,
  // Computed/default value
  defaultValue: null,
  // Whether this is explicitly set, or was just set
  // as a result of a default value, in which case it should
  // recalculate
  explicitlySet: 0,
  // User-set equation for calculator component
  equation: '',
  // Show in presentation to customer, oPresentationSettings.showAssemblyDimensions must ALSO be 1
  showCustomer: 1,
  // manual sorting
  sort: null
}

/**
 * List out all possible dimensions
 * @type {object}
 */
const deprecatedDimensions = {
  fa: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'area', // area/length
    measures: ['ft2', 'm2', 'mm2'],
    dimension_type_id: 100,
    abbr: 'fa',
    unit_of_measure_ids: [9, 608],
    baseMeasures: {
      9: 'ft2',
      608: 'm2'
    },
    name: 'Floor Area'
  },
  fp: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'area', // area/length
    measures: ['ft2', 'm2', 'mm2'],
    dimension_type_id: 150,
    defaultValue: 'fa',
    equation: 'fa',
    isAdvanced: 1,
    abbr: 'fp',
    unit_of_measure_ids: [600, 609],
    baseMeasures: {
      600: 'ft2',
      609: 'm2'
    },
    name: 'Building Footprint'
  },
  iwp: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'length', // area/length
    measures: ['ft', 'm', 'mm'],
    dimension_type_id: 200,
    abbr: 'iwp',
    unit_of_measure_ids: [10, 29],
    baseMeasures: {
      10: 'ft',
      29: 'm'
    },
    name: 'Interior Wall Perimeter'
  },
  iwh: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'length', // area/length
    measures: ['ft', 'm', 'mm'],
    dimension_type_id: 210,
    abbr: 'iwh',
    unit_of_measure_ids: [24, 610],
    baseMeasures: {
      24: 'ft',
      610: 'm'
    },
    name: 'Interior Wall Height'
  },
  ca: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'area', // area/length
    measures: ['ft2', 'm2', 'mm2'],
    defaultValue: 'fa',
    equation: 'fa',
    inherit: 0,
    dimension_type_id: 215,
    abbr: 'ca',
    unit_of_measure_ids: [607, 616],
    baseMeasures: {
      607: 'ft2',
      616: 'm2'
    },
    name: 'Ceiling Area'
  },
  iwa: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'area', // area/length
    measures: ['ft2', 'm2', 'mm2'],
    defaultValue: 'iwp * iwh',
    equation: 'iwp * iwh',
    inherit: 0,
    dimension_type_id: 220,
    abbr: 'iwa',
    unit_of_measure_ids: [23, 611],
    baseMeasures: {
      23: 'ft2',
      611: 'm2'
    },
    name: 'Interior Wall Area'
  },
  ewp: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'length', // area/length
    measures: ['ft', 'm', 'mm'],
    dimension_type_id: 300,
    abbr: 'ewp',
    unit_of_measure_ids: [601, 612],
    baseMeasures: {
      601: 'ft',
      612: 'm'
    },
    name: 'Exterior Wall Perimeter'
  },
  ewh: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'length', // area/length
    measures: ['ft', 'm', 'mm'],
    dimension_type_id: 310,
    abbr: 'ewh',
    unit_of_measure_ids: [602, 613],
    baseMeasures: {
      602: 'ft',
      613: 'm'
    },
    name: 'Exterior Wall Height'
  },
  ewa: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'area', // area/length
    measures: ['ft2', 'm2', 'mm2'],
    defaultValue: 'ewp * ewh',
    equation: 'ewp * ewh',
    inherit: 0,
    dimension_type_id: 320,
    abbr: 'ewa',
    unit_of_measure_ids: [603, 614],
    baseMeasures: {
      603: 'ft2',
      614: 'm2'
    },
    name: 'Exterior Wall Area'
  },
  rp: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'length', // area/length
    measures: ['ft', 'm', 'mm'],
    // defaultValue: '12/6',
    dimension_type_id: 500,
    isAdvanced: 1,
    abbr: 'rp',
    unit_of_measure_ids: [],
    baseMeasures: {},
    name: 'Roof Perimeter'
  },
  rl: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'length', // area/length
    measures: ['ft', 'm', 'mm'],
    dimension_type_id: 510,
    isAdvanced: 1,
    abbr: 'rl',
    unit_of_measure_ids: [605, 510],
    baseMeasures: {
      605: 'ft',
      510: 'm'
    },
    name: 'Roof Length'
  },
  ra: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'area', // area/length
    measures: ['ft2', 'm2', 'mm2'],
    defaultValue: 'fp * 1.2',
    equation: 'fp * 1.2',
    dimension_type_id: 520,
    isAdvanced: 1,
    abbr: 'ra',
    unit_of_measure_ids: [604, 615],
    baseMeasures: {
      604: 'ft2',
      615: 'm2'
    },
    name: 'Roof Area'
  },
  la: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'area', // area/length
    measures: ['ft2', 'm2', 'mm2'],
    dimension_type_id: 900,
    isAdvanced: 1,
    abbr: 'la',
    unit_of_measure_ids: [],
    baseMeasures: {},
    name: 'Land Area'
  },
  lp: {
    ...defaultDimension,
    measure: null, // 'm', 'ft, 'm2', 'mm' etc
    measureType: 'length', // area/length
    measures: ['ft', 'm', 'mm'],
    dimension_type_id: 910,
    isAdvanced: 1,
    abbr: 'lp',
    unit_of_measure_ids: [],
    baseMeasures: {},
    name: 'Land Perimeter'
  }
}

/**
 * All possible countries
 * @type {object}
 */
const countries = {
  1: {
    name: 'Canada',
    abbr: 'ca'
  },
  2: {
    name: 'United States',
    abbr: 'us'
  },
  3: {
    name: 'United Kingdom',
    abbr: 'uk'
  },
  4: {
    name: 'Australia',
    abbr: 'au'
  },
  5: {
    name: 'New Zealand',
    abbr: 'nz'
  },
  6: {
    name: 'South Africa',
    abbr: 'za'
  },
  7: {
    name: 'Ireland',
    abbr: 'ie'
  }
}

const validations = {
  name_full: {
    test: /^\w+\s\w+/,
    message: 'Please enter your full name ie: "Jane Smith".'
  },
  email: {
    test: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    message: 'Please enter a valid email. (ie: "name@host.com")'
  },
  email_list: {
    test: /^\s|,*(?:([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})|,\b|,\s*)+$/i,
    message: 'Please enter a valid list of emails. (ie: "name@host.com, othername@otherhost.com")'
  },
  phone_ca: {
    test: /^(\+?)(1?)(-?)(\s?)(\.?)(\(?)[2-9][0-9][0-9](\)?)(-?)(\s?)(\.?)(([2-9][2-9][2-9])|([2-9][0-9]([2-9]|0))|([2-9]([2-9]|0)[0-9]))(-?)(\s?)(\.?)[0-9]{4}$/,
    message: 'Please enter a valid Canadian phone number. (ie: "403-321-1234")'
  },
  phone_us: {
    test: /^(\+?)(1?)(-?)(\s?)(\.?)(\(?)[2-9][0-9][0-9](\)?)(-?)(\s?)(\.?)(([2-9][2-9][2-9])|([2-9][0-9]([2-9]|0))|([2-9]([2-9]|0)[0-9]))(-?)(\s?)(\.?)[0-9]{4}$/,
    message: 'Please enter a valid North American phone number. (ie: "555-555-5555")'
  },
  // phone_nz: {
  //   test: /^(\+?)(64)?(-?)(\s?)(\.?)(\d(\d?)(\d?))\d{7}$/,
  //   message: 'Please enter a valid New Zealand phone number.',
  // },
  postal_ca: {
    test: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}(-?)(\s?)(\.?)\d{1}[A-Z]{1}\d{1}$/i,
    message: 'Please enter a valid Canadian postal-code. (ie: "T2G-0X2")'
  },
  // postal: {
  //   test: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1}(-?)(\s?)(\.?)\d{1}[A-Z]{1}\d{1}$/i,
  //   message: 'Please enter a valid postal-code. (ie: "T2G-0X2")',
  // },
  postal_us: {
    test: /^\d{5}(?:[-\s]\d{4})?$/i,
    message: 'Please enter a valid US zip-code. (ie: "90210")'
  },
  date: {
    test: new RegExp(
      '((([0][1-9]|[12][\\d])|[3][01])[-/]([0][13578]|[1][02])[-/][1-9]\\d\\d\\d)|((([0][1-9]|[12][\\d])|[3][0])[-/]([0][13456789]|[1][012])[-/][1-9]\\d\\d\\d)|(([0][1-9]|[12][\\d])[-/][0][2][-/][1-9]\\d([02468][048]|[13579][26]))|(([0][1-9]|[12][0-8])[-/][0][2][-/][1-9]\\d\\d\\d)',
      'i'
    ),
    message: 'Please enter a valid date. (ie: "dd-mm-yyyy")'
  },
  imperial: {
    test: /(?:(\d+)\s?(?:ft|'|feet|foot))\s?(?:(\d+)\s(\d{1,2}\/\d{1,2})|(\d{1,2}\/\d{1,2})|(\d+))\s?(?:in|inch|inches|")|(?:(?:(\d+)(?:ft|'|feet|foot))(?:(\d+)))|(?:(?:(\d+)\s?(?:ft|'|feet|foot))|(?:(?:(\d+)\s)?(\d{1,2}\/\d{1,2})\s?(?:in|inch|inches|"))|(?:(\d+)\s?(?:in|inch|inches|")))/i,
    message: 'Please enter a valid ft/in notation (ie: 3ft2, 3ft2in or 3\' 2 3/4" etc)'
  },
  routing_ca_number: {
    test: /^\d{8}$/,
    message:
      'Routing number must be eight digits. five-digit transit and a three-digit financial institution number.'
  },
  routing_us_number: {
    test: /^\d{9}$/,
    message: 'Routing number must be nine digits'
  },
  account_number: {
    test: /^\d{7,17}$/,
    message: 'Account number must be between 8-17 digits'
  }
}

/**
 * ['a','b','a','',null] //=> ['a','b']
 * @param array
 * @param forceRemoveBlanks
 * @returns {*}
 */
const cleanArray = (array = [], forceRemoveBlanks = false, unique = true) => {
  let newArray = array
  if (Array.isArray(array) && array.length) {
    newArray = array.filter(
      (item) =>
        !(
          typeof item === 'undefined' ||
          (item === '' && forceRemoveBlanks) ||
          item === null ||
          (typeof item === 'number' && isNaN(item))
        )
    )
  }
  return unique ? ld.uniq(newArray) || [] : newArray || []
}

/**
 * Removes tags, leaves the text between tags.
 * <i>hello</i> //=> hello
 * hi<i>there</i> //=> hithere
 * @param sText
 * @returns {string}
 */
const removeHtml = (sText) =>
  $('<textarea />')
    .html(
      String(sText || '')
        .replace(/<script(.|\n)*?>(.|\n)*?<\/script>/gi, '')
        .replace(/<style(.|\n)*?>(.|\n)*?<\/style>/gi, '')
        .replace(/<meta(.|\n)*?>(.|\n)*?<\/meta>/gi, '')
        .replace(/(<br>|<br\/>)/gi, '\r\n')
        .replace(/(<(.|\n)*?>)/gi, '')
    )
    .text()

/**
 * Quickly returns a 0 if number is NaN
 *
 * Example:
 * let a = 'abcd';             //=> 'abcd'
 * let b = 10;                //=> 10
 * let c = 1 / a;            //=> NaN
 * let d = b - notNaN(c);   //=> 10
 *
 * Add default:
 * let a = 'abcd';              //=> 'abcd'
 * let b = 10;                 //=> 10
 * let c = 1 / a;             //=> NaN
 * let d = b - notNaN(c, 1); //=> 9
 *
 * @param n
 * @param alt
 * @returns {number}
 */
const notNaN = (n, alt = 0) => {
  const numVal = +String(n).replace(/[^-\d.]/g, '')
  return isNaN(numVal) ? alt : numVal
}

/**
 * Tests if the arguments provided are empty or not.
 * Multiple arguments tests if any of the given arguments are empty.
 * let a = 0;
 * let b = null;
 * let c = 'false';
 * let d = true;
 *
 * isempty(a) //=> true
 * isempty(b) //=> true
 * isempty(c) //=> false
 * isempty(d) //=> false
 * isempty(e) //=> true
 * isempty(a, b, c, d) //=> true
 *
 * @param args
 * @returns {boolean}
 */
const isempty = (...args) =>
  args.reduce(
    (acc, arg) =>
      acc +
      (typeof arg === 'undefined' ||
      arg === 'undefined' ||
      arg === '-' ||
      arg === 'NaN' ||
      arg === null ||
      arg === '' ||
      arg === 0 ||
      arg === false ||
      (Array.isArray(arg) && arg.length < 1) ||
      (typeof arg === 'number' && isNaN(arg))
        ? 1
        : 0),
    0
  ) === args.length

/**
 * Returns true for scalar values AND null values
 * @param value
 * @returns {boolean}
 */
const isScalar = (value) =>
  typeof value === 'boolean' ||
  typeof value === 'string' ||
  typeof value === 'number' ||
  value === null

/**
 * Same ase isempty() except the numeric 0 does not return true.
 * In isnan() 0 is valid.
 * @param args
 */
const isnan = (...args) =>
  args.reduce(
    (acc, arg) =>
      acc +
      (typeof arg === 'undefined' ||
      arg === 'undefined' ||
      arg === '-' ||
      arg === 'NaN' ||
      arg === null ||
      arg === '' ||
      arg === false ||
      (Array.isArray(arg) && arg.length < 1) ||
      (typeof arg === 'number' && isNaN(arg))
        ? 1
        : 0),
    0
  ) === args.length

const imperialRegex =
  /(?:\b|^|\s)(?:(?:(?:(\d+)\s?(?:ft|'|feet|foot))\s?(?:(\d+)\s(\d{1,2}\/\d{1,2})|(\d{1,2}\/\d{1,2})|(\d+))\s?(?:in|inch|inches|"))|(?:(?:(\d+)(?:ft|'|feet|foot))(?:(\d+)))|(?:(\d+)\s?(?:ft|'|feet|foot))|(?:(?:(\d+)\s)?(\d{1,2}\/\d{1,2})\s?(?:in|inch|inches|"))|(?:(\d+)\s?(?:in|inch|inches|")))(?=\b|$|\s?|\))/i
const hasImperialNotation = (s) => imperialRegex.test(s)

/**
 * imperialToDecimal
 * Convert feet/inch notation into FOOT decimals
 * '3ft 2 3/4"'   //=> 3.2291666666666665
 * '3ft2 3/4'     //=> 3.2291666666666665
 * '3ft2 3/4in'   //=> 3.2291666666666665
 * '3\'2 3/4in'   //=> 3.2291666666666665
 * '3\' 2 3/4"'   //=> 3.2291666666666665
 * '3\'2'         //=> 3.1666666666666665
 * '3ft2'         //=> 3.1666666666666665
 * '3ft2in'       //=> 3.1666666666666665
 * '3\'2"'        //=> 3.1666666666666665
 * '3\' 3/4'      //=> 3.0625
 * '3\' 3/4"'     //=> 3.0625
 * '3\' 3/4in'    //=> 3.0625
 * '3ft 3/4in'    //=> 3.0625
 * '3/4in'        //=> 0.0625
 * '900in'        //=> 75 (feet)
 * @param imperialNotation
 * @returns {number}
 */
const imperialToDecimal = (imperialNotation) => {
  if (hasImperialNotation(imperialNotation)) {
    const matches = String(imperialNotation).match(imperialRegex)
    return (
      (+matches[1] || +matches[6] || +matches[8] || 0) +
      ld.divide(+matches[2] || +matches[5] || +matches[7] || +matches[9] || +matches[11] || 0, 12) +
      ld.divide(notNaN(eval(matches[3] || matches[4] || matches[10] || 0)), 12)
    )
  }
  return +imperialNotation
}

/**
 * replaceImperialNotation
 * Replaces all instances of imperial notation in a string to decimal notation
 * '3ft2*2in' //=> '3.1666666666666665*0.16666666666666666'
 * 'hall: (3ft2*2in) + bedroom: (3\'1 3/4")'
 *    //=> "hall: (3.1666666666666665*0.16666666666666666) + bedroom: (3.1458333333333335)"
 * @param string
 */
const replaceImperialNotation = (string) =>
  String(string).replace(new RegExp(imperialRegex, 'ig'), (...matches) =>
    matches[0] ? `${imperialToDecimal(matches[0])}` : matches[0]
  )

/**
 * Converts 00h 00m 00s into time or 00:00:00
 * @param hrs
 * @returns {number}
 */
const hoursRegex =
  /\b(?:(\d+)(?:(?:\s?h(?:rs?)?(?:ours?)?\s?)|:))?(?:(\d+)(?:(?:\s?m(?:ins?)?(?:inutes?)?\s?)|:))?(?:(\d+)\s?s(?:ecs?)?(?:econds?)?)?\b/i
const fromHours = (hrs = 0) => {
  if (typeof hrs === 'number') return hrs
  if (!/m|s|h/gi.test(String(hrs))) return +String(hrs).replace(/[^0-9.-]/gi, '')
  const hrString = String(hrs)
  const re = hoursRegex

  if (re.test(hrString)) {
    const [, hr = 0, min = 0, sec = 0] = hrString.match(re)
    return notNaN(hr) + notNaN(min / 60) + notNaN(sec / (60 * 60))
  }
  return +hrs
}

/**
 * replaceHoursNotation
 * Replaces all instances of HMS notation ie 1h 2m 33s to its decimal equivalent within strings
 * '3ft2*2in' //=> '3.1666666666666665*0.16666666666666666'
 * 'hall: (3ft2*2in) + bedroom: (3\'1 3/4")'
 *    //=> "hall: (3.1666666666666665*0.16666666666666666) + bedroom: (3.1458333333333335)"
 * @param string
 */
const replaceHoursNotation = (string) => {
  const parts = String(string || '').split(/(?=[^a-z0-9\s.])/)
  const replacedParts = parts.map((part) => {
    if (!hoursRegex.test(part)) {
      return part
    }

    return part.replace(hoursRegex, (...matches) =>
      matches[0] ? `${fromHours(String(matches[0]))}` : matches[0]
    )
  })

  return replacedParts.join('')
}

/**
 * Calc functions
 * @type {{min: string, sqrt: string, max: string, ceil: string, floor: string}}
 */
const functions = {
  if: {
    full: '$if',
    desc: 'Return different values if test is true or false: if(test==true, trueValue, falseValue)',
    start: 'if(ROF=0, 1, 2)',
    def: `function $if(test, yes, no) {
      return (test) ? yes : no
    }`
  },
  and: {
    full: '$and',
    desc: 'Check that all the values are true or not zero: if(and(a, b, ...), ...)',
    start: 'and(a, b)',
    def: `function $and(...args) {
      return args.every((arg) => !!arg)
    }`
  },
  or: {
    full: '$or',
    desc: 'Check if any one of the values are true or not zero: if(or(a, b, ...), ...)',
    start: 'or(a, b)',
    def: `function $or(...args) {
      return args.some((arg) => !!arg)
    }`
  },
  not: {
    full: '$not',
    desc: 'Check if all values are false or zero: if(not(a, ...), ...)',
    start: 'not(a)',
    def: `function $not(...args) {
      return args.every((arg) => !arg)
    }`
  },
  median: {
    full: '$median',
    desc: 'Median of multiple values: median(a, b, ...)',
    start: '$median(a, b)',
    def: `function $median(...args) {
      const sortedArgs = args.sort((a, b) => a - b)
      const midIndex = Math.floor(sortedArgs.length / 2)

      if (sortedArgs.length % 2 === 0) {
        return (sortedArgs[midIndex - 1] + sortedArgs[midIndex]) / 2
      }

      return sortedArgs[midIndex]
    }`
  },
  average: {
    full: '$avg',
    desc: 'Average of multiple values: average(a, b, ...)',
    start: 'average(a, b)',
    def: `function $avg(...args) {
      if (!args.length) return 0
      return args.reduce((acc, arg) => acc + arg, 0) / args.length
    }`
  },
  sum: {
    full: '$sum',
    desc: 'Sum of multiple values: sum(a, b, ...)',
    start: 'sum(a, b)',
    def: `function $sum(...args) {
      if (!args.length) return 0
      return args.reduce((acc, arg) => acc + arg, 0)
    }`
  },
  sqrt: {
    full: 'Math.sqrt',
    desc: 'Square root: sqrt(a)',
    start: 'sqrt(1)'
  },
  // roundown: {
  //   full: 'Math.floor',
  //   start: 'rounddown(1)',
  // },
  // roundup: {
  //   full: 'Math.ceil',
  //   start: 'roundup(1)',
  // },
  floor: {
    full: 'Math.floor',
    desc: 'Round down: floor(a)',
    start: 'floor(1)'
  },
  ceil: {
    full: 'Math.ceil',
    desc: 'Round up: ceil(a)',
    start: 'ceil(1)'
  },
  round: {
    full: 'Math.round',
    desc: 'Round: round(a)',
    start: 'round(1)'
  },
  min: {
    full: 'Math.min',
    desc: 'min(a)',
    start: 'min(1, 2)'
  },
  max: {
    full: 'Math.max',
    desc: 'max(a)',
    start: 'max(0, 1)'
  }
}

/**
 * Tests if the value given is an equation or not
 * Examples:
 * '1*10' //=> true
 * '1,200.00' or 1200 //=> false
 * @param s
 * @returns {boolean}
 */
const isEquation = (s, variables = {}) => {
  const varsRegex = new RegExp(`\\b${Object.keys(variables).join('|')}\\b`)
  const funcRegex = new RegExp(`\\b${Object.keys(functions).join('|')}`, 'i')
  return (
    (Object.keys(variables).length && varsRegex.test(s)) ||
    /[\*\+\-\/xX]/.test(s) || // eslint-disable-line
    imperialRegex.test(s) ||
    funcRegex.test(s)
  )
  // || hoursRegex.test(s);
}

const replaceVariables = (formula, variables = {}) => {
  const variableKeys = Object.keys(variables)
  let val = formula
  if (variableKeys.length) {
    const variableRegex = new RegExp(`(\\b)(${variableKeys.join('|')})(\\b)`, 'g')
    val = val.replace(variableRegex, (...matches) => {
      const dim = variables[matches[2]]
      const finalVal = (isScalar(dim) && dim) || (dim && dim.value) || 0
      return `${matches[1]}(${finalVal})${matches[3]}`
    })
  }

  return val
}

/**
 * Fix various user inputted issues with calculations arising from white-space
 *  new lines, parenthesis multiplying (not supported in javascript),
 *  double modifiers ie: 34+ *34
 * @param val
 * @param variables {'key': 1} key, 2-3 characters a-z only
 */
const fixBlock = (block, variables = {}) => {
  if (typeof block !== 'string') return block

  let val = block

  // Replace variables
  val = replaceVariables(val, variables)

  // Replace imperial notation
  val = replaceImperialNotation(val)

  // Replace hours notation
  val = replaceHoursNotation(val)

  // Remove single spaces, commas and other numeric separators
  val = val.replace(/(\d)([, ]+)(\d)/g, '$1$3')

  // Fixe spaced numbers
  // converts 2 5 to 2*5
  val = val.replace(/(\d)\s+(\d)/g, '$1*$2')

  // Replace all <br> with new lines
  val = val.replace(/<\/?br\/?>/g, '\r\n')

  // Logic replacements
  val = val.replace(/([^=])(=)([^=])/, '$1==$3')
  val = val.replace('<>', '!=')

  // Remove all other HTML
  try {
    val = removeHtml(val)
  } catch (e) {
    // makes it web-worker compatible
  }

  // Replace all x or X characters with valid * multiplier
  val = val.replace(/([^a-zA-Z])[Xx]([^a-zA-Z])/g, '$1*$2')

  // Replace all a-z and other characters
  const funcLookBehind = `\\b(?!(?:${Object.keys(functions).join('|')})(?=\\())`
  const azregex = new RegExp(`${funcLookBehind}[^*+\\-\\/0-9.()\\s\\r\\n<>=!]+`, 'ig')
  val = val.replace(azregex, '')

  // Repeated segments without any numeric characters
  // Fixes:  23 + some text+ 34 => 23 + some text 34
  val = val.replace(/([^*+\-/])([*+\-/])([^0-9]+)([*+\-/])([^*+\-/])/g, '$1$3$4$5')

  // val = val.replace(/ /g, '')
  // Newline /spacing with text issue
  // Fixes:
  //      Garage: 30*20
  //      House:
  //          FRONT: 30*20
  //      BACK: 30* 20
  //      RIGHT: 30*20
  //      Height is twenty metres.
  //      3
  //      Floor 3
  //      Floor 3
  //      Window 3
  //    => 3 \n Floor +3 \n Floor +3 \n Window +3
  if (/(\d[^*+\-/0-9.() ]+)(?=\d|$)/g.test(val))
    // eslint-disable-line
    val
      .match(/(\d[^*+\-/0-9.() ]+)(?=\d|$)/g) // eslint-disable-line
      .forEach((s) => {
        try {
          val = val.replace(new RegExp('(' + s.replace(' ', '\\s') + ')(?!\\+)'), '$1+') // eslint-disable-line
        } catch (o) {
          // Nothing
        }
      })

  // Newline between digits
  // Fixes: 3\n3 => 3\n+3
  val = val.replace(/(\d\s+)(?=\d)/g, '$1+')

  // Parenthesis multiplication
  // Fixes: 3(34) => 3*(34)
  val = val.replace(/(\d)\s*(\()/g, '$1*$2')

  // Parenthesis multiplication
  // Fixes: (9+9)9 to (9+9)*9
  val = val.replace(/(\))\s*(\d)/g, '$1*$2')

  // Remove all non numeric, paranthesis, modifier or decimal characters
  const nonnumregex = new RegExp(`${funcLookBehind}`, 'gi')
  val = val.replace(nonnumregex, '')

  // Remove trailing modifiers
  // val = val.replace(/[\*\/\+\-][^0-9]*$/ig, '');

  // Double modifiers
  // Fixes:  4 +* 5 => 4 * 5 (keeps second modifer by default)
  val = val.replace(/([*+\-/])((?:[^0-9]+)?)([*+/])/g, '$3')

  return val
}

const blockEnds = [`[a-zA-Z]+\\(`, `,`, `\\(`, `\\)`, `,`]
const blockEndRegexp = new RegExp(`(${blockEnds.join('|')})`)
const functionsRegexp = new RegExp(`(${Object.keys(functions).join('|')})\\(`, 'ig')

const fixCalculation = (string, variables = {}) => {
  if (typeof string !== 'string') return string

  let fixed = string

  // remove leading =
  fixed = fixed.replace(/^=/, '')

  const splits = fixed.split(blockEndRegexp)

  const processed = []
  for (let i = 0; i < splits.length; i += 1) {
    let split = splits[i]

    if (split === '') continue

    if (splits[i + 1] && /[0-9]\s*$/.test(split) && /^\s*\(/.test(splits[i + 1])) {
      split = `${split} * `
    }

    if (functionsRegexp.test(split)) {
      processed.push(
        `${split.replace(functionsRegexp, (match, found) => `${functions[found.toLowerCase()].full}`)}(`
      )
    } else if (blockEndRegexp.test(split)) {
      processed.push(split)
    } else {
      processed.push(fixBlock(split, variables))
    }
  }

  let finalCalc = processed.join('')

  // These needed to be re-added since blocks separate sections based on parentheses
  // Parenthesis multiplication
  // Fixes: 3(34) => 3*(34)
  finalCalc = finalCalc.replace(/(\d)(?:\s*)(\()/g, '$1*$2') // eslint-disable-line
  // Parenthesis multiplication
  // Fixes: (9+9)9 to (9+9)*9
  finalCalc = finalCalc.replace(/(\))(?:\s*)(\d)/g, '$1*$2') // eslint-disable-line

  // Remove trailing modifiers
  finalCalc = finalCalc.replace(/([+xX*-/^])(\s*$)/, '$2')

  return `(function(){${Object.values(functions)
    .filter((func) => func.def)
    .map((func) => func.def)
    .join(';')}; return (${finalCalc});})();`
}

/**
 * Transforms a calculation into a number/result
 * @param calc
 * @returns {*}
 */
const renderCalculation = (calc, variables = {}) => {
  try {
    return eval(fixCalculation(calc, variables))
  } catch (o) {
    return 0
  }
}

const exponentFormRegex = /^-?\d+(\.\d+)?e([-+])\d+$/

/**
 * Transforms a formatted value or equation (if calcAllowed==true) into a number
 * Examples:
 *  '$1,344.34' //=> 1344.34
 *  10*2 (calcAllowed=true) //=> 20
 *  10*2 (calcAllowed=false) //=> 102
 *  '10' //=> 10
 * @param num
 * @param dec
 * @param calcAllowed
 * @returns {number}
 */
const toNum = (num = 0.0, dec = 20, calcAllowed = false, variables = {}) => {
  let value = 0
  let string = String(num)
  const conformsToExponent = exponentFormRegex.test(string)

  if (
    num &&
    calcAllowed &&
    typeof num === 'string' &&
    !conformsToExponent &&
    isEquation(num, variables)
  ) {
    value = renderCalculation(num, variables)
  } else {
    // Allow limited non-equation conversions, like decimal and hours notation
    string = replaceImperialNotation(string)
    string = replaceHoursNotation(string)

    string = !conformsToExponent
      ? `${/^-/.test(string) ? '-' : ''}${string.replace(/[^0-9.]/gi, '')}`
      : string

    value = num && Math.abs(+string) >= +`1e-${dec}` ? string : '0.00'
  }

  const intRequested = dec === 0

  if (conformsToExponent && (+value > 1e20 || +value <= 1e-7)) {
    return +value
  }

  // to requested decimal points
  const decimalized = intRequested ? parseInt(value, 10) : parseFloat(value).toFixed(dec)

  return notNaN(decimalized, intRequested ? 0 : +`0.${'0'.repeat(dec)}`)
}

/**
 * Zero-Safe division
 * @param top
 * @param bottom
 * @returns {number}
 */
const divide = (top, bottom) => {
  const t = toNum(top)
  const b = toNum(bottom)
  if (!t) return 0
  if (!b) return 0

  return t / b
}

/**
 * Convert measurements between different measurement base types
 * (1, 'm', 'mm') // => 1000
 * (1, 'm', 'ft') // => 3.2808
 * @param from
 * @param fromMeasure
 * @param to
 * @param toMeasure
 * @returns {*}
 */
const convertMeasures = (from, fromMeasure, toMeasure) => {
  if (
    fromMeasure &&
    toMeasure &&
    fromMeasure in conversionTables &&
    toMeasure in conversionTables[fromMeasure].conversions
  ) {
    return toNum(
      conversionTables[fromMeasure].conversions[toMeasure].replace('from', from),
      20,
      true
    )
  }
  if (fromMeasure === toMeasure) return from
  return false
}
const convertMeasure = convertMeasures

/**
 * Ensures the given integer of unix time is returnedin seconds.
 * WARNING only works with current times, representing times
 *   20 or > years in the past will not work.
 * timeToMs(2343333) => 2343333 (no change)
 * timeToMs(2343234323423234) => 2343234323423
 * @param time
 * @returns {*}
 */
const timeToSec = (time, toUtc = false) => {
  if (isempty(time)) return time
  // String given, parse
  else if (typeof time === 'string' && /NULL|[><=&|]/.test(time)) {
    return time
      .match(/!|NULL|\d+|[>=<]|(&&)|(\|\|)|,/g)
      .map((s) => (s.match(/\d+/) && !isNaN(parseFloat(s)) ? timeToSec(toNum(s, 0)) : s))
      .join('')
  }

  const secOffset = toUtc ? new Date().getTimezoneOffset() * 60 : 0
  return (
    notNaN(
      parseFloat(time) < 9999999999 ? parseFloat(time) : toNum(parseFloat(time) / 1000, 0),
      new Date().valueOf()
    ) + secOffset
  )
}

const utcToLocal = (utcTime) => {
  const msOffset = new Date().getTimezoneOffset() * 60000
  return toNum(utcTime) + msOffset
}

/**
 * Ensures the given integer unix time is returned in milliseconds instead of seconds.
 * WARNING only works with current times, representing times
 *   20 or > years in the past will not work.
 * timeToMs(2343333) => 2343333000
 * timeToMs(2343234323423234) => 2343234323423234 (no change)
 * @param time
 * @returns {*}
 */
const timeToMs = (time, fromUtc = false) => {
  if (isempty(time)) return time
  // String given, parse
  else if (typeof time === 'string' && /[><=&|]/.test(time)) {
    return time
      .match(/!|NULL|\d+|[>=<]|(&&)/g)
      .map((s) => (s.match(/\d+/) && !isNaN(parseFloat(s)) ? timeToMs(toNum(s, 0)) : s))
      .join('')
  }

  // Raw number given
  const isSeconds = toNum(time, 0) < 9999999999
  const msOffset = fromUtc ? new Date().getTimezoneOffset() * 60 * 1000 : 0
  return (isSeconds ? toNum(time * 1000, 0) : +time) - msOffset
}

const getUtcOffset = () => new Date().getTimezoneOffset()

const getUtcHourOffset = () => {
  const off = getUtcOffset()
  const west = off > 0
  return (west ? -1 : 1) * Math.floor(Math.abs(off) / 60)
}

const getUtcMinOffset = () => {
  const off = getUtcOffset()
  const hourOff = getUtcHourOffset()
  const west = off > 0
  return (west ? -1 : 1) * (Math.abs(off) - Math.abs(hourOff) * 60)
}

const rollMinutes = (v) => {
  if (v > 60) return v - 60
  if (v < 0) return v + 60
  return v
}

const rollHours = (v) => {
  if (v > 24) return v - 24
  if (v < 0) return v + 24
  return v
}

const subtractHours = (a = 0, b = 0) => rollHours(a - b)
const subtractMinutes = (a = 0, b = 0) => rollMinutes(a - b)
const addHours = (a = 0, b = 0) => rollHours(a + b)
const addMinutes = (a = 0, b = 0) => rollHours(a + b)

const hourToUtc = (h) => subtractHours(h, getUtcHourOffset())
const minuteToUtc = (h) => subtractMinutes(h, getUtcMinOffset())

const hourFromUtc = (h) => addHours(h, getUtcHourOffset())
const minuteFromUtc = (h) => addMinutes(h, getUtcMinOffset())

const toUtc = (h = 0, m = 0) => [hourToUtc(h), minuteToUtc(m)]
const fromUtc = (h = 0, m = 0) => [hourFromUtc(h), minuteFromUtc(m)]

/* const fromUtc = (h = 0, m = 0) {

}; */

/**
 * Reverts from toTimeString, formats a human readable string into a second/ or ms
 *  integer of unix time.  Seconds = true for seconds, and seconds = false for ms;
 * @param stringTime
 * @param seconds
 * @returns {*}
 */
const toTime = (time, seconds = false) => {
  let momentObj
  let a
  const stringTime = time ? String(time) : null

  if (!stringTime) return null
  else if (/^[\d<>\-.=&|,]+$/.test(stringTime)) {
    return seconds
      ? timeToSec(stringTime.replace(/,/g, ''))
      : timeToMs(stringTime.replace(/,/g, ''))
  } else if (stringTime === 'never') return 'NULL||0'
  else if (stringTime === 'any time' || stringTime === 'anytime') return '!NULL&&>0'
  else if (typeof stringTime === 'number') momentObj = moment(stringTime)
  // Ranges
  else if (stringTime.match(/(\w+) to (\w+)/i)) {
    a = stringTime.split(' to ').map((s) => toTime(s, seconds))
    // make the last day in the range one day later,
    // so that it encompasses the whole last day,
    // not just the first second.
    momentObj = moment(timeToMs(a[1]))
    momentObj.add(1, 'day')
    a[1] = seconds ? momentObj.unix() : momentObj.valueOf()
    return `>=${a.join('&&<')}`
    // Datetime
  } else if (/(\d{1,2})(:(\d{2}))(:(\d{2}))?\s?(am|pm)?/i.test(stringTime)) {
    a = stringTime.match(/(\d{1,2})(:(\d{2}))(:(\d{2}))?\s?(am|pm)?/i)
    const iHr = toNum(a[1], 0)
    const afternoonIf24Hr = iHr !== 12 ? iHr + 12 : 12
    const sHr = a[6] && String(a[6]).toLowerCase() === 'pm' ? afternoonIf24Hr : toNum(a[1], 0)
    const sMin = a[3] || '00'
    const sSec = a[5] || '00'
    momentObj = moment(
      stringTime
        .replace(
          /((sun(day)?)|(mon(day)?)|(tue(sday)?)|(wed(nesday)?)|(thu(rsday)?)|(fri(day)?)|(sat(urday)?)(\s)?)?(at\s)?((\d{1,2})(:)(\d{2}))?(:\d{2})?(\s?(am|pm))?(\son)?(\s(sun(day)?)|(mon(day)?)|(tue(sday)?)|(wed(nesday)?)|(thu(rsday)?)|(fri(day)?)|(sat(urday)?))?/gi,
          ''
        )
        .trim()
    )
    momentObj.minutes(sMin)
    momentObj.hours(sHr)
    momentObj.seconds(sSec)
    // Date only
  } else {
    momentObj = moment(
      stringTime
        .replace(
          /((sun(day)?)|(mon(day)?)|(tue(sday)?)|(wed(nesday)?)|(thu(rsday)?)|(fri(day)?)|(sat(urday)?)(\s)?)?(at\s)?((\d{1,2})(:)(\d{2}))?(:\d{2})?(\s?(am|pm))?(\son)?(\s(sun(day)?)|(mon(day)?)|(tue(sday)?)|(wed(nesday)?)|(thu(rsday)?)|(fri(day)?)|(sat(urday)?))?/gi,
          ''
        )
        .trim()
    )
  }

  if (momentObj) {
    return seconds ? parseInt(momentObj.valueOf() / 1000, 10) : +momentObj.valueOf()
  }
  return null
}

/**
 * Builds a developer friendly error message with a stack trace, only
 *  if in development mode.  Will not show in production mode.
 * @param assertion
 * @param failMessage
 */
const assert = (assertion = false, ...logs) => {
  if (isDev()) {
    const passed = typeof assertion === 'function' ? assertion() : assertion
    if (!passed) {
      console.warn(...logs)
    }
  }
}

/**
 * Builds a developer friendly error message with a stack trace, only
 *  if in development mode.  Will not show in production mode.
 * @param assertion
 * @param failMessage
 */
const log = (...logs) => {
  if (isDev()) {
    console.log(`%c${logs.join(' ')}`, 'color: #47c097; font-weight: bold;')
  }
}
const benchLog = (...logs) => {
  if (isDev()) {
    console.log(`%c[BENCH] ${logs.join(' ')}`, 'color: #ef3c52; font-weight: bold;')
  }
}
const error = (...logs) => {
  if (isDev()) {
    console.log('%c[CC-ERROR]', 'color: #ef3c52; font-weight: bold;')
    console.log(...['    ', ...logs])
  }
}

const getContainerParent = (element) => {
  if (!element) return null
  let positioned = null
  let cursor = element

  while (!positioned && cursor.parentNode) {
    const style = getComputedStyle(cursor.parentNode)

    if (/(auto|scroll)/.test(style.overflowY) || /(absolute|relative|fixed)/.test(style.position)) {
      positioned = cursor.parentNode
    } else {
      cursor = cursor.parentNode
    }
  }

  return positioned || cursor || element.closest('#app')
}

const getSizedParent = (element) => {
  if (!element) return null
  let positioned = null
  let cursor = element

  while (!positioned && cursor.parentNode) {
    const style = getComputedStyle(cursor.parentNode)

    const { width, height } = cursor.parentNode.getBoundingClientRect()

    if (
      (/(auto|scroll)/.test(style.overflowY) || /(absolute|relative|fixed)/.test(style.position)) &&
      width &&
      height
    ) {
      positioned = cursor.parentNode
    } else {
      cursor = cursor.parentNode
    }
  }

  return positioned || cursor || element.closest('#app')
}

/**
 * Get nearest scrollable container from given element
 * @param el
 */
const getScrollParent = (element) => {
  const body = $('body')[0]
  const appContainer = body.querySelector('#app')
  const check = (el) => {
    if (el && el.nodeName) {
      const style = getComputedStyle(el)
      if (el === appContainer) return appContainer
      else if (/(auto|scroll)/.test(style.overflowY)) {
        return el
      } else if (el.parentElement) {
        return check(el.parentElement)
      }
      return el
    }
    return appContainer
  }
  if (!element) return
  return check(element.parentElement)
}

const getScrollParentX = (element) => {
  const body = $('body')[0]
  const appContainer = body.querySelector('#app')
  const check = (el) => {
    if (el && el.nodeName) {
      const style = getComputedStyle(el)
      if (el === appContainer) return appContainer
      else if (/(auto|scroll)/.test(style.overflowX)) {
        return el
      } else if (el.parentElement) {
        return check(el.parentElement)
      }
      return el
    }
    return appContainer
  }
  return check(element.parentElement)
}

/**
 * Scroll to element in any container
 * @param element
 * @param buffer
 * @returns {Promise}
 */
const scrollTo = (element, buffer = 100, delay = 1000) => {
  if (element && element.nodeName) {
    const scrollParent = getScrollParent(element)
    const $scrollParent = $(scrollParent)
    return new Promise((resolve) => {
      $scrollParent.animate(
        {
          scrollTop:
            element.getBoundingClientRect().top +
            $scrollParent.scrollTop() -
            buffer -
            scrollParent.getBoundingClientRect().top
        },
        delay,
        'swing',
        resolve
      )
    })
  }
  return new Promise((resolve) => resolve())
}
/**
 * Scroll to element in any container
 * @param element
 * @param buffer
 * @returns {Promise}
 */
const scrollToX = (element, buffer = 100, delay = 1000) => {
  if (element && element.nodeName) {
    const scrollParent = getScrollParentX(element)
    const $scrollParent = $(scrollParent)
    return new Promise((resolve) => {
      $scrollParent.animate(
        {
          scrollLeft:
            element.getBoundingClientRect().left +
            $scrollParent.scrollLeft() -
            buffer -
            scrollParent.getBoundingClientRect().left
        },
        delay,
        'swing',
        resolve
      )
    })
  }
  return new Promise((resolve) => resolve())
}

const ucfirst = (string) =>
  `${String(string).charAt(0).toUpperCase()}${String(string).toLowerCase().slice(1)}`

const hungarianToCamelCase = (hungarianString) =>
  ld.camelCase(String(hungarianString).replace(/^(ao|aa|ai|af|as|o)([A-Z]\w+)$/g, '$2'))

const titleCase = (string) =>
  `${ld.capitalize(string).substr(0, 1)}${ld.camelCase(string).substr(1)}`

const underCase = (string) => ld.kebabCase(string).replace(/-/g, '_')

const nameCase = (string) => ld.kebabCase(string).replace(/-/g, ' ')

/**
 * Test float/decimal equality within {dec} signification decimal places;
 *   Defaults to 10 decimal places.
 * @param firstNum
 * @param secondNum
 * @param dec
 * @returns {boolean}
 */
const eq = (firstNum, secondNum = 0, dec = 20) =>
  Math.round(Math.abs(toNum(firstNum) - toNum(secondNum)) * 10 ** dec) === 0

/**
 * Checks if val === val2, converting measures if required,
 * for example, val could be in metres, and val2 could be
 * in feet. If val = 1, measure = 'm', val2 = 3.280839895013123, and measure2 = 'ft'
 * then this would return true;
 *
 * @param val
 * @param measure
 * @param val2
 * @param measure2
 * @param precision
 * @returns {*}
 */
const eqMeasure = (val, measure, val2, measure2, precision = 4) =>
  eq(convertMeasures(val, measure, measure2), val2, precision)

/**
 * Tests whether two json strings are equal, regardless of order
 * @param value1
 * @param value2
 * @param strict
 * @returns {boolean}
 */
const deepEquals = (value1, value2) => DeepEqual(value1, value2)

// const getContentLink = value => linkifyFind(value);

// const fetchLinkPreviewData = async (value) => {
//  try {
//    const data = await getLinkPreview(value);
//    return data;
//  } catch (e) {
//    return false;
//  }
// };

// const getLinkPreviewData = async (value) => {
//   if (!hasLink(value)) return false;
//   const links = getContentLink(value);
//   const promises = [];
//   links.forEach(v => promises.push(fetchLinkPreviewData(v.value)));
//   try {
//     const data = await Promise.all(promises);
//     return data;
//   } catch (e) {
//     return false;
//   }
// };

/**
 * Tests whether two json strings are equal
 * @param value1
 * @param value2
 * @param strict
 * @returns {boolean}
 */
const jsonEquals = (value1, value2, strict = false) => {
  const replacer = strict
    ? (key, value) => value
    : (key, value) => {
        if (typeof value === 'number') {
          return String(value)
        }
        return value
      }

  let deformatted1
  let deformatted2
  try {
    deformatted1 = typeof value1 === 'string' ? JSON.parse(value1) : value1
  } catch (e) {
    deformatted1 = value1
  }

  try {
    deformatted2 = typeof value2 === 'string' ? JSON.parse(value2) : value2
  } catch (e) {
    deformatted2 = value2
  }

  const json1 = JSON.stringify(deformatted1, replacer)
  const json2 = JSON.stringify(deformatted2, replacer)

  return (
    json1 === json2 ||
    (!strict && json1 === value2) ||
    (!strict && value1 === json2) ||
    value1 === value2
  )
}

/**
 * decimalToImperial
 * Display a decimal number in an imperial ft/in notation
 * 3.0625 //=> "3' 3/4\""
 *
 * @param decimal
 * @returns {string}
 */
const decimalToImperial = (decimal) => {
  const num = notNaN(decimal)
  const ft = Math.floor(num)
  let inch = Math.floor((num - ft) / (1 / 12))
  const subInch = num - ft - ld.divide(inch, 12)
  let subInchF = eq(0, subInch, 2) ? false : new Fraction(subInch / (1 / 12))
  if (subInchF.n === subInchF.d && subInchF.n > 0) {
    inch += 1
    subInchF = false
  }

  return `${ft}'${inch || subInchF ? ' ' : ''}${inch || ''}${subInchF ? ` ${subInchF.n}/${subInchF.d}` : ''}${subInchF || inch ? '"' : ''}`
}

/**
 * getSigFigs(344.33, 3433.43, 343.44443333, 343.433, 43.3) = 1
 * getSigFigs(344.3333, 3433.4333343434, '343.343333') = 4
 * @param args
 */
const getSigFigs = (...args) =>
  args
    .map((num = 0) => {
      const parts = num.toString().split('.') || []
      if (parts.length > 1) {
        return parts[1].length
      }
      return 0
    })
    .reduce((acc, sf) => (acc < sf ? acc : sf))

/**
 * Get cursor/caret position inside an editable div
 * @param parent
 * @returns {*}
 */
const getCurrentCursorPosition = (parent) => {
  const selection = window && window.getSelection()
  let charCount = -1
  let node

  if (selection.focusNode) {
    if (nodeIsChildOf(selection.focusNode, parent)) {
      node = selection.focusNode

      if (node === parent) return parent.textContent.length
      charCount = selection.focusOffset

      // If text content is on first index of new line just a return, add one
      if (node.previousSibling && /(?:\r\n|\r|\n)$/.test(node.previousSibling.textContent))
        charCount += 1

      while (node) {
        if (node === parent) {
          break
        }

        if (node.previousSibling) {
          node = node.previousSibling
          charCount += node.textContent.length
        } else {
          node = node.parentNode
          if (node === null) {
            break
          }
        }
      }
    }
  }
  return Math.min(charCount, parent.textContent.length)
}

/**
 *
 * @param editableDiv
 * @returns {number}
 */
const getCaretPosition = (editableDiv) => {
  let caretPos = 0
  let sel
  let range

  if (window && window.getSelection) {
    sel = window.getSelection()
    if (sel.rangeCount) {
      range = sel.getRangeAt(0)
      if (range.commonAncestorContainer.parentNode === editableDiv) {
        caretPos = range.endOffset
      }
    }
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange()
    if (range.parentElement() === editableDiv) {
      const tempEl = document.createElement('span')
      editableDiv.insertBefore(tempEl, editableDiv.firstChild)
      const tempRange = range.duplicate()
      tempRange.moveToElementText(tempEl)
      tempRange.setEndPoint('EndToEnd', range)
      caretPos = tempRange.text.length
    }
  }
  return caretPos
}

/**
 * Create a selection range object, for example inside an editable div
 * @param node
 * @param charsDesired
 * @param rangeDesired
 * @returns {*}
 */
const createSelectionRange = (node, charsDesired, rangeDesired) => {
  let range = rangeDesired
  const chars = charsDesired

  if (!range) {
    range = document.createSelectionRange()
    range.selectNode(node)
    range.setStart(node, 0)
  }

  if (chars.count === 0) {
    range.setEnd(node, chars.count)
  } else if (node && chars.count > 0) {
    if (node.nodeType === Node.TEXT_NODE) {
      if (node.textContent.length < chars.count) {
        chars.count -= node.textContent.length
      } else {
        range.setEnd(node, chars.count)
        chars.count = 0
      }
    } else {
      node.childNodes.every((child) => {
        range = createSelectionRange(child, chars, range)
        if (chars.count === 0) return false
        return true
      })
    }
  }
  return range
}

/**
 * Set caret or cursor at specific position
 * @param element
 * @param chars
 */
const setCurrentCursorPosition = (element, chars) => {
  if (chars >= 0) {
    const selection = window && window.getSelection()
    const range = createSelectionRange(element, { count: chars })

    if (range) {
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }
}

/**
 * Set caret or cursor position at end of editable div
 * @param el
 */
const setCurrentCursorAtEnd = (el) => {
  el.focus()
  if (
    window &&
    typeof window.getSelection !== 'undefined' &&
    typeof document.createRange !== 'undefined'
  ) {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    sel.removeAllRanges()
    sel.addRange(range)
  } else if (typeof document.body.createTextRange !== 'undefined') {
    const textRange = document.body.createTextRange()
    textRange.moveToElementText(el)
    textRange.collapse(false)
    textRange.select()
  }
}

/**
 * Test if node is child of parent
 * @param node
 * @param parent
 * @returns {boolean}
 */
const nodeIsChildOf = (node, parent) => {
  let child = node
  while (child !== null) {
    if (child === parent) {
      return true
    }
    child = node.parentNode
  }
  return false
}

/**
 * Decode HTML Entities
 * @param s
 * @returns {*|jQuery}
 */
const decodeEntities = (s = '') => $('<textarea />').html(s).text()

const viewPortSize = () =>
  window && (innerWidth || document.documentElement.clientWidth || document.body.clientWidth)

const viewPortHeight = () =>
  window &&
  (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)

/**
 * Get the size of the viewport in sm/md/lg notation
 * @returns String
 */
const docSize = () => {
  const w = viewPortSize()
  if (w > 1199) return 'xl'
  else if (w > 991) return 'lg'
  else if (w > 767) return 'md'
  else if (w > 575) return 'sm'
  return 'xs'
}

/**
 * Returns the prefered click action
 * @returns {string}
 */
const getClickActionName = () => null // TODO: Don't rely on old is()

/**
 * Serialize objects into query strings
 * @param Object obj to serialize
 * @param String prefix ??
 * @returns String
 */
const serialize = (obj, prefix) =>
  Object.keys(obj)
    .map((p) => {
      const k = prefix ? `${prefix}[${p}]` : p
      const v = obj[p]
      return v && typeof v === 'object'
        ? serialize(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
    })
    .join('&')

/**
 * Ensure the return value of array
 *  'a,2,3'     //=> ['a',2,3]
 *  [1,2,3]     //=> [1,2,3] (no changes)
 *  {a:1, b:2}  //=> [{a:1, b:2}]
 *
 * @param values
 * @param clearEmpties
 * @returns {*}
 */
const makeArray = (values = [], clearEmpties = false) => {
  let returnArray = []
  if (typeof values === 'string' && /^\[(.*?)\]$/.test(values)) {
    try {
      returnArray = JSON.parse(values)
    } catch (o) {
      console.warn('Wrong json value.')
    }
  }

  if (typeof values === 'string' && !Array.isArray(values)) {
    returnArray = values.split(',')
  } else if (Array.isArray(values)) {
    returnArray = values
  } else {
    returnArray = [values]
  }
  return cleanArray(returnArray, !clearEmpties)
}

const makeStringArray = (values = [], clearEmpties = false) =>
  makeArray(values, clearEmpties).map((v) => String(v))

const clearEmptyArrayValues = (array = []) =>
  (Array.isArray(array) ? array : [])
    .map((a) => (Array.isArray(a) ? clearEmptyArrayValues(a) : a))
    .filter((a) => !isempty(a))

const extractSingle = (array) => makeArray(array).join(',') || null

/**
 * Format for filtering
 * @param object
 * @returns {{}}
 */
const getFilters = (object) => {
  const newFilters = { ...object }
  Object.keys(object).forEach((key) => {
    if (Array.isArray(object[key])) newFilters[key] = object[key].join('||')
  })
  return newFilters
}

const toHours = (num) => {
  const fVal = toNum(num, 20)
  const iHr = parseInt(fVal, 10)
  const fMin = (fVal - iHr) * 60
  const iMin = parseInt(fMin, 10)
  const fSec = (fMin - iMin) * 60
  // Round only seconds, bc we aren't calculating sub second time
  const iSec = Math.round(fSec)
  const sHour = iHr < 1 || isNaN(iHr) ? '00' : iHr < 10 ? '0' + String(iHr) : String(iHr)
  const sMin = iMin < 1 || isNaN(iMin) ? '00' : iMin < 10 ? '0' + String(iMin) : String(iMin)
  const sSec = iSec < 1 || isNaN(iSec) ? '00' : iSec < 10 ? '0' + String(iSec) : String(iSec)
  return `${sHour}h ${sMin}m ${sSec}s`
}

/**
 * Formats a number in a currency display friendly format. 0,000.00
 * @param Int iDecimals to display, default 2
 * @returns String
 */
const toCurrency = (num = 0.0, dec = 2, decimalSeparator = '.', thousandsSeparator = ',') => {
  const string = String(toNum(num, dec)) // JS truncates at 8 anyway
  const parts = string.includes('.')
    ? string.split('.')
    : [string, dec > 0 ? '0'.repeat(Math.min(dec, 2)) : '']
  const decimalsProvided = parts[1].length
  const decimalsToDisplay =
    decimalsProvided < 2 && decimalsProvided < dec
      ? 2
      : decimalsProvided < dec
        ? decimalsProvided
        : dec

  const start = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${thousandsSeparator}`) || '0'
  const end =
    parts[1]
      .replace(new RegExp(`(\\d*?[1-9])0{,${decimalsToDisplay}}$`, 'g'), '$1')
      .substr(0, decimalsToDisplay) || '0'.repeat(decimalsToDisplay)

  return (
    start +
    (end
      ? decimalSeparator +
        (end.length < decimalsToDisplay
          ? end + '0'.repeat(decimalsToDisplay - end.length || 0)
          : end)
      : '')
  )
}

/**
 *
 * @param val
 * @param validationRequired bool|null
 * @param validationType string|regex|null
 * @returns object {valid{bool}, type{bool}, required{bool}}
 */
const validate = (val, req = null, format = null, msg = null) => {
  // If there are no requirements as to validation, it is always valid
  if (!req && !format) {
    return {
      valid: true,
      format: true,
      required: true,
      message: ''
    }
  }

  // If a value is required, but none is provided, it is invalid
  // on the basis that is empty
  if (req && !val) {
    return {
      valid: false,
      format: true,
      required: false,
      message: 'This is a required value'
    }
  }

  // No type requirements, or there is but it is empty, then it cannot fail the test!
  if ((val && !format) || (format && !val)) {
    return {
      valid: true,
      format: true,
      required: true,
      message: ''
    }
  }

  // Built-in validation type
  if (format in validations) {
    const result = validations[format].test.test(val)
    return {
      required: true, // not that it is required, only that it passed that test
      valid: result,
      format: result,
      message: validations[format].message
    }
  }

  // Custom validation type function
  if (typeof format === 'function') {
    const result = !!format(val)
    return {
      required: true, // not that it is required, only that it passed that test
      valid: result,
      format: result,
      message: msg || 'Invalid format'
    }
  }

  // Custom validation type regex
  if (typeof format.test === 'function') {
    const result = !!format.test(val)
    return {
      required: true, // not that it is required, only that it passed that test
      valid: result,
      format: result,
      message: format.message || msg || 'Invalid format'
    }
  }

  return {
    valid: true,
    format: true,
    required: true
  }
}

/**
 * Tests the validity of the equation
 * @param calc
 * @returns {boolean}
 */
const isValidEquation = (calc, variables = {}) => {
  try {
    const fixed = fixCalculation(calc, variables)
    eval(fixed)
    return true
  } catch (o) {
    return false
  }
}

/**
 * Rounds a number, to 2 decimal spaces by default, for currency calculations etc.
 * 1.314192343234324234 //=> 1
 * 1.5 //=> 2
 * @param num
 * @returns {number}
 */
const toRound = (num, dec = 2) => {
  const divisor = 10 ** dec
  return Math.round(toNum(num, 20) * divisor) / divisor
}

/**
 * Rounds floor based on decimals provided.
 * 1.314192343234324234 //=> 1
 * 1.5 //=> 1
 * @param num
 * @returns {number}
 */
const toFloor = (num, dec = 2) => {
  const divisor = 10 ** dec
  return Math.floor(toNum(num, 20) * divisor) / divisor
}

/**
 * Get the significant decimals
 * @param num
 * @param requestedDec
 * @returns {number}
 */
const getSignificantDecimals = (num, requestedDec = null) => {
  const parts = String(+num).split('.')
  const natDecLength = parts.length > 1 ? parts[1].length : 0
  const setDecLength = requestedDec === null ? 2 : Number(requestedDec)
  const dec = natDecLength <= setDecLength ? natDecLength : setDecLength

  return dec
}

/**
 * Represents a unix or javascript integer time format (seconds or milliseconds) in
 * human readable format, given by parameter format.
 * @param value
 * @param format
 * @returns {*}
 */
const toTimeString = (value, format = 'DD MMMM YYYY') => {
  let momentObj
  const newValue = toTime(value)
  if (window) {
    window.moment = moment
  }
  // value not set
  if (!value || !newValue) return null
  // Number given
  else if (typeof newValue === 'number') momentObj = moment(timeToMs(newValue))
  // Any time
  else if (newValue === '!NULL&&>0') return 'any time'
  // Never
  else if (newValue === 'NULL||0') return 'never'
  // Ranges
  else if (newValue.match(/(.*)&&(.*)/))
    return newValue
      .split('&&')
      .map((s) => toTimeString(s, format))
      .join(' to ')
  // Less than, means we need to back up one ms, and use that date
  else if (newValue.match(/<\d+/))
    momentObj = moment(timeToMs(toNum(newValue.replace('<', ''), 0)) - 1000)
  // Greater than, add one second, use taht date
  else if (newValue.match(/>\d+/))
    momentObj = moment(timeToMs(toNum(newValue.replace('>', ''), 0)) + 1000)
  // Less than or equal to, greater than or equal to, use the exact time
  else if (newValue.match(/((>=)|(<=))\d+/))
    momentObj = moment(timeToMs(toNum(newValue.replace(/((>=)|(<=))/, ''), 0)))
  return momentObj ? momentObj.format(format) : null
}

/**
 * Route all formatting/filtering through one function.
 *   Route all deformatting through deformat only.
 * @param sValue
 * @param sFormat
 * @returns {*}
 */
const formatValue = (value, format, ...extra) => {
  let s
  let parts
  let dec
  let rest
  switch (format) {
    case 'ucfirst':
      if (value === null) return value
      s = ucfirst(value)
      break
    case 'imperial':
    case 'feet':
    case 'foot':
    case 'ft':
    case "'":
      if (value === null) return value
      if (eq(value, 0)) return '0\' 0"'
      s = decimalToImperial(value)
      break
    case 'boolean':
      s =
        value && String(value).toLowerCase() !== 'false' && String(value).toLowerCase() !== 'null'
          ? 'True'
          : 'False'
      break
    case 'text':
      s = removeHtml(value)
      break
    case 'raw':
      parts = String(value).split('.')
      dec = parts.length > 1 ? parts[1].length : 0
      s = String(value)
      if (s[s.length - 1] === '.') s = `${s}0`
      s = toNum(s, 20, true)
      break
    case '$':
    case 'currency':
      ;[dec = undefined, ...rest] = extra
      s = toCurrency(value, dec, ...rest)
      break
    case 'markup':
      ;[dec = undefined, ...rest] = extra
      s = toCurrency(value, dec, ...rest)
      break
    case 'number':
      ;[dec = null, ...rest] = extra
      s = toCurrency(value, getSignificantDecimals(value, dec), ...rest)
      break
    case 'postal':
    case 'postal_ca':
      if (value === null) return null
      s = String(value)
        .replace(/[^0-9a-zA-Z]/g, '')
        .replace(/^(.{3})(.{3})$/i, '$1-$2')
        .toUpperCase()
      break
    case 'postal_us':
      if (value === null) return null
      s = value
      break
    case 'phone_ca':
    case 'phone_us':
    case 'phone':
      s = String(value || '').trim()
      if (!s) return ''
      dec = extra && extra[0]
      dec = typeof dec === 'string' ? dec.toUpperCase() : null
      dec = !dec && !/^\+/.test(s) ? 'US' : dec
      s = parsePhoneNumberFromString(s, dec)
      s = s && s.isPossible() ? s.formatNational() : String(value || '')
      break
    case 'date':
      if (value === null) return null

      s = moment(toTime(value))

      if (extra && extra === 'endofday') {
        s = s.endOf('day')
      } else if (extra && extra === 'startofday') {
        s = s.startOf('day')
      }

      s = s.format('DD MMM YYYY')

      break
    case 'timerelative':
      if (value === null) return null

      s = toTime(value)
      // s = utcToLocal(s);

      s = moment(s).fromNow()
      break
    case 'time':
      if (value === null) return null

      s = toTime(value)
      // s = utcToLocal(s);

      s = toTimeString(s, 'h:mma') // moment(value).format('h:mma');
      break
    case 'timelength':
      if (value === null) return null

      s = toTime(value)
      // s = utcToLocal(s);

      s = moment(s).fromNow(true)
      break
    case 'daterelative':
    case 'calendar':
      if (value === null) return null

      s = toTime(value)
      // s = utcToLocal(s);

      s = moment(s).calendar(null, {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'DD MMM YYYY'
      })
      break
    case 'datetime':
      if (value === null) return null

      s = toTime(value)
      // s = utcToLocal(s);

      if (extra && extra === 'endofday') {
        s = moment(s).endOf('day').valueOf()
      } else if (extra && extra === 'startofday') {
        s = moment(s).startOf('day').valueOf()
      }

      s = toTimeString(s, 'h:mma • DD MMM YYYY') // moment(value).format('h:mma D MMM YYYY');

      break
    case 'cron':
      if (value === null) return null
      s = value
      break
    case 'cron_next':
      if (value === null) return null
      s = value
      break
    case 'status':
      if (value === null) return null
      s = statuses[value] || value
      break
    case 'percent':
    case '%':
    case 'percentage':
      s = `${toNum(+value * 100, 2, true)}%`
      break
    case 'percentWhole':
    case '%Whole':
    case 'percentageWhole':
      s = `${toNum(value, 2, true)}%`
      break
    case 'array':
      s = makeArray(value)
      break
    case 'hours':
      s = toHours(value)
      break
    case 'zeropadded':
      s = `00${value}`.slice(-2)
      break
    default:
      s = value
      break
  }
  return s
}

/**
 * Run all plaining/rawing/defiltering/deformatting through here
 * @param value
 * @param format
 * @returns {*}
 */
const deformatValue = (value = '', format = 'number', extra = null) => {
  let s
  switch (format) {
    case 'raw':
    case 'int':
      s = toNum(value, 0)
      break
    case 'imperial':
    case 'feet':
    case 'foot':
    case 'ft':
    case "'":
      s = imperialToDecimal(value)
      break
    case 'float':
      s = toNum(value, 20)
      break
    case 'bool':
    case 'boolean':
      s = value && value !== '0' && value !== 'false'
      break
    case 'tinyint':
    case 'toggle':
      s = value && value !== '0' && value !== 'false' && notNaN(+value) > 0 ? 1 : 0
      break
    case '$':
    case 'currency':
    case 'markup':
      s = toNum(String(value), 20, true)
      break
    case 'number':
      s = String(value)
      if (s[s.length - 1] === '.') s = `${s}0`
      s = toNum(s, 20, true)
      break
    case 'postal':
    case 'postal_ca':
      s = String(value).replace(/[^0-9a-zA-Z]/g, '')
      break
    case 'postal_us':
      s = String(value).replace(/[^0-9-]/g, '')
      break
    case 'phone_ca':
    case 'phone_us':
    case 'phone':
      s = String(value || '').trim()
      if (!s) return ''
      s = parsePhoneNumberFromString(
        String(value || ''),
        !extra && /^\+/.test(s) ? null : String(extra || 'US').toUpperCase()
      )
      s = s && s.isPossible() ? s.format('E.164') : String(value || '').replace(/[^0-9+]/g, '')
      break
    case 'date':
    case 'datetime':
      s = toTime(String(value))
      if (extra && extra === 'endofday') {
        s = moment(s).endOf('day').valueOf()
      } else if (extra && extra === 'startofday') {
        s = moment(s).startOf('day').valueOf()
      }
      break
    case 'status':
      s = statuses[value] || String(value)
      break
    case 'percent':
    case '%':
    case 'percentage':
      s = toNum(toNum(String(value), 20, true) / 100, 20)
      break
    case 'array':
      s = makeArray(String(value)).join(',')
      break
    case 'hours':
      s = fromHours(String(value))
      break
    default:
      s = String(value)
      break
  }
  return s
}

/**
 * Alias for deformat
 * example: raw('$2,300.34') //==> 2300.34
 * @param args
 * @returns {*}
 */
const raw = (...args) => deformatValue(...args)

/**
 * Returns whether the string name of an object field should be numeric or not;
 *
 * @examples
 * isNumericField('quote_price_net')          //=> true
 * isNumericField('quote_name')               //=> false
 * isNumericField('client_time_created')      //=> true
 * isNumericField('user_is_admin')            //=> true
 * isNumericField('user_has_logged_in')       //=> true
 * isNumericField('user_id')                  //=> true
 * isNumericField('contact_id')               //=> false
 *
 * @param field
 * @returns {boolean}
 */
const isNumericField = (field) =>
  /(_order$)|_net|_gross|_tax|_percent|_percentage|_hours|_is_|_use_|_has_|_time|_count|_show_|_locked|_link_|_sum/.test(
    field
  )
/**
 * Alias for isempty
 * @param args
 * @returns {boolean}
 */
const empty = (...args) => isempty(...args)

/**
 * Tests whether the value is defined, empty string, null, etc
 * @param args
 * @returns {boolean}
 */
const isset = (...args) =>
  args.reduce(
    (acc, arg) =>
      arg && typeof arg !== 'undefined' && arg !== '' && arg !== 'NaN' && arg !== null && arg !== ''
  ) === args.length

const mangle = (str) => {
  if (str === null) return null
  return encodeURI(str)
}

const unmangle = (str) => {
  if (str === null) return null
  return decodeURI(str)
}

let ccStorage = {}
const getStorageSize = () => {
  const str = `${JSON.stringify(ccStorage)}${JSON.stringify((window && window.localStorage) || '')}`
  const m = encodeURIComponent(str).match(/%[89ABab]/g)
  return str.length + (m ? m.length : 0)
}

const setStorage = (key, val) => {
  if (window && window.localStorage) {
    try {
      window.localStorage.setItem(
        key,
        val && (typeof val === 'object' || Array.isArray(val)) ? JSON.stringify(val) : val
      )
    } catch (err) {
      // nothing
    }
  }
  ccStorage[key] = val
}

const setCookie = (key, val) => {
  if (typeof window !== 'undefined' && typeof window.document !== 'undefined') {
    try {
      // Get Date
      const myDate = new Date()
      myDate.setMonth(myDate.getMonth() + 12)
      document.cookie = `${key}=${val}; expires=${myDate}; domain=.${process.env.FE_BASE}; path=/`
    } catch (err) {
      // nothing
    }
  }
}

const clearStorage = () => {
  if (window && window.localStorage) window.localStorage.clear()
  ccStorage = {}
}

const removeStorageItem = (key) => {
  if (window && window.localStorage) {
    window.localStorage.removeItem(key)
  }
  // eslint-disable-next-line no-unused-vars
  const { [key]: omit, ...rest } = ccStorage
  ccStorage = rest
}

const getStorage = (key) => {
  if (!key) {
    return {
      ...((window && window.localStorage) || {}),
      ...ccStorage
    }
  }

  if (key in ccStorage) {
    return ccStorage[key]
  }

  let value = null
  if (window && window.localStorage && window.localStorage.getItem(key)) {
    value = window.localStorage.getItem(key)
    if (typeof value === 'string') {
      try {
        value = JSON.parse(window.localStorage.getItem(key))
      } catch (err) {
        // nothing
      }
    }
  }

  return value
}

/**
 * Build an absolute link from relative path
 * @param path
 * @returns {*}
 */
const link = (path, query = {}, setToken = true, setScope = null, host = baseFileUrl()) => {
  const userToken = setToken ? getStorage('persistentUserToken') : ''
  if (!userToken && setToken && window) {
    window.dispatch('authFailed')
  }
  const companyToken = setToken ? getStorage('persistentCompanyToken') : ''
  const current = getStorage('scope')
  const scope = setScope || (Object.keys(current).length ? current : null) || null
  const url = `${host.replace(/\/$/, '')}/${path.replace(/^\//, '')}`
  const queryString = serialize({
    ...(scope ? { sc: btoa(JSON.stringify(scope)) } : {}),
    ...query,
    ...(userToken
      ? {
          ut: userToken
        }
      : {}),
    ...(companyToken
      ? {
          ct: companyToken
        }
      : {})
  })
  return `${url}${queryString ? `?${queryString}` : ''}`
}

/**
 * Open link in new window
 * @param url
 */
const openLink = (url) => {
  const a = document.createElement('a')
  a.href = url
  a.target = '_blank'
  a.click()
}

const focusInput = (input) => {
  if (input && input.focus && !matchMedia('(hover: none)').matches) input.focus()
  return input
}

/**
 * Collect all args returned from jquery ajax function and then
 * determine what the actual response was
 * @param args
 * @returns {any}
 */
const getResponseFromAjaxArgs = (args = []) => {
  const xhr = args.find((arg) => typeof arg.getResponseHeader === 'function')
  // Decode and decompress
  let json = {}
  if (+xhr.getResponseHeader('CostCertified-Encoding') === 0) {
    json = xhr.responseJSON || JSON.parse(xhr.responseText)
  } else {
    json = JSON.parse(Pako.inflate(xhr.responseText, { to: 'string' }))
  }
  return json
}

/**
 * NOT WORKING
 * attempt merge that retains the order of keys of source
 * @param object
 * @param source
 * @returns {{}}
 */
const customMerge = (object = {}, source = {}) => {
  if (typeof object === 'object' && typeof source === 'object') {
    let newObject = {}
    const imObject = immutable(object)
    const imSource = immutable(source)

    Object.keys(Object(imSource)).forEach((k) => {
      if (
        imSource !== null &&
        typeof imSource === 'object' &&
        typeof imSource[k] === 'object' &&
        typeof imObject === 'object' &&
        typeof imObject[k] === 'object' &&
        imObject[k] !== null
      ) {
        newObject = {
          ...newObject,
          [k]: customMerge(imObject[k] || {}, imSource[k])
        }
      } else {
        newObject = {
          ...newObject,
          [k]: imSource[k]
        }
      }
    })

    ld.difference(Object.keys(imObject), Object.keys(newObject)).forEach((k) => {
      newObject = {
        ...newObject,
        [k]: imObject[k]
      }
    })

    return newObject
  }
  return typeof object === 'object' ? immutable(object) : {}
}

const marginToMarkup = (margin) => 1 / (1 - margin)

const markupToMargin = (markup) => (markup === 0 ? 0 : (markup - 1) / markup)

let benchTime = new Date().valueOf()
const bench = (resetOrMessage) => {
  const delta = new Date().valueOf() - benchTime
  if (typeof resetOrMessage === 'string') {
    benchLog(resetOrMessage, ` -> Completed in ${delta}ms`)
  } else if (resetOrMessage === true) {
    benchLog('restarting', ` -> Completed in ${delta}ms`)
    benchTime = new Date().valueOf()
  }
}

/**
 * Tokenize a string for the purpose of searching
 * @param string
 * @param weight
 * @returns {*}
 */
const tokenize = (string, weight = 1) =>
  cleanArray(
    String(string || '')
      .toLowerCase()
      .replace(/[^a-zA-Z\s]/gi, ' ')
      .replace(/(age|ry|al|tion|ion|ies|s|es|ment|ent|ise|ize|ing|ous)\b/gi, ' ')
      .replace(
        /(\b(and|or|&|the|an|a|their|his|hers|then|where|this|that|all|no|none|yes|may|you|will|null)\b)+/gi,
        ' '
      )
      .concat(' ')
      .repeat(weight, ' ')
      .trim()
      .split(/\s|-|\t|_/)
      .filter((r) => r.length >= 3),
    true,
    false
  )

/**
 * Determien term frequency from a set of search tokens, and content tokens
 * @param needleTokens
 * @param haystackTokens
 * @returns {*}
 */
const termFrequency = (needleTokens, haystackTokens) => {
  const found = needleTokens.filter((nt) => haystackTokens.includes(nt))

  let combinedFrequency = 0
  if (found.length) {
    const freq = ld.countBy(haystackTokens)
    combinedFrequency = found.reduce((acc, nt) => acc + freq[nt], 0)
  }

  const tf = divide(combinedFrequency, haystackTokens.length)

  return tf
}

/**
 * In a string of equation, remove dimensions and replace with values
 */
const replaceDimensions = (formula, dimensions, convertToMeasure = null) => {
  const variablesRegex = new RegExp(`\\b(${Object.keys(dimensions || {}).join('|')})\\b`, 'g')

  if (!variablesRegex.test(formula)) {
    return [formula, []]
  }

  const measurea = makeArray(convertToMeasure)
  let meas =
    (measurea.length && measurea[0] && typeof measurea[0] === 'string' && measurea[0]) || 'ft'
  const mt = getMeasureTypeForUnitOfMeasure(meas)
  meas = mt === 'count' ? 'count' : meas
  const base = conversionTables[meas]?.baseMeasure ?? 'ft'
  const dimensionsUsed = []

  const replaced = String(formula).replace(variablesRegex, (abbr) => {
    if (!(abbr in dimensions)) {
      return abbr
    }

    dimensionsUsed.push(abbr)

    const num = notNaN(dimensions[abbr].value)
    // If we don't have a dimension to convert from or to, just return the raw number
    // regardless of what dimension it derives from
    if (!convertToMeasure || !base || !dimensions[abbr].value || base === 'count') {
      return num
    }

    // if they are compatible measure types, convert directly
    if (dimensions[abbr].measureType === conversionTables[meas]?.measureType) {
      const converted = convertMeasures(num, dimensions[abbr].measure, meas)

      return converted === false ? num : converted
    }

    // get the measure to convert TO
    let measureTo = base
    if (dimensions[abbr].measureType === 'area' && measureTo !== 'sqr') {
      measureTo = conversionTables[base]?.area ?? `${base}2`
    } else if (dimensions[abbr].measureType === 'volume') {
      measureTo = conversionTables[base]?.volume ?? `${base}3`
    }

    if (convertToMeasure) {
      const converted = convertMeasures(num, dimensions[abbr].measure, measureTo)

      return converted === false ? num : converted
    }

    // If no measure provided, we cannot convert
    return num
  })

  return [replaced, dimensionsUsed]
}

/**
 *
 * @param dimensions object of dimensions
 * @param measure to convert TO 'ft', 'ft2', 'm', 'm2', 'mm', 'mm2'
 * @param equation string equation to convert
 */
const dimensionCompute = (equation, dimensions, converToMeasure = null) => {
  const [formula] = replaceDimensions(equation.trim(), dimensions, converToMeasure)
  return toNum(formula, 20, true)
}

/**
 *
 * @param dimensions
 * @param string convertToMeasure if null, do not convert
 * @param formula
 * @returns {{
 *  usingDimensions: [],
 *  equation: *,
 *  value: (*|number)}}
 */
const getComputedDimension = (dimensions, formula, convertToMeasure = null) => {
  if (formula && typeof formula !== 'string') {
    console.warn('Formula should be string', formula)
    formula = `${formula}`
  }

  if (!formula) {
    return {
      value: 0,
      equation: '',
      usingDimensions: []
    }
  }

  const [newFormula, usingDimensions] = replaceDimensions(formula, dimensions, convertToMeasure)

  return {
    value: toNum(newFormula, 20, true),
    equation: formula,
    formula,
    usingDimensions,
    dimensionsUsed: usingDimensions
  }
}

/**
 * From the quantity equation, determine not which
 * dimensions are used or required or linked BUT ONLY the dimensions
 * that are ultimately required to be provided to fulfil computed
 * dimensions and the quantity equation.
 * @param allDimensions
 * @param formula
 * @returns {[]|*[]}
 */
const getRequiredDimensionInputs = (allDimensions, formula) => {
  if (!allDimensions || !Object.keys(allDimensions).length) return []
  const { usingDimensions } = getComputedDimension(allDimensions, formula)
  const all = allDimensions

  let alreadyFound = []
  const inputRequired = []
  const getRequiredDimesionsFromDimension = (abbr) => {
    const equation = all[abbr].defaultValue

    const computedDimension = getComputedDimension(all, equation, all[abbr].measure)

    const dimUses = computedDimension.usingDimensions

    if (!dimUses.length) {
      inputRequired.push(abbr)
    } // no dependants, this is required

    let recursedUsed = []
    alreadyFound.push(abbr)
    dimUses.forEach((usedAbbr) => {
      if (!alreadyFound.includes(usedAbbr)) {
        recursedUsed = [...recursedUsed, ...getRequiredDimesionsFromDimension(usedAbbr)]
      }
    })

    alreadyFound = [...alreadyFound, ...dimUses, ...recursedUsed]

    return [...dimUses, ...recursedUsed]
  }

  usingDimensions.forEach((abbr) => getRequiredDimesionsFromDimension(abbr))

  return inputRequired
}

/**
 * Call functions one by one in order
 * @param tasks array list of async functions to call one by one
 * @returns {Promise<boolean|[]>}
 */
const waterfall = async (tasks) => {
  if (!Array.isArray(tasks)) throw new Error('Tasks must be an array')
  if (!tasks.length) return true
  let taskIndex = 0
  const returns = []

  const next = async () => {
    if (taskIndex > tasks.length - 1) {
      return true
    }
    const taskToCall = tasks[taskIndex]
    returns[taskIndex] = await taskToCall()
    taskIndex += 1
    return next()
  }

  await next()

  return returns
}

const externalScript = (url) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.setAttribute('src', url)
    script.onload = () => resolve(url)
    script.onerror = () => reject('Could not load script')
    document.head.appendChild(script)
  })

/**
 *
 * @param dependencies
 * @returns {{}}
 */
const getCircularDimensionDependencies = (dependencies) => {
  const visited = new Set() // Tracks fully processed variables
  const stack = new Set() // Tracks variables in the current path (to detect cycles)
  const circularDeps = {} // Store circular dependencies

  function dfs(variable) {
    if (stack.has(variable)) {
      circularDeps[variable] = Array.from(stack) // Capture the circular path
      return true // Cycle detected
    }
    if (visited.has(variable)) {
      return false // Already processed, no cycle
    }

    stack.add(variable) // Mark as part of the current path

    if (dependencies[variable]) {
      for (const dep of dependencies[variable]) {
        if (dfs(dep)) {
          circularDeps[variable] = circularDeps[variable] || []
          circularDeps[variable].push(dep)
        }
      }
    }

    stack.delete(variable) // Remove from the current path
    visited.add(variable) // Mark as fully processed

    return false
  }

  // Check each variable in the dependencies object
  for (const variable in dependencies) {
    if (!visited.has(variable)) {
      dfs(variable)
    }
  }

  return Object.keys(circularDeps).length > 0 ? circularDeps : {}
}

/**
 * Get interdimensional dependencies
 * @param dimensions
 * @returns {{totalDependencies: number, dependencies}}
 */
const getDimensionDependencies = (dimensions, list = null) => {
  const all = dimensions
  const regx = new RegExp('(?:^|\\b)(' + Object.keys(all).join('|') + ')(?:$|\\b)', 'g')
  const required = ld.uniq(list || Object.keys(all))
  let totalDependencies = 0

  const dependencies = required.reduce((acc, abbr) => {
    const matches = ld.uniq(String(all?.[abbr]?.equation ?? '').match(regx) || [])
    totalDependencies += matches.length
    const r = {
      ...acc,
      [abbr]: matches
    }
    return r
  }, {})

  return {
    totalDependencies,
    dependencies
  }
}

/**
 * Get a warning message for circular dimension references
 * @param circ
 * @param all
 * @returns {string}
 */
const getCircularDimensionWarning = (circ, all) => {
  const circKeys = Object.keys(circ)
  const dimLabel = (abbr) => `${all[abbr].dimension_name} (${abbr})`

  const message = circKeys.reduce(
    (acc, abbr) =>
      `${
        acc ? `${acc}\r\n` : ''
      }  • ${dimLabel(abbr)} ↹ ${circ[abbr].map((aabbr) => dimLabel(aabbr)).join(', ')}`,
    ''
  )

  const full = `You have a circular reference in your dimensions, you must fix them for your dimensions to calculate properly.
      
      ${message}\r\n\r\n`

  return full
}

/**
 * Sort dimensions in chronological order of inheritance
 * @param all
 * @param list
 * @param mapped
 * @returns {{[p: string]: *}|string[]}
 */
const sortDimensions = (
  dimensions,
  // eslint-disable-next-line no-unused-vars
  parentDimensions = false,
  list = null,
  mapped = false,
  throwError = false
) => {
  const all = immutable(dimensions)
  // console.log('sort');

  // First sort by number of dependencies
  const { totalDependencies, dependencies } = getDimensionDependencies(all, list)

  // // Check for circular dependencies
  // const circ = getCircularDimensionDependencies(dependencies)
  // // console.log('circ', circ, dependencies);
  // const circKeys = Object.keys(circ)
  // if (circKeys.length && throwError) {
  //   const full = getCircularDimensionWarning(circ, all)
  //   throw new Error({
  //     userMessage: full,
  //     message: full
  //   })
  // }

  const required = ld.uniq(list || Object.keys(all))

  const presorted = required.sort((a, b) => dependencies[a].length - dependencies[b].length)

  const nonDependant = presorted.filter((abbr) => dependencies[abbr].length === 0)

  const dependant = presorted.filter((abbr) => dependencies[abbr].length !== 0)

  // if (presorted.includes('cabd')) {
  //   console.log('PRESORT', JSON.stringify({
  //     presorted,
  //     nonDependant,
  //     dependant,
  //     dependencies,
  //   }, null, 2));
  // }

  const sorted = [...nonDependant]

  let count = 0
  const max = (dependant.length + totalDependencies + 1) * 3
  const run = (dep) => {
    count += 1
    let skipNext = false
    dep.forEach((abbr1, index) => {
      if (dependencies[abbr1].every((dabbr) => sorted.includes(dabbr))) {
        sorted.push(abbr1)
        dep.splice(index, 1)
      } else {
        skipNext = true
        sorted.push(abbr1)
        dep.splice(index, 1)
      }
    })

    if (dep.length && (!skipNext || count <= max)) {
      run(dep)
    } else if (count > max && throwError) {
      throw new Error({
        userMessage: 'Could not complete dimension calculations',
        message: 'Could not complete dimension calculations'
      })
    }
  }
  run(dependant)

  // const pd = parentDimensions;

  // const regx = new RegExp(`(^|\b)(${Object.keys(all).join('|')})($|\b)`);

  // const equationDerived = dim =>
  //   dim
  //   && (!dim.inherit || !pd)
  //   && (dim.equation && regx.test(dim.equation));

  const isRequired = (dim) => dim && !dim.inherit && !dim.equation

  const sortFunction = (a, b) => {
    // console.log('sort', a, b);
    if (!(a in all)) {
      all[a] = immutable(defaultDimension)
    }
    if (!(b in all)) {
      all[b] = immutable(defaultDimension)
    }

    // By dependency
    const eqa = all[a].equation || ''
    const eqb = all[b].equation || ''
    // console.log('   dependant', eqa.includes(b), eqb.includes(a));
    if (dependencies[a]?.includes(b)) return ['a dependant', 1]
    if (dependencies[b]?.includes(a)) return ['b dependant', -1]

    if (dependencies[a].length > dependencies[b].length) return ['a more dependant', 1]
    if (dependencies[a].length < dependencies[b].length) return ['b more dependant', -1]

    if (all[a].inherit && !all[b].inherit) {
      return ['linked', 1]
    }
    if (!all[a].inherit && all[b].inherit) {
      return ['linked', -1]
    }

    const iseqa = isEquation(eqa)
    const iseqb = isEquation(eqb)
    if (iseqa && !iseqb) return ['eq a', 1]
    if (!iseqa && iseqb) return ['eq b', -1]

    const bothInherit = all[a].inherit && all[b].inherit
    if (bothInherit && all[a].value && !all[b].value) {
      return ['linked defaulted', 1]
    }
    if (bothInherit && !all[a].value && all[b].value) {
      return ['linked defaulted', -1]
    }

    // // By derived
    // const dera = equationDerived(all[a]);
    // const derb = equationDerived(all[b]);
    //
    // console.log('   derived', dera, derb);
    // if (dera && !derb) return ['a derived', 1];
    // if (!dera && derb) return ['b derived', -1];

    const ra = isRequired(all[a])
    const rb = isRequired(all[b])
    // console.log('   req', ra, rb);
    // console.log('   empty', !all[a].value, !all[b].value);
    if (!all[a].value && all[b].value) return ['a empty', -1]
    if (all[a].value && !all[b].value) return ['b empty', 1]

    // Sort by required
    if (ra && !rb) return ['a req', -1]
    if (rb && !ra) return ['b req', 1]

    // Neither requires the other
    return ['nothing', 0]
  }

  // const secondPass = (a, b) => {
  //   // console.log('second pass', a, b);
  //
  //   // By dependency
  //   const eqa = all[a].equation || ''
  //   const eqb = all[b].equation || ''
  //
  //   // console.log('   dependant', eqa.includes(b), eqb.includes(a));
  //   if (eqa.includes(b)) return ['a dependant', 1]
  //   if (eqb.includes(a)) return ['b dependant', -1]
  //
  //   return ['none', 0]
  // }

  sorted.sort((a, b) => {
    const val = sortFunction(a, b)[1]
    // const [exp, val] = sortFunction(a, b);
    // console.log('result', exp, a, b, val);
    return val
  })
  // console.log('AFTER FIRST PAS', immutable(sorted));

  // Second pass by dependency only
  // sorted.sort((a, b) => {
  //   // const [exp, val] = secondPass(a, b);
  //   const val = secondPass(a, b)[1]
  //   // console.log('second pass', exp, val);
  //   return val
  // })
  // console.log('AFTER SEONCD PAS', immutable(sorted));

  if (mapped) {
    return sorted.reduce(
      (acc, abbr) => ({
        ...acc,
        [abbr]: {
          ...all[abbr],
          deps: dependencies?.[abbr] ?? [],
          demote: dependencies?.[abbr]?.length || all[abbr]?.inherit || 0
        }
      }),
      {}
    )
  }

  return sorted
}

const hexWithOpacity = (hex, alpha) => {
  // Remove the '#' if it's there
  if (hex.charAt(0) === '#') {
    hex = hex.slice(1)
  }

  // If shorthand hex (3 characters), expand to full (6 characters)
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('')
  }

  // Ensure the hex is now valid (6 characters)
  if (hex.length !== 6) {
    console.warn('Invalid hex value', `#${hex}`)
    return `#${hex}`
  }

  // Convert alpha to hexadecimal
  let alphaHex
  if (alpha <= 1 && alpha >= 0) {
    // If alpha is a decimal (0 to 1)
    alphaHex = Math.round(alpha * 255).toString(16)
    if (alphaHex.length === 1) {
      alphaHex = `0${alphaHex}` // Ensure 2 digits
    }
  } else if (typeof alpha === 'string' && alpha.endsWith('%')) {
    // If alpha is a percentage
    const percent = parseFloat(alpha) / 100
    alphaHex = Math.round(percent * 255).toString(16)
    if (alphaHex.length === 1) {
      alphaHex = `0${alphaHex}` // Ensure 2 digits
    }
  } else {
    throw new Error('Invalid alpha value')
  }

  // Return the hex color with alpha
  return `#${hex}${alphaHex}`
}

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : hex
}

const getAuthorizationHeaders = (authorization, externalRequest = false) => {
  let headers = {}
  let ignoreScope = false

  if (externalRequest) {
    return {
      headers: {},
      ignoreScope: true
    }
  }

  // username password authentication
  if (authorization.email && authorization.password) {
    headers = {
      Authorization: `Basic ${btoa(`${authorization.email}:${authorization.password}`)}`
    }
    ignoreScope = true
  } else if (authorization.token) {
    headers = {
      Authorization: `Bearer ${authorization.token}`,
      'Access-Token': `${authorization.accessToken}`,
      'Id-Token': `${authorization.idToken}`,
      'Refresh-Token': `${authorization.refreshToken}`
    }
    ignoreScope = false
  } else if (authorization.shortToken) {
    headers = {
      Authorization: `Short ${authorization.shortToken}`,
      'Access-Token': `${authorization.accessToken}`,
      'Id-Token': `${authorization.idToken}`,
      'Refresh-Token': `${authorization.refreshToken}`
    }
    ignoreScope = true
  }

  return {
    headers,
    ignoreScope
  }
}

/**
 * map the ids as keys and return as an array
 * @param array
 * @returns {[]}
 */
const mapKeys = (array, key = 'refId', asArray = false) => {
  if (!asArray) {
    return array.reduce((acc, item) => ({ ...acc, [item[key]]: item }), {})
  }
  return array.reduce((acc, item) => {
    if (item) acc[item[key]] = item
    return acc
  }, [])
}

/**
 * Takes an object of filters and converts it into Appsyncs
 * expected filter pattern.
 * example filters:
 * {
 *  status: 'active||inactive',
 *  type: 'type1&&type2',
 *  name: 'james'
 * }
 * @param filters
 * @returns {(string|null)}
 */
const generateAppsyncFilterPattern = (filters) => {
  if (!filters) return null

  const filts = Object.keys(filters).reduce((acc, key) => {
    if (!filters[key]) return acc
    const splits = filters[key].split('||')
    splits.forEach((split, index) => {
      const andSplits = split.split('&&')
      if (andSplits.length > 1) {
        splits.splice(index, 1, ...split.split('&&'))
      }
    })

    return {
      ...acc,
      [key]: {
        in: splits
      }
    }
  }, {})

  if (!Object.keys(filts).length) return null
  return JSON.stringify(filts)
}

const getFullName = (object, type = object.type) =>
  `${object[`${type}_fname`]} ${object[`${type}_lname`]}`

/**
 * Generate a timestamp for the current time
 * @returns
 */
const generateCurrentUnix = () => {
  return Math.floor(Date.now() / 1000).toString(10)
}

const hashCode = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash // Convert to 32bit integer
  }
  return hash
}

const stringToColor = (str) => {
  const hash = hashCode(str)
  const r = (hash & 0xff0000) >> 16
  const g = (hash & 0x00ff00) >> 8
  const b = hash & 0x0000ff

  // Calculate the luminance of the color
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b

  // If the color is too light, make it darker
  if (luminance > 186) {
    // 186 is an arbitrary threshold for light colors
    return `rgb(${r % 128}, ${g % 128}, ${b % 128})` // Make the color darker
  }

  return `rgb(${r}, ${g}, ${b})`
}

/**
 * Hash a string into a consistent hex color.
 * If the generated color is too light, return a darker variant.
 * @param {string} str - The input string to convert to a color.
 * @return {string} - A hex color string (e.g., "#ff5733").
 */
const stringToHexColor = (str) => {
  const hashCode = (str) => {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }
    return hash
  }

  const hash = hashCode(str)
  let r = (hash & 0xff0000) >> 16
  let g = (hash & 0x00ff00) >> 8
  let b = hash & 0x0000ff

  // Calculate the luminance of the color
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b

  // If the color is too light, make it darker
  if (luminance > 186) {
    // 186 is an arbitrary threshold for light colors
    r = r % 128
    g = g % 128
    b = b % 128
  }

  const toHex = (component) => component.toString(16).padStart(2, '0')

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`
}

const rgbToHex = (color = '#37c462') => {
  const hexRegex = /^#([0-9A-Fa-f]{3}){1,2}$/
  if (hexRegex.test(color)) {
    return color
  }

  // Check if the color string is in rgba format
  const rgbaRegex = /^rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)(?:\s*,\s*([\d.]+))?\s*\)$/
  const match = color.match(rgbaRegex)

  if (match) {
    const r = parseInt(match[1], 10)
    const g = parseInt(match[2], 10)
    const b = parseInt(match[3], 10)
    const a = parseFloat(match[4]) || 1 // Default alpha to 1 if not provided

    // Convert RGB to HEX
    const hex = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`

    // Convert alpha to 2-digit hex
    let alphaHex = ''
    if (a < 1) {
      alphaHex = Math.round(a * 255)
        .toString(16)
        .padStart(2, '0')
        .toUpperCase()
    }

    return `${hex}${alphaHex}`
  }

  // If color format is not recognized, return the original color string
  return color
}

/**
 * Blend two hex colors together based on a specified ratio.
 * @param {string} color1 - The first hex color (e.g., "#ff0000").
 * @param {string} color2 - The second hex color (e.g., "#00ff00").
 * @param {number} ratio - The ratio for blending the colors. A ratio of 0.5 gives equal blend.
 * @return {string} - The blended hex color (e.g., "#808000").
 */
function blendColors(rcolor1, rcolor2, ratio = 0.5) {
  const color1 = rcolor1.includes('rgb') ? rgbToHex(rcolor1) : rcolor1
  const color2 = rcolor2.includes('rgb') ? rgbToHex(rcolor2) : rcolor2

  const hexToRgb = (hex) => {
    const parsedHex = hex.replace(/^#/, '')
    return [
      parseInt(parsedHex.substring(0, 2), 16),
      parseInt(parsedHex.substring(2, 4), 16),
      parseInt(parsedHex.substring(4, 6), 16),
      1 / parseFloat(parsedHex.substring(6, 8)) || 1
    ]
  }

  const [r1, g1, b1, a1] = hexToRgb(color1)
  const [r2, g2, b2, a2] = hexToRgb(color2)

  const blend = (start, end, ratio) => Math.round(start * (1 - ratio) + end * ratio)

  const blendedRgb = [
    blend(r1, r2, ratio),
    blend(g1, g2, ratio),
    blend(b1, b2, ratio),
    blend(a1, a2, ratio)
  ]

  const [bl1, bl2, bl3, bl4] = blendedRgb
  return rgbToHex(`rgba(${bl1}, ${bl2}, ${bl3}, ${bl4})`)
}

const ensureContrastWithWhite = (h) => {
  let hex = rgbToHex(String(h))
  // Ensure the hex is a valid 6-character string
  if (hex.startsWith('#')) hex = hex.slice(1)
  if (hex.length !== 6) {
    console.warn(`Invalid hex color ${hex}`)
    return hex
  }

  // Convert hex to RGB
  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)

  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

  // Determine the amount to darken based on luminance
  let amount
  if (luminance > 0.9) {
    amount = 80
  } else if (luminance > 0.7) {
    amount = 60
  } else if (luminance > 0.5) {
    amount = 40
  } else if (luminance > 0.3) {
    amount = 0
  } else {
    amount = 0
  }

  // Function to darken a single color component
  const darken = (value) => Math.max(0, Math.min(255, value - amount))
  const newR = darken(r)
  const newG = darken(g)
  const newB = darken(b)

  // Convert back to hex
  const toHex = (value) => value.toString(16).padStart(2, '0')
  return `${toHex(newR)}${toHex(newG)}${toHex(newB)}`
}

const reorderObject = (obj, keysOrder) => {
  return keysOrder.reduce((acc, key) => {
    if (key in obj) {
      acc[key] = obj[key]
    }
    return acc
  }, {})
}

const calculateDuration = (timestamp1, timestamp2) => {
  // Convert timestamps to milliseconds
  const ms1 = timestamp1 * 1000
  const ms2 = timestamp2 * 1000
  // Calculate the difference in milliseconds
  const diffInMs = Math.abs(ms2 - ms1)
  // Convert milliseconds to days
  const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))
  // Return the difference with a minimum of 1 day
  return Math.max(diffInDays, 1)
}

const getDeferredPromise = () => {
  let resolve, reject

  // Create a new promise and assign its resolve and reject to the variables
  const promise = new Promise((res, rej) => {
    resolve = res
    reject = rej
  })

  // Return an object containing the promise and its resolve/reject functions
  return {
    promise,
    resolve,
    reject
  }
}

const removeUnnecessaryBulk = (normalized) => {
  return Object.entries(normalized).reduce((acc, [key, obj]) => {
    if (typeof obj !== 'object' || obj === null) {
      acc[key] = obj
      return acc
    }

    // List of keys to remove
    const keysToRemove = [
      'aoChangeOrders',
      'aoFullChanges',
      'aoExplicitChanges',
      'oCreator',
      'oOwner',
      'oClient',
      'oUpdates',
      'aoCollections',
      'aoActivities',
      'oPresentationSettings',
      'oSowColumns',
      'oMore',
      'oNormalized',
      'aoAssembliesUsingItem'
    ]

    // Remove unnecessary properties
    keysToRemove.forEach((k) => delete obj[k])

    // Process 'aoAddons' if present and is an array
    if (Array.isArray(obj.aoAddons)) {
      obj.aoAddons.forEach((addon) => {
        const costTypeId = addon?.bulk?.cost_type_id
        const assemblyId = addon?.bulk?.assembly_id

        if (costTypeId || assemblyId) {
          addon.id = costTypeId || assemblyId
          addon.type = addon.bulk?.type

          if (!addon.original) {
            addon.bulk = null
          }
        }
      })
    }

    // Clear specific array properties
    if (obj.oVariations?.items) obj.oVariations.items = []
    if (obj.aoVariationItems) obj.aoVariationItems = []

    // Remove old change tracking fields
    Object.keys(obj).forEach((field) => {
      if (
        (/_updated$|_original$|_change$/.test(field) && !/_qty_net_base_original/.test(field)) ||
        (/^item_/.test(field) && !(/^item_vendor_/.test(field) && /paid/.test(field))) ||
        /^sheet_/.test(field)
      ) {
        delete obj[field]
      }
    })

    acc[key] = obj
    return acc
  }, {})
}

/**
 * Download URL using Capacitor plugin
 * @param url
 * @param fileName
 */
const downloadFromURL = (url, fileName) => {
  return new Promise((resolve) => {
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.target = '_blank'
    a.download = fileName
    a.href = url
    a.click()
    resolve()
  })
}

// Extend the lodash object
export default ld.extend(ld, {
  ...FieldDetection,

  getDeferredPromise,

  // Document/state utiltiies
  viewPortHeight,
  viewPortSize,
  docSize,
  downloadFromURL,

  removeUnnecessaryBulk,

  // DOM utilties,
  createSelectionRange,
  nodeIsChildOf,
  setCurrentCursorAtEnd,
  setCurrentCursorPosition,
  getCaretPosition,
  getCurrentCursorPosition,
  scrollTo,
  scrollToX,
  getScrollParent,
  getScrollParentX,
  getContainerParent,
  getSizedParent,
  externalScript,

  // Utilities
  isempty,
  isScalar,
  isnan,
  empty,
  isset,
  getFilters,
  getClickActionName,
  focusInput,
  link,
  openLink,
  clone,
  immutable,
  imm,
  getMeasureForUnitOfMeasure,
  getResponseFromAjaxArgs,
  waterfall,

  setStorage,
  getStorage,
  setCookie,
  removeStorageItem,
  clearStorage,
  getStorageSize,

  // Debugging
  stackTrace,
  assert,
  log,
  error,

  isImageFileType,
  // Object utiltiies
  serialize,
  // merge,
  customMerge, // : ld.merge,
  coalesce,

  // Array utilities
  cleanArray,
  clearEmptyArrayValues,
  extractSingle,
  makeArray,
  makeStringArray,
  mapKeys,

  // String utiltiies
  decodeEntities,
  ucfirst,
  titleCase,
  nameCase,
  underCase,
  removeHtml,
  hungarianToCamelCase,
  mangle,
  unmangle,
  replaceImperialNotation,
  replaceHoursNotation,
  hasImperialNotation,
  idFromText,
  encode,
  decode,
  compress,
  decompress,
  encodeURI,
  decodeURI,
  jsonEquals,
  deepEquals,
  getFullName,

  // Number functions
  isNumericField,
  toNum,
  raw,
  notNaN,
  n: notNaN,
  toRound,
  toFloor,
  renderCalculation,
  fixCalculation,
  replaceVariables,
  isValidEquation,
  isEquation,
  eq,
  eqMeasure,
  getSigFigs,
  imperialToDecimal,
  marginToMarkup,
  markupToMargin,
  hoursRegex,
  imperialRegex,
  getSignificantDecimals,

  // Numeric formatting
  toTime,
  toTimeString,
  timeToMs,
  timeToSec,
  toCurrency,
  fromHours,
  toHours,
  toUtc,
  utcToLocal,
  fromUtc,
  convertMeasures,
  convertMeasure,
  decimalToImperial,

  // Dimensions
  getComputedDimension,
  dimensionCompute,
  defaultDimension,
  deprecatedDimensions,
  getRequiredDimensionInputs,
  getMeasureTypeForUnitOfMeasure,
  sortDimensions,
  getCircularDimensionDependencies,
  getDimensionDependencies,
  getCircularDimensionWarning,

  // Other formatting
  format: formatValue,
  deformat: deformatValue,
  validate,

  // Reference
  validations,
  countries,
  conversionTables,
  isUnitOfMeasureLinkable,
  bench,

  // Math
  divide,
  calculatorFunctions: functions,

  // Colors
  hexToRgb,
  hexWithOpacity,
  rgbToHex,
  ensureContrastWithWhite,
  stringToColor,
  blendColors,
  stringToHexColor,

  // Search
  tokenize,
  termFrequency,

  // Dates
  generateCurrentUnix,
  calculateDuration,

  defaultAddon,

  ...Throttle,
  ...Shorten,

  getAuthorizationHeaders,

  btoa,

  generateAppsyncFilterPattern,

  hashCode,
  reorderObject
})
