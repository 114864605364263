// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import _ from '../Helpers.js'
import Quote from './Quote'
import Normalize from '../NormalizeUtilities'

import TranslationMixin from '../../../src/mixins/TranslationMixin'

const { l, getLang } = TranslationMixin

export default {
  type: 'change_order',

  possibleStatuses: ['p', 'k', 'h'],

  fields: {
    ..._.zipObject(
      Object.keys(Quote.fields),
      Object.keys(Quote.fields).map((f) => ({
        ...Quote.fields[f],
        validate: {}, // make sure fields are not required
        trackChanges: false,
        save: false
      }))
    ),
    quote_id: {
      type: 'int',
      mapTo: 'quote'
    },
    change_order_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewChangeOrder'
    },
    change_order_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      validate: {
        required: true
      }
    },
    change_order_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
      ommitFromDuplicate: true,
      default: 'p'
    },
    change_order_time_created: {
      type: 'float',
      filter: true,
      format: false
    },
    change_order_time_seen: {
      type: 'float',
      filter: true,
      format: false
    },
    change_order_time_sent: {
      type: 'float',
      filter: true,
      format: false
    },
    change_order_time_booked: {
      type: 'float',
      filter: true,
      format: false
    },
    change_order_time_declined: {
      type: 'float',
      filter: true,
      format: false
    },
    change_order_client_approved_time: {
      type: 'float',
      save: false,
      trackChanges: false
    },
    oClientMeta: {
      type: 'object',
      save: false,
      trackChanges: false
    },
    change_order_creator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'user',
      trackChanges: false
    },
    change_order_approved_terms: {
      type: 'string',
      filter: false,
      save: false,
      trackChanges: false
    },
    contract_file_id: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: 'file',
      trackChanges: true
    },
    oAuthFile: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: 'file'
    },
    change_order_declined_reason: {
      type: 'string',
      save: true,
      default: '',
      trackChanges: false
    }
  },

  generateVueActions() {
    const fetchNormalizedVersion = async ({ dispatch }, id) => {
      const { object } = await dispatch('ajax', {
        path: `change_order/fetchNormalizedVersion/${id}`
      })
      let { normalized, rootRefId } = object

      normalized = Normalize.removeOrphaned(normalized)

      const { object: defaultedNormalized } = await dispatch('buildDefaultObjectNormalized', {
        embue: normalized
      })

      const auditPayload = await dispatch('auditDependencies', {
        refId: rootRefId,
        immediate: true,
        force: true,
        normalized: defaultedNormalized
      })

      const audited = auditPayload.normalized

      return { normalized: audited, object: audited, rootRefId }
    }

    return {
      async getChanges({ dispatch }, { id }) {
        const { set } = await dispatch('ajax', {
          path: `/change_order/getChanges/${id}`
        })
        return set
      },
      fetchNormalizedVersion,

      async downloadContract({ dispatch }, payload = {}) {
        const { object: co } = await dispatch('resolveObject', payload)
        const clientId = co.client_id
        const quoteId = co.quote_id
        const authFileId = co.contract_file_id

        if (authFileId) {
          return dispatch('File/download', { id: authFileId }, { root: true })
        }

        const prefix = `${clientId}/${quoteId}/${co.change_order_id}`
        return dispatch(
          'downloadFromS3',
          { prefix, name: `${co.change_order_name}-${co.change_order_id}.pdf` },
          { root: true }
        )

        return null
      },
      /**
       * Decline a proposed changeorder or qute
       * @param dispatch
       * @param rootState
       * @param payload
       * @returns {Promise<void>}
       */
      async requestRevision({ dispatch, rootState }, payload = {}) {
        const {
          message = l('How can we improve this proposal for you?', getLang(rootState.session.user))
        } = payload
        const request = await dispatch(
          'prompt',
          {
            message,
            required: false,
            inputType: 'textarea'
          },
          { root: true }
        )

        dispatch('addLoading', {}, { root: true })

        try {
          const { object } = await dispatch('resolveObject', payload)

          // await dispatch('partialUpdate', {
          //   selected: [
          //     {
          //       type: 'change_order',
          //       change_order_id: object.change_order_id,
          //       change_order_declined_reason: reason,
          //     },
          //   ],
          // });

          await dispatch('ajax', {
            path: `change_order/requestRevision/${object.change_order_id}`,
            data: {
              request
            }
          })

          dispatch(
            'alert',
            {
              message: l(
                'Revisions requested. Check your email soon for a new version.',
                getLang(rootState.session.user)
              )
            },
            { root: true }
          )
        } finally {
          dispatch('endLoading', {}, { root: true })
        }

        return payload
      },

      /**
       * Decline a proposed changeorder or qute
       * @param dispatch
       * @param rootState
       * @param payload
       * @returns {Promise<void>}
       */
      async decline({ dispatch, rootState }, payload = {}) {
        const {
          go = true,
          alert = true,
          message = l(
            `Sorry I couldn't help you further! Please help me do a better job next time by letting us know why you declined:`,
            getLang(rootState.session.user)
          )
        } = payload

        const reason = await dispatch(
          'prompt',
          {
            message,
            required: false
          },
          { root: true }
        )

        dispatch('addLoading', {}, { root: true })

        try {
          const { object } = await dispatch('resolveObject', payload)

          // await dispatch('partialUpdate', {
          //   selected: [
          //     {
          //       type: 'change_order',
          //       change_order_id: object.change_order_id,
          //       change_order_declined_reason: reason,
          //     },
          //   ],
          // });

          await dispatch('markMultiple', {
            selected: [
              {
                change_order_id: object.change_order_id,
                type: 'change_order'
              }
            ],
            embue: {
              change_order_declined_reason: reason
            },
            markAs: 'declined',
            go: false,
            alert: true
          })

          if (go) {
            const scopeRoute = await dispatch(
              'getScopeRouteFromScope',
              {
                scope: {
                  company: rootState.session.company.company_id,
                  quote: object.quote_id
                }
              },
              { root: true }
            )
            dispatch('to', `${scopeRoute}/project`, { root: true })
          }
          if (alert) {
            dispatch(
              'alert',
              {
                message: l('Proposal marked as declined.', getLang(rootState.session.user))
              },
              { root: true }
            )
          }
        } finally {
          dispatch('removeLoading', {}, { root: true })
        }

        return payload
      },
      /**
       * refId,
       * refernece
       * object,
       * selected: []
       * id, type,
       * @param dispatch
       * @param rootState
       * @param payload
       * @returns {*}
       */
      async markApprovedByClient({ dispatch, rootState }, payload = {}) {
        const {
          file: preSignedFile = false,
          getTermsApproval = true,
          termsAccepted: alreadyAcceptedTerms = ''
        } = payload
        let termsAccepted = alreadyAcceptedTerms
        let file = {}

        // try {
        const { object: changeOrder } = await dispatch('resolveObject', payload)

        if (!preSignedFile && getTermsApproval) {
          const { terms: newTerms } = await dispatch('sign/termsAndConditions', payload, {
            root: true
          })
          termsAccepted = `${termsAccepted}\r\n${newTerms}`
        }

        if (preSignedFile) {
          file = preSignedFile
        } else if (
          !preSignedFile &&
          rootState.session.company.company_project_signature_is_required
        ) {
          const { file: signedFile } = await dispatch(
            'sign/toFile',
            {
              type: 'quote',
              id: changeOrder.quote_id,
              object: changeOrder
            },
            { root: true }
          )
          file = signedFile
        }
        dispatch(
          'alert',
          {
            message: Object.keys(file).length
              ? 'Thank you! File signed & saved. Please wait while we update your records.'
              : 'Thank you for booking! Please wait while we update your records.'
          },
          { root: true }
        )

        dispatch('addLoading', {}, { root: true })
        const userMeta = await dispatch('getUserMeta', {}, { root: true })

        const approvalPayload = await dispatch('ajax', {
          button: payload.button || null,
          data: {
            oClientMeta: userMeta || null,
            contract_file_id:
              file.file_id || payload.contract_file_id || changeOrder.contract_file_id || null,
            change_order_approved_terms: termsAccepted
          },
          path: `/change_order/markApprovedByClient/${changeOrder.change_order_id}`
        })
        setTimeout(() => dispatch('removeLoading', {}, { root: true }), 250)

        return {
          ...payload,
          ...approvalPayload
        }
        /* } catch(err) {
          dispatch('alert', {
            error: true,
            message: 'Could not book this project. Please try again if you\'d like to proceed.' }, { root: true });
          throw new Error(err.message);
        } */
      },
      async markApprovedByCompany({ dispatch }, payload) {
        const { object } = await dispatch('resolveObject', payload)

        const returned = await dispatch('ajax', {
          data: {
            contract_file_id: payload.contract_file_id || object.contract_file_id || null,
            change_order_approved_terms:
              payload.change_order_approved_terms || object.change_order_approved_terms || null
          },
          path: `/change_order/markApprovedByCompany/${object.change_order_id}`
        })

        return { ...payload, ...returned }
      },
      async addContract({ dispatch }, payload) {
        const { selected: selectedFiles } = await dispatch(
          'modal/selector',
          {
            multiple: false
          },
          { root: true }
        )

        const { object: changeOrder } = await dispatch('resolveObject', payload)

        const returned = await dispatch('ajax', {
          ...payload,
          path: 'change_order/saveChanges',
          data: [
            {
              change_order_id: changeOrder.change_order_id,
              contract_file_id: _.makeArray(selectedFiles)[0].file_id
            }
          ]
        })

        return {
          ...payload,
          ...returned
        }
      }
    }
  }
}
