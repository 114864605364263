import {
  signIn,
  signInWithRedirect,
  resetPassword,
  confirmResetPassword,
  confirmSignIn,
  signOut,
  fetchAuthSession
} from 'aws-amplify/auth'
import { useStore } from 'vuex'
import { useSession } from '@/stores/session'

export function useAuthentication() {
  const store = useStore()
  const sessionStore = useSession()

  function login(email, password) {
    const signInInput = {
      username: email,
      password,
      options: {
        authFlowType: 'USER_PASSWORD_AUTH' // Required to migrate users
      }
    }

    return signIn(signInInput)
  }

  function magicLogin(email) {
    return signIn({
      username: email,
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP'
      }
    })
  }

  function reset(email) {
    return resetPassword({
      username: email
    })
  }

  async function logout() {
    await store.dispatch('logout', { go: false })
    await signOut()
    sessionStore.$reset()
  }

  function confirmReset(code, email, password) {
    return confirmResetPassword({
      username: email,
      confirmationCode: code,
      newPassword: password
    })
  }

  function confirmLogin(email, password) {
    return confirmSignIn({
      challengeResponse: password,
      options: {
        userAttributes: {
          email: email
        }
      }
    })
  }

  function confirmChallengeLogin(challengeResponse) {
    return confirmSignIn({
      challengeResponse: challengeResponse
    })
  }

  function externalLogin(provider) {
    return signInWithRedirect({
      provider
    })
  }

  async function tokenExchange() {
    const { tokens } = await fetchAuthSession()
    await store.dispatch('ajax', {
      path: '/auth/exchangeToken',
      setToken: true,
      data: {
        accessToken: tokens.accessToken.toString(),
        idToken: tokens.idToken.toString()
      }
    })
    await store.dispatch('getBaseValues')
  }

  async function updatePassword(password) {
    const { userSub } = await fetchAuthSession()

    if (!userSub) {
      return 'not authorized'
    }
    return store.dispatch('ajax', {
      path: 'user/setPassword',
      data: {
        password
      }
    })
  }

  return {
    login,
    magicLogin,
    externalLogin,
    logout,
    reset,
    confirmReset,
    confirmLogin,
    confirmChallengeLogin,
    tokenExchange,
    updatePassword
  }
}
