// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'payment_segment_item',

  possibleStatuses: ['a', 'c', 'i'],

  skipAudit: true,

  fields: {
    payment_segment_item_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    payment_segment_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'payment_segment'
    },
    item_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'item'
    },
    payment_segment_item_status: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    payment_segment_item_time_created: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    payment_segment_item_time_last_modified: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    }
  }
}
