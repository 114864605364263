const meta = {
  scopesAllowed: ['user', 'vendor'],
  menuContext: 'vendor'
}

export default [
  {
    path: '/:scopeRoute/vendor',
    alias: ['/pub/vendor', '/pub/user', '/pub/user/:id'],
    component: () => import('@/components/layout/Dashboard.vue'),
    children: [
      {
        name: 'Tasks',
        path: 'task-list',
        component: () => import('@/components/bodies/vendor/VendorDashboardTaskList.vue'),
        meta
      }
    ],
    meta
  }
]
