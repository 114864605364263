<template>
  <div class="w-full" :class="classes" :style="$slots.button ? '' : 'display: none !important;'">
    <fade>
      <span
        ref="trigger"
        class="trigger-container w-full"
        @click.capture.stop.prevent="(e) => open(e)"
      >
        <slot name="button"></slot>
      </span>
    </fade>

    <Portal :appendTo="appendTo">
      <div
        data-component="drop"
        :data-event-id="eventId"
        :style="`z-index: ${topZIndex} !important`"
        :ref="containerRef"
        v-focustrap
        role="dialog"
        @click="(e) => onOverlayClick(e)"
        class="overlay-panel--container"
        :class="[
          { hide: !visible },
          containerClass,
          `!z-[${topZIndex + 1}]`,
          { 'hide-pointer': hidePointer }
        ]"
        v-bind="{ ...$attrs, ...ptm('root') }"
      >
        <div
          :class="[cx('content')]"
          @click="(e) => onContentClick(e)"
          @mousedown="(e) => onContentClick(e)"
          @keydown="(e) => onContentKeydown(e)"
          v-bind="ptm('content')"
        >
          <slot></slot>
        </div>
      </div>
    </Portal>
  </div>
</template>

<script>
import Fade from '@/components/transitions/TransitionFade.vue'
import FocusTrap from 'primevue/focustrap'
import Portal from 'primevue/portal'
import OverlayEventBus from 'primevue/overlayeventbus'
import BaseOverlayPanel from 'primevue/overlaypanel/BaseOverlayPanel.vue'
import {
  ConnectedOverlayScrollHandler,
  DomHandler,
  UniqueComponentId,
  ZIndexUtils
} from 'primevue/utils'

export default {
  name: 'OverlayPanel',
  extends: BaseOverlayPanel,
  inheritAttrs: false,
  emits: ['open', 'close', 'closed'],
  data() {
    return {
      visible: false,
      eventId: _.uniqueId(),
      zIndexOnShow: 499
    }
  },
  props: {
    fixTo: {
      Type: [String, Object],
      required: false
    },
    isOpen: {
      type: [Boolean, Number],
      default: null
    },
    containerClass: {
      default: () => []
    },
    baseZIndex: {
      type: Number,
      default: 499
    },
    autoZIndex: {
      type: Boolean,
      default: false // this needs to be false so we can manage ourselves, NOT overlaypanel
    },
    hidePointer: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String
    }
  },
  watch: {
    isOpen(b, c) {
      if (b && !c) {
        this.open()
      } else if (!b) {
        this.close()
      }
    },
    visible(a) {
      if (!a) this.$emit('closed')
    },
    dismissable: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.bindOutsideClickListener()
        } else {
          this.unbindOutsideClickListener()
        }
      }
    }
  },
  selfClick: false,
  target: null,
  eventTarget: null,
  outsideClickListener: null,
  scrollHandler: null,
  resizeListener: null,
  container: null,
  styleElement: null,
  overlayEventListener: null,
  documentKeydownListener: null,
  beforeUnmount() {
    if (this.dismissable) {
      this.unbindOutsideClickListener()
    }

    if (this.scrollHandler) {
      this.scrollHandler.destroy()
      this.scrollHandler = null
    }

    this.destroyStyle()
    this.unbindResizeListener()
    this.target = null

    if (this.container && this.autoZIndex) {
      ZIndexUtils.clear(this.container)
    }

    if (this.overlayEventListener) {
      OverlayEventBus.off('overlay-click', this.overlayEventListener)
      this.overlayEventListener = null
    }

    this.container = null
  },
  mounted() {
    if (this.breakpoints) {
      this.createStyle()
    }
  },
  methods: {
    toggle(event, target) {
      if (this.visible) this.close()
      else this.open(event, target)
    },
    open(event, target) {
      this.zIndexOnShow = this.$store.state.modal.topZIndex
      const fixToTarget =
        this.fixTo && (typeof this.fixTo === 'string' ? $(this.fixTo)[0] : this.fixTo)
      this.eventTarget = (event && event.currentTarget) || fixToTarget
      this.target = target || (event && event.currentTarget) || fixToTarget
      this.visible = true

      this.$nextTick(() => {
        this.onEnter()
      })
    },
    close() {
      this.visible = false
      this.$nextTick(() => {
        this.onLeave()
        this.onAfterLeave()
      })
    },
    onContentClick() {
      this.selfClick = true
    },
    onEnter() {
      this.container.setAttribute(this.attributeSelector, '')
      DomHandler.addStyles(this.container, { position: 'absolute', top: '0', left: '0' })
      this.alignOverlay()

      if (this.dismissable) {
        this.bindOutsideClickListener()
      }

      this.bindScrollListener()
      this.bindResizeListener()

      if (this.autoZIndex) {
        ZIndexUtils.set(
          'overlay',
          this.container,
          this.baseZIndex + this.$primevue.config.zIndex.overlay
        )
      }

      this.overlayEventListener = (e) => {
        if (this.container?.contains(e.target)) {
          this.selfClick = true
        }
      }

      this.focus()
      OverlayEventBus.on('overlay-click', this.overlayEventListener)
      this.$emit('open')

      if (this.closeOnEscape) {
        this.bindDocumentKeyDownListener()
      }
    },
    onLeave() {
      this.unbindOutsideClickListener()
      this.unbindScrollListener()
      this.unbindResizeListener()
      this.unbindDocumentKeyDownListener()
      OverlayEventBus.off('overlay-click', this.overlayEventListener)
      this.overlayEventListener = null
      this.$emit('close')
    },
    onAfterLeave() {
      if (this.autoZIndex) {
        ZIndexUtils.clear(this.container)
      }
    },
    alignOverlay() {
      DomHandler.absolutePosition(this.container, this.target)

      const containerOffset = DomHandler.getOffset(this.container)
      const targetOffset = DomHandler.getOffset(this.target)
      let arrowLeft = 0

      if (containerOffset.left < targetOffset.left) {
        arrowLeft = targetOffset.left - containerOffset.left
      }

      this.container.style.setProperty('--overlayArrowLeft', `${arrowLeft}px`)

      if (containerOffset.top < targetOffset.top) {
        this.container.setAttribute('data-p-overlaypanel-flipped', 'true')
        !this.isUnstyled && DomHandler.addClass(this.container, 'p-overlaypanel-flipped')
      }
    },
    onContentKeydown(event) {
      if (event.code === 'Escape' && this.closeOnEscape) {
        this.close()
        DomHandler.focus(this.target)
      }
    },
    onButtonKeydown(event) {
      switch (event.code) {
        case 'ArrowDown':
        case 'ArrowUp':
        case 'ArrowLeft':
        case 'ArrowRight':
          event.preventDefault()
          break
        default:
          break
      }
    },
    focus() {
      let focusTarget = this.container.querySelector('[autofocus]')

      if (focusTarget) {
        focusTarget.focus()
      }
    },
    onKeyDown(event) {
      if (event.code === 'Escape' && this.closeOnEscape) {
        this.visible = false
      }
    },
    bindDocumentKeyDownListener() {
      if (!this.documentKeydownListener) {
        this.documentKeydownListener = this.onKeyDown.bind(this)
        window.document.addEventListener('keydown', this.documentKeydownListener)
      }
    },
    unbindDocumentKeyDownListener() {
      if (this.documentKeydownListener) {
        window.document.removeEventListener('keydown', this.documentKeydownListener)
        this.documentKeydownListener = null
      }
    },
    bindOutsideClickListener() {
      if (!this.outsideClickListener && DomHandler.isClient()) {
        this.outsideClickListener = (event) => {
          if (
            this.visible &&
            !this.selfClick &&
            !this.isTargetClicked(event) &&
            !this.isChooseClicked(event)
          ) {
            this.visible = false
          }

          this.selfClick = false
        }

        window.addEventListener('mousedown', this.outsideClickListener)
      }
    },
    unbindOutsideClickListener() {
      if (this.outsideClickListener) {
        window.removeEventListener('mousedown', this.outsideClickListener)
        this.outsideClickListener = null
        this.selfClick = false
      }
    },
    bindScrollListener() {
      if (!this.scrollHandler) {
        this.scrollHandler = new ConnectedOverlayScrollHandler(this.target, () => {
          if (this.visible) {
            this.visible = false
          }
        })
      }

      this.scrollHandler.bindScrollListener()
    },
    unbindScrollListener() {
      if (this.scrollHandler) {
        this.scrollHandler.unbindScrollListener()
      }
    },
    bindResizeListener() {
      if (!this.resizeListener) {
        this.resizeListener = () => {
          if (this.visible && !DomHandler.isTouchDevice()) {
            this.visible = false
          }
        }

        window.addEventListener('resize', this.resizeListener)
      }
    },
    unbindResizeListener() {
      if (this.resizeListener) {
        window.removeEventListener('resize', this.resizeListener)
        this.resizeListener = null
      }
    },
    isTargetClicked(event) {
      return (event.target?.closest(`[data-event-id="${this.eventId}"]`) ?? null) !== null
    },
    isChooseClicked(event) {
      return (
        (event.target?.closest(`[data-component="calculator"]`) ?? null) !== null ||
        (event.target?.closest(`[data-component="drop"]`) ?? null) !== null ||
        (event.target?.closest(`[data-component="confirm"]`) ?? null) !== null ||
        (event.target?.closest(`[data-component="choose"]`) ?? null) !== null ||
        (event.target?.closest(`.choose-drop`) ?? null) !== null
      )
    },
    containerRef(el) {
      this.container = el
    },
    createStyle() {
      if (!this.styleElement && !this.isUnstyled) {
        this.styleElement = document.createElement('style')
        this.styleElement.type = 'text/css'
        DomHandler.setAttribute(this.styleElement, 'nonce', this.$primevue?.config?.csp?.nonce)
        document.head.appendChild(this.styleElement)

        let innerHTML = ''

        for (let breakpoint in this.breakpoints) {
          innerHTML += `
                        @media screen and (max-width: ${breakpoint}) {
                            .p-overlaypanel[${this.attributeSelector}] {
                                width: ${this.breakpoints[breakpoint]} !important;
                            }
                        }
                    `
        }

        this.styleElement.innerHTML = innerHTML
      }
    },
    destroyStyle() {
      if (this.styleElement) {
        document.head.removeChild(this.styleElement)
        this.styleElement = null
      }
    },
    onOverlayClick(event) {
      OverlayEventBus.emit('overlay-click', {
        originalEvent: event,
        target: this.target
      })
    }
  },
  computed: {
    topZIndex() {
      return this.zIndexOnShow
    },
    attributeSelector() {
      return UniqueComponentId()
    },
    closeAriaLabel() {
      return this.$primevue.config.locale.aria ? this.$primevue.config.locale.aria.close : undefined
    }
  },
  directives: {
    focustrap: FocusTrap
  },
  components: {
    Portal,
    Fade
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.overlay-panel--container {
  opacity: 1;
  height: max-content;
  transition: opacity 100ms ease;

  &.hide-pointer {
    &::after {
      content: none;
    }
  }

  &.hide {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
}

.drop-source-container {
  display: flex;
  align-items: center;

  .trigger-container {
    display: inline-flex !important;
  }
  &.invisible {
    display: inline !important;
    .trigger-container {
      width: 0px;
      height: 0px;
      opacity: 0;
      padding: 0px;
      margin: 0px;
      display: inline !important;
    }
  }
}
</style>
