import * as types from '../../mutation-types'
import { subscribeToChannelUserOnline } from '../../../apollo-client/api-calls/activity'
import { client } from '@/apollo-client/ApolloClients'

const state: {
    usersOnline: {
        [userId: string]: {
            userOnline: boolean,
            userOnlineSubscription: any,
            userOnlineInterval: any
        }
    }
} = {
    usersOnline: {},
}

const getters = {}

const actions = {
    async subscribeUserOnline({ dispatch, commit }, userId: string) {
        const observer = client().subscribe({
            query: subscribeToChannelUserOnline,
            variables: {
                userId
            }
        })

        try {
            const userOnlineObserver = observer.subscribe({
                next() {
                    dispatch('trackUserOnline', { userId, userOnline: true, userOnlineSubscription: userOnlineObserver })
                },
                error(err) {
                    console.error(err)
                }
            })
        } catch (err) {
            console.error(err)
            await dispatch(
                'alert',
                {
                    message: 'Failed to track online user',
                    error: true
                },
                { root: true }
            )
        }
    },
    trackUserOnline({ dispatch, commit }, payload: { userId: string, userOnline: boolean, userOnlineSubscription: any }) {
        const setUserOfflineInterval = setInterval(() => {
            commit({ type: types.SET_USER_OFFLINE, payload })
        }, 20000)
        commit({
            type: types.SET_USER_ONLINE, payload: {
                ...payload,
                userOnlineInterval: setUserOfflineInterval
            }
        })

    },
    unTrackUserOffline({ dispatch, commit }, payload: { userId: string }) {
        commit({ type: types.REMOVE_USER_ONLINE, payload })
    },
    unSubscribeToChannelUserOnline({ dispatch, commit }, userId: string) {
        // First unsubscribe from the GraphQL subscription
        if (state.usersOnline?.[userId]?.userOnlineSubscription) {
            state.usersOnline[userId].userOnlineSubscription.unsubscribe();
        }

        // Then clear any timer interval
        if (state.usersOnline?.[userId]?.userOnlineInterval) {
            clearInterval(state.usersOnline[userId].userOnlineInterval);
        }

        // Finally, remove the user from state
        commit({type: types.REMOVE_USER_ONLINE, payload: {userId}});
    }
}

const mutations = {
    [types.SET_USER_ONLINE](state, {payload}) {
        if(state.usersOnline?.[payload.userId]?.userOnlineInterval) {
            clearInterval(state.usersOnline?.[payload.userId]?.userOnlineInterval)
        }
        state.usersOnline[payload.userId] = {
            userOnline: payload.userOnline,
            userOnlineSubscription: payload.userOnlineSubscription,
            userOnlineInterval: payload.userOnlineInterval
        }
    },
    [types.SET_USER_OFFLINE](state, {payload}) {
        if (state.usersOnline?.[payload.userId]?.userOnline) {
            clearInterval(state.usersOnline?.[payload.userId]?.userOnlineInterval)
            state.usersOnline[payload.userId].userOnline = false
        }
    },
    [types.REMOVE_USER_ONLINE](state, {payload}) {
        delete state.usersOnline[payload.userId]
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
