import {
  toRefs,
  ref,
  computed,
  onBeforeUnmount,
  onBeforeMount,
  watch,
  getCurrentInstance,
  onMounted
} from 'vue'
import { useStore } from 'vuex'
import NormalizeUtilities from '../../../imports/api/NormalizeUtilities.js'

/**
 * example schema
 * {
 *   "layers": [
 *     {
 *       "id": "f52b8f8f-40b2-4205-a750-bf3cd71e67b5",
 *       "name": "Plans-23423343.png",
 *       "type": "image",
 *       "position": {
 *         "x": 0,
 *         "y": 0,
 *         "width": 2832.6976273922623,
 *         "height": 1876.956433441078
 *       },
 *       "metadata": {
 *         "centroid": {
 *           "x": 0,
 *           "y": 0
 *         },
 *         "user": {
 *           "user_id": "4",
 *           "user_fname": "Mike",
 *           "user_lname": "Bignold",
 *           "user_name": "Mike Bignold"
 *         }
 *       },
 *       "zIndex": 2,
 *       "url": "https://files.bolsterbuilt.com/file/view/fi-9b1000a0281rn3gm?sc=eyJjb21wYW55IjoyMTYwMywidXNlciI6IjQifQ%3D%3D&ut=4U5493276a&ct=21603C87252a7b",
 *       "file": {
 *         "file_id": "fi-9b1000a0281rn3gm",
 *         "file_type": "image/png",
 *         "file_name": "Plans-23423343.png",
 *         "width": 3610,
 *         "height": 2392,
 *         "url": "https://files.bolsterbuilt.com/file/view/fi-9b1000a0281rn3gm?sc=eyJjb21wYW55IjoyMTYwMywidXNlciI6IjQifQ%3D%3D&ut=4U5493276a&ct=21603C87252a7b"
 *       },
 *       "isExplicitlyClosed": true,
 *       "scaledTo": {
 *         "pixels": 20,
 *         "length": 1,
 *         "unit": "ft",
 *         "adjustment": 0.784680783211153
 *       }
 *     },
 *     {
 *       "id": "19100eb4-b742-4653-b0e7-205ed2ee44ec",
 *       "name": "Layer 2",
 *       "type": "line",
 *       "position": {
 *         "x": 630.7199999999999,
 *         "y": 848.1599999999999,
 *         "width": 524.16,
 *         "height": 505.45846120131387
 *       },
 *       "metadata": {
 *         "isComplete": false,
 *         "length": 0,
 *         "centroid": {
 *           "x": 0,
 *           "y": 0
 *         },
 *         "user": {
 *           "user_id": "4",
 *           "user_fname": "Mike",
 *           "user_lname": "Bignold",
 *           "user_name": "Mike Bignold"
 *         },
 *         "segment_length_total": 59182.13902837521,
 *         "segment_length_outside": 59182.13902837521,
 *         "segment_length_inside": 0,
 *         "perimeter_inside": 59182.13902837521,
 *         "area_total": 949.8575402895091
 *       },
 *       "zIndex": 1,
 *       "segments": [
 *         [
 *           {
 *             "x": 630.7199999999999,
 *             "y": 848.1599999999999,
 *             "snapping": false,
 *             "sid": "73d6c6e0-a304-44a9-9e7a-701fcb5874da",
 *             "sidx": 0,
 *             "lid": "19100eb4-b742-4653-b0e7-205ed2ee44ec",
 *             "outside": true
 *           },
 *           {
 *             "x": 630.7199999999999,
 *             "y": 1353.6184612013137,
 *             "snapping": "screen",
 *             "sid": "73d6c6e0-a304-44a9-9e7a-701fcb5874da",
 *             "sidx": 0,
 *             "lid": "19100eb4-b742-4653-b0e7-205ed2ee44ec",
 *             "outside": true
 *           }
 *         ],
 *         [
 *           {
 *             "x": 630.7199999999999,
 *             "y": 1353.6184612013137,
 *             "snapping": "screen",
 *             "sid": "9cf23a09-618e-4edc-b965-2e15976193d1",
 *             "sidx": 1,
 *             "lid": "19100eb4-b742-4653-b0e7-205ed2ee44ec",
 *             "outside": true
 *           },
 *           {
 *             "x": 1150.56,
 *             "y": 1353.6184612013137,
 *             "snapping": "perpendicular-horizontal",
 *             "sid": "9cf23a09-618e-4edc-b965-2e15976193d1",
 *             "sidx": 1,
 *             "lid": "19100eb4-b742-4653-b0e7-205ed2ee44ec",
 *             "outside": true
 *           }
 *         ],
 *         [
 *           {
 *             "x": 1150.56,
 *             "y": 1353.6184612013137,
 *             "snapping": "perpendicular-horizontal",
 *             "sid": "a1ef5c32-a5f6-499c-9158-d9e9b52169e8",
 *             "sidx": 2,
 *             "lid": "19100eb4-b742-4653-b0e7-205ed2ee44ec",
 *             "outside": true
 *           },
 *           {
 *             "x": 1154.8799999999999,
 *             "y": 848.1599999999999,
 *             "snapping": "perpendicular-horizontal",
 *             "sid": "a1ef5c32-a5f6-499c-9158-d9e9b52169e8",
 *             "sidx": 2,
 *             "lid": "19100eb4-b742-4653-b0e7-205ed2ee44ec",
 *             "outside": true
 *           }
 *         ],
 *         [
 *           {
 *             "x": 1154.8799999999999,
 *             "y": 848.1599999999999,
 *             "snapping": "perpendicular-horizontal",
 *             "sid": "5e411cda-7ab7-4dc7-a62c-9400632b2601",
 *             "sidx": 3,
 *             "lid": "19100eb4-b742-4653-b0e7-205ed2ee44ec",
 *             "outside": true
 *           },
 *           {
 *             "x": 630.7199999999999,
 *             "y": 848.1599999999999,
 *             "snapping": "endpoint",
 *             "sid": "5e411cda-7ab7-4dc7-a62c-9400632b2601",
 *             "sidx": 3,
 *             "lid": "19100eb4-b742-4653-b0e7-205ed2ee44ec",
 *             "outside": true
 *           }
 *         ]
 *       ],
 *       "isExplicitlyClosed": true,
 *       "calcs": {
 *         "segment_length_total": 102.74676914648475,
 *         "segment_length_outside": 102.74676914648475,
 *         "segment_length_inside": 0,
 *         "perimeter_inside": 102.74676914648475,
 *         "area_total": 659.6232918677147
 *       },
 *       "dims": {
 *         "segment_length_total": {
 *           "links": [
 *               {
 *                 "dim": "tfl",
 *                 "assembly": null
 *               }
 *             ]
 *           },
 *           "segment_length_outside": {
 *             "links": [
 *               {
 *                 "dim": "efl",
 *                 "assembly": null
 *               }
 *             ]
 *           },
 *           "segment_length_inside": {
 *             "links": [
 *               {
 *                 "dim": "ifl",
 *                 "assembly": null
 *               }
 *             ]
 *           },
 *           "perimeter_inside": {
 *             "links": [
 *               {
 *                 "dim": "iwp",
 *                 "assembly": null
 *               }
 *             ]
 *           },
 *           "area_total": {
 *             "links": [
 *               {
 *                 "dim": "fa",
 *                 "assembly": null
 *               }
 *             ]
 *           }
 *         }
 *       }
 *     ],
 *     "rooms": [],
 *     "scale": {
 *       "pixels": 20,
 *       "length": 1,
 *       "unit": "ft"
 *     }
 *   }
 * @param payload
 * @returns {{getAssemblyRef: (function(*): (undefined|null)), possibleDimensions: [{}] extends [Ref] ? IfAny<{}, Ref<{}>, {}> : Ref<UnwrapRef<{}>, UnwrapRef<{}> | {}>, norm: ComputedRef<any>, sortAssembliesNatural: ComputedRef<string[]>, dimensions: [{}] extends [Ref] ? IfAny<{}, Ref<{}>, {}> : Ref<UnwrapRef<{}>, UnwrapRef<{}> | {}>}}
 */
export function useQuoteConnector(payload) {
  const $store = useStore()
  const $this = getCurrentInstance().proxy

  const {
    refId,
    store = 'Quote',
    takeoff,
    addLineLayer,
    addCountLayer,
    updateLayer,
    selectLayer
  } = payload

  const {
    loaded,
    norm = computed(() => $store.state[store].normalized),
    quote = computed(() => norm.value[refId])
    // refId,
  } = toRefs(payload)

  const dimensions = ref({})
  let poss = ref({})

  const getLinkKey = (link) => {
    const id = link.assembly?.assembly_id
    const refId = link.assembly?.refId
    let linkTo = 'ANY'

    if (id) linkTo = `ID:${id}`
    if (refId) linkTo = `REFID:${refId}`

    return `${link.dim}|${linkTo}` // prefer assembly id
  }

  const handleLayerDeleted = (layer) => {
    const allLinks = _.flatMap(layer.dims, 'links')
    const ignore = allLinks.reduce((acc, link) => {
      const key = getLinkKey(link)
      acc[key] = { ignore: true, link }
      return acc
    }, {})
    takeoff.ignoreLinks = {
      ...takeoff.ignoreLinks,
      ...ignore
    }
  }

  const removeIgnoreLink = async (linkKey) => {
    const il = { ...takeoff.ignoreLinks }
    delete il[linkKey]
    takeoff.ignoreLinks = { ...il }
    const layers = await loadQuoteRequirements(false) // since we aren't ignoring anymore it we should reload any missing
    selectLayer(layers[0].id)
    return layers
  }

  onBeforeMount(() => {
    $this.$on('layer-deleted', handleLayerDeleted)
  })
  onBeforeUnmount(() => {
    $this.$off('layer-deleted', handleLayerDeleted)
  })

  const initialize = async () => {
    await retrieve()

    await loadQuoteRequirements()
  }

  const loadQuoteRequirements = async (askFirst) => {
    // Check for missing dimensions and create required layers
    const missingDims = checkForMissingAssemblyDims(dimensions.value, takeoff.layers)

    if (!missingDims.length) return

    if (
      askFirst &&
      !(await $store.dispatch('modal/asyncConfirm', {
        message: `Do you want to add ${missingDims.length} dimensions to the takeoff?
        ${missingDims.map((md) => `  • ${md.assemblyName}: ${md.dim?.name ?? md.abbr}`).join(`\r\n`)}`
      }))
    )
      return

    return createLayerForMissingAssemblyDims(missingDims)
  }

  const retrieve = async () => {
    poss.value = await $store.dispatch('Dimension/getPossibleDimensions')
    const assemblyAreas = {}

    for (let object of Object.values(norm.value)) {
      if (object.type === 'cost_item') continue

      const dims = object.oDimensions ?? {}

      assemblyAreas[object.refId] = dims
      // const reqDims = Object.values(dims)
      //   .some(v => !v.inherit && !String(v.equation || '').trim())
      //
      // if (reqDims.length) {
      //   areas.push(object.refId)
      // }
      // if (reqDims.length) {
      //   areas.push(object.refId)
      // }
    }

    dimensions.value = assemblyAreas
  }

  // now check to see if each assembly area is covered by a link to layer, and if not create additional layers

  const layerLinkLookup = computed(() => {
    // Build a reverse lookup of layer links to quickly find which layers use which dimensions
    return takeoff.layers?.reduce((lookup, layer) => {
      if (!layer.dims) return lookup

      Object.entries(layer.dims).forEach(([calcKey, calc]) => {
        calc.links?.forEach((link) => {
          const key = `${link.assembly?.refId || 'root'}-${link.dim}`
          if (!lookup[key]) {
            lookup[key] = []
          }
          lookup[key].push({
            layerId: layer.id,
            layerType: layer.type,
            calcKey,
            assemblyRef: link.assembly?.refId || 'root',
            dim: link.dim
          })
        })
      })
      return lookup
    }, {})
  })

  const checkForMissingAssemblyDims = (assemblyAreas) => {
    const linkLookup = layerLinkLookup.value
    const missingDims = []

    // Check each assembly for required dimensions that aren't linked
    for (const [assemblyRef, oDimensions] of Object.entries(assemblyAreas)) {
      for (const [abbr, dim] of Object.entries(oDimensions || {})) {
        // Skip if dimension is inherited or has an equation
        if (dim.inherit || c.isEquation(`${dim.equation || ''}`.trim())) continue

        const lookupKey = `${assemblyRef}-${abbr}`
        if (lookupKey in linkLookup) continue

        // Check if this dimension is required and not linked in any layer
        missingDims.push({
          assemblyRef,
          assemblyName: norm.value[assemblyRef]?.assembly_name ?? 'Project root',
          oDimensions,
          abbr,
          dim,
          measureType: dim.dimension_measure_type
        })
      }
    }

    return missingDims
  }

  const createLayerForMissingAssemblyDims = (missingAssemblyDims) => {
    if (!missingAssemblyDims?.length) return []

    const layers = []
    const groups = {
      walls: {
        // can all be calculated from the same drawing
        iwh: 'none',
        ewh: 'none',
        footd: 'none',
        slabh: 'none',
        iwp: 'perimeter_inside',
        iwa: 'area_total',
        ewp: 'segment_length_outside',
        fa: 'area_total',
        ca: 'area_total',
        fp: 'segment_length_outside',
        ifl: 'segment_length_inside',
        efl: 'segment_length_outside',
        tfl: 'segment_length_total',
        slaba: 'area_total',
        footl: 'segment_length_total',
        footw: 'none',
        ceilh: 'none'
      },
      fence: {
        fena: 'area_total',
        fenarea: 'area_total',
        fenh: 'none',
        fenl: 'segment_length_total',
        fenpkets: 'none',
        fenrail: 'none',
        tfenlength: 'segment_length_total'
      },
      deck: {
        dcka: 'area_total',
        dckht: 'none',
        deckp: 'segment_length_outside'
      },
      'deck ledger': {
        deledger: 'area_total'
      },
      'deck posts': {
        deledger: 'area_total'
      },
      'deck railing': {
        rail: 'segment_length_total',
        railh: 'none'
      },
      'roof general': {
        ra: 'area_total',
        fl: 'segment_length_outside',
        eaves: 'segment_length_outside',
        pitch: 'none',
        rp: 'none',
        rpitch: 'none',
        rise: 'none',
        run: 'none'
      },
      'roof ridges': {
        rridge: 'segment_length_total'
      },
      'roof hips': {
        rhip: 'segment_length_total'
      },
      'roof rakes': {
        rrake: 'segment_length_total'
      },
      'roof valleys': {
        rvalley: 'segment_length_total'
      },
      'roof gables': {
        rgbable: 'segment_length_total'
      }
    }

    const getCalcKey = (item) => {
      const dim = poss.value[item.abbr]
      if (item.measureType === 'count') return 'count_total'
      if (item.measureType === 'area') return 'area_total'
      if (item.measureType === 'length' && dim.options?.preset?.options?.length) return 'none'
      if (item.measureType === 'length' && /perimeter|wall|baseboard|crown/.test(dim.name))
        return 'perimeter_inside'
      if (item.measureType === 'length') return 'segment_length_total'
      return 'none'
    }

    // First, group by assembly
    const dimsByAssembly = missingAssemblyDims.reduce((acc, item) => {
      const { assemblyRef, abbr, dim, measureType } = item

      let placed = false
      for (const [groupName, dimensions] of Object.entries(groups)) {
        // Then categorize within each assembly
        if (!(abbr in dimensions)) continue
        acc[assemblyRef] ??= {}
        acc[assemblyRef][groupName] ??= []
        acc[assemblyRef][groupName].push({ ...item, calcKey: dimensions[abbr] })
        placed = true
      }

      if (!placed) {
        acc[assemblyRef] ??= {}
        acc[assemblyRef].other ??= []
        acc[assemblyRef].other.push({ ...item, calcKey: getCalcKey(item) })
      }

      return acc
    }, {})

    // Process each assembly separately
    for (const [assemblyRef, groups] of Object.entries(dimsByAssembly)) {
      const assembly = norm.value[assemblyRef] ?? null
      const assemblyName = assembly?.assembly_name || 'Root'

      for (const [groupName, groupDims] of Object.entries(groups)) {
        const dims = {}

        let layerFunc = addLineLayer
        for (const link of groupDims) {
          if (link.dim.measureType === 'count') layerFunc = addCountLayer

          const fullLink = {
            dim: link.dim.abbr,
            color: `#${link.dim.color}`,
            assembly: {
              refId: assemblyRef,
              assembly_name: assemblyName,
              assembly_id: assembly.assembly_id || null
            }
          }

          // check if we are ignoring this link or not
          const linkKey = getLinkKey(fullLink)
          if (!takeoff.ignoreLinks?.[linkKey]?.ignore) {
            // add link
            dims[link.calcKey] ??= { links: [] }
            dims[link.calcKey].links.push(fullLink)
          }
        }

        const firstLink = Object.values(dims)[0]?.links?.[0] ?? null
        if (firstLink) {
          layers.push(
            layerFunc({
              name: `${assemblyName} - ${groupName === 'none' ? poss.value[firstLink.abbr]?.name ?? 'none' : groupName}`,
              position: { x: 0, y: 0, width: 0, height: 0 },
              dims
            })
          )
        }
      }
    }

    return layers
  }

  const roundFtTo16thInch = (value) => {
    let step = 1 / 96
    return Math.round(value / step) * step
  }

  onBeforeMount(async () => {
    if (loaded.value) await initialize()
    else {
      const unwatch = watch(loaded, (is, was) => {
        if (is && !was) {
          unwatch()
          initialize()
        }
      })
    }
  })

  const getAssemblyRef = (assemblyRef) => {
    if (!assemblyRef || assemblyRef === 'root') return refId
    return assemblyRef
  }

  const layersHandler = () => {
    const layers = takeoff.layers

    // add em all up grouped by destination (root by default)
    const assemblyDims = dimensions.value ?? {}

    for (let layer of layers) {
      if (!layer.calcs || !layer.dims) continue

      for (const [calc, opts] of Object.entries(layer.dims)) {
        for (const link of opts.links ?? []) {
          const ref = getAssemblyRef(link.assembly?.refId)
          assemblyDims[ref] ??= {}
          const dim = (assemblyDims[ref][link.dim] ??= poss.value[link.dim] ?? { value: 0 })
          dim.value += layer.calcs[calc] ?? 0
        }
      }
    }

    dimensions.value = assemblyDims
  }

  watch(
    () => takeoff.layers,
    () => c.throttle(() => layersHandler()),
    { deep: true }
  )

  const getDimChangeSet = () => {
    const dimChanges = {}
    const layers = takeoff.layers

    // Cache object method references for reuse
    const keys = Object.keys
    const entries = Object.entries

    // First pass: collect all measurements from layers
    for (const layer of layers) {
      if (!keys(layer.dims ?? {}).length) continue

      const layerCalcs = layer.calcs
      const lenUnit = layerCalcs.length_unit

      // Process each calculation type in the layer
      for (const [calcKey, calc] of entries(layer.dims)) {
        const links = calc.links
        if (!links?.length) continue

        const calcValue = layerCalcs[calcKey]

        // Process each dimension link
        for (const link of links) {
          const from = calcValue ?? link.value ?? 0
          let fromUnit = lenUnit
          let convert = true

          if (calcKey === 'area_total') {
            fromUnit = layerCalcs.area_unit
          } else if (calcKey === 'none') {
            convert = false
            fromUnit = link.unit
          }

          const assemblyRef = link.assembly?.refId || 'root'
          const dimAbbr = link.dim

          // Initialize change structure if needed
          const assemblyChanges = (dimChanges[assemblyRef] ??= { oDimensions: {} })
          const origDim =
            norm.value[assemblyRef]?.oDimensions?.[dimAbbr] || assemblyChanges.oDimensions[dimAbbr]
          const toUnit = origDim?.measure || fromUnit

          const existingDim = (assemblyChanges.oDimensions[dimAbbr] ??= {
            value: 0,
            measure: toUnit,
            equation: null
          })

          // Convert measurement
          let converted = convert ? c.convertMeasure(from, fromUnit, toUnit) || from || 0 : from

          // Round to nearest 1/36 of an inch if in 'ft'
          if (toUnit === 'ft') {
            const roundTo = 1 / 432
            converted = Math.round(converted / roundTo) * roundTo
          }

          // Update dimension value
          existingDim.value += converted
        }
      }
    }

    // Return changes for processing by caller
    const fullChanges = {}

    // Assign each dimension.abbr change into each one in norm
    for (const [assemblyRef, changesForAssembly] of entries(dimChanges)) {
      const assembly = norm.value[assemblyRef]
      const fullAssemblyChanges = (fullChanges[assemblyRef] ??= {
        oDimensions: assembly.oDimensions ?? {}
      })

      for (const [dimAbbr, change] of entries(changesForAssembly.oDimensions)) {
        const isFtMeasurement = change.measure === 'ft'
        fullAssemblyChanges.oDimensions[dimAbbr] = {
          ...fullAssemblyChanges.oDimensions[dimAbbr],
          ...change,
          value: isFtMeasurement ? roundFtTo16thInch(change.value) : change.value,
          fromTakeoff: true
        }
      }
    }

    // Handle ignored dimensions by setting fromTakeoff: false
    if (takeoff.ignoreLinks) {
      for (const [linkKey, ignored] of entries(takeoff.ignoreLinks)) {
        if (!ignored.link?.assembly?.refId || !ignored.link?.dim) continue

        const assemblyRef = ignored.link.assembly.refId || 'root'
        const dimAbbr = ignored.link.dim

        // Initialize if needed
        fullChanges[assemblyRef] ??= {
          oDimensions: norm.value[assemblyRef]?.oDimensions ?? {}
        }

        // Keep existing values but mark as not from takeoff
        if (fullChanges[assemblyRef].oDimensions[dimAbbr]) {
          fullChanges[assemblyRef].oDimensions[dimAbbr] = {
            ...fullChanges[assemblyRef].oDimensions[dimAbbr],
            fromTakeoff: false
          }
        }
      }
    }

    return fullChanges
  }

  // Auto detect commit
  const handleChange = () => c.throttle(() => commit(), { delay: 5000 })
  onMounted(() => {
    $this.$on('takeoff-changed', handleChange)
  })
  onBeforeUnmount(async () => {
    $this.$off('takeoff-changed', handleChange)
    await commit()
  })

  const commit = async () => {
    const changes = getDimChangeSet()

    return $store.dispatch(`${store}/field`, {
      changes,
      explicit: true
    })
  }

  const setDimUnit = (refId, abbr, toUnit) => {
    const dims = norm.value[refId]?.oDimensions

    const from = dims[abbr].value
    const fromUnit = dims[abbr].measure
    const toVal = c.convertMeasure(from, fromUnit, toUnit)
    dims[abbr].measure = toUnit
    dims[abbr].value = toVal

    const changes = {
      [refId]: {
        oDimensions: dims
      }
    }

    return $store.dispatch(`${store}/field`, {
      changes,
      explicit: true
    })
  }

  const forceDim = (layerId, calcKey, linkIndex, rvalue) => {
    if (!rvalue) return

    const [svalue, unit] = rvalue.split(' ')
    const value = c.toNum(svalue)
    const layerIndex = takeoff.layers.findIndex((l) => l.id === layerId)

    if (layerIndex < 0) return

    const layer = takeoff.layers[layerIndex]
    layer.dims[calcKey].links[linkIndex] = {
      ...layer.dims[calcKey].links[linkIndex],
      value,
      unit
    }
    layer.dims[calcKey].links[linkIndex] = {
      ...layer.dims[calcKey].links[linkIndex],
      value,
      unit
    }

    updateLayer(layerId, layer)
  }

  const sortAssembliesNatural = computed(() =>
    _.intersection(
      Object.keys(dimensions.value ?? {}),
      NormalizeUtilities.sortNatural(norm.value ?? {})
    )
  )

  return {
    setDimUnit,
    removeIgnoreLink,
    possibleDimensions: poss,
    norm,
    sortAssembliesNatural,
    dimensions,
    getAssemblyRef,
    forceDim
  }
}
