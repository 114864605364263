// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'payment_segment',

  possibleStatuses: ['a', 'c', 'i'],

  skipAudit: true,

  fields: {
    payment_segment_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    payment_segment_percentage: {
      type: 'int',
      save: true,
      format: 'number',
      filter: true
    },
    payment_segment_request_sent: {
      type: 'bool',
      filter: true,
      format: false,
      default: false
    },
    payment_segment_request_time_sent: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    payment_segment_time_due: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    payment_segment_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
      default: 'a'
    },
    payment_segment_name: {
      type: 'string',
      filter: true,
      save: true,
      mapTo: false
    },
    payment_segment_desc: {
      type: 'string',
      save: true.valueOf,
      filter: true,
      mapTo: false
    },
    payment_segment_time_created: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    payment_segment_time_last_modified: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    quote_id: {
      type: 'int',
      filter: true,
      mapTo: 'quote'
    },
    company_id: {
      type: 'int',
      filter: true,
      mapTo: 'company'
    },
    payment_segment_order: {
      type: 'int',
      filter: true,
      format: false
    }
  }
}
