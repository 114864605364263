import { computed } from 'vue'
import { v4, v4 as uuidv4 } from 'uuid'
import { useStore } from 'vuex'

export function useImageLayer({ layers, viewBox, addLayer, takeoff, viewport }) {
  const $store = useStore()
  const getImageDimensions = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve({ width: img.width, height: img.height })
      img.onerror = reject
      img.src = url
    })
  }

  // In useImageLayer.js, modify the loadFiles function
  const determineImagePlacement = (location = null) => {
    // Get content bounds if location is specified
    let bounds = viewport.getContentBounds(takeoff)

    // Calculate initial position based on location
    let x = 40
    let y = 40
    const padding = -40 // padding from bounds

    if (!bounds) return { x, y }

    if (location === 'right' || (!location && bounds.maxY < 2000)) {
      x = bounds.maxX + padding
    } else {
      y = bounds.maxY + padding
    }

    return { x, y }
  }

  // Layer Management Methods
  const addImageLayer = async (file, imageLocation = null) => {
    try {
      const url = c.link(`file/view/${file.file_id}`)
      const img = new Image()

      await new Promise((resolve, reject) => {
        img.onload = resolve
        img.onerror = reject
        img.src = url
      })

      // Get actual image dimensions
      const imageWidth = img.naturalWidth
      const imageHeight = img.naturalHeight

      // Calculate position to center the image
      const { x, y } = determineImagePlacement(imageLocation)

      const layer = {
        id: v4(),
        type: 'image',
        name: file.file_name || 'Untitled Image',
        url,
        file: {
          file_id: file.file_id,
          file_size: file.file_size,
          file_type: file.file_type,
          file_name: file.file_name,
          width: imageWidth, // will never change
          height: imageHeight,
          url
        },
        position: {
          x,
          y,
          width: imageWidth, // will change
          height: imageHeight
        },
        zIndex: layers.value.length
      }

      addLayer('image', layer)

      if (file.file_id) {
        // analyzeLayer(layer)
      }
    } catch (error) {
      console.error('Error adding image layer:', error)
      error.value = 'Failed to load image. Please try again.'
    }
  }

  const addImageLayerByFileId = async (fileId, location = null) => {
    const { object } = await $store.dispatch('File/fetch', { id: fileId })
    return addImageLayer(object, location)
  }

  // File handling methods
  const loadFiles = async (ids = [], location = null) => {
    let fileIds = ids
    if (!ids.length) {
      const { selected } = await $store.dispatch('modal/selector', {
        type: 'file',
        multiple: true
      })
      selected.forEach((file) => addImageLayer(file, location))
      return
    }

    for (const fileId of fileIds) {
      await addImageLayerByFileId(fileId, location)
    }
  }

  const updateImageAnalysis = (layerId, analysis) => {
    const layerIndex = layers.value.findIndex((l) => l.id === layerId)
    if (layerIndex === -1) return

    const layer = layers.value[layerIndex]
    layer.metadata.analysis = analysis

    layers.value = [
      ...layers.value.slice(0, layerIndex),
      { ...layer },
      ...layers.value.slice(layerIndex + 1)
    ]
  }

  const imageLayers = computed(() => layers.value.filter((layer) => layer.type === 'image'))

  return {
    addImageLayer,
    updateImageAnalysis,
    imageLayers,
    loadFiles,
    addImageLayerByFileId
  }
}
