import { onMounted, onUnmounted, nextTick } from 'vue'

export function useResizeEvents(viewportRef, containerSize, viewBox) {
  // Function to update container size
  const updateContainerSize = () => {
    const container = viewportRef.value
    if (container) {
      const rect = container.getBoundingClientRect()
      containerSize.value = {
        width: rect.width || 1000,
        height: rect.height || 1000
      }
      // Update viewBox to match container size
      viewBox.value = {
        width: containerSize.value.width,
        height: containerSize.value.height
      }
    }
  }

  onMounted(() => {
    window.addEventListener('resize', updateContainerSize)

    // Initial size calculation - wait for next tick to ensure DOM is updated
    nextTick(() => {
      updateContainerSize()
    })
  })

  onUnmounted(() => {
    window.removeEventListener('resize', updateContainerSize)
  })

  return {
    updateContainerSize
  }
}
