import { useFileService } from '@/components/composables/UseFileService'

import NormalizeUtilities from '../../../../imports/api/NormalizeUtilities.js'
import { usePresentationManager } from '@/components/quote/presentation/usePresentationManager.js'

import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import jsPDF from 'jspdf'
import CurrencyFilter from '@/components/composables/CurrencyFilter.js'
import QrCodeWithLogo from 'qrcode-with-logos'
import BolsterIcon from '@/assets/logos/Bolster_Icon_Fill_Yellow.png'
import '@/assets/fonts/jsPDF/Whisper-Regular-normal'
import { font } from '@/assets/fonts/jsPDF/Whisper-Regular-normal.js'

const translate = (v) => v
// const l = translate

export function useQuotePdf(props) {
  const $store = props.$store ?? useStore()
  const { currencySymbol } = CurrencyFilter()
  const { getPreSignedPost } = useFileService()

  const {
    showItem,
    showPrice,
    showQty,
    showAttrs,
    showCost,
    creds,
    termsAndConditions,
    showQuantities,
    showCostItemPrices,
    showItemizedPrices,
    showCosts,
    showSubtotal,
    showItemSpecificTax
  } = usePresentationManager({
    refId: props.refId,
    store: 'Quote',
    autoSave: false,
    $store: $store
  })

  const pdfUrl = ref('')

  const norm = computed(() => props.norm || $store.state.Quote.normalized)
  const quote = computed(() => norm.value[props.refId])

  const refIds = computed(() =>
    NormalizeUtilities.sortNatural(norm.value).filter((ref) => norm.value[ref].type !== 'quote')
  )
  const lineItems = computed(() =>
    refIds.value.map((ref) => {
      const obj = norm.value[ref]
      const depth = obj.depth
      const name = obj.cost_type_name || obj.assembly_name
      const desc = obj.cost_type_desc || obj.quote_notes
      const attrs = obj.aoProperties || []
      const price = obj.cost_item_price_net || obj.quote_subtotal_net
      const cost = obj.cost_item_total_cost_net || obj.quote_total_cost_net
      const type = obj.type
      const qty = obj.cost_item_qty_net || obj.quote_qty_net
      const units = obj.unit_of_measure_abbr || 'units'
      const inputs =
        obj.oInputRequired?.inputs?.reduce((acc, input) => {
          let accr = ''
          if (input.type === 'text') {
            accr = `${acc ? `${acc}\n` : ''}${input.message}: ${input.input.text}`
          }
          if (input.type === 'color') {
            accr = `${acc ? `${acc}\n` : ''} Color selection - ${input.input.data.vendor || 'Benjamin Moore'} - ${input.input.data.name || ''}`
          }
          return accr
        }, '') ?? ''
      return { depth, name, desc, attrs, price, cost, qty, units, inputs, type, refId: obj.refId }
    })
  )

  const topMargin = 15
  const leftMargin = 10
  const indentSize = 3
  const maxWidthExpanded = 200
  const rightCols = [200, 175, 150]
  const defaultLineHeight = 5 // Default vertical line spacing
  const lineSpacing = 3 // Additional space between items

  const drawText = (doc, text = '', x, y, options = {}) => {
    const ZERO_WIDTH_REGEX = /[\u200B\u200C\u200D\u180E\uFEFF\u2060\u2063\u2028\u2029]/g;

    let t = text;
    if (typeof text === 'string') {
      t = t.replace(ZERO_WIDTH_REGEX, '');
    } else if (Array.isArray(text)) {
      t = text.map((tt) => tt.replace(ZERO_WIDTH_REGEX, ''));
    }

    // if (typeof t === 'string') t = utf8.encode(t);
    doc.text(t, x, y, options);
  };

  const drawLongText = (doc, text, x, y, options = {}) => {
    const { processMarkdown: doMarkdown = false } = options
    const margin = 20
    const maxWidth = doc.internal.pageSize.width - margin * 2
    const lineSpacing = 5
    const pageHeight = doc.internal.pageSize.height
    const bottomMargin = 15 // Space at the bottom before triggering a new page

    let currentY = y
    const paragraphs = text.split(/\n|\r|\r\n/)

    paragraphs.forEach((paragraph) => {
      // Process Markdown and square bracket bolding before wrapping
      const formattedParagraph = doMarkdown ? processMarkdown(paragraph) : paragraph

      const wrappedLines = doc.splitTextToSize(formattedParagraph, maxWidth) // Automatically wrap if too wide

      wrappedLines.forEach((line) => {
        // Check if there's space for the next line; if not, create a new page
        if (currentY + lineSpacing > pageHeight - bottomMargin) {
          doc.addPage()
          currentY = margin // Reset Y position for the new page
        }

        drawFormattedText(doc, line, x, currentY, options)
        currentY += lineSpacing // Move down after each line
      })

      // currentY += lineSpacing // Extra space between paragraphs
    })

    return currentY
  }

  // Helper function to process Markdown and bold square brackets
  const processMarkdown = (text) => {
    return text
      .replace(/\*\*([^*]+)\*\*/g, '<b>$1</b>') // Bold (**text**)
      .replace(/\*([^*]+)\*/g, '<b>$1</b>') // Bold (*text*)
      .replace(/(?:[^_])__([^*]+)__(?:[^_])/g, '<i>$1</i>') // Bold (__text__)
      .replace(/_([^_]+)_/g, '<i>$1</i>') // Italic (_text_)
      .replace(/\[([^\[\]]+)\]/g, '<b>$1</b>') // Bold square brackets [text]
  }

  // Function to draw formatted text (basic HTML-like tags)
  const drawFormattedText = (doc, text, x, y, options = {}) => {
    const parts = text.split(/(<b>|<\/b>|<i>|<\/i>)/)
    let isBold = false
    let isItalic = false
    let currentX = x

    parts.forEach((part) => {
      if (part === '<b>') {
        isBold = true
        doc.setFont(undefined, isItalic ? 'bolditalic' : 'italic')
      } else if (part === '</b>') {
        isBold = false
        doc.setFont(undefined, isItalic ? 'italic' : 'normal')
      } else if (part === '<i>') {
        isItalic = true
        doc.setFont(undefined, isBold ? 'bolditalic' : 'italic')
      } else if (part === '</i>') {
        isItalic = false
        doc.setFont(undefined, isBold ? 'bold' : 'normal')
      } else {
        drawText(doc, part, currentX, y, options)
        currentX += doc.getTextWidth(part) // Move X position for inline formatting
      }
    })

    // Reset font after text drawing
    doc.setFont(undefined, 'normal')
  }

  const drawLine = (doc, x, y, width, lineWidth = 0.5) => {
    // Draw a horizontal line at position (x, y) with the specified width
    doc.setLineWidth(lineWidth) // Set the line width (you can adjust as needed)
    doc.line(x, y, x + width, y) // Draw the line

    // Add some space below the line for the next item
    const newY = y + 5 // Space after the line (adjust as needed)

    return newY // Return the new Y position after the line
  }

  const generateQRCodeAndInject = async (doc, link, label, x, y, size = 50) => {
    // Generate the QR code as a base64 image for the provided link
    const qrCodeData = new QrCodeWithLogo({
      content: link,
      width: 500,
      logo: {
        src: BolsterIcon // Optional logo to embed in the center of the QR code
      }
    })

    const canvas = await qrCodeData.getCanvas()

    // Inject the generated QR code image into the PDF at position (x, y)
    doc.addImage(canvas.toDataURL(), 'JPEG', x, y - 2, size, size) // Adjust size as needed

    // Set the position for the label (just to the right of the QR code)
    const labelX = x + size + 2 // Add 10 units of spacing from the QR code
    const labelY = y + 1 // Vertically centered to the QR code

    // Set font size and style for the label
    doc.setFontSize(8) // Adjust font size as needed

    // Add label text with text wrapping
    const maxWidth = 50 // Max width for text wrapping
    const wrappedText = doc.splitTextToSize(label, maxWidth) // Wrap text

    drawText(doc, wrappedText, labelX, labelY) // Add wrapped text to the PDF

    // Return the new Y coordinate after the QR code and label are added
    return y + size + 1 // Space after the QR code and label
  }

  const generateHeader = async (doc, headerData, yStart) => {
    let y = yStart // Start at the given Y position

    const {
      client: { user_fname: client_fname, user_lname: client_lname },
      company: {
        company_name_short = '', // may not exist
        company_address,
        company_city,
        company_prov,
        company_postal,
        company_unit,
        company_name,
        company_phone,
        company_email
      },
      owner: {
        // the estimator presenting this quote
        user_fname: owner_fname,
        user_lname: owner_lname
      },
      quote: {
        quote_name,
        quote_time_created,
        quote_time_expired,
        quote_credentials,
        quote_id, // project unique id
        change_order_id, // revision unique ID
        quote_client_ref,
        change_order_name, // revision or change order name
        // change_order_time_created,
        quote_address,
        quote_city,
        quote_prov, // province or state,
        quote_postal, // zip or postal
        quote_unit // suite or unit number
      }
    } = headerData

    let maxY = 0
    const rightMargin = doc.internal.pageSize.width - 10 // Right margin, 10 units from the page right edge

    // ** Company Info (Left Aligned) **
    doc.setFontSize(14)
    doc.setFont('helvetica', 'bold')
    if (company_name) drawText(doc, company_name, leftMargin, y) // Company full name
    y += 7

    if (company_name_short && company_name !== company_name_short) {
      doc.setFontSize(12)
      doc.setFont('helvetica', 'normal')
      drawText(doc, company_name_short, leftMargin, y) // Short company name (optional)
      y += 5
    }
    const companyAddress =
      `${(company_unit && `${company_unit},`) || ''} ${(company_address && `${company_address},`) || ''} ${(company_city && `${company_city},`) || ''} ${(company_prov && `${company_prov},`) || ''} ${(company_postal && `${company_postal}`) || ''}`.trim()

    doc.setFontSize(10)
    doc.setFont('helvetica', 'normal')
    if (companyAddress) {
      drawText(doc, companyAddress, leftMargin, y)
      y += 5
    } // Project address content

    if (company_phone) {
      doc.setFontSize(10)
      doc.setFont('helvetica', 'normal')
      drawText(doc, `Phone: ${c.format(company_phone, 'phone')}`, leftMargin, y) // Company phone
      y += 5
    }

    if (company_email) {
      drawText(doc, `Email: ${company_email}`, leftMargin, y) // Company email
      y += 7
    }

    if (creds && Array.isArray(creds.value)) {
      const validCreds = creds.value.filter(pair => (pair?.[0] ?? "").trim() && (pair?.[1] ?? "").trim())

      if (validCreds.length > 0) {
        drawText(doc, `Credentials:`, leftMargin, y)
        y += 5

        validCreds.forEach(pair => {
          const key = pair[0].trim()
          const value = pair[1].trim()
          drawText(doc, `${key}: ${value}`, leftMargin, y)
          y += 5
        })
      }
    }

    // ** Owner/Estimator Info (Left Aligned) **
    if (owner_fname && owner_lname) {
      doc.setFontSize(10)
      doc.setFont('helvetica', 'normal')
      drawText(doc, `Estimator: ${owner_fname} ${owner_lname}`, leftMargin, y)
      y += 5
    }

    y = drawLine(doc, leftMargin, y, 100)

    y = await generateQRCodeAndInject(
      doc,
      'https://app.bolsterbuilt.com',
      'Go to your client portal to see your contracts and documents, track progress on the job, approve changes, make payments and chat to us.',
      leftMargin,
      y,
      20
    )
    y = drawLine(doc, leftMargin, y, 100)
    maxY = y
    // if (client_phone) {
    //   doc.setFontSize(10);
    //   doc.setFont("helvetica", "normal");
    //   drawText(doc, `Phone: ${client_phone}`, rightMargin - doc.getTextWidth(`Phone: ${client_phone}`), y); // Client phone
    //   y += 5;
    // }
    //
    // if (client_email) {
    //   drawText(doc, `Email: ${client_email}`, rightMargin - doc.getTextWidth(`Email: ${client_email}`), y); // Client email
    //   y += 10;
    // }

    // ** Project Address (Right Aligned) **
    y = yStart
    if (quote_address || quote_city || quote_prov || quote_postal) {
      doc.setFontSize(12)
      doc.setFont('helvetica', 'bold')
      drawText(doc, `Project`, rightMargin - doc.getTextWidth(`Project`), y) // Project address label
      y += 7

      doc.setFontSize(8)
      doc.setFont('helvetica', 'normal')

      if (quote_client_ref) {
        drawText(
          doc,
          `Project Ref/RFQ#: ${quote_client_ref}`,
          rightMargin - doc.getTextWidth(`Project Ref/RFQ#: ${quote_client_ref}`),
          y
        )
        y += 4
      }

      doc.setFontSize(10)
      drawText(
          doc,
              `Project name: ${quote_name} `,
          rightMargin - doc.getTextWidth(`Project Name: ${quote_name}`),
          y
      )
      y += 8

      doc.setFontSize(8)
      drawText(
          doc,
          `Time created: ${c.format(quote_time_created, 'date')} `,
          rightMargin - doc.getTextWidth(`Time Created: ${c.format(quote_time_created, 'date')}`),
          y
      )
      y += 4

      if(quote_time_expired) {
        drawText(
            doc,
            `Quote expires: ${c.format(quote_time_expired, 'date')} `,
            rightMargin - doc.getTextWidth(`Time Created: ${c.format(quote_time_expired, 'date')}`),
            y
        )
        y += 4
      }

      drawText(
        doc,
        `Project ID: ${quote_id}`,
        rightMargin - doc.getTextWidth(`Project ID: ${quote_id}`),
        y
      )
      y += 4
      drawText(
        doc,
        `Revision: ${change_order_name} - ${change_order_id}`,
        rightMargin - doc.getTextWidth(`Revision: ${change_order_name} - ${change_order_id}`),
        y
      )
      y += 4

      const projectAddress =
        `${(quote_unit && `${quote_unit},`) || ''} ${(quote_address && `${quote_address},`) || ''} ${(quote_city && `${quote_city},`) || ''} ${(quote_prov && `${quote_prov}`) || ''} ${(quote_postal && `${quote_postal}`) || ''}`.trim()

      doc.setFont('helvetica', 'normal')
      if (projectAddress) {
        drawText(doc, projectAddress, rightMargin - doc.getTextWidth(projectAddress), y)
      } // Project address content

      y += 10
    }

    // ** Client Info (Right Aligned) **
    doc.setFontSize(12)
    if (client_fname && client_lname) {
      // Aligning client info to the right
      doc.setFont('helvetica', 'bold')
      drawText(doc, `Client`, rightMargin - doc.getTextWidth(`Client`), y) // Client's full name
      y += 5
      doc.setFont('helvetica', 'normal')
      doc.setFontSize(10)
      drawText(
        doc,
        `${client_fname} ${client_lname}`,
        rightMargin - doc.getTextWidth(`${client_fname} ${client_lname}`),
        y
      )
      y += 7
    }

    return y > maxY ? y : maxY // Return updated Y position for line items
  }

  const generateLineItem = (
    doc,
    item,
    x,
    y,
    { showItemPrice = true, showItemCost = true, showItemQty = true, showItemAttrs = true }
  ) => {
    const textX = x
    // Choose the appropriate max width based on the showCost flag
    const maxWidth =
      maxWidthExpanded - (showItemCost ? 20 : 0) - (showItemPrice ? 20 : 0) - (showItemQty ? 20 : 0)

    // Split name and description into multiple lines if needed
    const indent = x
    const nameLines = doc.splitTextToSize(item.name ?? '', maxWidth - indent)

    // Ensure `y` is valid before drawing text
    if (isNaN(y) || y < 0) y = 20

    let left = y
    if (item.type === 'assembly') {
      doc.setFontSize(12)
      doc.setTextColor(100, 100, 100) // Dark grey color
      doc.setFont('helvetica', 'bold')
      left += 2
    } else {
      doc.setFontSize(10)
      doc.setTextColor(0, 0, 0) // Black color
      doc.setFont('helvetica', 'normal')
    }

    // Print name
    drawText(doc, nameLines, textX, left)
    doc.setFontSize(10)
    left += nameLines.length * defaultLineHeight

    // Format the price and cost to always have two decimal places
    let formattedPrice
    if (item.price) formattedPrice = currencySymbol(item.price)
    else if (item.qty) formattedPrice = 'Included'
    else formattedPrice = 'N/A'

    // Format the price and cost to always have two decimal places
    let formattedQty
    if (item.qty) formattedQty = `${_.format(item.qty, 'number')} ${item.units}`
    else formattedQty = 'N/A'

    const formattedCost = showItemCost ? currencySymbol(item.cost) : 'N/A'

    // Calculate right-aligned text positions
    const qtyTextWidth = formattedCost ? doc.getTextWidth(formattedQty) : 0
    const costTextWidth = formattedCost ? doc.getTextWidth(formattedCost) : 0
    const priceTextWidth = doc.getTextWidth(formattedPrice)
    const colPos = [...rightCols]

    // Right-align price and cost by subtracting their width from the margin (page width)
    const rightAlignedPriceX = showItemPrice ? colPos.shift() - priceTextWidth : null
    const rightAlignedCostX = showItemCost ? colPos.shift() - costTextWidth : null
    const rightAlignedQtyX = showItemQty ? colPos.shift() - qtyTextWidth : null

    // Print price (right-aligned)
    if (showItemQty && formattedQty) {
      doc.setFont('helvetica', 'italic')
      drawText(doc, formattedQty, rightAlignedQtyX, y)
    }

    // Print cost (right-aligned) if showCost is true
    if (showItemCost && formattedCost) {
      doc.setFont('helvetica', 'italic')
      drawText(doc, formattedCost, rightAlignedCostX, y)
    }

    // Print price (right-aligned)
    if (showItemPrice) {
      doc.setFont('helvetica', 'bold')
      drawText(doc, formattedPrice, rightAlignedPriceX, y)
    }

    // Print description
    if (item.desc && item.desc.trim()) {
      doc.setTextColor(140, 140, 140) // Dark grey color
      doc.setFont('helvetica', 'normal')
      left = drawLongText(doc, item.desc.trim(), textX, left)
      // drawText(doc, descLines, textX, left)
      doc.setTextColor(0, 0, 0) // Black color
    }

    // Print Attrs
    if (item?.attrs?.length && showItemAttrs) {
      item.attrs.forEach((attr) => {
        doc.setTextColor(140, 140, 140) // Dark grey color
        doc.setFont('helvetica', 'normal')
        left = drawLongText(doc, ` • ${attr[0]}: ${attr[1]}`, textX, left)
        doc.setTextColor(0, 0, 0) // Black color
      })
    }

    if (item.inputs) {
      doc.setFontSize(10)
      const inputLines = doc.splitTextToSize(item.inputs ?? '', maxWidth - indent)

      // Print description
      doc.setTextColor(100, 100, 100) // Dark grey color
      doc.setFont('helvetica', 'normal')
      drawText(doc, inputLines, textX, left)
      doc.setTextColor(0, 0, 0) // Black color
      left += inputLines.length * defaultLineHeight
    }

    return left
  }

  const generateLineItems = (doc, lineItems, startY) => {
    let y = startY

    doc.setFontSize(14)
    doc.setFont('helvetica', 'bold')
    drawText(doc, 'Project scope', leftMargin, y)
    doc.setFontSize(10)
    y += defaultLineHeight + lineSpacing // Move down after title

    const other = {
      cost: 0,
      price: 0,
      qty: 1
    }

    // draw headings
    const colPos = [...rightCols]

    // Right-align price and cost by subtracting their width from the margin (page width)
    const rightAlignedPriceX = showItemizedPrices.value ? colPos.shift() : null
    const rightAlignedCostX = showCosts.value ? colPos.shift() : null
    const rightAlignedQtyX = showQuantities.value ? colPos.shift() : null

    // Print price (right-aligned)
    doc.setFontSize(8)
    if (rightAlignedPriceX) {
      doc.setFont('helvetica', 'italic')
      drawText(doc, 'Price', rightAlignedPriceX, y - 6, { align: 'right' })
    }
    if (rightAlignedCostX) {
      doc.setFont('helvetica', 'italic')
      drawText(doc, 'Cost', rightAlignedCostX, y - 6, { align: 'right' })
    }
    if (rightAlignedQtyX) {
      doc.setFont('helvetica', 'italic')
      drawText(doc, 'Qty', rightAlignedQtyX, y - 6, { align: 'right' })
    }
    doc.setFontSize(10)

    let depth = 0
    lineItems.forEach((item) => {
      const itemIsVisible = showItem(item.refId)

      if (!itemIsVisible) {
        if (item.type !== 'assembly' && item.type !== 'quote') {
          other.cost += item.cost
          other.price += item.price
        }
        return
      }

      depth = item.depth ?? depth
      const indent = (depth - 1) * indentSize + (item.type !== 'assembly' ? 2 : 0)
      y = generateLineItem(doc, item, leftMargin + indent, y, {
        showItemCost: showCost(item.refId),
        showItemPrice: showPrice(item.refId),
        showItemQty: showQty(item.refId),
        showItemAttrs: showAttrs(item.refId)
      })

      // Move Y position down for next line item
      y += lineSpacing / 2

      // Prevent text from going off the page (add a new page if needed)
      if (y > 250) {
        // 270px is near bottom of the page
        doc.addPage()
        y = topMargin
      }
    })

    if (other.cost || other.price) {
      y = generateLineItem(
        doc,
        {
          ...other,
          name: 'Other misc',
          type: 'cost_item',
          refId: 'a',
          units: 'each'
        },
        leftMargin,
        y,
        {
          showItemCost: showCosts.value,
          showItemPrice: showItemizedPrices.value && showCostItemPrices.value,
          showItemQty: showQuantities.value
        }
      )
      // Prevent text from going off the page (add a new page if needed)
      if (y > 270) {
        // 270px is near bottom of the page
        doc.addPage()
        y = topMargin
      }
    }

    if (quote.value.quote_notes) {
      y += lineSpacing
      y = drawLongText(doc, quote.value.quote_notes, leftMargin, y, { processMarkdown: true })
    }

    return y
  }

  const drawTotals = (doc, { subTotal = 0, taxes = {}, discount = 0, grandTotal = 0 }, startY) => {
    const xLabel = 100 // X coordinate for labels
    const xValue = 200 // X coordinate for values
    const lineSpacing = 6 // Space between lines

    let y = startY
    // Prevent text from going off the page (add a new page if needed)
    if (y > 250) {
      // 270px is near bottom of the page
      doc.addPage()
      y = topMargin
    }

    doc.setFontSize(10)

    if (showSubtotal.value) {
      doc.setFont('helvetica', 'normal')
      // Draw subtotal
      drawText(doc, 'Subtotal:', xLabel, y)
      drawText(doc, currencySymbol(subTotal), xValue, y, { align: 'right' })
      y += lineSpacing

      // Draw taxes
      if (showItemSpecificTax.value) {
        Object.values(taxes).forEach(({ name, sum }) => {
          if (c.eq(sum, 0)) return
          drawText(doc, `${name}:`, xLabel, y)
          drawText(doc, currencySymbol(sum), xValue, y, { align: 'right' })
          y += lineSpacing
        })
      } else {
        const tax = Object.values(taxes).reduce((acc, { sum }) => {
          acc += sum
          return acc
        }, 0)

        if (!c.eq(tax, 0)) {
          drawText(doc, `Sales tax:`, xLabel, y)
          drawText(doc, currencySymbol(tax), xValue, y, { align: 'right' })
          y += lineSpacing
        }
      }

      // Draw discount if applicable
      if (discount > 0) {
        drawText(doc, 'Discount:', xLabel, y)
        drawText(doc, `- ${currencySymbol(discount)}`, xValue, y, { align: 'right' })
        y += lineSpacing
      }

      // Draw a separator line
      y = drawLine(doc, xLabel, y - 1, 100, 0.1)
    }

    // Draw grand total
    doc.setFont('helvetica', 'bold')
    drawText(doc, 'Grand total:', xLabel, y)
    drawText(doc, currencySymbol(grandTotal), xValue, y, { align: 'right' })

    return y + lineSpacing // Return updated Y position
  }
  const drawTermsAndConditions = (doc, tandc = '', startY) => {
    const margin = 20
    const lineSpacing = 6

    if (!`${tandc}`.trim()) return startY
    doc.addPage()
    startY = topMargin

    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12)
    drawText(doc, 'Terms and conditions', margin, startY)
    startY += lineSpacing

    doc.setFont('helvetica', 'normal')
    doc.setFontSize(10)

    // Split terms into lines where newlines already exist
    startY = drawLongText(doc, tandc, margin, startY, { processMarkdown: true })

    return startY
  }

  const users = {}
  const getUser = async (id) => {
    if (!(id in users)) {
      const { object: user } = await $store.dispatch('User/fetch', { id })
      users[id] = user
    }

    return users[id] ?? false
  }

  const drawApprovals = async (doc, changeOrderList, startY) => {
    const pageMarginX = leftMargin
    const pageMarginY = topMargin
    const pageWidth = doc.internal.pageSize.width
    const pageHeight = doc.internal.pageSize.height
    const lineHeight = 5
    const indent = pageMarginX
    const signatureSpaceNeeded = 20

    let currentY = startY

    // Description text
    const description = translate(
      `Having reviewed the Terms and Conditions and subsequently indicated your acceptance by entering your name in the designated signature box and selecting the ‘I accept the Terms and Conditions’ button at the bottom of the checkout page, you acknowledged and agreed to the terms of this Agreement and the Scope of Work outlined therein. This action constituted your legally binding acceptance of the Agreement, and you were thereby bound by its provisions.`
    )

    // Add a new page for Approvals
    doc.addPage()
    currentY = pageMarginY

    // Title
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(14)
    drawText(doc, translate('Approvals history'), pageMarginX, currentY)
    currentY += lineHeight * 2

    // Description
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(10)
    const wrappedDesc = doc.splitTextToSize(description, pageWidth - 2 * pageMarginX)
    drawText(doc, wrappedDesc, pageMarginX, currentY)
    currentY += wrappedDesc.length * lineHeight

    // Process change orders
    if (changeOrderList && changeOrderList.length) {
      for (let co of changeOrderList) {
        // Page break if needed
        if (currentY + signatureSpaceNeeded > pageHeight - pageMarginY) {
          doc.addPage()
          currentY = pageMarginY
        }

        // Change Order Title
        const orderText = `${co.change_order_name} • ${currencySymbol(co.quote_price_gross)}`

        const isCurrent = String(co.change_order_id) === String(changeOrderList[0].change_order_id)
        const versionText = isCurrent ? ` (${translate('Version displayed')})` : ''

        co = isCurrent ? quote.value : co
        doc.setFont('helvetica', 'bold')
        doc.setFontSize(12)
        currentY += lineHeight
        drawText(doc, `${orderText}${versionText}`, pageMarginX, currentY)

        // Approval Details
        if (co.change_order_client_has_approved && co.change_order_client_approved_by) {
          currentY += lineHeight
          doc.setFont('helvetica', 'normal')
          doc.setFontSize(10)

          const sigy = currentY
          // Signature (if exists)
          if (co.oClientMeta?.signature) {
            doc.setFontSize(8)
            drawText(doc, translate('Manually keyed digital signature (Client):'), indent, currentY)
            currentY += lineHeight * 1.5
            doc.setFontSize(24)
            doc.setFont('Whisper-Regular', 'normal')
            drawText(doc, co.oClientMeta.signature, indent, currentY)
            currentY += lineHeight

            doc.setFontSize(8)
            // Approval info
            const clientName = co.oClientMeta?.signature || 'customer'
            const approvalText = translate(
              `Approved at ${new Date(co.change_order_client_approved_time).toISOString()}; \rApproved by ${clientName} (user #${co.change_order_client_approved_by});\r${co.oClientMeta?.IP ? `IP: ${co.oClientMeta?.IP}` : ''}`
            )
            doc.setFont('helvetica', 'normal')
            drawText(doc, approvalText.split('\r'), indent, currentY)
            currentY += lineHeight * 2
          }

          // Signature (if exists)
          const companySigner = await getUser(co.change_order_company_approved_by)
          if (companySigner) {
            currentY = sigy
            const coX = 100
            doc.setFontSize(8)
            drawText(doc, translate('Digitally approved (Company):'), coX, currentY)
            // currentY += lineHeight * 1.5
            // doc.setFontSize(24)
            // doc.setFont('Whisper-Regular', 'normal')
            const coName =
              `${companySigner?.user_fname || ''} ${companySigner?.user_lname || ''}` ||
              'company representative'
            // drawText(doc, coName, coX, currentY)
            currentY += lineHeight

            doc.setFontSize(8)
            // Approval info
            const approvalText = translate(
              `Approved at ${new Date(co.change_order_company_approved_time).toISOString()}; \rApproved by ${coName} (user #${co.change_order_company_approved_by});`
            )
            doc.setFont('helvetica', 'normal')
            drawText(doc, approvalText.split('\r'), coX, currentY)
            currentY += lineHeight * 2
          }
        } else {
          currentY += lineHeight
          doc.setFont('helvetica', 'italic')
          drawText(
            doc,
            translate('This version was never approved by the client.'),
            indent,
            currentY
          )
        }

        if (currentY > pageHeight - pageMarginY) {
          doc.addPage()
          currentY = pageMarginY
        }
      }
    }

    return currentY
  }

  const loadFonts = () => {
    const callAddFont = function () {
      this.addFileToVFS('Whisper-Regular-normal.ttf', font)
      this.addFont('Whisper-Regular-normal.ttf', 'Whisper-Regular', 'normal')
    }
    return jsPDF.API.events.push(['addFonts', callAddFont])
  }

  let pdfDoc
  const drawPDF = async (lineItems, tandc = '', approvals = false) => {
    pdfDoc = new jsPDF()
    const doc = pdfDoc

    await loadFonts()

    let y = topMargin // Start position

    y = await generateHeader(
      doc,
      {
        client: quote.value.oClient,
        owner: quote.value.oOwner,
        company: $store.state.session.company,
        quote: quote.value
      },
      y
    )
    y += 4

    y = generateLineItems(doc, lineItems, y)
    y = drawLine(doc, leftMargin, y, 190)
    y += 2
    y = drawTotals(
      doc,
      {
        subTotal: quote.value.quote_price_net_undiscounted,
        discount: quote.value.quote_discount_net,
        grandTotal: quote.value.quote_price_gross,
        taxes: quote.value.oTaxSums
      },
      y
    )

    y = drawTermsAndConditions(doc, tandc, y)

    if (approvals && approvals.length) await drawApprovals(doc, approvals, y)

    const totalPages = doc.internal.getNumberOfPages() // Get total pages
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i) // Move to the current page
      doc.setFontSize(10)
      doc.setTextColor(0, 0, 0) // Black color for the page numbers
      drawText(doc, `${i}/${totalPages}`, leftMargin, 290) // Position it at the bottom right
    }

    // Create a Blob URL and update the iframe source
    const pdfBlob = doc.output('blob')
    pdfUrl.value = URL.createObjectURL(pdfBlob)
  }

  const fullTerms = computed(() => {
    const terms =
      (changeOrderIsApproved(quote.value) &&
        `${((quote.value.change_order_approved_terms || '').trim() && `${quote.value.change_order_approved_terms}`) || ''}`) ||
      ''.concat(
        `${($store.state.session.company.company_terms_and_conditions && `${$store.state.session.company.company_terms_and_conditions}\r\n`) || ''}`,
        `${(termsAndConditions.value && `${termsAndConditions.value}`) || ''}`,
        `${(quote.value.quote_terms && `${quote.value.quote_terms}`) || ''}`
      )

    return c
      .removeHtml(terms)
      .replace(/(?:\r\n|\r|\n){2,}/, '\r') // replace multi lines to just one line
      .replace(/\t/g, '  ') // replace tab characters with two spaces
  })

  const changeOrderIsApproved = (co) =>
    co.change_order_status === 'k' &&
    co.change_order_company_has_approved &&
    co.change_order_company_approved_by &&
    co.change_order_client_has_approved &&
    co.change_order_client_approved_by

  const getApprovals = async () => {
    const { set: changeOrders } = await $store.dispatch('Quote/getChangeOrders', {
      id: quote.value.quote_id
    })

    return changeOrders.filter((co) => changeOrderIsApproved(co))
  }

  const renderDoc = async () => {
    const approvals = quote.value.quote_status !== 'p' ? await getApprovals() : false
    await drawPDF(lineItems.value, fullTerms.value, approvals)
    return pdfDoc
  }

  const uploadToS3 = async () => {
    if (!pdfDoc) throw new Error('call renderDoc first')
    const fileName = `${quote.value.change_order_id}`
    const key = `${quote.value.client_id}/${quote.value.quote_id}/${fileName}.pdf`
    await new Promise((resolve) => setTimeout(resolve, 500))
    const file = await pdfDoc.output('blob')

    // Get the pre-signed URL
    const { url, fields } = await getPreSignedPost(key)

    // Create FormData and append the fields and file
    const formData = new FormData()
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append('file', file)

    // Upload the file to S3
    return await fetch(url, { method: 'POST', body: formData, mode: 'no-cors' })
  }

  const uploadToDrive = async (name = null, folder = props.parent_file_id) => {
    if (!pdfDoc) throw new Error('call renderDoc first')
    const fileName = name ?? `${quote.value.change_order_name}.pdf`
    const fileData = await pdfDoc.output('blob')

    const { object: file } = await $store.dispatch('File/upload', {
      data: fileData,
      file: {
        parent_file_id: folder,
        file_name: fileName,
        file_type: 'application/pdf'
      },
      alert: false
    })

    return file
  }

  const save = async () => {
    if (!pdfDoc) throw new Error('call renderDoc first')
    pdfDoc.save(`${quote.value.quote_name}-${quote.value.change_order_name}.pdf`)
  }

  const getBlob = () => {
    if (!pdfDoc) throw new Error('call renderDoc first')
    return pdfDoc.output('blob')
  }

  const getObjectUrl = () => {
    return URL.createObjectURL(getBlob())
  }

  return {
    renderDoc,
    save,
    uploadToS3,
    uploadToDrive,
    getBlob,
    getObjectUrl
  }
}
