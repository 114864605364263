import { checkSubscriptionStatus } from '@/router/guards/beforeEnter'

export default [
  {
    path: '/:scopeRoute',
    meta: {
      scopesAllowed: ['user', 'company'],
      menuContext: 'company'
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/components/pages/Dashboard.vue')
      },
      {
        path: 'pipeline',
        name: 'Pipeline',
        component: () => import('@/components/pages/Pipeline.vue'),
        meta: {
          fixedLayout: true
        }
      },
      {
        path: 'academy',
        name: 'Academy',
        component: () => import('@/components/pages/Academy.vue'),
        meta: {
          fixedLayout: true
        }
      },
      {
        path: 'marketing',
        name: 'Marketing dashboard',
        component: () => import('@/components/pages/Marketing.vue')
      },
      {
        path: 'invoice_presentation/:id',
        name: 'Scoped Invoice Presentation',
        component: () => import('@/components/pages/InvoicePresentation.vue'),
        meta: {
          confirmLeave: true
        }
      },
      {
        path: 'leadRequest/:id',
        name: 'LeadRequest',
        component: () => import('@/components/pages/LeadRequestQuote.vue')
      },

      {
        path: 'lead_sources',
        name: 'LeadSources',
        component: () => import('@/components/pages/LeadSources.vue'),
        meta: {
          confirmLeave: false
        }
      },
      {
        path: 'autocost',
        name: 'AutoCost',
        component: () => import('@/components/pages/AutoCost.vue')
      },
      {
        path: 'com',
        name: 'Cloud Office Managers',
        component: () => import('@/components/pages/CloudOfficeManager.vue')
      },
      {
        path: 'metrics',
        name: 'Metrics',
        alias: ['/metrics'],
        component: () => import('@/components/pages/Metrics.vue')
      },
      {
        path: 'schedule',
        name: 'Schedule',
        component: () => import('@/components/pages/Schedule.vue'),
        beforeEnter: [checkSubscriptionStatus]
      },
      {
        path: 'support',
        name: 'Support',
        component: () => import('@/components/pages/Support.vue')
      },
      {
        path: 'announcements',
        name: 'Announcements',
        component: () => import('@/components/pages/Announcements.vue')
      },
      {
        path: 'subscription',
        name: 'Billing',
        component: () => import('@/components/pages/Billing.vue')
      },
      {
        path: 'features',
        name: 'Subscription Management',
        component: () => import('@/components/pages/SubscriptionManagement.vue')
      },
      {
        path: 'template/new',
        name: 'Create Template',
        component: () => import('@/components/pages/TemplateNew.vue'),
        meta: {
          confirmLeave: true,
          fixedLayout: true
        }
      },
      {
        path: 'template/:id',
        name: 'Template',
        component: () => import('@/components/pages/Template.vue'),
        meta: {
          confirmLeave: true,
          fixedLayout: true
        }
      },
      {
        path: 'templates',
        meta: {
          subNav: () => import('@/components/pages/subnav/SubNavTemplates.vue')
        },
        children: [
          {
            path: 'templates/documents',
            name: 'Document Templates',
            component: () => import('@/components/pages/TemplatesDocuments.vue')
          },
          {
            path: 'templates/presentations',
            alias: ['templates'],
            name: 'Presentations',
            component: () => import('@/components/pages/TemplatesPresentations.vue')
          },
          {
            path: 'templates/emails',
            name: 'Email Templates',
            component: () => import('@/components/pages/TemplatesEmails.vue')
          },
          {
            path: 'templates/marketing',
            name: 'Marketing Email Templates',
            component: () => import('@/components/pages/TemplatesMarketing.vue')
          }
        ]
      },
      {
        path: 'client/:id',
        name: 'Client',
        component: () => import('@/components/pages/Client.vue'),
        meta: {
          confirmLeave: true,
          fixedLayout: true,
          menu: () => import('@/components/menus/ClientMenu.vue'),
          header: () => import('@/components/headers/ClientHeader.vue'),
          type: 'client'
        }
      },
      {
        path: 'company',
        name: 'Company',
        component: () => import('@/components/pages/CompanySettings.vue'),
        meta: {
          confirmLeave: true
        }
      },
      {
        path: 'payments',
        name: 'Payments',
        component: () => import('@/components/pages/Payments.vue'),
        meta: {
          confirmLeave: true
        }
      },
      {
        path: 'businessAccounts',
        name: 'Business Accounts',
        component: () => import('@/components/pages/BusinessAccounts.vue'),
        meta: {
          confirmLeave: true
        }
      },
      {
        path: 'bolsterCard',
        name: 'Bolster Card',
        component: () => import('@/components/pages/BolsterCard.vue'),
        meta: {
          confirmLeave: true
        }
      },
      {
        path: 'forms',
        name: 'Custom forms',
        component: () => import('@/components/pages/Forms.vue'),
        meta: {
          subNav: () => import('@/components/pages/subnav/SubNavForms.vue')
        }
      },
      {
        path: 'create/form',
        name: 'Create custom form',
        component: () => import('@/components/pages/CustomForm.vue'),
        props: {
          type: 'form'
        },
        meta: {
          selectBlank: true,
          confirmLeave: false,
          header: () => import('@/components/headers/CustomFormHeader.vue'),
          hideMenu: true,
          type: 'form'
        }
      },
      {
        path: 'form/:id',
        name: 'Custom form',
        component: () => import('@/components/pages/CustomForm.vue'),
        props: {
          type: 'form'
        },
        meta: {
          confirmLeave: true,
          header: () => import('@/components/headers/CustomFormHeader.vue'),
          hideMenu: true,
          type: 'form'
        }
      },
      {
        path: 'import',
        children: [
          {
            path: 'items',
            name: 'Import Items',
            component: () => import('@/components/pages/ImportItems.vue'),
            meta: {
              subNav: () => import('@/components/pages/subnav/SubNavEstimating.vue')
            }
          },
          {
            path: 'clients',
            name: 'Import Clients',
            component: () => import('@/components/pages/ImportClients.vue')
          },
          {
            path: 'vendors',
            name: 'Import Vendors',
            component: () => import('@/components/pages/ImportVendors.vue')
          }
        ]
      },
      {
        path: 'estimating_defaults',
        name: 'Estimating Defaults',
        alias: ['margin', 'margins', 'markup'],
        component: () => import('@/components/pages/EstimatingDefaults.vue'),
        meta: {
          subNav: () => import('@/components/pages/subnav/SubNavEstimating.vue'),
          confirmLeave: true
        }
      },
      {
        path: 'invoices',
        name: 'Invoices List',
        alias: ['receipts'],
        component: () => import('@/components/pages/Invoices.vue')
      },
      {
        path: 'invoice/:id',
        name: 'Invoice',
        component: () => import('@/components/pages/Invoice.vue'),
        meta: {
          confirmLeave: true,
          menu: () => import('@/components/menus/InvoiceMenu.vue'),
          header: () => import('@/components/headers/InvoiceHeader.vue'),
          type: 'invoice'
        }
      },
      {
        path: 'invoice/create',
        name: 'InvoiceNew',
        component: () => import('@/components/pages/InvoiceNew.vue'),
        meta: {
          selectBlank: true,
          confirmLeave: true,
          menu: () => import('@/components/menus/InvoiceMenu.vue'),
          header: () => import('@/components/headers/InvoiceHeader.vue'),
          type: 'invoice'
        }
      },
      {
        path: 'items',
        alias: ['cost_items', 'cost_types', 'assemblies', 'assemblys'],
        name: 'Items List',
        component: () => import('@/components/pages/Items.vue'),
        meta: {
          subNav: () => import('@/components/pages/subnav/SubNavEstimating.vue'),
          confirmLeave: true,
          fixedLayout: true
        }
      },
      {
        path: 'dimensions',
        name: 'Dimensions',
        component: () => import('@/components/pages/Dimensions.vue'),
        meta: {
          subNav: () => import('@/components/pages/subnav/SubNavEstimating.vue')
        }
      },
      {
        path: 'item_types',
        alias: ['stages'],
        name: 'Construction stages',
        component: () => import('@/components/pages/Stages.vue'),
        meta: {
          subNav: () => import('@/components/pages/subnav/SubNavEstimating.vue')
        }
      },
      {
        path: 'taxes',
        alias: ['taxs'],
        name: 'Taxes',
        component: () => import('@/components/pages/Taxes.vue'),
        meta: {
          subNav: () => import('@/components/pages/subnav/SubNavEstimating.vue')
        }
      },
      {
        path: 'labor',
        name: 'Labor',
        alias: ['labor_types'],
        component: () => import('@/components/pages/LaborTypes.vue'),
        meta: {
          subNav: () => import('@/components/pages/subnav/SubNavEstimating.vue')
        }
      },
      {
        path: 'leads',
        name: 'Leads List',
        alias: ['lead'],
        component: () => import('@/components/pages/Leads.vue'),
        meta: {
          fixedLayout: true
        }
      },
      {
        path: 'lead_rotations',
        alias: ['lead_rotation'],
        name: 'Lead Rotations',
        component: () => import('@/components/pages/LeadRotations.vue'),
        meta: {
          fixedLayout: true
        }
      },
      {
        path: 'lead_rotations/custom',
        alias: ['lead_rotation/custom'],
        name: 'Custom Lead Rotations',
        component: () => import('@/components/pages/LeadRotationsCustom.vue'),
        meta: {
          fixedLayout: true
        }
      },
      {
        path: 'create/lead_rotation',
        name: 'Create Lead Rotation',
        component: () => import('@/components/pages/LeadRotation.vue'),
        props: {
          type: 'lead_rotation'
        },
        meta: {
          selectBlank: true,
          header: () => import('@/components/headers/LeadRotationHeader.vue'),
          confirmLeave: false,
          hideMenu: true,
          type: 'lead_rotation'
        }
      },
      {
        path: 'lead_rotation/:id',
        name: 'Edit Lead Rotation',
        component: () => import('@/components/pages/LeadRotation.vue'),
        props: {
          type: 'lead_rotation'
        },
        meta: {
          confirmLeave: true,
          header: () => import('@/components/headers/LeadRotationHeader.vue'),
          hideMenu: true,
          type: 'lead_rotation'
        }
      },
      {
        path: 'clients',
        name: 'Clients List',
        alias: ['client'],
        component: () => import('@/components/pages/Clients.vue'),
        meta: {
          fixedLayout: true
        }
      },
      {
        path: 'presentation/:id',
        props: {
          type: 'quote'
        },
        name: 'Presentation',
        component: () => import('@/components/pages/QuotePresentation.vue'),
        meta: {
          confirmLeave: true
        }
      },
      {
        path: 'estimates',
        alias: ['quote', 'proposals', 'pendings', 'quotes', 'pending', 'proposal'],
        name: 'Estimates List',
        component: () => import('@/components/pages/Quotes.vue')
      },
      {
        path: 'projects',
        alias: ['job', 'jobs'],
        name: 'Projects List',
        component: () => import('@/components/pages/Projects.vue')
      },
      {
        path: 'files',
        name: 'Files',
        component: () => import('@/components/pages/Files.vue'),
        meta: {
          fixedLayout: true
        }
      },
      {
        path: 'assembly/:id',
        name: 'Assembly',
        component: () => import('@/components/pages/Assembly.vue'),
        props: {
          type: 'assembly'
        },
        meta: {
          confirmLeave: true,
          type: 'assembly'
        }
      },
      {
        path: 'reports/:id?',
        name: 'Reports',
        component: () => import('@/components/pages/Reports.vue')
      },
      {
        path: 'estimate/presentation/:id',
        props: {
          type: 'quote'
        },
        name: 'In-person presentation',
        component: () => import('@/components/pages/QuotePresentation.vue'),
        meta: {
          hideMenu: true,
          inperson: true,
          // reduces scope to maximum user and company and quote
          scopesAllowed: ['user', 'company'],
          fixedLayout: true,
          type: 'quote'
        }
      },
      {
        path: 'estimate/:id',
        alias: ['project/:id', 'quote/:id', 'proposal/:id'],
        name: 'Proposal',
        component: () => import('@/components/pages/Quote/Quote.vue'),
        props: {
          type: 'quote'
        },
        meta: {
          confirmLeave: true,
          menu: () => import('@/components/menus/QuoteMenu.vue'),
          header: () => import('@/components/headers/QuoteHeader.vue'),
          type: 'quote'
        }
      },
      {
        path: 'accounting',
        name: 'Accounting',
        component: () => import('@/components/pages/Accounting.vue')
      },
      {
        path: 'abcsupply',
        name: 'ABC Supply',
        component: () => import('@/components/pages/ABCSupply.vue')
      },
      {
        path: 'user/:id',
        name: 'User',
        component: () => import('@/components/pages/User.vue'),
        meta: {
          confirmLeave: true,
          fixedLayout: true,
          menu: () => import('@/components/menus/UserMenu.vue'),
          header: () => import('@/components/headers/UserHeader.vue'),
          type: 'user'
        }
      },
      {
        path: 'users',
        name: 'Users',
        component: () => import('@/components/pages/Users.vue'),
        meta: {
          subNav: () => import('@/components/pages/subnav/SubNavUsersRoles.vue')
        }
      },
      {
        path: 'groups',
        alias: ['locations'],
        name: 'Groups',
        component: () => import('@/components/pages/Groups.vue'),
        meta: {
          subNav: () => import('@/components/pages/subnav/SubNavUsersRoles.vue')
        }
      },
      {
        path: 'roles',
        name: 'Roles',
        component: () => import('@/components/pages/Roles.vue'),
        meta: {
          subNav: () => import('@/components/pages/subnav/SubNavUsersRoles.vue')
        }
      },
      {
        path: 'vendors',
        name: 'Vendors',
        component: () => import('@/components/pages/Vendors.vue')
      },
      {
        path: 'vendor/:id',
        name: 'Vendor',
        component: () => import('@/components/pages/Vendor.vue'),
        meta: {
          confirmLeave: true,
          fixedLayout: true,
          menu: () => import('@/components/menus/VendorMenu.vue'),
          header: () => import('@/components/headers/VendorHeader.vue'),
          type: 'vendor'
        }
      },
      {
        path: 'webhooks',
        name: 'Webhooks',
        component: () => import('@/components/pages/Webhooks.vue')
      },
      {
        path: 'CompanyCam',
        name: 'CompanyCam',
        component: () => import('@/components/pages/CompanyCam.vue')
      },
      {
        path: 'intake',
        name: 'Intake',
        alias: ['intake', '/intake'],
        component: () => import('@/components/pages/IntakeForm.vue')
      },
      {
        path: 'bolster_showcase/:id',
        name: 'Showcase',
        component: () => import('@/components/pages/PublicProfileBuilder.vue'),
        meta: {
          header: () => import('@/components/headers/PublicProfileHeader.vue'),
          hideMenu: true,
          type: 'company'
        }
      },
      {
        path: 'messages',
        name: 'Messages',
        component: () => import('@/components/pages/Messages.vue'),
        children: [
          {
            path: '',
            name: 'Default channel',
            props: true,
            component: () => import('@/components/Activities/DefaultActivityChannelBody.vue')
          },
          {
            path: ':channelType/:channelTypeId',
            name: 'Channel',
            props: true,
            component: () => import('@/components/Activities/ActivityChannelBody.vue')
          }
        ]
      },
      {
        path: 'locked',
        name: 'LockedCompany',
        component: () => import('@/components/pages/company/LockedCompany.vue')
      },
      {
        path: 'takeoff',
        name: 'Takeoff',
        component: () => import('@/components/Takeoff/Takeoff.vue'),
        meta: {
          confirmLeave: true,
          fixedLayout: true
        }
      }
    ]
  }
]
