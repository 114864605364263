import { ref, computed, getCurrentInstance, onMounted, onBeforeUnmount, provide } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { useAreaLayer } from './layers/useAreaLayer'
import { useImageLayer } from './layers/useImageLayer'
import { useMarkupLayer } from '@/components/Takeoff/composables/layers/useMarkupLayer.js'
import { useStore } from 'vuex'
import { useTextLayer } from '@/components/Takeoff/composables/layers/useTextLayer.js'
import { useCountLayer } from '@/components/Takeoff/composables/layers/useCountLayer.js'
import { useLineLayer } from '@/components/Takeoff/composables/layers/useLineLayer.js'
import { useCalculations } from '@/components/Takeoff/composables/useCalculations.js'

export function useLayers({
  getScale,
  selectedLayers,
  takeoff,
  isMeasuring,
  activeTool,
  viewBox,
  zoom,
  selectLayer,
  svgRef,
  viewport,
  refScaler,
  emit,
  addToHistory
}) {
  const $store = useStore()

  const {
    getCentroid,
    getGroupedOuterPolygons,
    calculatePixelArea,
    calculatePixelOuterPerimeterLength,
    calculatePixelSegmentLength
  } = useCalculations({ zoom })

  const calculateArea = (polygons) => {
    const pixelArea = calculatePixelArea(polygons)
    if (!pixelArea) return 0
    const pixelsPerUnit = takeoff.scale.pixels / takeoff.scale.length

    return pixelArea / (pixelsPerUnit * pixelsPerUnit)
  }

  const calculateSegmentLength = (segments) => {
    const pixels = calculatePixelSegmentLength(segments)

    // Convert from pixels to units using scale
    const pixelsPerUnit = takeoff.scale.pixels / takeoff.scale.length

    return pixels / pixelsPerUnit
  }

  const calculateOuterPerimeter = (polygons) => {
    const pixels = calculatePixelOuterPerimeterLength(polygons)

    // Convert from pixels to units using scale
    const pixelsPerUnit = takeoff.scale.pixels / takeoff.scale.length

    return pixels / pixelsPerUnit
  }

  const layers = computed({
    get: () => _.imm(takeoff.layers ?? []),
    set: (l) => (takeoff.layers = l)
  })

  const calculateLayerPosition = (layer) => {
    if (layer.type === 'image') return layer.position
    if (layer.type === 'text') return calculateTextDimensions(layer)

    const points = layer.points?.length ? layer.points : _.flatten(layer.segments ?? [])
    if (!points?.length) return { x: 0, y: 0, width: 0, height: 0 }

    // Find the bounding box
    const bounds = points.reduce(
      (box, point) => {
        return {
          minX: Math.min(box.minX, point.x),
          maxX: Math.max(box.maxX, point.x),
          minY: Math.min(box.minY, point.y),
          maxY: Math.max(box.maxY, point.y)
        }
      },
      {
        minX: points[0].x,
        maxX: points[0].x,
        minY: points[0].y,
        maxY: points[0].y
      }
    )

    return {
      x: bounds.minX,
      y: bounds.minY,
      width: bounds.maxX - bounds.minX,
      height: bounds.maxY - bounds.minY
    }
  }

  const updateLayerCalcs = (layer) => {
    const segments = layer?.segments ?? []
    const points = layer?.points ?? []

    const polygons = getGroupedOuterPolygons(segments) ?? []

    layer.isExplicitlyClosed = polygons.every((pg) => pg.isExplicitlyClosed) ?? false
    const isComplete = layer.isExplicitlyClosed || false

    const segment_length_total = calculateSegmentLength(segments)
    const segment_length_outside = (segments.length && calculateOuterPerimeter(polygons)) || 0
    const segment_length_inside = !isComplete ? 0 : segment_length_total - segment_length_outside
    const perimeter_inside = !isComplete ? 0 : segment_length_outside + segment_length_inside * 2
    const area_total = !isComplete ? 0 : segments.length > 2 ? calculateArea(polygons) : 0
    const count_total = points.length ?? 0

    const calcs = {
      length_unit: takeoff.scale.unit,
      area_unit: `${takeoff.scale.unit}2`,
      segment_length_total,
      segment_length_outside,
      segment_length_inside,
      perimeter_inside,
      area_total,
      count_total
    }

    layer.calcs = calcs

    if (!layer.dims) {
      layer.dims = applyLinks(layer)
    }

    const allOutsideSegments = polygons.reduce((list, group) => {
      return [...list, ...(group?.outsideSegmentIds || [])]
    }, [])
    layer.segments = segments.map((s) => {
      const isOutside = allOutsideSegments.includes(s[0].sid)
      s[0].outside = isOutside
      s[1].outside = isOutside
      return s
    })

    layer.metadata.centroid = getCentroid(layer)

    return layer
  }

  const applyLinks = (layer) => {
    if (!layer.calcs || (layer.type !== 'line' && layer.type !== 'count')) return {}

    return {
      segment_length_total: {
        links: [
          {
            dim: 'tfl',
            assembly: null
          }
        ]
      },
      segment_length_outside: {
        links: [
          {
            dim: 'efl',
            assembly: null
          }
        ]
      },
      segment_length_inside: {
        links: [
          {
            dim: 'ifl',
            assembly: null
          }
        ]
      },
      perimeter_inside: {
        links: [
          {
            dim: 'iwp',
            assembly: null
          }
        ]
      },
      area_total: {
        links: [
          {
            dim: 'fa',
            assembly: null
          }
        ]
      }
    }
  }

  const triggerAudit = () => {
    return c.throttle(() => auditLayers())
  }

  const auditLayers = () => {
    for (let layer of takeoff.layers) {
      layer = updateLayerCalcs(layer)
    }
    addToHistory(takeoff, false)
  }

  const updateLayer = (layerId, changes = {}, debounceHistory = false) => {
    const i = takeoff.layers.findIndex((l) => l.id === layerId)
    if (i === -1) return

    // Create the updated layer
    let layer = {
      ...takeoff.layers[i],
      ...changes
    }

    // Update metadata
    layer.metadata ??= {}

    const user = $store.state.session?.authorizedUser ?? null
    if (user) {
      layer.metadata.user = {
        user_id: user.user_id,
        user_fname: user.user_fname,
        user_lname: user.user_lname,
        user_name: `${user.user_fname || ''} ${user.user_lname || ''}`
      }
    }
    layer.position = calculateLayerPosition(layer)

    // Replace the layer in the array
    takeoff.layers.splice(i, 1, layer)

    if (debounceHistory) {
      c.throttle(() => addToHistory(takeoff), { debounce: true, delay: 400 })
    } else addToHistory(takeoff)
    triggerAudit()

    return layer
  }

  const addLayer = (type, data = {}) => {
    const lyrs = [...layers.value]
    const layer = {
      id: uuidv4(),
      name: `Layer ${lyrs.length + 1}`,
      type,
      position: data.position || { x: 0, y: 0 },
      metadata: data.metadata || {},
      zIndex: layers.value.length,
      ...data
    }
    lyrs.push(layer)
    layers.value = lyrs
    const updated = updateLayer(layer.id)

    // if (!viewport.layerIsInView(updated)) {
    //   viewport.positionLayerAtTopLeft(updated)
    // }

    emit('layer-created', layer)
    return updated
  }

  // Initialize specialized layer composables
  const {
    addAreaLayer,
    updateAreaPoints,
    completeArea,
    areaLayers,
    getPathData: getAreaPathData,
    selectedPointIndex: areaSelectedPointIndex,
    snapping: areaSnapping
  } = useAreaLayer({
    activeTool,
    layers,
    zoom,
    updateLayer,
    selectLayer,
    selectedLayers
  })

  const {
    addLineLayer,
    updateLineSegments,
    completeLine,
    lineLayers,
    getPathData: getLinePathData,
    selectedSegmentIndex,
    snapping: lineSnapping,
    getOutsideLength,
    isDrawingWalls,
    editMode: lineEditMode,
    previewLength
  } = useLineLayer({
    activeTool,
    layers,
    zoom,
    updateLayer,
    selectLayer,
    selectedLayers,
    viewport,
    addLayer,
    takeoff
  })

  const snapping = computed(() => areaSnapping.value || lineSnapping.value)

  const {
    addCountLayer,
    countLayers,
    updatePoints: updateCountPoints,
    selectedPointIndex: countSelectedPointIndex
  } = useCountLayer({
    activeTool,
    layers,
    zoom,
    updateLayer,
    selectLayer,
    selectedLayers,
    addLayer
  })

  const { loadFiles, addImageLayerByFileId, addImageLayer, updateImageAnalysis, imageLayers } =
    useImageLayer({
      activeTool,
      layers,
      viewBox,
      selectLayer,
      addLayer,
      takeoff,
      viewport
    })

  useMarkupLayer({
    layers,
    activeTool,
    zoom,
    selectLayer,
    selectedLayers,
    updateLayer,
    addLayer
  })

  const textLayer = useTextLayer({
    updateLayer,
    layers,
    activeTool,
    zoom,
    selectLayer,
    selectedLayers,
    svgRef,
    addLayer
  })

  const selectedPointIndex = computed(() => {
    if (activeTool.value === 'area') return areaSelectedPointIndex.value
    if (activeTool.value === 'count') return countSelectedPointIndex.value
    return null
  })
  const {
    editingLayer: textEditingLayer,
    isEditing: isEditingText,
    editingText,
    calculateTextDimensions
  } = textLayer

  const $this = getCurrentInstance().proxy

  const getScaleAdjustementRequired = (nativeScale, desiredScale) => {
    // Convert both scales to the same unit if needed
    const nativeUnitsPerPixel = nativeScale.distance / nativeScale.pixels
    const desiredUnitsPerPixel = desiredScale.length / desiredScale.pixels

    // The adjustment factor is the ratio of the desired scale to the native scale
    // If this factor is > 1, the layer needs to be enlarged
    // If this factor is < 1, the layer needs to be shrunk
    const adjustmentFactor = nativeUnitsPerPixel / desiredUnitsPerPixel

    return adjustmentFactor
  }

  const scaleLayer = async (layer) => {
    const shouldContinue = await $store.dispatch('modal/asyncConfirm', {
      message: `To scale the layer, find a place that indicates the scale on your image.  
      
      When you continue, we will activate the measurement tool so you can: 
      
      1) click once at the start of the length, then
      2) click a second time at the end of the length, and then
      3) you will be prompted to enter in the physical length that it represents
      
      and your image will be scaled!
      
      Press continue to set your first point...`,
      yes: 'Continue'
    })
    if (!shouldContinue) return
    const nativeScale = await getScale() // without adjusting the layer sizing, this is the original scale

    if (!nativeScale) return

    const scaleOfCanvas = takeoff.scale // scale of the viewport/environment
    let adjustment = getScaleAdjustementRequired(nativeScale, scaleOfCanvas)

    // Apply the adjustment to the layer's dimensions
    // Assuming we're scaling the selected layer
    if (layer && layer.type === 'image') {
      const existingAdjustment = layer.scaledTo?.adjustment ?? 1
      adjustment = adjustment * existingAdjustment

      layer.position = {
        ...layer.position,
        width: layer.file.width * adjustment,
        height: layer.file.height * adjustment
      }
      layer.scaledTo = { ...scaleOfCanvas, adjustment } // Mark the layer as scaled
    }

    updateLayer(layer.id, layer)

    $store.dispatch('alert', {
      message: 'Layer scaled successfully',
      success: true
    })
  }

  provide('layerState', {
    scaleLayer
  })

  const handleClick = ({ layer = null } = {}) => {
    if (
      !layer ||
      layer.type !== 'image' ||
      layer.scaledTo?.pixels ||
      activeTool.value === 'measurement'
    )
      return
    activeTool.value = 'select'

    return scaleLayer(layer)
  }

  onMounted(() => {
    $this.$on('mousedownCanvas', handleClick)
  })
  onBeforeUnmount(() => {
    $this.$off('mousedownCanvas', handleClick)
  })

  const addMeasurementLayer = (data = {}) => {
    return addLayer('measurement', {
      position: {
        start: data.position?.start || { x: 0, y: 0 },
        end: data.position?.end || { x: 0, y: 0 },
        ...data.position
      },
      metadata: {
        length: data.metadata?.length || 0,
        ...data.metadata
      },
      ...data
    })
  }

  const addTextLayer = (data = {}) => {
    return addLayer('text', {
      text: data.text || '',
      ...data
    })
  }

  const getImageDimensions = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => resolve({ width: img.width, height: img.height })
      img.onerror = reject
      img.src = url
    })
  }

  const getLayer = (layerId) => {
    return layers.value.find((layer) => layer.id === layerId)
  }

  const removeLayer = (layerId) => {
    const layer = getLayer(layerId)
    if (!layer) return

    if (layer.type === 'image' && layer.url) {
      URL.revokeObjectURL(layer.url)
    }

    layers.value = layers.value.filter((l) => l.id !== layerId)
    selectedLayers.value = selectedLayers.value.filter((l) => l.id !== layerId)
  }

  const updateLayerPosition = (layerId, position) => {
    const layerIndex = layers.value.findIndex((l) => l.id === layerId)
    if (layerIndex === -1) return

    const layer = layers.value[layerIndex]
    layer.position = { ...layer.position, ...position }

    layers.value = [
      ...layers.value.slice(0, layerIndex),
      { ...layer },
      ...layers.value.slice(layerIndex + 1)
    ]
  }

  const reorderLayers = (newOrder) => {
    layers.value = newOrder.map((layer, index) => ({
      ...layer,
      zIndex: newOrder.length - index
    }))
  }

  const cleanup = () => {
    layers.value.forEach((layer) => {
      if (layer.type === 'image' && layer.url) {
        URL.revokeObjectURL(layer.url)
      }
    })
    layers.value = []
    selectedLayers.value = []
  }

  const toJSON = () => {
    return layers.value.map((layer) => ({
      ...layer,
      file: undefined,
      url: undefined
    }))
  }
  return {
    // State
    layers,
    selectedLayers,
    areaLayers,
    imageLayers,

    // Layer type specific methods
    addAreaLayer,
    updateAreaPoints,
    completeArea,
    addImageLayer,
    updateImageAnalysis,
    loadFiles,
    addImageLayerByFileId,
    selectedPointIndex,

    textEditingLayer,
    isEditingText,
    textLayer,
    editingText,

    addCountLayer,
    countLayers,
    updateCountPoints,

    // General layer methods
    getLayer,
    removeLayer,
    updateLayerPosition,
    reorderLayers,
    cleanup,
    toJSON,
    scaleLayer,
    getCentroid,

    // Area specific methods
    getAreaPathData,
    // line
    getLinePathData,
    getOutsideLength,

    triggerAudit,
    addLineLayer,

    snapping,
    isDrawingWalls,
    lineEditMode,
    updateLayer,
    previewLength
  }
}
