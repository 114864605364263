import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import {
  getUserSession,
  getUserSessions,
  postUserSession,
  updateUserSession,
  getSessionActivity as getSessionActivityRequest,
  getSessionActivities as getSessionActivitiesRequest,
  postSessionActivity as postSessionActivityRequest,
  updateSessionActivity as updateSessionActivityRequest,
  getNpsEntry as getNpsEntryRequest,
  getNpsEntries as getNpsEntriesRequest,
  shouldGatherNpsEntry as shouldGatherNpsEntryRequest,
  postNpsEntry as postNpsEntryRequest,
  getNpsScoreByCompany as getCompanyNpsScoreRequest,
  getNpsScoreByCompanies as getCompaniesNpsScoreRequest
} from '../apollo-client/requests/sessionAnalytics'
import type { ISession } from '@bolster/types/sessionAnalytics/interfaces/session.ts'
import type { ISessionFetchFilters } from '@bolster/types/sessionAnalytics/interfaces/session_fetch_filters.ts'
import type { ISessionActivityFetchFilters } from '@bolster/types/sessionAnalytics/interfaces/session_activity_fetch_filters.ts'
import type { ISessionActivity } from '@bolster/types/sessionAnalytics/interfaces/session_activity.ts'
import type { INpsEntryFetchFilters } from '@bolster/types/sessionAnalytics/interfaces/nps_entry_fetch_filters.ts'
import type { INpsEntryShouldGather } from '@bolster/types/sessionAnalytics/interfaces/nps_entry_should_gather.ts'
import type { INpsEntry } from '@bolster/types/sessionAnalytics/interfaces/nps_entry.ts'
import type { INpsModal } from '../types/nps/nps_modal'
import type { ENpsEntryFeatureType } from '@bolster/types/sessionAnalytics/enums/nps_entry_feature_type.ts'
import Cache from '../../imports/api/Cache'

export const useSessionAnalytics = defineStore('session_analytics', () => {
  // ===== Data ===== //
  const activeSessionId = ref<string>()
  const activeSession = ref<ISession>()
  const activeNpsModals = ref<INpsModal[]>([])

  // ===== Methods ===== //
  const getActiveSessionIdState = (): string | undefined => {
    return activeSessionId.value
  }
  const setActiveSessionIdState = (session_id: string) => {
    activeSessionId.value = session_id
  }
  const setActiveSessionState = (session: ISession) => {
    if (!session) return
    activeSession.value = session
    activeSessionId.value = session.session_id
    window.localStorage.setItem('analytics_session_id', session.session_id)
    window.localStorage.setItem('analytics_session', JSON.stringify(session))
  }
  const getActiveSessionState = (): ISession | undefined => {
    if (activeSession.value) {
      return activeSession.value
    }
    const storedSession = window.localStorage.getItem('analytics_session') as string
    if (storedSession !== 'null' && storedSession !== '') {
      setActiveSessionState(JSON.parse(storedSession))
      return JSON.parse(storedSession)
    }
    return activeSession.value
  }
  const clearSessionState = () => {
    activeSession.value = undefined
    activeSessionId.value = undefined
    window.localStorage.setItem('analytics_session_id', '')
    window.localStorage.setItem('analytics_session', '')
  }

  const openModal = (nps_entry_type: ENpsEntryFeatureType, nps_modal_title: string) => {
    if (hasRequestedRecently()) return
    markRequested()
    activeNpsModals.value.push({ nps_entry_type, nps_modal_title })
  }
  const closeModal = (nps_entry_type: ENpsEntryFeatureType) => {
    activeNpsModals.value = activeNpsModals.value.filter(
      (npsModal) => npsModal.nps_entry_type !== nps_entry_type
    )
  }

  const getSession = (session_id: string): Promise<ISession> => {
    return getUserSession(session_id)
  }
  const getSessions = (
    filters: ISessionFetchFilters,
    limit: number,
    last_evaluated_key: string
  ) => {
    return getUserSessions(filters, limit, last_evaluated_key)
  }
  const postSession = (payload: Omit<ISession, 'session_id'>) => {
    return postUserSession(payload)
  }
  const updateSession = (payload: ISession) => {
    return updateUserSession({
      session_id: payload.session_id,
      time_start: payload.time_start,
      company_id: payload.company_id,
      time_end: payload.time_end,
      user_id: payload.user_id
    })
  }

  const getSessionActivity = (session_activity_id: string) => {
    return getSessionActivityRequest(session_activity_id)
  }
  const getSessionActivities = (
    filters: ISessionActivityFetchFilters,
    limit: number,
    last_evaluated_key: string
  ) => {
    return getSessionActivitiesRequest(filters, limit, last_evaluated_key)
  }
  const postSessionActivity = (payload: Omit<ISessionActivity, 'session_activity_id'>) => {
    payload.session_id = getActiveSessionIdState() as string
    return postSessionActivityRequest(payload)
  }
  const updateSessionActivity = (payload: Partial<ISessionActivity>) => {
    return updateSessionActivityRequest({
      session_id: payload.session_id,
      session_activity_id: payload.session_activity_id,
      page_time_end: payload.page_time_end,
      page_time_start: payload.page_time_start,
      click_element: payload.click_element,
      session_activity_time_created: payload.session_activity_time_created,
      session_activity_type: payload.session_activity_type,
      page_name: payload.page_name,
      page_route: payload.page_route,
      user_id: payload.user_id,
      company_id: payload.company_id
    })
  }

  const getNpsEntry = (nps_entry_id: string) => {
    return getNpsEntryRequest(nps_entry_id)
  }
  const getNpsEntries = (
    filters: INpsEntryFetchFilters,
    limit: number,
    last_evaluated_key: string
  ) => {
    return getNpsEntriesRequest(filters, limit, last_evaluated_key)
  }
  const hasRequestedRecently = () => {
    const time = Cache.getCacheItem('npsLastRequested', 'nps') ?? 0
    return time === 0 ? false : true
  }
  const markRequested = () => {
    Cache.setCacheItem(
      'npsLastRequested',
      Date.now(),
      'nps',
      null,
      Date.now() + 9 * 24 * 60 * 60 * 1000
    )
  }
  const shouldGatherNpsEntry = (filters: INpsEntryShouldGather) => {
    if (hasRequestedRecently()) return false
    return shouldGatherNpsEntryRequest(filters)
  }
  const postNpsEntry = (payload: Omit<INpsEntry, 'nps_entry_id'>) => {
    return postNpsEntryRequest(payload)
  }
  const getCompanyNpsScore = (company_id: string) => {
    return getCompanyNpsScoreRequest(company_id)
  }
  const getCompaniesNpsScore = (company_ids: string[]) => {
    return getCompaniesNpsScoreRequest(company_ids)
  }

  return {
    activeSessionId,
    activeSession,

    getActiveSessionIdState,
    setActiveSessionIdState,
    getActiveSessionState,
    setActiveSessionState,
    clearSessionState,

    activeNpsModals,
    openModal,
    closeModal,

    getSession,
    getSessions,
    postSession,
    updateSession,

    getSessionActivity,
    getSessionActivities,
    postSessionActivity,
    updateSessionActivity,

    getNpsEntry,
    getNpsEntries,
    shouldGatherNpsEntry,
    postNpsEntry,
    getCompanyNpsScore,
    getCompaniesNpsScore
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSessionAnalytics, import.meta.hot))
}
