<script setup>
import {
  getCurrentInstance,
  watch,
  ref,
  computed,
  defineModel,
  defineOptions,
  defineEmits,
  onBeforeUnmount
} from 'vue'
import CurrencyFilter from '@/components/mixins/CurrencyFilter'
import CalculatorField from '@/components/ui/Calculator/Field.vue'

defineOptions({
  name: 'CalculatorFormatted',
  mixins: [CurrencyFilter],
  compatConfig: { MODE: 3 }
})

const $this = getCurrentInstance().proxy

const emit = defineEmits(['input', 'blur', 'focusout', 'update'])

const props = defineProps({
  format: {
    type: String,
    default: 'number'
  },
  placeholder: {
    type: String,
    default: ''
  },
  variables: {
    type: Object,
    default: () => ({})
  },
  id: {},
  multiLine: {
    type: Boolean,
    default: false
  },
  forceInline: {
    type: Boolean,
    default: false
  },
  showPocket: {
    type: Boolean,
    default: true
  },
  numeric: {
    type: Boolean,
    default: true
  },
  fitContent: {
    type: Boolean,
    default: true
  },
  width: {
    type: Number,
    default: null
  },
  height: {
    type: Number,
    default: null
  },
  autoMultiline: {
    type: Boolean,
    default: true
  }
})

const rawValue = defineModel({
  default: () => ''
})
const equation = defineModel('equation', {
  default: () => ''
})
const formattedValue = defineModel('formatted', {
  default: () => ''
})

const formatValue = (value) => {
  const filterFunc =
    props.format in $this.$options.filters
      ? $this.$options.filters[props.format]
      : (val) => c.format(val, props.format)
  return filterFunc(value)
}
const formattedRaw = computed(() => formatValue(rawValue.value))

const typedValue = ref(equation.value || rawValue.value || '')

const focused = ref(false)
const editableDiv = ref(null)

const handleBlur = () => {
  focused.value = false
  emit('blur')
  emit('focusout')
}

const handleClick = async () => {
  focused.value = true
  await $this.$nextTick()
  editableDiv.value.focus()
}

watch([rawValue, equation], () => {
  if (!focused.value) typedValue.value = equation.value || rawValue.value || ''
})

const commit = () => {
  console.log('commiting')
  const input = typedValue.value
  const eq = c.isEquation(input, props.variables ?? {}) ? input : null
  const raw = c.toNum(input, 20, true, props.variables ?? {})
  const fmt = formatValue(raw)
  const eqSame = eq === equation.value

  if (!eqSame) equation.value = eq
  if (raw !== rawValue.value) {
    rawValue.value = raw
    formattedValue.value = fmt
    equation.value = eq
  }
}

watch(focused, (is, was) => {
  if (was && !is) {
    commit()
  } else if (is && !was) {
    typedValue.value = equation.value || rawValue.value || ''
  }
})

onBeforeUnmount(() => {
  if (focused.value) commit()
})

const {
  // eslint-disable-next-line no-unused-vars
  value: omit,
  // eslint-disable-next-line no-unused-vars
  modelValue: omit2,
  ...rest
} = props
const passOnProps = {
  ...rest
}
</script>

<template>
  <div
    v-if="!focused"
    v-bind="$attrs"
    @click.capture.stop.prevent="(e) => handleClick(e)"
    :contenteditable="false"
    :class="[
      'inline-calculator-container inactive tabular-nums ',
      'border-2 border-transparent hover:border-blue-print '
    ]"
  >
    {{ formattedRaw }}
  </div>
  <CalculatorField
    v-if="focused"
    v-bind="passOnProps"
    class="active tabular-nums"
    ref="editableDiv"
    v-model="typedValue"
    :variables="variables"
    @blur="handleBlur"
  />
</template>

<style lang="scss" rel="stylesheet/scss" scoped>
$bolsterBlue: $blue-print;

.inline-calculator-container {
  transition: border-color 0.1s ease;

  margin-right: -0.25em;
  margin-left: -0.25em;

  padding-right: 0.25em;
  padding-left: 0.25em;
  border-radius: 3px;

  overflow: hidden;

  &.inactive {
    cursor: pointer;
    border-color: transparent;
    &:hover {
      border-color: $bolsterBlue;
    }
  }
  &.active {
    border-color: $bolsterBlue;
    width: fit-content;
    max-width: 100%;
  }
}
</style>
