import { ref, computed, getCurrentInstance, onMounted, onUnmounted, watch, nextTick } from 'vue'
import { v4 as uuidv4 } from 'uuid'

export function useTextLayer({
  updateLayer,
  layers,
  activeTool,
  zoom,
  selectLayer,
  selectedLayers,
  svgRef,
  addLayer
}) {
  const $this = getCurrentInstance().proxy
  const editingLayer = ref(null)
  const isEditing = ref(false)
  const editingText = ref('')

  watch(selectedLayers, (sel) => {
    if (editingLayer.value && !sel.find((l) => l.id === editingLayer.value)) {
      editingLayer.value = null
      isEditing.value = false
      editingText.value = ''
    }
  })

  watch(activeTool, (newTool) => {
    if (newTool !== 'text' && isEditing.value) {
      finishEditing()
    }
  })

  const calculateTextDimensions = (layer) => {
    const { text = 'Text', fontSize = 14, fontFamily = 'sans-serif' } = layer.metadata ?? {}
    // Create a temporary text element to measure dimensions
    const svg = svgRef.value
    const tempText = document.createElementNS('http://www.w3.org/2000/svg', 'text')
    tempText.setAttribute('font-family', fontFamily)
    tempText.setAttribute('font-size', fontSize + 'px')
    tempText.textContent = text || 'Text'
    svg.appendChild(tempText)
    const bbox = tempText.getBBox()
    svg.removeChild(tempText)
    return {
      x: layer.position.x,
      y: layer.position.y,
      width: bbox.width,
      height: bbox.height
    }
  }

  const addTextLayer = (point) => {
    const fontSize = 14
    const fontFamily = 'Arial'

    const layer = {
      id: uuidv4(),
      type: 'text',
      position: {
        x: point.x,
        y: point.y
      },
      metadata: {
        isComplete: false,
        text: 'Text',
        fontSize,
        fontFamily,
        fontWeight: 'normal',
        color: '#FF0000'
      },
      zIndex: layers.value.length
    }

    return addLayer('text', layer)
  }

  const startEditing = (layer) => {
    editingLayer.value = layer.id
    editingText.value = layer.metadata.text
    isEditing.value = true
  }

  const finishEditing = async () => {
    if (!editingLayer.value) return

    const layer = layers.value.find((l) => l.id === editingLayer.value)
    if (layer) {
      layer.metadata.text = editingText.value
      layer.metadata.isComplete = true
      updateLayer(layer.id, layer)
    }

    editingLayer.value = null
    isEditing.value = false
    editingText.value = ''
  }

  const updateTextPosition = (layer, point) => {
    if (!layer) return

    layer.position = {
      ...layer.position,
      x: point.x,
      y: point.y
    }
    updateLayer(layer.id, layer)
  }

  const handleClick = (event) => {
    if (activeTool.value !== 'text') return
    const { svgPoint } = event

    // If we're already editing, finish the current edit
    if (isEditing.value) {
      finishEditing()
      return
    }

    // Check if we clicked an existing text layer
    const clickedLayer = layers.value.find((l) => {
      if (l.type !== 'text') return false
      const { x, y, width, height } = l.position
      return (
        svgPoint.x >= x - 4 &&
        svgPoint.x <= x + width + 4 &&
        svgPoint.y >= y - height - 4 &&
        svgPoint.y <= y + 4
      )
    })

    if (clickedLayer) {
      selectLayer(clickedLayer.id)
      startEditing(clickedLayer)
    } else {
      // Create new text layer
      const layer = addTextLayer(svgPoint)
      selectLayer(layer.id)
      startEditing(layer)
    }
  }

  const handleKeydown = (event) => {
    if (!isEditing.value) return

    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      finishEditing()
    } else if (event.key === 'Escape') {
      event.preventDefault()
      // Cancel editing
      if (editingLayer.value) {
        const layer = layers.value.find((l) => l.id === editingLayer.value)
        if (layer && !layer.metadata.isComplete) {
          // Remove incomplete layer
          layers.value = layers.value.filter((l) => l.id !== layer.id)
        }
      }
      editingLayer.value = null
      isEditing.value = false
      editingText.value = ''
    }
  }

  onMounted(() => {
    $this.$on('clickCanvas', handleClick)
    window.addEventListener('keydown', handleKeydown)
  })

  onUnmounted(() => {
    $this.$off('clickCanvas', handleClick)
    window.removeEventListener('keydown', handleKeydown)
  })

  return {
    editingLayer,
    isEditing,
    editingText,
    addTextLayer,
    startEditing,
    finishEditing,
    updateTextPosition,
    calculateTextDimensions
  }
}
