export default {
  type: 'gatewaytoken',
  skipAudit: true,
  generateVueActions() {
    return {
      async clearConnection({ dispatch }, data = {}) {
        const { payload } = await dispatch('ajax', {
          path: 'export_token/clearConnection',
          data
        })

        return { payload }
      },
      async createConnection({ dispatch }, data = {}) {
        const { payload } = await dispatch('ajax', {
          path: 'export_token/createConnection',
          data
        })

        return { payload }
      },
      async hasAccess({ dispatch }, data = {}) {
        const { payload } = await dispatch('ajax', {
          path: 'export_token/hasAccess',
          data
        })

        return { payload }
      },
      async refreshToken({ dispatch }) {
        const { payload } = await dispatch('ajax', {
          path: 'export_token/refreshExportToken'
        })

        return { payload }
      },
      async sync({ dispatch }, data) {
        const { payload } = await dispatch('ajax', {
          path: 'export_token/sync',
          data
        })
        return { payload }
      },
      async import({ dispatch }, data) {
        const { payload } = await dispatch('ajax', {
          path: 'export_token/import',
          data
        })
        return { payload }
      },
      async saveToken({ state, dispatch }, payload) {
        const { accessCode, realmId = null, companyId, type = 'quickbooks' } = payload

        const result = await dispatch('ajax', {
          path: 'export_token/callback',
          data: {
            accessCode,
            realmId,
            companyId,
            type: type
          }
        })

        return { result }
      },
      checkIfExportTokenHasExpired({ dispatch, rootState }, { exporter }) {
        if (
          !rootState.session.company ||
          !rootState.session.company.aoExportTokens ||
          !rootState.session.company.aoExportTokens.length > 0
        ) {
          return false
        }

        const token = rootState.session.company.aoExportTokens.find(
          (t) => t.export_token_name === exporter
        )
        if (!token || token.export_token_time_refresh_token_expiry > new Date()) {
          return false
        }

        return true
      }
    }
  }
}
