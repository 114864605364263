export default {
    'Review all': 'Revisar tudo',
    'Review all items': 'Revisar todos os itens',
    'Selections': 'Seleções',
    'Click to toggle expand':  'Clique para expandir',
    'Please make a selection above before continuing': 'Faça uma seleção acima antes de continuar',
    'This is a required selection': 'Esta é uma seleção obrigatória',
    'Category': 'Categoria',
    'Product': 'Produto',
    'Quantity': 'Quantidade',
    'Price': 'Preço',
    'Management fee': 'Taxa de administração',
    'not included': 'não incluído',
    'each': 'cada',
    'No item name': 'Sem nome de item',
    'Review the details of this quote': 'Revisar os detalhes desta cotação',
    'tax on {taxOn} derived from {taxType}': 'imposto sobre {taxOn} derivado de {taxType}',
    'loan': 'empréstimo',
    'From': 'De',
    'Cancel loan': 'Cancelar empréstimo',
    'month': 'mês',
    'Loan payment': 'Pagamento do empréstimo',
    'powered by': 'distribuído por',
    'See projects': 'Ver projetos',
    'Rate your experience': 'Avalie sua experiência',
    'How would you rate your recent experience with': 'Como você avaliaria sua experiência recente com',
    'Submit review': 'Enviar avaliação',
    'Review submitted': 'Avaliação enviada',
    'Anonymous': 'Anônimo',
    'You are a read-only reviewer': 'Você é um revisor de somente leitura',
    'This proposal has expired and is no longer valid': 'Esta proposta expirou e não é mais válida',
    'Request a new proposal': 'Solicitar uma nova proposta',
    'No selection required by customer': 'Nenhuma seleção exigida pelo cliente',
    "Thank you for your submission! We will get back to you as soon as possible": "Obrigado pelo seu envio! Entraremos em contato com você o mais breve possível.",
    "Submission Successful": "Envio Bem-Sucedido",
    "Could not find form": "Não foi possível encontrar o formulário",
    "This form does not exist. Please double check the url": "Este formulário não existe. Por favor, verifique o URL.",
    "Saving form submission": "Salvando envio do formulário",
    "Submit": "Enviar",
    "To get started creating an estimate, please select the options provided below": "Para começar a criar uma cotação, selecione as opções fornecidas abaixo",
    "About us": "Sobre nós",
    "Get a quote": "Obter uma cotação",
    "Company rating": "Classificação da empresa",
    "One of our dedicated agents will review your requirements to provide a personalized response. Check your inbox for confirmation and details. We’re excited to help make your dream home a reality": "Um de nossos agentes dedicados revisará seus requisitos para fornecer uma resposta personalizada. Verifique sua caixa de entrada para confirmação e detalhes. Estamos entusiasmados em ajudar a tornar sua casa dos sonhos uma realidade.",
    "You will receive a copy of the estimate by email for your records": "Você receberá uma cópia da estimativa por e-mail para seus registros.",
    "Thank you for your submission! We've received your details and are already working on creating an estimate for your project. We will follow up with you shortly to discuss the estimate and the next steps": "Obrigado pelo seu envio! Recebemos seus dados e já estamos trabalhando na criação de uma estimativa para o seu projeto. Entraremos em contato com você em breve para discutir a estimativa e os próximos passos.",
    "First Name": "Primeiro Nome",
    "Last Name": "Último Nome",
    "Email": "E-mail",
    "Phone": "Telefone",
    "Suite": "Suíte",
    "Address": "Endereço",
    "City": "Cidade",
    "Zip Code / Postal Code": "Código Postal / CEP",
    "Project Details": "Detalhes do Projeto",
    "Project Type": "Tipo de Projeto",
    "Project Budget": "Orçamento do Projeto",
    "Project Timeline": "Cronograma do Projeto",
    "Tell us about your project.": "Conte-nos sobre o seu projeto.",
    "Tell us the type of project you need.": "Diga-nos o tipo de projeto que você precisa.",
    "Tell us your budget for the project.": "Informe-nos o seu orçamento para o projeto.",
    "Tell us when you want the project to start and any other important dates.": "Diga-nos quando você deseja iniciar o projeto e qualquer outra data importante.",
    "Add to estimate": "Adicionar à estimativa",
    "Remove from estimate": "Remover da estimativa",
    "Add": "Adicionar",
    "Save": "Economizar",
    "Included": "Incluído",
    "Not included": "Não incluído",
    "Same price": "Mesmo preço",
    "Learn more": "Saiba mais",
    "Select": "Selecionar",
    "Optional item details": "Detalhes do item opcional",
    "No description available": "Nenhuma descrição disponível",
    "Description": "Descrição",
    "Features": "Características",
    "Upgrade availability may vary according to individual project circumstances. Pictures are examples and may not exactly represent how it appears on your project.": "A disponibilidade de melhorias pode variar de acordo com as circunstâncias específicas do projeto. As imagens são exemplos e podem não representar exatamente como aparecem no seu projeto.",
    "Cancel": "Cancelar",
    "Upgrade details": "Detalhes da melhoria",
    "Select this": "Selecione isto",
    "Please complete the form below, and we'll instantly generate a shoppable estimate. You can make upgrades, refine your selections, and submit a request for proposal.": "Por favor, preencha o formulário abaixo e geraremos instantaneamente um orçamento personalizável. Você poderá fazer upgrades, refinar suas seleções e enviar uma solicitação de proposta.",
    "View options": "Ver opções",
    "Welcome to your interactive estimate": "Bem-vindo à sua estimativa interativa",
    "In this proposal, your contractor or builder has specified some items which require a selection to be made by you before your project can start.": "Nesta proposta, seu empreiteiro ou construtor especificou alguns itens que exigem uma escolha sua antes que seu projeto possa começar.",
    "There are also items marked 'Optional', which you can choose to add to your project if you wish.": "Também há itens marcados como 'Opcional', que você pode escolher adicionar ao seu projeto, se desejar.",
    "Thanks, and enjoy your experience using Bolster's Interactive Estimates!": "Obrigado e aproveite sua experiência usando as Estimativas Interativas da Bolster!",
    "Dismiss": "Dispensar",
    "Download relevant files to enhance your project or documentation such as contracts, invoices, receipts, images, or any other supporting documents.": "Baixe arquivos relevantes para aprimorar seu projeto ou documentação, como contratos, faturas, recibos, imagens ou outros documentos de apoio.",
    "The achievements and recognitions demonstrate our expertise and commitment, helping you feel confident in your decision.": "As conquistas e reconhecimentos demonstram nossa expertise e compromisso, ajudando você a se sentir confiante em sua decisão.",
    "Awards and badges": "Prêmios e distintivos",
    "Attached files": "Arquivos anexados",
    "Rolling Basis Payments": "Pagamentos em regime contínuo",
    "Pay as work is completed. You'll receive individual payment requests for each finished and quality-approved item. An itemized receipt summarizing all completed items will be provided.": 
      "Pague à medida que o trabalho é concluído. Você receberá solicitações de pagamento individuais para cada item concluído e aprovado. Será fornecido um recibo detalhado com todos os itens concluídos.",
    "Transparent Costs": "Custos transparentes",
    "Gain clear insight into costs and track progress in real time.": 
      "Tenha uma visão clara dos custos e acompanhe o progresso em tempo real.",
    "Flexible Payments": "Pagamentos flexíveis",
    "Pay for items as they're completed, reducing large upfront expenses and improving budget management.": 
      "Pague pelos itens à medida que são concluídos, reduzindo despesas iniciais elevadas e melhorando a gestão do orçamento.",
    "Aligned Expectations": "Expectativas alinhadas",
    "Payments tied to progress ensure quality and scope meet expectations.": 
      "Os pagamentos vinculados ao progresso garantem que a qualidade e o escopo atendam às expectativas.",
      'Total items from the estimate': 'Total de itens do orçamento',
      'Installment': 'Parcela',
      'Total': 'Total',
      'Payments are required': 'Os pagamentos são obrigatórios',
      'Project payment terms': 'Termos de pagamento do projeto',
      "Rolling Basis Payments offer flexibility and transparency by allowing you to pay as work is completed. After approving the estimate, you’ll be prompted to add a payment method, ensuring you can quickly approve and pay for items as they’re completed. Access your project’s Progress Dashboard within the portal to track ongoing work, review completed items, and approve them for payment using a simple checkout process. Easily add or remove payment methods directly from the portal, giving you control over how payments are made. All approval files and receipts are available within the portal for easy reference and record-keeping, ensuring payments align with project progress while helping maintain budget control.": "Os pagamentos em base contínua oferecem flexibilidade e transparência, permitindo que você pague à medida que o trabalho é concluído. Após aprovar a estimativa, você será solicitado a adicionar um método de pagamento, garantindo que possa aprovar e pagar rapidamente pelos itens à medida que são finalizados. Acesse o Painel de Progresso do seu projeto dentro do portal para acompanhar o trabalho em andamento, revisar os itens concluídos e aprová-los para pagamento por meio de um processo de checkout simples. Adicione ou remova métodos de pagamento diretamente no portal com facilidade, proporcionando controle sobre como os pagamentos são feitos. Todos os arquivos de aprovação e recibos estão disponíveis no portal para fácil referência e manutenção de registros, garantindo que os pagamentos estejam alinhados com o progresso do projeto enquanto ajudam a manter o controle do orçamento.",
      "Flexible Payments, also known as Percentage-Based Payments, provide a structured yet adaptable approach by aligning payments with project milestones. After approving the estimate, you’ll add a payment method, enabling you to make payments as each milestone is reached. Use the Progress Dashboard in the portal to monitor completion percentages, review milestone details, and approve payments through a straightforward checkout process. The portal allows you to manage your payment methods, ensuring flexibility and convenience. Approval files and receipts are readily available within the portal, offering clear documentation of each milestone payment and ensuring transparency throughout the project.": "Os pagamentos flexíveis, também conhecidos como pagamentos baseados em porcentagem, oferecem uma abordagem estruturada, mas adaptável, alinhando os pagamentos aos marcos do projeto. Após aprovar a estimativa, você adicionará um método de pagamento, permitindo que faça pagamentos à medida que cada marco seja atingido. Utilize o Painel de Progresso no portal para monitorar as porcentagens de conclusão, revisar detalhes dos marcos e aprovar pagamentos por meio de um processo de checkout simples. O portal permite que você gerencie seus métodos de pagamento, garantindo flexibilidade e conveniência. Os arquivos de aprovação e recibos estão disponíveis no portal, oferecendo documentação clara de cada pagamento de marco e garantindo transparência durante todo o projeto.",
      "Phase-Based Payments allow you to make payments as your project reaches key milestones. Once you approve the estimate, you'll be prompted to add a payment method and gain access to your project portal. From there, you can navigate to the Progress dashboard to review and approve completed project phases. After approving the work for a phase, you’ll receive a notification that it’s time to pay, and the approved items will be added to a checkout process where you can securely submit payment. The portal also lets you manage your payment methods, giving you the flexibility to add or remove them as needed. Additionally, all approval files and receipts are stored in the portal for easy access, ensuring you have complete visibility into your payments and project progress.": "Os pagamentos baseados em fases permitem que você realize pagamentos à medida que seu projeto atinge marcos importantes. Depois de aprovar a estimativa, você será solicitado a adicionar um método de pagamento e terá acesso ao portal do seu projeto. A partir daí, você pode navegar até o Painel de Progresso para revisar e aprovar as fases concluídas do projeto. Após aprovar o trabalho de uma fase, você receberá uma notificação informando que é hora de pagar, e os itens aprovados serão adicionados a um processo de checkout seguro, onde você poderá efetuar o pagamento com segurança. O portal também permite que você gerencie seus métodos de pagamento, proporcionando a flexibilidade de adicioná-los ou removê-los conforme necessário. Além disso, todos os arquivos de aprovação e recibos são armazenados no portal para fácil acesso, garantindo total visibilidade sobre seus pagamentos e o progresso do projeto.",
      "Stage-Based Payments allow you to make payments as your project progresses. Once you approve the estimate, you'll be prompted to add a payment method and gain access to your project portal. From there, you can navigate to the Progress dashboard to review and approve completed project stages. When all items within a stage are approved, they will be added to a checkout process where you can securely submit payment. You’ll receive notifications when it’s time to pay, keeping you informed every step of the way. The portal also allows you to manage your payment methods, giving you the flexibility to add or remove them as needed. Additionally, all approval files and receipts are stored in the portal for easy access, ensuring you have full visibility into your payments and project milestones.": "Os pagamentos baseados em estágios permitem que você faça pagamentos conforme seu projeto avança. Depois de aprovar a estimativa, você será solicitado a adicionar um método de pagamento e terá acesso ao portal do seu projeto. A partir daí, você pode navegar até o Painel de Progresso para revisar e aprovar os estágios concluídos do projeto. Quando todos os itens dentro de um estágio forem aprovados, eles serão adicionados a um processo de checkout seguro, onde você poderá realizar o pagamento com segurança. Você receberá notificações quando for a hora de pagar, mantendo-o informado em cada etapa do processo. O portal também permite que você gerencie seus métodos de pagamento, proporcionando a flexibilidade de adicioná-los ou removê-los conforme necessário. Além disso, todos os arquivos de aprovação e recibos são armazenados no portal para fácil acesso, garantindo total visibilidade sobre seus pagamentos e os marcos do projeto."
};
