import { onBeforeUnmount, computed, watch } from 'vue'
import { useSessionAnalytics } from '@/stores/session_analytics.ts'
import { useStore } from 'vuex'

import type { INpsEntryShouldGather } from '@bolster/types/sessionAnalytics/interfaces/nps_entry_should_gather.ts'

import { ENpsEntryFeatureType } from '@bolster/types/sessionAnalytics/enums/nps_entry_feature_type'

export const useNpsDialog = (
  type: ENpsEntryFeatureType,
  title: string,
  conditions?: INpsEntryShouldGather
) => {
  // ==== Composables ==== //
  const { openModal, closeModal, shouldGatherNpsEntry } = useSessionAnalytics()
  const store = useStore()
  // ===================== //

  // ==== Computed ==== //
  const companyId = computed(() => store.state.session?.company?.company_id)
  const userId = computed(() => store.state.session?.authorizedUser?.user_id)
  // ================== //

  // ==== Methods ==== //
  const openNpsModal = () => openModal(type, title)
  const closeNpsModal = () => closeModal(type)
  const shouldGatherNps = async () => {
    if (store.getters.isGuestUser) return false
    if (!store.state.session.authorizedUser.user_is_super_user) return false
    if (conditions) conditions.time_since_last = { gte: '777600000' }
    Object.keys(conditions || {}).forEach((key) => {
      if (conditions && 'user_id' in (conditions?.[key].filters || {}))
        conditions[key].filters.user_id = userId.value.toString()
      if (conditions && 'company_id' in (conditions?.[key].filters || {}))
        conditions[key].filters.company_id = companyId.value.toString()
    })

    try {
      const shouldDisplayNps = conditions ? await shouldGatherNpsEntry(conditions) : false
      if (shouldDisplayNps) openNpsModal()
    } catch (error) {
      console.error('Error in shouldGatherNpsEntry:', error)
    }
  }
  // =================== //

  // ==== Watchers ==== //
  watch(
    companyId,
    async () => {
      if (companyId.value) {
        await shouldGatherNps()
      }
    },
    { immediate: true }
  )
  // =================== //

  // ==== Lifecycle ==== //
  onBeforeUnmount(() => closeNpsModal())
  // =================== //
}
