import { ref, onMounted, onUnmounted, getCurrentInstance, computed, nextTick } from 'vue'

export const useLayerDrag = ({ takeoff, activeTool, selectedLayers, zoom, selectedLayersById }) => {
  const $this = getCurrentInstance().proxy

  const startPoint = ref(null)
  const currentPoint = ref(null)
  const isDragging = ref(false)

  const deltas = computed(() => {
    if (!isDragging.value) return { dx: 0, dy: 0 }
    // caluclate deltas between startPoint and currentPoint so that
    // we can use it to set translations on the  layers that are selected
    return (
      currentPoint.value &&
      startPoint.value && {
        dx: currentPoint.value.x - startPoint.value.x,
        dy: currentPoint.value.y - startPoint.value.y
      }
    )
  })

  const transforms = computed(() => {
    if (!isDragging.value) return {}
    // now create a transform style for each of the selected layers
    // in an object with the layer id as the key, and the transform style
    // as a string, using the deltas so that they will appear to move before
    // we commit the deltas
    return selectedLayers.value.reduce((transforms, layer) => {
      transforms[layer.id] = `transform: translate(${deltas.value.dx}px, ${deltas.value.dy}px);`
      return transforms
    }, {})
  })

  const commitDeltas = () => {
    for (const layer of takeoff.layers) {
      if (!(layer.id in selectedLayersById.value)) continue

      layer.position.x = layer.position.x + deltas.value.dx
      layer.position.y = layer.position.y + deltas.value.dy

      // now do this for each layer.points
      if (layer.points?.length) {
        layer.points.forEach((point) => {
          point.x = point.x + deltas.value.dx
          point.y = point.y + deltas.value.dy
        })
      }

      // now do this for the centroid
      if (layer.metadata?.centroid) {
        layer.metadata.centroid.x = layer.metadata.centroid.x + deltas.value.dx
        layer.metadata.centroid.y = layer.metadata.centroid.y + deltas.value.dy
      }
    }
  }

  const handleDragstart = ({ svgPoint, layer, element }) => {
    if (
      !layer ||
      !selectedLayers.value.some((l) => l.id === layer.id) ||
      activeTool.value !== 'select' ||
      (element &&
        element.hasAttribute('data-drag-from') &&
        element.getAttribute('data-drag-from') !== true)
    )
      return // can only drag from an actual selected layer
    startPoint.value = svgPoint
    currentPoint.value = svgPoint
    isDragging.value = true
  }

  const handleDragmove = ({ svgPoint }) => {
    if (!isDragging.value) return
    currentPoint.value = svgPoint
  }

  const handleDragend = ({ svgPoint }) => {
    if (!isDragging.value) return
    // modify all selectedLayers so that the x and y coords of them are
    // changed by the same difference
    commitDeltas()
    isDragging.value = false
  }

  onMounted(() => {
    $this.$on('dragstartCanvas', handleDragstart)
    $this.$on('dragmoveCanvas', handleDragmove)
    $this.$on('dragendCanvas', handleDragend)
  })
  onUnmounted(() => {
    $this.$off('dragstartCanvas', handleDragstart)
    $this.$off('dragmoveCanvas', handleDragmove)
    $this.$off('dragendCanvas', handleDragend)
  })

  return {
    isDragging,
    transforms
  }
}
