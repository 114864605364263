<script setup>
import {
  ref,
  computed,
  defineEmits,
  defineProps,
  defineOptions,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  watch
} from 'vue'
import CurrencyFilter from '@/components/mixins/CurrencyFilter'
import Calculator from '@/components/ui/Calculator/Calculator'
import Portal from 'primevue/portal'
import ScrollPanel from 'primevue/scrollpanel'
import DimensionSelector from '@/components/quote/item/DimensionSelector.vue'
import Drop from '@/components/ui/Drop.vue'

const emit = defineEmits(['input', 'blur', 'focusout', 'focusin', 'focus'])
const props = defineProps({
  value: {
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  variables: {
    type: Object,
    default: () => ({})
  },
  multiLine: {
    type: Boolean,
    default: false
  },
  forceInline: {
    type: Boolean,
    default: false
  },
  showPocket: {
    type: Boolean,
    default: true
  },
  numeric: {
    type: Boolean,
    default: true
  },
  fitContent: {
    type: Boolean,
    default: true
  },
  width: {
    type: Number,
    default: null
  },
  height: {
    type: Number,
    default: null
  },
  autoMultiline: {
    type: Boolean,
    default: true
  }
})
const editableDiv = ref(null)

const calcReturns = Calculator.useCalculator({
  props,
  editableDiv,
  emit
})
// eslint-disable-next-line vue/no-dupe-keys
const {
  // cursorPosition,
  injectValue,
  handleMouseDown,
  editable,
  handleInput,
  handleFocusIn,
  handleKeydown,
  handleKeyup,
  handleClick,
  focused,
  forceMultiline,
  // eslint-disable-next-line vue/no-dupe-keys
  multiLine,
  updateHtml
} = calcReturns

const handleMouseUp = () => {}

const showCalcPocket = computed(() => props.showPocket)
const variables = computed(() => props.variables)
const numeric = computed(() => props.numeric)

defineExpose({
  ...calcReturns,
  editableDiv,
  showCalcPocket,
  multiLine,
  forceMultiline
})

defineOptions({
  name: 'CalculatorField',
  mixins: [CurrencyFilter]
})

const rand = computed(() => `calc${_.uniqueId()}`)
const clickAwayHandler = (event) => {
  if (event.target.closest(`[data-id=${rand.value}]`)) return

  calcReturns.handleFocusOut()
}
onBeforeMount(() => {
  window.addEventListener('mousedown', clickAwayHandler)
})
onBeforeUnmount(() => {
  window.removeEventListener('mousedown', clickAwayHandler)
})
const refRootEl = ref(null)
const refContainerRoot = ref(null)
const calcFuncDefs = { ...c.calculatorFunctions }
const calcFuncs = Object.keys(calcFuncDefs)
const optionsDrop = ref(null)

const dropBtnClass =
  'min-w-8 border text-sm px-2 py-1 border-flame-white/60 rounded-sm text-flame-white/80 hover:text-flame-white hover:border-flame-white'
const pocketBtnClass =
  'class="bg-transparent text-white/60 hover:bg-white/20 hover:text-white rounded-sm w-4 h-4 flex justify-center items-center'

const originalPosition = ref(null)

const enableMultiline = () => {
  originalPosition.value = refContainerRoot.value.getBoundingClientRect()
  forceMultiline.value = true
}
const disableMultiline = () => {
  forceMultiline.value = false
}
const toggleMultiline = () => {
  if (!multiLine.value) {
    enableMultiline()
  } else {
    disableMultiline()
  }
}

onMounted(() => {
  forceMultiline.value = false
  if (
    props.autoMultiline &&
    (c.isEquation(props.value, props.variables) || `${props.value || ''}`.includes('\n'))
  )
    enableMultiline()
  else if (props.multiLine) enableMultiline()
})

const showAbs = computed(
  () =>
    forceMultiline.value &&
    !props.multiLine &&
    originalPosition.value &&
    showCalcPocket.value &&
    !props.forceInline
)
const positionClass = computed(() => (showAbs.value ? `absolute z-[10000] shadow-md` : 'relative'))
const widthHeightClass = computed(
  () =>
    `${props.width ? `w-[${props.width}px]` : ''} ${props.height ? `h-[${props.height}px]` : ''}`
)
const widthHeightStyle = computed(() => ({
  ...(props.height ? { minHeight: `${props.height}px` } : {}),
  ...(props.width ? { minWidth: `${props.width}px` } : {})
}))
const positionStyle = computed(() => {
  if (!originalPosition.value?.width) return ''

  return showAbs.value
    ? {
        top: `${Math.max(0, originalPosition.value.top).toFixed(0)}px`,
        left: `${Math.max(0, originalPosition.value.left).toFixed(0)}px`,
        minWidth: `${Math.max(0, Math.max(originalPosition.value.width, 200)).toFixed(0)}px`
      }
    : ''
})
watch(showAbs, () => {
  // Whenever theres a change we need to reset innerHTML
  setTimeout(() => {
    updateHtml()
  })
})

const seeAllDims = ref(false)

const format = (value, measure) => {
  if (measure === 'ft') return c.format(value, 'imperial')

  return c.format(value, 'number')
}
</script>

<template>
  <div
    data-component="calculator"
    ref="refContainerRoot"
    :class="[
      {
        'rounded-sm bg-blue-print-500/50 min-w-40 min-h-4 flex justify-center items-center text-blue-print px-1 py-0.5 gap-1':
          showAbs
      }
    ]"
  >
    <template v-if="showAbs">
      <font-awesome-icon icon="calculator" size="xs" />
      <font-awesome-icon icon="arrow-up-right" size="xs" />
    </template>
    <Portal :disabled="!showAbs" :class="positionClass">
      <div
        data-component="calculator"
        :style="positionStyle"
        :class="[
          positionClass,
          'transition-all will-change-transform',
          {
            relative: !forceMultiline || props.multiLine
          }
        ]"
        :data-id="rand"
        ref="refRootEl"
      >
        <div
          ref="editableDiv"
          :contenteditable="editable"
          :class="[
            'min-h-4',
            'inline-calculator-container tabular-nums rounded-sm',
            'border-blue-print border-2',
            '!whitespace-break-spaces',
            'lining-nums tabular-nums slashed-zero leading-1',
            ' max-w-[400px]',
            '!bg-white',
            {
              '!px-1 !py-1.5 line-clamp-none !text-nowrap !whitespace-nowrap max-w-fit overflow-x-auto w-fit':
                !multiLine,
              '!px-2 !py-2 !min-w-40 !min-h-40 !h-fit !max-h-[400px] !overflow-y-scroll !text-left':
                multiLine,
              '!border-l-[20px]': showCalcPocket,
              'user-select-all pointer-events-all outline-none bg-flame-white': focused,
              '!w-full !min-w-full': !props.fitContent,
              '!bg-white !pr-4': focused
            },
            widthHeightClass,
            $attrs.class
          ]"
          :style="widthHeightStyle"
          v-bind="$attrs"
          @focusin="(e) => handleFocusIn(e)"
          @input="(e) => handleInput(e)"
          @keydown="(e) => handleKeydown(e)"
          @keyup="(e) => handleKeyup(e)"
          @click.stop.prevent="(e) => handleClick(e)"
          @mousedown="(e) => handleMouseDown(e)"
          @mouseup="(e) => handleMouseUp(e)"
          spellcheck="false"
          autocorrect="false"
          autocomplete="false"
          autocapitalize="false"
          style="
            -webkit-user-select: text;
            user-select: text;
            -webkit-text-security: none;
            spellcheck: false;
            -webkit-user-modify: read-write-plaintext-only;
          "
          tabindex="0"
        ></div>
        <div class="z-10 transition !min-w-0 !w-[20px] absolute top-0 left-0" v-if="showCalcPocket">
          <div class="flex flex-col px-1 py-0.5 gap-0.5 justify-start items-center">
            <div
              :class="[
                'h-fit w-fit flex flex-col justify-start items-center',
                {
                  ' mb-2': multiLine,
                  ' mb-0.5': !multiLine
                }
              ]"
              v-tooltip="
                multiLine ? 'Minimize to single line' : 'Expand calculator for more room...'
              "
            >
              <Btn
                size="sm"
                unstyled
                :class="pocketBtnClass"
                @click="
                  () => {
                    toggleMultiline()
                  }
                "
              >
                <!--          <font-awesome-icon icon="arrow-up-right-and-arrow-down-left-from-center"-->
                <!--                             size="xs"-->
                <!--                             class="fa-flip-horizontal" />-->
                <font-awesome-icon
                  :icon="multiLine ? 'compress' : 'expand'"
                  size="sm"
                  class="fa-flip-horizontal"
                />
              </Btn>
            </div>

            <slot name="calculator"></slot>

            <template v-if="multiLine && numeric">
              <Btn
                size="sm"
                unstyled
                :class="pocketBtnClass"
                @click="
                  () => {
                    injectValue(' + ')
                  }
                "
              >
                <font-awesome-icon icon="fas fa-plus" size="sm" />
              </Btn>

              <Btn
                size="sm"
                unstyled
                :class="pocketBtnClass"
                @click="
                  () => {
                    injectValue(' - ')
                  }
                "
              >
                <font-awesome-icon icon="fas fa-minus" size="sm" />
              </Btn>

              <Btn
                size="sm"
                unstyled
                :class="pocketBtnClass"
                @click="
                  () => {
                    injectValue(' * ')
                  }
                "
              >
                <font-awesome-icon icon="fas fa-times" size="sm" />
              </Btn>

              <Btn
                size="sm"
                unstyled
                :class="pocketBtnClass"
                @click="
                  () => {
                    injectValue(' / ')
                  }
                "
              >
                <font-awesome-icon icon="fas fa-divide" size="sm" />
              </Btn>
            </template>

            <div
              v-tooltip="'See more calculator options, like functions and available variables...'"
              :class="['h-fit w-fit flex flex-col justify-start items-center  mt-1']"
              v-if="numeric"
            >
              <Btn size="sm" unstyled :class="pocketBtnClass" :action="() => optionsDrop.toggle()">
                <font-awesome-icon icon="ellipsis" size="sm" />
              </Btn>
            </div>
          </div>
        </div>

        <Drop :data-id="rand" ref="optionsDrop" :fixTo="editableDiv" :key="multiLine">
          <div class="flex flex-col gap-4" :data-id="rand" v-if="!seeAllDims">
            <div class="flex justify-between w-full items-center">
              <div class="flex gap-1">
                <Btn
                  size="sm"
                  unstyled
                  :class="dropBtnClass"
                  @click="
                    () => {
                      injectValue(' + ')
                    }
                  "
                >
                  <font-awesome-icon icon="fas fa-plus" size="sm" />
                </Btn>

                <Btn
                  size="sm"
                  unstyled
                  :class="dropBtnClass"
                  @click="
                    () => {
                      injectValue(' - ')
                    }
                  "
                >
                  <font-awesome-icon icon="fas fa-minus" size="sm" />
                </Btn>

                <Btn
                  size="sm"
                  unstyled
                  :class="dropBtnClass"
                  @click="
                    () => {
                      injectValue(' * ')
                    }
                  "
                >
                  <font-awesome-icon icon="fas fa-times" size="sm" />
                </Btn>

                <Btn
                  size="sm"
                  unstyled
                  :class="dropBtnClass"
                  @click="
                    () => {
                      injectValue(' / ')
                    }
                  "
                >
                  <font-awesome-icon icon="fas fa-divide" size="sm" />
                </Btn>

                <Btn
                  size="sm"
                  unstyled
                  :class="dropBtnClass"
                  @click="
                    () => {
                      injectValue(' ( ')
                    }
                  "
                >
                  <font-awesome-icon icon="fas fa-bracket-round" size="sm" />
                </Btn>

                <Btn
                  size="sm"
                  unstyled
                  :class="dropBtnClass"
                  @click="
                    () => {
                      injectValue(' ) ')
                    }
                  "
                >
                  <font-awesome-icon icon="fas fa-bracket-round-right" size="sm" />
                </Btn>
              </div>

              <div class="flex gap-1">
                <Btn
                  v-if="!props.multiLine"
                  link
                  rounded
                  @click="
                    () => {
                      toggleMultiline()
                    }
                  "
                >
                  <font-awesome-icon
                    :icon="multiLine ? 'compress' : 'expand'"
                    size="sm"
                    class="fa-flip-horizontal !text-white"
                  />
                </Btn>

                <Btn @click="optionsDrop.close()" rounded link
                  ><font-awesome-icon icon="times" class="!text-white" size="xl"
                /></Btn>
              </div>
            </div>

            <div>
              <span class="text-md font-medium text-white">Variables</span>
              <ScrollPanel class="max-h-[200px] min-h-[100px] overflow-y-scroll">
                <div class="flex flex-wrap gap-1 max-w-[300px]">
                  <template v-if="!Object.keys(variables ?? {}).length">
                    <span class="text-white/60 text-sm">No variables available</span>
                  </template>
                  <span
                    v-for="(def, abbr) in variables"
                    v-else
                    :key="abbr"
                    v-tooltip="`${variables[abbr].name}: ${variables[abbr].desc}`"
                  >
                    <Btn
                      size="xs"
                      unstyled
                      :class="dropBtnClass"
                      @click="
                        () => {
                          injectValue(` ${abbr} `)
                        }
                      "
                      ><font-awesome-icon icon="fas fa-circle" :style="`color: #${def.color}`" />{{
                        abbr
                      }}
                      • {{ format(def.value, def.measure) }} {{ def.measure || '' }}</Btn
                    >
                  </span>
                  <Btn size="xs" unstyled :class="dropBtnClass" @click="seeAllDims = true"
                    >See all...</Btn
                  >
                </div>
              </ScrollPanel>
            </div>

            <div>
              <span class="text-md font-medium text-white">Functions</span>
              <div class="flex flex-wrap gap-1 max-w-[300px]">
                <span v-for="name in calcFuncs" :key="name" v-tooltip="calcFuncDefs[name].desc">
                  <Btn
                    size="xs"
                    unstyled
                    @click="
                      () => {
                        injectValue(` ${name}() `)
                      }
                    "
                    :class="dropBtnClass"
                    >{{ name }}()</Btn
                  >
                </span>
              </div>
            </div>
          </div>
          <div v-else class="bg-surface-100 flex flex-col justify-start items-start gap-4 p-2">
            <Btn @click="seeAllDims = false" link class="!font-medium"
              ><font-awesome-icon icon="arrow-left" />Back</Btn
            >
            <DimensionSelector
              v-if="seeAllDims"
              :object="{}"
              :parentDimensions="variables"
              :value="value"
              @input="(abbr) => injectValue(` ${abbr} `)"
            />
          </div>
        </Drop>
      </div>
    </Portal>
  </div>
</template>

<style lang="scss" rel="stylesheet/scss">
.inline-calculator-container {
  $bolsterBlue: $blue-print;
  /* Your styles here */
  overflow: hidden;
  z-index: 1;

  padding: 0.15em 0.15em;
  min-width: 3em;

  modifier {
    color: #360268;
  }
  parenthesis {
    color: #cc7833;
  }
  time {
    white-space: nowrap;

    color: darken($green-400, 5%);

    background: rgba($green-400, 0.5);
    border-radius: 5px;
    padding: 0 0.25em 0 0.15em;

    number {
      color: darken($green-400, 10%);
    }
    comments {
      color: darken($green-400, 10%);
    }
  }
  imperial {
    white-space: nowrap;

    color: darken($warning, 35%);

    background: lighten($warning, 15%);
    border-radius: 5px;
    padding: 0 0.25em 0 0.2em;

    number {
      white-space: nowrap;
      color: darken($warning, 45%);
    }
    comments {
      color: darken($warning, 35%);
    }
  }
  variable {
    white-space: nowrap;
    //background: lighten($blue-print-700, 10%);
    color: $purple-500;
    font-weight: 500;
  }
  number {
    color: #0b4bff;
  }
  comments {
    color: #797979;
  }
  newline {
    color: #a5c261;
  }
  div {
    display: inline;
  }

  tag {
    color: blue;
    /* Your styles for the <tag> element */
  }
}
</style>
