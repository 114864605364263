<script setup>
import { watch, ref, computed, onMounted, nextTick, defineExpose, onUnmounted } from 'vue'
import eventBus from '@/eventBus.js'

const props = defineProps({
  value: {
    default: ''
  },
  placeholder: {
    default: 'Enter a value'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  tooltip: {
    default: false
  },
  classes: {
    type: String
  },
  focusClasses: {
    type: String
  },
  styles: {
    type: String
  },
  multiline: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'input',
  'blur',
  'focusout',
  'focusin',
  'keydown',
  'keyup',
  'mouseup',
  'dblclick',
  'click',
  'tab',
  'enter'
])

const propValue = computed(() => props.value)
const rawValue = ref(props.value)
const focused = ref(false)
const editableDiv = ref(null)
const rawTrimmed = computed(() => `${rawValue.value || ''}`.trim())

const isNumeric = false

const sameAsOriginal = (value, original = props.value) => {
  if (!isNumeric.value && `${value}` === `${original}`) return true

  if (isNumeric.value && c.eq(value, original)) return true

  return false
}

const doEmit = () => {
  const deformatted = !props.multiline
    ? String(rawValue.value)
        .trim()
        .replace(/[\r\n]/g, '')
    : rawValue.value
  if (sameAsOriginal(deformatted)) return

  emit('input', deformatted)
}

const handleFocusIn = (e) => {
  focused.value = true
  emit('focusin', e)
}

const handleBlur = () => {
  if (editableDiv.value) rawValue.value = editableDiv.value.innerText
  doEmit()
  focused.value = false
  emit('blur', rawValue.value)
  emit('focusout')
}

const handleInput = (event) => {
  if (event.inputType === 'insertParagraph') {
    emit('enter', editableDiv.value.innerText)
    if (!props.multiline) {
      handleBlur()
      return event.preventDefault()
    }
  }
  // if (event.key === 'Tab') {
  //   emit('tab')
  // }
  rawValue.value = editableDiv.value.innerText // optimistic
}

const handleClick = async () => {
  if (focused.value || props.disabled) return
  focused.value = true
  await nextTick()
  editableDiv.value.innerText = rawValue.value
  editableDiv.value.focus()
}

watch(propValue, (newVal) => {
  rawValue.value = newVal
})
watch(rawValue, (newVal) => {
  if (!focused.value && editableDiv.value) {
    editableDiv.value.innerText = newVal
  }
})

const box = ref({})
const refPlaceholder = ref(null)
onMounted(() => {
  box.value = refPlaceholder.value?.getBoundingClientRect() ?? {}
})

const focus = () => {
  handleClick()
}

const blur = () => {
  try {
    handleBlur()
  } catch (e) {} // eslint-disable-line
}

const editableStyle = computed(() => [
  [
    'absolute inset-0',
    'py-1 px-0.5',
    'will-change-height w-full',
    {
      '!text-surface-400': !rawTrimmed.value
    },
    {
      'whitespace-nowrap leading-tight !h-fit !min-h-fit': !props.multiline,
      'whitespace-break-spaces leading-tight !h-fit !min-h-fit': props.multiline
    },
    focused.value ? props.focusClasses : ''
  ]
])

const containerStyle = ref({})

watch([editableDiv, rawValue, focused], async () => {
  if (!editableDiv.value) {
    await nextTick()
    if (!editableDiv.value) {
      return
    }
  }
  const bound = editableDiv.value.getBoundingClientRect()
  containerStyle.value = {
    height: `${bound.height}px`,
    minHeight: `fit-content`
  }
})

const handleForceBlur = () => {
  if (focused.value) handleBlur()
}

// Force blur before leaving
onMounted(() => {
  eventBus.$on('blurAllFields', handleForceBlur)
})
onUnmounted(() => {
  eventBus.$off('blurAllFields', handleForceBlur)
})

defineExpose({ focus, blur, refPlaceholder })
</script>

<template>
  <div
    @click.stop.prevent.capture="(e) => handleClick(e)"
    :style="containerStyle"
    :class="[
      'string-field-reset',
      'relative',
      'transition-all',
      'border-2',
      'rounded-sm',
      'leading-tight',
      'line-clamp-4',
      'min-w-12 max-w-full',
      'flex justify-stretch items-stretch',
      {
        'pointer-events-none': disabled,
        'text-surface-400 border-surface-300 border-dashed': !rawTrimmed && !focused,
        'border-transparent': rawTrimmed && !focused,
        '!border-blue-print': focused,
        ' z-[10000]': focused,
        '!overflow-hidden': !focused,
        'hover:border-blue-print hover:border-solid cursor-text': !disabled && !focused
      },
      classes
    ]"
  >
    <div
      ref="refPlaceholder"
      v-tooltip="tooltip"
      style="outline: none !important"
      :contenteditable="false"
      :class="[editableStyle, { 'opacity-0': focused }]"
      v-text="rawValue"
    ></div>
    <div
      v-if="focused"
      ref="editableDiv"
      contenteditable="true"
      style="outline: none !important"
      :class="editableStyle"
      @focusin="(e) => handleFocusIn(e)"
      @focusout="(e) => handleBlur(e)"
      @input="(e) => handleInput(e)"
      @beforeInput="(e) => $emit('beforeInput', e)"
      @keydown.stop="(e) => $emit('keydown', e)"
      @keypress.stop="(e) => $emit('keydown', e)"
      @keyup.stop="(e) => $emit('keyup', e)"
      @dblclick="(e) => $emit('dblclick', e)"
      @click="(e) => $emit('click', e)"
      @mousedown="(e) => $emit('mousedown', e)"
      @mouseup="(e) => $emit('mouseup', e)"
      autocomplete="on"
      autocorrect="on"
      autocapitalize="on"
      spellcheck="false"
      tabindex
    ></div>

    <!-- for displaying actual placholder: -->
    <div
      :style="styles"
      :class="[
        'absolute inset-0 flex justify-start items-center pointer-events-none z-[1] opacity-60 whitespace-nowrap',
        editableStyle
      ]"
      v-if="!rawTrimmed"
    >
      {{ placeholder }}
    </div>

    <!-- for holding space: -->
    <div
      :style="styles"
      :class="[
        {
          'opacity-0': focused.value || !rawTrimmed
        },
        '!relative inset-0 flex justify-start items-center pointer-events-none z-[10001] opacity-0 select-none',
        editableStyle
      ]"
    >
      {{ rawTrimmed ? rawValue : placeholder }}
    </div>
  </div>
</template>

<style lang="scss" rel="stylesheet/scss">
.string-field-reset {
  display: flex;
  outline: none !important;

  font-family:
    Cera Pro,
    sans-serif !important;
}
</style>
