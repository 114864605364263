import { ref, toRaw, watch, onMounted, nextTick, computed } from 'vue'
import { useStore } from 'vuex'

export const useHistory = ({
  takeoff,
  importData,
  exportData,
  getExportTakeoff,
  deselectAll,
  emit,
  triggerAudit
}) => {
  const history = ref([])
  const redoList = ref([])
  const $store = useStore()
  const isUndoingOrRedoing = ref(false)
  const initialized = ref(false)

  const getEntry = (takeoff) => ({
    takeoff,
    time: Date.now(),
    user: $store.state.session.authorizedUser
  })

  // Save initial state
  onMounted(() => {
    const exp = getExportTakeoff(takeoff)
    addToHistory(exp)
    initialized.value = true
  })

  const addToHistory = (to, explicit = true) => {
    console.log(to)
    const takeoff = typeof to === 'string' ? to : JSON.stringify(to)
    // Don't add to history if we're in the middle of an undo/redo
    if (isUndoingOrRedoing.value) return

    if (initialized.value) {
      emit('takeoff-changed', takeoff)
      if (explicit) emit('takeoff-changed-explicit', takeoff)
    }

    const entry = getEntry(takeoff)

    // make sure it isn't a duplicate entry
    if (history.value.length && entry.takeoff === history.value[history.value.length - 1].takeoff) {
      return
    }

    if (explicit) {
      historyPush(entry)
      // Clear redo list when new action is performed
      redoList.value = []

      // Limit history size
      if (history.value.length > 100) historyShift()
    }
  }

  const historyPop = () => {
    const hist = [...history.value]
    const to = hist.pop()
    history.value = hist
    return to
  }

  const historyShift = () => {
    const hist = [...history.value]
    const to = hist.shift()
    history.value = hist
    return to
  }

  const historyPush = (to) => {
    const hist = [...history.value]
    hist.push(to)
    history.value = hist
  }

  const redoPush = (to) => {
    const hist = [...redoList.value]
    hist.push(to)
    redoList.value = hist
  }

  const redoPop = () => {
    const hist = [...redoList.value]
    const to = hist.pop()
    redoList.value = hist
    return to
  }

  const undo = () => {
    deselectAll()
    if (history.value.length <= 1) return // Keep at least initial state

    const previousState = historyPop()

    if (previousState) {
      isUndoingOrRedoing.value = true
      redoPush(previousState)
      importData(previousState.takeoff)
      c.throttle(() => {
        isUndoingOrRedoing.value = false
      })
    }
    triggerAudit()
  }

  const redo = () => {
    deselectAll()
    if (redoList.value.length === 0) return

    const nextState = redoPop()

    if (nextState) {
      isUndoingOrRedoing.value = true
      historyPush(nextState) // back to the undo pile
      importData(nextState.takeoff)
      c.throttle(() => {
        isUndoingOrRedoing.value = false
      })
    }
    triggerAudit()
  }

  const canUndo = computed(() => history.value.length > 1)
  const canRedo = computed(() => redoList.value.length > 0)

  return {
    initialized,
    undo,
    redo,
    history,
    canUndo,
    canRedo,
    addToHistory
  }
}
