<script setup>
import { ref, computed, watch } from 'vue'
import CalculatorField from '@/components/ui/Calculator/CalculatorFormatted.vue'
import MiniModal from '@/components/modals/MiniModal.vue'
import Choose from '@/components/ui/Choose/Choose.vue'
import { useStore } from 'vuex'

const $store = useStore()
const refModal = ref(null)

const distance = ref('0')
const defaultMetric = computed(() => $store.getters.companyDefaultMetric)
const unit = ref(defaultMetric.value ? 'm' : 'ft')

const resolver = ref(null)
const resolve = (val) => {
  resolver.value?.(val)
  resolver.value = null
  refModal.value?.close()
}

const cancel = () => {
  resolve(false)
}

const accept = () => {
  resolve({
    distance: distance.value,
    unit: unit.value
  })
}

const handleClose = () => {
  return c.throttle(() => {
    if (resolver.value) return cancel()
    return true
  })
}

const requestScale = () => {
  const { promise: p, resolve: r } = c.getDeferredPromise()

  resolver.value = r
  refModal.value?.open()

  return p
}

defineExpose({
  requestScale
})
</script>

<template>
  <MiniModal ref="refModal" @close="handleClose">
    <template #header> Scale your file to the canvas </template>
    <div class="flex flex-col gap-6">
      <div class="text-base text-surface-700">
        For the length you just drew, set the physical length that it should be so we can scale your
        file:
      </div>

      <div class="flex justify-center items-center w-full gap-2 text-2xl">
        <font-awesome-icon icon="ruler" class="pr-4" />
        <CalculatorField
          :width="80"
          placeholder="Physical length"
          :class="[
            '!min-w-[80px] !min-h-[40px] !border-2 text-right',
            {
              '!border-blue-print-400 ': !distance,
              '!border-blue-print-400/20 ': distance
            }
          ]"
          classes="h-full min-w-40"
          :format="unit === 'ft' ? 'imperial' : 'number'"
          v-model="distance"
        />
        <Choose
          v-model="unit"
          :return-array="false"
          :filters="{
            dimension_measure_type: 'length'
          }"
          :sort="[['unit_of_measure_is_metric', defaultMetric ? 'desc' : 'asc']]"
          schema="unit_of_measure:unit_of_measure_id"
        >
          <template #default="{ text }">
            <div class="p-2 font-medium cursor-pointer">
              {{ text }}
              <font-awesome-icon icon="chevron-down" />
            </div>
          </template>
        </Choose>
      </div>

      <div class="flex gap-1 justify-end items-center">
        <Btn :action="cancel" severity="tertiary" size="lg">Cancel</Btn>
        <Btn :action="accept" hotkey="enter" size="lg">Accept</Btn>
      </div>
    </div>
  </MiniModal>
</template>
