// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import CostMatrix from './CostMatrix'
import CostType, { defaultVariationItem } from './CostType'
import Item from './Item'
import _ from '../Helpers'
import Normalize from '../NormalizeUtilities'
import AutoCost from '../AutoCost.js'
import { v4 } from 'uuid'
const { extractDescendants, getNormalizedRootRefId } = Normalize

const { getAllVariationPossibilities } = CostType

/**
 * return CostItem.getIncludedPrice(this.target, this.norm, this.possibleDimensions ?? {}, Auditing.cascadeDependencies)
 * @param object
 * @param norm
 * @param possibleDimensions
 * @param cascade
 * @returns {{default: number, save: boolean, type: string}|*|{default: number, save: boolean, type: string}|(function(*): *)|number|string}
 */
const getIncludedPrice = (object, norm, possibleDimensions, cascade) => {
  const modified = {
    ...object,
    cost_item_is_included: 1,
    cost_item_qty_net_base: object.cost_item_qty_net_base_original
  }

  const [{ [object.refId]: audited }] = cascade(
    {
      ...norm,
      [object.refId]: modified
    },
    object.refId,
    {},
    possibleDimensions
  )

  return audited.cost_item_price_net
}

const getLockedSelections = (object) => {
  const variationTypes = object.oVariations?.variationTypes || []
  const selectedVariations = object.oVariations?.selectedItem?.variations
  const res = {}

  for (const vtype in variationTypes) {
    if (vtype.locked) {
      res[vtype.name] = selectedVariations[vtype.name]
    }
  }

  return _.imm(res)
}

const findBestVariation = (object, variationType, variationChoice) => {
  const locked = getLockedSelections(object)

  const variationTypes = object.oVariations?.variationTypes || []
  const existingChoices = object.oVariations?.selectedItem?.variations
  const index = variationTypes.findIndex((vt) => vt.name === variationType)

  const fixed = variationTypes
    .slice(0, index)
    .filter((vtype) => vtype?.name in (existingChoices ?? {}))
    .map((vtype) => vtype.name)
  const should = variationTypes
    .slice(index + 1)
    .filter((vtype) => vtype?.name in (existingChoices ?? {}))
    .map((vtype) => vtype.name)

  // Get omnly items that match with the locked variation type selection
  // since no other items are possible
  const omitLocked = object.oVariations?.items.filter((vitem) => {
    return Object.keys(locked).every(
      (lockedType) => vitem.variations[lockedType] === locked[lockedType]
    )
  })

  // First look for exact match for required
  const withRequested = omitLocked.filter((vitem) => {
    return (
      String(vitem.variations[variationType]).toLowerCase() ===
      String(variationChoice).toLowerCase()
    )
  })

  const withAllRequired = withRequested.filter((vitem) => {
    return fixed.every(
      (vtype) =>
        String(vitem.variations[vtype]).toLowerCase() ===
        String(existingChoices[vtype]).toLowerCase()
    )
  })

  const matchAllTypes = withAllRequired.filter((vitem) => {
    return should.every(
      (vtype) =>
        String(vitem.variations[vtype]).toLowerCase() ===
        String(existingChoices[vtype]).toLowerCase()
    )
  })

  const withShould = withAllRequired.filter((vitem) => {
    return should.some(
      (vtype) =>
        String(vitem.variations[vtype]).toLowerCase() ===
        String(existingChoices[vtype]).toLowerCase()
    )
  })

  const withRequestAndShould = withRequested.filter((vitem) => {
    return should.some(
      (vtype) =>
        String(vitem.variations[vtype]).toLowerCase() ===
        String(existingChoices[vtype]).toLowerCase()
    )
  })

  const withRequestAndSomeRequired = withRequested.filter((vitem) => {
    return fixed.some(
      (vtype) =>
        String(vitem.variations[vtype]).toLowerCase() ===
        String(existingChoices[vtype]).toLowerCase()
    )
  })

  const withRequestSomeRequiredAndSomeShould = withRequestAndSomeRequired.filter((vitem) => {
    return should.some(
      (vtype) =>
        String(vitem.variations[vtype]).toLowerCase() ===
        String(existingChoices[vtype]).toLowerCase()
    )
  })

  if (matchAllTypes.length) {
    return _.imm(matchAllTypes[0])
  }

  if (withShould.length) {
    return _.imm(withShould[0])
  }

  if (withAllRequired.length) {
    return _.imm(withAllRequired[0])
  }

  if (withRequestSomeRequiredAndSomeShould.length) {
    return _.imm(withRequestSomeRequiredAndSomeShould[0])
  }

  if (withRequestAndSomeRequired.length) {
    return _.imm(withRequestAndSomeRequired[0])
  }

  if (withRequestAndShould.length) {
    return _.imm(withRequestAndShould[0])
  }

  if (withRequested.length) {
    return _.imm(withRequested[0])
  }

  if (omitLocked.length) {
    return _.imm(omitLocked[0])
  }

  return null
}

const taxSettingsDefaulter = (embue = {}) => {
  const defaultTax = {
    name: 'Sales tax',
    on: 'all', // all | cost | profit
    pcnt: 0
  }

  const defaultTaxSettings = {
    // Sales taxes on subcontracted labor costs
    // SubLaborTaxes
    slt: [],
    // Sales taxes on in house labor costs
    //InHouseLaborTaxes
    ihlt: [],
    // Sales taxes for in materials costs
    // MaterialsTaxes
    mt: [],
    // Simple sales taxes applied to entire price
    // CombinedTaxes
    ct: []
  }

  const defaulted = Object.keys(defaultTaxSettings).reduce((acc, category) => {
    const taxCategory = embue && category in embue ? embue[category] || [] : []

    const taxes = taxCategory.map((tax) => ({
      ...defaultTax,
      ...tax
    }))

    return {
      ...acc,
      [category]: taxes
    }
  }, {})

  // const hasValues = Object.keys(defaulted)
  //   .reduce((acc, cat) => acc + defaulted[cat].length, 0);
  //
  // console.log('hasValues, item type - skipping?', JSON.stringify(item), (!hasValues && item.type === 'cost_item'));
  // if (!hasValues && item.type === 'cost_item') return {};

  return defaulted
}

const getItemAssigneeFormatFromAssignee = (assignee) => {
  const [type, id] = assignee.split(':')
  return {
    item_assignee_object_id: id,
    item_assignee_object_type: type
  }
}
const getItemAssigneeFormatFromAssignees = (assignees) =>
  _.makeArray(assignees).map((assignee) => getItemAssigneeFormatFromAssignee(assignee))

const getAssigneeIdsFromItemAssignees = (itemAssignees, norm) => {
  // map assignees to change set for items
  const changes = {}
  for (let i = 0; i < itemAssignees.length; i++) {
    const itemAssignee = itemAssignees[i]
    const { item_id, item_assignee_object_id: id, item_assignee_object_type: type } = itemAssignee

    let ref =
      (item_id in norm
        ? item_id
        : Object.values(norm).find((o) => o.item_id === itemAssignee.item_id)?.refId) ?? null
    if (!ref) continue

    if (!(ref in changes)) changes[ref] = {}

    changes[ref] = {
      assignee_ids: [..._.makeArray(changes[ref]?.assignee_ids ?? []), `${type}:${id}`]
    }
  }

  return changes
}

const validateAssignees = (itemAssignees, norm) => {
  return Object.keys(norm).reduce((acc, refId) => {
    const item = norm[refId]

    if (item.type !== 'cost_item' || !item.assignee_ids || item.assignee_ids.length === 0)
      return acc

    const assigneeIds = item.assignee_ids.map((assigneeId) => {
      const [, id] = assigneeId.split(':')
      return id
    })
    const existingAssignees = itemAssignees.find((assignee) =>
      assigneeIds.includes(assignee.item_assignee_object_id)
    )
    if (!existingAssignees) {
      return {
        ...acc,
        [refId]: {
          assignee_ids: []
        }
      }
    }
    return acc
  }, {})
}

let assigneeRefIdsToFlush = []
const flushAssignees = ({ dispatch, norm, object }) => {
  const refs = [...assigneeRefIdsToFlush]
  assigneeRefIdsToFlush = []
  let assignments = []
  for (let i = 0; i < refs.length; i++) {
    const refId = refs[i]
    const assigneeIds = norm[refId].assignee_ids
    if (!assigneeIds) {
      assignments = [
        ...assignments,
        {
          item_id: refId,
          item_assignee_object_id: null
        }
      ]
      continue
    }
    const itemAssignees = getItemAssigneeFormatFromAssignees(assigneeIds).map((itemAssignee) => ({
      ...itemAssignee,
      item_id: norm[refId].item_id
    }))

    assignments = [...assignments, ...itemAssignees]
  }

  return dispatch(
    'Assignee/reassign',
    {
      assignments,
      quoteId: object.quote_id
    },
    { root: true }
  )
}

export default {
  type: 'cost_item',

  possibleStatuses: ['a', 'h'],

  cachedTypes: ['cost_type'],

  findBestVariation,
  getLockedSelections,

  fields: {
    ...Item.fields,

    cost_type_materials_purchase_price_net: CostType.fields.cost_type_materials_purchase_price_net,
    oMeta: CostType.fields.oMeta,
    cost_type_is_addon_group: CostType.fields.cost_type_is_addon_group,
    aoVariationItems: CostType.fields.aoVariationItems,
    asVariationTypes: CostType.fields.asVariationTypes,
    variation_parent_cost_type_id: CostType.fields.variation_parent_cost_type_id,
    cost_type_is_variation_parent: CostType.fields.cost_type_is_variation_parent,
    oVariations: CostType.fields.oVariations,
    cost_type_is_variation_none_allowed: CostType.fields.cost_type_is_variation_none_allowed,
    variation_parent_cost_type_name: CostType.fields.variation_parent_cost_type_name,
    oVariationTraits: CostType.fields.oVariationTraits,
    oVariationLinks: CostType.fields.oVariationLinks,
    cost_type_weight_per_purchase_unit_net: CostType.fields.cost_type_weight_per_purchase_unit_net,
    weight_unit_of_measure_id: CostType.fields.weight_unit_of_measure_id,
    weight_unit_of_measure_abbr: CostType.fields.weight_unit_of_measure_abbr,
    cost_type_is_fee: CostType.fields.cost_type_is_fee,
    oTaxSettings: CostType.fields.oTaxSettings,
    tax_id: CostType.fields.tax_id,
    aoAddons: CostType.fields.aoAddons,
    aoPath: CostType.fields.aoPath,
    labor_type_is_indexed: CostType.fields.labor_type_is_indexed,
    cost_type_is_indexed: CostType.fields.cost_type_is_indexed,
    cost_type_sku: CostType.fields.cost_type_sku,
    oInputRequired: CostType.fields.oInputRequired,
    oViewOptions: CostType.fields.oViewOptions,
    cost_type_hash: CostType.fields.cost_type_hash,
    cost_type_current_hash: CostType.fields.cost_type_current_hash,
    cost_type_is_variance: CostType.fields.cost_type_is_variance,
    cost_type_is_task: CostType.fields.cost_type_is_task,
    unit_of_measure_id: CostType.fields.unit_of_measure_id,
    stage_id: CostType.fields.stage_id,
    trade_type_id: CostType.fields.trade_type_id,
    purchase_unit_of_measure_id: CostType.fields.purchase_unit_of_measure_id,
    cost_type_is_subcontracted: CostType.fields.cost_type_is_subcontracted,
    cost_type_has_labor: CostType.fields.cost_type_has_labor,
    cost_type_has_materials: CostType.fields.cost_type_has_materials,
    cost_type_budget_code: CostType.fields.cost_type_budget_code,

    assignee_ids: {
      type: 'array',
      mapTo: 'assignee',
      save: false,
      trackChanges: false
    },
    selected_cost_type_id: {
      type: 'string',
      save: true
    },
    cost_type_materials_purchase_qty_per_unit: {
      type: 'float',
      save: true,
      trackChanges: true,
      defaultSetting: false,
      default: null
    },
    cost_item_is_materials_purchased: {
      type: 'int',
      save: true,
      trackChanges: true,
      default: 0,
      defaultSetting: false
    },
    cost_item_materials_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
      default: 'o'
    },
    cost_item_weight_net: {
      type: 'float',
      save: true,
      default: 0
    },
    purchase_unit_of_measure_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: false,
      default: null
    },
    purchase_unit_of_measure_abbr: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: false,
      default: null
    },
    unit_of_measure_abbr: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      default: 'each',
      defaultSetting: true
    },
    unit_of_measure_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Unit',
      default: 'Each',
      defaultSetting: true
    },
    dimension_measure_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      default: null, // length|area|volume|count
      title: 'Measure type',
      defaultSetting: true
    },
    cost_type_material_waste_factor_net: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: false,
      default: 0
    },

    /**
     * HOw many purchase_unit_of_measure_id (box, roll, package etc) do you need
     * to buy to make up cost_item_qty_net.
     */
    cost_item_schedule_start_date: {
      type: 'float',
      format: 'int',
      save: true
    },
    cost_item_schedule_end_date: {
      type: 'float',
      format: 'int',
      save: true
    },
    purchase_cost_item_qty_net_base: {
      type: 'float',
      save: true,
      trackChanges: true
    },
    purchase_cost_item_qty_net: {
      type: 'float',
      save: true,
      trackChanges: true
    },
    item_id: {
      save: true,
      type: 'string',
      reload: true,
      trackChanges: false
    },
    file_ids: {
      type: 'array',
      default: () => [],
      save: true,
      trackChanges: true,
      normalize: false,
      set: (value) => {
        return Array.isArray(value) ? value : [value]
      },
      get: (value) => {
        return Array.isArray(value) ? value : [value]
      },
      isChanged: (oldSet = [], newSet = []) => {
        const newPared = (newSet || []).map((id) => String(id))
        const oldPared = (oldSet || []).map((id) => String(id))
        return !_.deepEquals(oldPared, newPared)
      }
    },
    sheet_name: {
      type: 'string',
      mapTo: false
    },
    sheet_total_price: {
      type: 'number',
      format: 'currency'
    },
    sheet_total_c_per_u: {
      type: 'number',
      format: 'currency',
      mapTo: false
    },
    sheet_material_cost_per_unit: {
      type: 'number',
      format: 'currency',
      mapTo: false
    },
    sheet_net_markup: {
      type: 'number',
      mapTo: false
    },
    sheet_price_per_unit: {
      type: 'number',
      format: 'currency',
      mapTo: false
    },
    sheet_total_cost: {
      type: 'number',
      format: 'currency',
      mapTo: false
    },
    sheet_cost_type_hours: {
      type: 'float',
      filter: true,
      format: 'hours',
      mapTo: false
    },
    sheet_total_profit: {
      type: 'number',
      format: 'currency',
      mapTo: false
    },
    sheet_quantity_net_base_fixed: {
      type: 'number',
      format: 'currency',
      mapTo: false
    },
    cost_type_qty_equation: {
      type: 'string',
      filter: false,
      trackChanges: true,
      title: 'Quantity equation',
      save: true,
      default: ''
    },
    item_transactions_count: {
      type: 'int',
      default: 0,
      save: false,
      reload: true
    },
    cost_type_minimum_qty_net: {
      type: 'float',
      default: null
    },
    cost_type_is_highlighted_for_production: {
      type: 'int',
      filter: true,
      trackChanges: true,
      save: true,
      default: 0
    },
    cost_type_is_highlighted_for_estimating: {
      type: 'int',
      filter: true,
      trackChanges: true,
      save: true,
      default: 0
    },
    cost_type_minimum_price_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      title: 'Minimum price'
    },
    cost_type_static_materials_cost_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      title: 'Static base cost'
    },
    cost_type_static_labor_cost_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      title: 'Static base cost'
    },
    cost_type_minimum_labor_cost_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      title: 'Minimum labor cost',
      save: true,
      trackChanges: true
    },
    cost_type_minimum_materials_cost_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      title: 'Minimum materials cost'
    },

    /**
     * Changes appearance in the presentation
     * -1 === show in a bundle of other de-emphasized items
     * 0 === normal, show as item
     * +1 === show as item normally but with extra emphasis
     */
    cost_type_emphasis: {
      type: 'int',
      default: 0,
      filter: false,
      reload: false,
      save: true,
      trackChanges: true,
      autoPartialSave: true,
      defaultSetting: false
    },

    mod_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'mod'
    },
    mod_labor_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    // cost_item_paid_to_vendor_net: {
    //   type: 'float',
    //   save: true,
    //   trackChanges: true,
    // },
    // cost_item_unpaid_to_vendor_net: {
    //   type: 'float',
    //   save: true,
    //   trackChanges: true,
    // },
    item_vendor_unpaid_net: {
      type: 'float',
      trackChanges: true,
      save: true,
      reload: true
    },
    item_vendor_paid_net: {
      type: 'float',
      trackChanges: true,
      save: true,
      reload: true
    },
    cost_item_is_fully_paid: {
      type: 'int',
      default: 0,
      save: true,
      trackChanges: true
    },
    item_vendor_is_fully_paid: {
      type: 'int',
      default: 0,
      save: true,
      trackChanges: true
    },
    item_client_is_fully_paid: {
      type: 'int',
      default: 0,
      save: true,
      trackChanges: true
    },
    vendor_id: {
      type: 'string',
      filter: true,
      save: true,
      default: null,
      trackChanges: true,
      mapTo: 'vendor'
    },
    vendor_name: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    mod_materials_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    mod_equipment_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    cost_matrix_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'cost_matrix'
    },
    cost_matrix_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_matrix_time_created: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      reload: true,
      save: false
    },
    cost_matrix_use_company_markup: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_matrix_is_custom: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    parent_cost_type_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_name_full: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      title: 'Item notes'
    },
    cost_type_desc_full: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_production_notes: {
      title: 'Internal/production notes',
      type: 'string',
      save: true,
      trackChanges: true,
      default: ''
    },
    cost_type_time_created: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      reload: true,
      save: false
    },
    cost_type_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false
    },
    cost_type_hours_per_unit: {
      type: 'float',
      filter: true,
      format: 'hours',
      mapTo: false,
      title: 'Per-unit labor hours'
    },
    labor_type_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'labor_type',
      defaultSetting: true
    },
    cost_type_is_custom: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_time_last_modified: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      reload: true,
      save: false
    },
    labor_type_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: true
    },
    cost_matrix_is_restorable: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_is_child: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    labor_type_rate_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      default: 0,
      title: 'Labor rate per hour',
      defaultSetting: true
    },
    labor_type_callout_rate_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      default: 0,
      title: 'Labor rate per hour for subcontracted work',
      defaultSetting: true
    },
    cost_matrix_labor_cost_net_explicit: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false,
      default: null
    },
    cost_matrix_labor_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 0,
      title: 'Per-unit labor cost'
    },
    cost_matrix_materials_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 0,
      title: 'Per-unit materials cost'
    },
    cost_matrix_aggregate_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 0,
      title: 'Per-unit total cost'
    },
    company_default_markup: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      default: 1
    },
    cost_matrix_markup_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 0,
      title: 'Markup'
    },
    cost_matrix_rate_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 0,
      title: 'Per-unit price'
    },
    aoChildren: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: (child) => child.type
    },
    aoFiles: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: 'file',
      normalize: false,
      autoPartialSave: true,
      autoPartialSaveType: 'cost_type'
    },
    aoImages: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: 'file',
      deep: false,
      normalize: false,
      autoPartialSaveType: 'cost_type',
      default: () => []
    },
    cost_type_is_parent: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_item_price_net_undiscounted: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    cost_item_price_net_base: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    cost_item_price_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      title: 'Item price'
    },
    cost_item_link_qty: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      save: true,
      default: 1
    },
    cost_item_qty_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 1,
      title: 'Quantity'
    },
    cost_item_qty_net_base: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 1
    },
    cost_item_qty_net_base_original: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 1
    },
    cost_item_discount_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 0
    },
    cost_item_discount_net_base: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      default: 0
    },
    cost_item_discount_percentage: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
      default: 0
    },
    quantity_multiplier: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false,
      default: 1
    },
    cost_item_markup_percentage_adjustment: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      default: 0
    },
    cost_item_markup_net_adjustment: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      default: 0
    },
    cost_item_markup_net_adjusted: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      default: 0,
      title: 'Combined markup'
    },
    cost_item_price_net_base_adjustment: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      default: 0
    },
    cost_item_minimum_labor_net_adjustment: {
      type: 'float',
      default: 0
    },
    cost_item_minimum_materials_net_adjustment: {
      type: 'float',
      default: 0
    },
    cost_item_minimum_labor_net_adjustment_base: {
      type: 'float',
      default: 0
    },
    cost_item_minimum_materials_net_adjustment_base: {
      type: 'float',
      default: 0
    },
    cost_item_materials_cost_net_regular: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      default: 0
    },
    cost_item_labor_cost_net_regular: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      default: 0
    },
    cost_item_price_net_adjustment: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      default: 0,
      title: 'Price adjustment (from quote markup adj.)'
    },
    cost_item_is_optional: {
      type: 'int',
      default: 0
    },
    cost_item_total_net_base: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      default: 0
    },
    cost_item_total_net: {
      type: 'float',
      filter: false,
      format: false,
      mapTo: false,
      default: 0
    },
    cost_item_total_cost_net_base: {
      type: 'float',
      filter: false
    },
    cost_item_total_cost_net: {
      type: 'float',
      filter: false,
      title: 'Total combined item cost'
    },
    cost_item_profit_net: {
      type: 'float',
      filter: false
    },
    aoProperties: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false,
      deep: false,
      normalize: false,
      trackChanges: true,
      save: true,
      default: [],
      title: 'Item properties'
    },
    oUpdates: {
      type: 'object',
      filter: false,
      format: false,
      mapTo: false,
      save: false,
      trackChanges: false
    },
    stage_name: {
      type: 'string',
      save: true,
      defaultSetting: true
    },
    trade_type_name: {
      type: 'string',
      save: true,
      defaultSetting: true
    },
    aoStageTasks: {
      type: 'array',
      mapTo: 'task',
      trackChanges: true,
      reload: true,
      title: 'Item tasks'
    },
    cost_item_is_using_minimum_qty: {
      type: 'int',
      default: 0
    },
    asAssemblyPath: {
      type: 'array',
      default: [],
      save: true,
      trackChanges: false
    },
    cost_item_materials_cost_net: {
      type: 'float',
      title: 'Total item materials cost'
    },
    cost_item_materials_cost_net_base: {
      type: 'float'
    },
    cost_item_labor_cost_net_base: {
      type: 'float'
    },
    cost_item_labor_cost_net: {
      type: 'float',
      title: 'Total item labor cost'
    },
    cost_item_total_hours: {
      type: 'float'
    },
    cost_item_total_hours_base: {
      type: 'float',
      title: 'Item labor-hours',
      trackChanges: true,
      save: true
    },
    cost_item_regular_price_net_base: {
      type: 'float'
    },
    cost_item_regular_price_net: {
      type: 'float',
      title: 'Regular item price'
    },
    cost_item_is_complete_according_to_general: {
      type: 'int',
      title: 'Work completed?',
      filter: true,
      default: 0,
      mapTo: false
    },
    oSiblingsVariables: {
      type: 'object',
      save: false,
      trackChanges: false,
      filter: false,
      mapTo: false
    },
    /**
     * Status according to this company
     *
     * Can be null or 'p' pending
     * 'f' in progress
     * 'g' complete
     */
    item_company_status: {
      type: 'string',
      title: 'Work status?',
      filter: true,
      default: 'p'
    },
    /**
     * Status according to this company's vendor
     */
    item_vendor_status: {
      type: 'string',
      title: 'Work status?',
      filter: true,
      default: 'p'
    },
    /**
     * Status according to this company's client
     */
    item_client_status: {
      type: 'string',
      title: 'Work status?',
      filter: true,
      default: 'p'
    },
    cost_item_actual_materials_cost_net: {
      type: 'float',
      filter: true,
      title: 'Actual materials cost',
      default: null
    },
    cost_item_actual_total_hours: {
      type: 'float',
      filter: true,
      title: 'Actual total hours',
      default: null
    },
    cost_item_actual_labor_cost_net: {
      type: 'float',
      filter: true,
      title: 'Actual labor cost',
      format: 'currency',
      default: null
    },
    cost_item_actual_total_cost_net: {
      type: 'float',
      filter: true,
      trackChanges: true,
      save: true,
      title: 'Actual aggregate cost',
      format: 'currency',
      default: null
    },
    cost_item_actual_labor_cost_net_explicit: {
      type: 'float',
      filter: true,
      format: 'currency',
      trackChanges: true,
      save: true,
      default: null
    },
    cost_item_actual_materials_cost_net_explicit: {
      type: 'float',
      filter: true,
      format: 'currency',
      trackChanges: true,
      save: true,
      default: null
    },
    profit_tax_percentage: {
      type: 'float',
      save: true,
      trackChanges: true,
      default: 0,
      title: 'Profit tax percentage'
    },
    cost_tax_percentage: {
      type: 'float',
      save: true,
      trackChanges: true,
      default: null,
      title: 'Cost tax percentage'
    },
    cost_tax_percentage_explicit: {
      type: 'float',
      save: true,
      trackChanges: true,
      default: null,
      title: 'Sales tax percentage'
    },
    cost_item_cost_tax: {
      type: 'float',
      save: true,
      trackChanges: true,
      default: 0,
      title: 'Sales tax on costs'
    },
    cost_item_profit_tax: {
      type: 'float',
      save: true,
      trackChanges: true,
      default: 0,
      title: 'Sales tax on profit'
    },
    cost_item_tax: {
      type: 'float',
      save: true,
      trackChanges: true,
      default: 0,
      title: 'Combined sales tax'
    },
    cost_item_gross: {
      type: 'float',
      save: true,
      trackChanges: true,
      default: 0,
      title: 'Price (incl tax)'
    },
    oViewSettings: {
      type: 'object',
      filter: false,
      save: true,
      trackChanges: true,
      deep: false,
      title: 'View settings',
      // will inherit from parent assembly/quote, so if
      default: () => ({
        // showPrices: true,
        // showQuantities: true,
        // showCosts: false,
        // assemblyInitialState //
      })
    },
    cost_item_count_addons_available: {
      type: 'int',
      default: 0,
      trackChanges: false,
      reload: true,
      save: false,
      title: 'Has add-ons available'
    },
    cost_item_has_live_pricing: {
      type: 'int',
      default: 0,
      trackChanges: false,
      reload: true,
      save: false,
      title: 'is AutoCost item'
    },
    live_price_country: {
      type: 'string',
      default: null,
      trackChanges: false,
      reload: true,
      save: false,
      title: 'AutoCost country'
    },
    /**
     * If this item is ina  set of upgrades.
     * keep track of which item was the original
     * to see if an upgrade occurred
     * In format: `${type}:${id}`
     */
    upgradesOriginalKey: {
      type: 'string',
      default: 0,
      trackChanges: false,
      save: true
    },
    addon_is_upgraded: {
      type: 'int',
      default: 0,
      save: true,
      trackChanges: true
    },
    addon_is_saved: {
      type: 'int',
      default: 0,
      save: true,
      trackChanges: true,
      reload: true
    },
    addon_upgraded_by: {
      type: 'string'
    },
    addon_time_upgraded: {
      type: 'float',
      default: null
    },
    item_is_direct_pay: {
      type: 'int',
      default: 0
    },
    version: {
      type: 'string',
      default: '0',
      save: true,
      trackChanges: false
    },
    cost_item_show_itemized_prices: {
      type: 'int',
      default: 1
    },
    item_is_upgraded: {
      type: 'int',
      default: 0,
      trackChanges: false
    },
    asDimensionsLinked: {
      type: 'array',
      default: [],
      save: false,
      trackChanges: false
    },
    asDimensionsUsed: {
      type: 'array',
      default: [],
      save: false,
      trackChanges: false
    },
    item_count_upgrades: {
      type: 'int',
      default: 0,
      save: true,
      trackChanges: false
    },
    aoLocation: {
      type: 'array',
      trackChanges: false
    },
    /**
     * Calculate tax sums
     * individual based on the
     * individual tax type
     */
    oTaxSums: {
      type: 'object',
      default: () => {}
    },
    cost_item_is_included: {
      type: 'int',
      default: 1,
      save: true
    },
    cost_item_orig_qty_net: {
      type: 'float',
      default: 1,
      save: true
    },
    cost_item_orig_price_net: {
      type: 'float',
      default: 0,
      save: true
    },
    live_price_reference: {
      type: 'string',
      save: true,
      trackChanges: true,
      default: null
    },
    live_price_last_fetch: {
      type: 'string',
      save: true,
      trackChanges: true,
      default: null
    },
    live_price_online_stock: {
      type: 'string',
      save: true,
      trackChanges: true,
      default: null
    },
    live_price_store_stock: {
      type: 'string',
      save: true,
      trackChanges: true,
      default: null
    },
    live_price_vendor_id: {
      type: 'string',
      save: true,
      trackChanges: true,
      default: null
    },
    live_price_vendor_name: {
      type: 'string',
      save: true,
      trackChanges: true,
      default: null
    },
    live_price_item_url: {
      type: 'string',
      save: true,
      trackChanges: true,
      default: null
    },
    live_price_store_id: {
      type: 'string',
      save: true,
      trackChanges: true,
      default: null
    },
    live_price_store_name: {
      type: 'string',
      save: true,
      trackChanges: true,
      default: null
    },
    csi_code_id: {
      type: 'int',
      mapTo: 'csi_code',
      save: true
    }
  },
  taxSettingsDefaulter,

  getComputedDependants() {
    const n = _.notNaN

    return {
      // These fielsd are not in the quote schema (they are cost_item/cost_type/cost_matrix type)
      //  but are required for quote children depcostendency tracking.  They won't be added to
      //  quote level computed values, but will be used in dependency tracking.
      ...CostMatrix.getComputedDependants(),
      ...CostType.getComputedDependants(),
      item_is_upgraded(state, parent) {
        return state.addon_is_upgraded || parent.item_is_upgraded ? 1 : 0
      },
      // Override with this schema specific dependencies
      quantity_multiplier(state, parentState) {
        if (parentState.type === 'quote' || !state.parentRefId) return 1
        return n(parentState.quote_qty_net)
      },
      cost_item_link_qty(state, parent, children, dimensions) {
        return new RegExp(`(\b|^)(${Object.keys(dimensions).join('|')})($|\b)`).test(
          state.cost_type_qty_equation
        )
          ? 1
          : 0
      },
      oSiblingsVariables(state, parent, children, possibleDimensions, latest) {
        if (!state.cost_type_is_fee) return {}

        const parentIsRoot = parent.parentRefId === null

        let rootRefId = parentIsRoot
          ? parent.refId
          : getNormalizedRootRefId(latest, parent.parentRefId)

        // Get all NON-FEE siblings only
        const siblingDescendants = extractDescendants(
          latest,
          [parent.refId],
          false,
          (cr) => latest[cr] && latest[cr].type === 'cost_item' && !latest[cr].cost_type_is_fee
        )
        const siblings = Object.values(siblingDescendants)

        // Get all NON_FEE items that are not already included in siblings
        const rootDescendants = parentIsRoot // if is root, siblings == ALL items/descendants
          ? siblingDescendants
          : extractDescendants(
              latest,
              [rootRefId],
              false,
              (cr) =>
                latest[cr] &&
                latest[cr].type === 'cost_item' &&
                !latest[cr].cost_type_is_fee &&
                !(cr in siblingDescendants)
            )
        const all = Object.values(rootDescendants)

        // Build variables
        const pr = (type) => (type === 'cost_item' ? 'cost_item' : 'quote')

        // Weight
        const siblingWithWeight = [...siblings, ...all].find(
          (costItem) => costItem[`${pr(costItem.type)}_weight_net`]
        )
        const weightMeasure =
          state.weight_unit_of_measure_id ||
          (siblingWithWeight ? siblingWithWeight.weight_unit_of_measure_id : 'lbs')

        const getVariables = (itemSet) => {
          const variables = {
            weight: 0,
            hours: 0,
            cost: 0,
            price: 0,
            profit: 0
          }

          itemSet.forEach((costItem) => {
            // weight //
            const costItemWeight = costItem[`${pr(costItem.type)}_weight_net`]
            const costItemMeasure = costItem.weight_unit_of_measure_id || 'lbs'
            if (costItemMeasure !== weightMeasure) {
              variables.weight =
                variables.weight + _.convertMeasure(costItemWeight, costItemMeasure, weightMeasure)
            } else {
              variables.weight = variables.weight + costItemWeight
            }

            // hours //
            variables.hours = variables.hours + costItem[`${pr(costItem.type)}_total_hours`]

            // cost //
            variables.cost = variables.cost + costItem[`${pr(costItem.type)}_total_cost_net`]

            // price //
            variables.price = variables.price + costItem[`${pr(costItem.type)}_price_net`]

            // profit //
            variables.profit = variables.profit + costItem[`${pr(costItem.type)}_profit_net`]
          })

          return variables
        }

        const siblingVariables = getVariables(siblings)
        const projectVariables = getVariables(all)

        // combine siblings and other variables //
        Object.keys(projectVariables).forEach(
          (key) => (projectVariables[key] = projectVariables[key] + siblingVariables[key])
        )

        return {
          cost: siblingVariables.cost,
          project_cost: projectVariables.cost,
          hours: siblingVariables.hours,
          project_hours: projectVariables.hours,
          price: siblingVariables.price,
          project_price: projectVariables.price,
          profit: siblingVariables.profit,
          project_profit: projectVariables.profit,
          weight: siblingVariables.weight,
          project_weight: projectVariables.weight
        }
      },
      cost_item_qty_net_base(state, parent, children, possibleDimensions) {
        if (!state.cost_item_is_included) return 0

        // Fees basically use the qty_equation to find the qty,
        // and sets the cost_matrix_aggregate_cost_net to 1, making
        // it so that effectivly the quantity determines the price
        let otherVariables = (state.cost_type_is_fee && state.oSiblingsVariables) || {}

        const pd = {
          ...possibleDimensions,
          ...parent.oDimensions
        }
        // console.log('costitemqtynetbase', _.imm({
        //   latest,
        //   pd,
        //   pdo: parent.oDimensions,
        // }));

        const toMeasure = _.getMeasureForUnitOfMeasure(state.unit_of_measure_id)

        let base = +state.cost_item_qty_net_base
        if (pd && state.cost_type_qty_equation) {
          // replace fee variables
          const eq = _.replaceVariables(state.cost_type_qty_equation, otherVariables)

          // now calculate from dimensions
          base = _.dimensionCompute(eq, pd, toMeasure)

          // if (state.cost_type_is_fee) {
          //   console.log('compute',
          //     state.cost_type_name,
          //     JSON.stringify({
          //       base,
          //       eq,
          //       toMeasure,
          //     }, null, 2));
          // }
        }

        return base
      },
      cost_item_is_using_minimum_qty(state) {
        return (
          state.cost_type_minimum_qty_net &&
          state.cost_type_minimum_qty_net >= state.cost_item_qty_net_base
        )
      },
      cost_item_markup_percentage_adjustment(state, parent) {
        return n(parent.quote_markup_percentage_adjustment)
      },
      cost_item_total_hours_base(state) {
        return n(state.cost_type_hours_per_unit) * n(state.cost_item_qty_net_base)
      },
      cost_item_total_hours(state) {
        return n(state.cost_item_total_hours_base) * n(state.quantity_multiplier)
      },
      cost_item_qty_net(state) {
        const r = n(state.quantity_multiplier) * n(state.cost_item_qty_net_base)
        return r
      },

      purchase_cost_item_qty_net_base(state) {
        const qty =
          state.cost_type_minimum_qty_net && state.cost_item_qty_net_base
            ? Math.max(n(state.cost_item_qty_net_base), n(state.cost_type_minimum_qty_net))
            : n(state.cost_item_qty_net_base)

        if (state.cost_type_is_subcontracted) {
          return qty
        }

        const qtyPer = n(
          state.cost_type_materials_purchase_qty_per_unit === null
            ? 1
            : state.cost_type_materials_purchase_qty_per_unit,
          1
        )

        const withWastage = qty * (1 + _.n(state.cost_type_material_waste_factor_net, 0))

        const roundUp =
          qtyPer !== 1 ||
          String(state.purchase_unit_of_measure_id) !== String(state.unit_of_measure_id)

        const baseQty = _.eq(qty, 0) ? 0 : withWastage / qtyPer

        return roundUp ? Math.ceil(baseQty) : baseQty
      },

      purchase_cost_item_qty_net(state) {
        if (state.quantity_multiplier <= 0) return 0

        const qty =
          state.cost_type_minimum_qty_net && state.cost_item_qty_net
            ? Math.max(n(state.cost_item_qty_net, 0), n(state.cost_type_minimum_qty_net, 0))
            : n(state.cost_item_qty_net, 0)

        if (state.cost_type_is_subcontracted) {
          return qty
        }

        const qtyPer = n(
          state.cost_type_materials_purchase_qty_per_unit === null
            ? 1
            : state.cost_type_materials_purchase_qty_per_unit,
          1
        )

        const withWastage = qty * (1 + _.n(state.cost_type_material_waste_factor_net, 0))

        const roundUp =
          qtyPer !== 1 ||
          String(state.purchase_unit_of_measure_id) !== String(state.unit_of_measure_id)

        const baseQty = _.eq(qty, 0) ? 0 : withWastage / qtyPer

        return roundUp ? Math.ceil(baseQty) : baseQty
      },

      cost_item_weight_net(state) {
        if (!state.cost_type_has_materials) return 0
        if (state.cost_type_is_subcontracted) return 0

        return state.purchase_cost_item_qty_net * state.cost_type_weight_per_purchase_unit_net
      },

      cost_item_labor_cost_net_base(state) {
        if (state.cost_type_is_subcontracted && !state.labor_type_callout_rate_net) {
          return 0
        }
        const baseQty =
          state.cost_type_minimum_qty_net && state.cost_item_qty_net_base
            ? Math.max(n(state.cost_item_qty_net_base), n(state.cost_type_minimum_qty_net))
            : n(state.cost_item_qty_net_base)

        return (
          n(state.cost_matrix_labor_cost_net) * baseQty +
            n(state.cost_type_static_labor_cost_net) +
            n(state.cost_item_minimum_labor_net_adjustment) / n(state.quantity_multiplier) || 0
        )
      },
      cost_item_labor_cost_net_regular(state) {
        if (state.quantity_multiplier <= 0) return 0

        if (state.cost_type_is_subcontracted && !state.labor_type_callout_rate_net) {
          return 0
        }

        const qty =
          state.cost_type_minimum_qty_net && state.cost_item_qty_net
            ? Math.max(n(state.cost_item_qty_net, 0), n(state.cost_type_minimum_qty_net, 0))
            : n(state.cost_item_qty_net, 0)

        return n(state.cost_matrix_labor_cost_net) * qty + n(state.cost_type_static_labor_cost_net)
      },
      /* This is calculated on the full number, including multiplier, but it includes
       * static costs in the calculation
       */
      cost_item_minimum_labor_net_adjustment(state) {
        if (state.cost_item_qty_net <= 0) return 0

        if (state.cost_type_is_subcontracted && !state.labor_type_callout_rate_net) {
          return 0
        }

        const adj = state.cost_type_minimum_labor_cost_net
          ? n(state.cost_type_minimum_labor_cost_net) - n(state.cost_item_labor_cost_net_regular)
          : 0
        return adj > 0 ? adj : 0
      },
      cost_item_minimum_labor_net_adjustment_base(state) {
        if (state.cost_type_is_subcontracted && !state.labor_type_callout_rate_net) {
          return 0
        }
        if (state.cost_item_qty_net <= 0) return 0

        const adj =
          n(state.cost_type_minimum_labor_cost_net) - n(state.cost_item_labor_cost_net_base)
        return adj > 0 ? adj : 0
      },
      cost_item_materials_cost_net_regular(state) {
        if (state.cost_item_qty_net <= 0) return 0

        const qty =
          state.cost_type_minimum_qty_net && state.cost_item_qty_net
            ? Math.max(n(state.cost_item_qty_net), n(state.cost_type_minimum_qty_net))
            : n(state.cost_item_qty_net)

        const materialQuantity =
          n(state.purchase_cost_item_qty_net || qty) *
          n(state.cost_type_materials_purchase_qty_per_unit || 1)

        return (
          n(state.cost_matrix_materials_cost_net) * materialQuantity +
          n(state.cost_type_static_materials_cost_net)
        )
      },
      /* This is calculated on the full number, including multiplier and static costsp */
      cost_item_minimum_materials_net_adjustment(state) {
        if (state.cost_item_qty_net <= 0) return 0

        const adj =
          n(state.cost_type_minimum_materials_cost_net) -
          n(state.cost_item_materials_cost_net_regular)

        return adj > 0 ? adj : 0
      },
      cost_item_minimum_materials_net_adjustment_base(state) {
        if (state.cost_item_qty_net <= 0) return 0

        const adj =
          n(state.cost_type_minimum_materials_cost_net) - n(state.cost_item_materials_cost_net_base)

        return adj > 0 ? adj : 0
      },
      cost_item_labor_cost_net(state) {
        if (state.cost_type_is_subcontracted && !state.labor_type_callout_rate_net) {
          return 0
        }
        return (
          n(state.cost_item_labor_cost_net_regular) +
          n(state.cost_item_minimum_labor_net_adjustment)
        )
      },
      cost_item_materials_cost_net_base(state) {
        const baseQty =
          state.cost_type_minimum_qty_net && state.cost_item_qty_net_base
            ? Math.max(n(state.cost_item_qty_net_base), n(state.cost_type_minimum_qty_net))
            : n(state.cost_item_qty_net_base)

        const materialQuantity =
          n(state.purchase_cost_item_qty_net_base || baseQty) *
          n(state.cost_type_materials_purchase_qty_per_unit || 1)

        const base =
          n(state.cost_matrix_materials_cost_net) * materialQuantity +
          n(state.cost_type_static_materials_cost_net) +
          n(state.cost_item_minimum_materials_net_adjustment) / n(state.quantity_multiplier)

        return base || 0
      },
      cost_item_materials_cost_net(state) {
        return (
          n(state.cost_item_materials_cost_net_regular) +
          n(state.cost_item_minimum_materials_net_adjustment)
        )
      },
      cost_item_total_cost_net_base(state) {
        return n(state.cost_item_materials_cost_net_base) + n(state.cost_item_labor_cost_net_base)
      },
      cost_item_total_cost_net(state) {
        return n(state.cost_item_materials_cost_net) + n(state.cost_item_labor_cost_net)
      },
      cost_item_markup_net_adjustment(state) {
        const qm = n(state.cost_matrix_markup_net)
        const fromAdjustment = n(state.cost_item_markup_percentage_adjustment) * (qm - 1)

        const minPrice = n(state.cost_type_minimum_price_net)
        if (!minPrice) return fromAdjustment

        //Look ahead to see if the total multiplied price is above min
        let total = qm + fromAdjustment
        const totalCost = n(state.cost_item_total_cost_net)
        const adjPrice = total * totalCost

        if (minPrice <= adjPrice) return fromAdjustment

        total = _.divide(minPrice, totalCost)
        let newAdjustment = total - qm
        return newAdjustment
      },
      cost_item_markup_net_adjusted(state) {
        return n(state.cost_item_markup_net_adjustment) + n(state.cost_matrix_markup_net)
      },
      cost_item_price_net_adjustment(state) {
        return n(state.cost_item_markup_net_adjustment) * n(state.cost_item_total_cost_net)
      },
      cost_item_price_net_base_adjustment(state) {
        return state.cost_item_price_net_adjustment / state.quantity_multiplier || 0
      },
      // cost_item_net_adjustment_minprice(state) {
      //   const adj = n(state.cost_item_markup_net_adjustment);
      //   const qm = n(state.cost_matrix_markup_net);
      //
      //   const minPrice = n(state.cost_type_minimum_price_net);
      //   if (!minPrice) return 0;
      //
      //   //Look ahead to see if the total multiplied price is above min
      //   let total = qm + adj;
      //   const totalCost = n(state.cost_item_total_cost_net);
      //   const adjPrice = total * totalCost;
      //
      //   if (minPrice <= adjPrice) return fromAdjustment;
      //
      //   total = _.divide(minPrice, totalCost);
      //   let newAdjustment = total - qm;
      //   return newAdjustment;
      // },
      cost_item_regular_price_net_base(state) {
        return n(state.cost_item_total_cost_net_base) * n(state.cost_matrix_markup_net)
      },
      cost_item_regular_price_net(state) {
        return n(state.cost_item_total_cost_net) * n(state.cost_matrix_markup_net)
      },
      cost_item_price_net_base(state) {
        return (
          n(state.cost_item_regular_price_net_base) + n(state.cost_item_price_net_base_adjustment)
        )
      },
      cost_item_price_net(state) {
        return n(state.cost_item_regular_price_net) + n(state.cost_item_price_net_adjustment)
      },
      cost_item_profit_net(state) {
        return n(state.cost_item_price_net) - n(state.cost_item_total_cost_net)
      },
      cost_item_show_itemized_prices(state, parent) {
        return parent.quote_show_itemized_prices === null ? 1 : parent.quote_show_itemized_prices
      },
      cost_item_actual_total_hours(state) {
        return n(state.cost_item_actual_total_hours) || 0
      },
      cost_item_actual_labor_cost_net(state) {
        const cost = state.cost_item_actual_labor_cost_net_explicit
        const alt = state.cost_item_labor_cost_net
        return cost === '' || cost === 'null' || cost === null || _.isnan(cost) ? n(alt) : n(cost)
      },
      cost_item_actual_materials_cost_net(state) {
        const cost = state.cost_item_actual_materials_cost_net_explicit
        const alt = state.cost_item_materials_cost_net
        return cost === '' || cost === 'null' || cost === null || _.isnan(cost) ? n(alt) : n(cost)
      },
      cost_item_actual_total_cost_net(state) {
        return (
          n(state.cost_item_actual_labor_cost_net) + n(state.cost_item_actual_materials_cost_net)
        )
      },
      item_vendor_unpaid_net(state) {
        return n(state.cost_item_actual_total_cost_net) - n(state.item_vendor_paid_net)
      },
      // cost_item_unpaid_to_vendor_net(state) {
      //   return n(state.cost_item_actual_total_cost_net || n(state.cost_item_total_cost_net))
      //     - n(state.cost_item_paid_to_vendor);
      // },
      // cost_item_unpaid_to_vendor_net(state) {
      //   const totalCost = n(state.cost_item_actual_total_cost_net || n(state.cost_item_total_cost_net));
      //
      //   return n(state.cost_item_actual_total_cost_net || n(state.cost_item_total_cost_net))
      //     - n(state.cost_item_paid_to_vendor);
      // },
      /* cost_item_discount_percentage(state, parentState) {
        return n((parentState.quote_discount_percentage), 0);
      }, */
      /* cost_item_discount_net_base(state) {
        return (n(state.cost_item_discount_percentage))
          * n(state.cost_item_price_net_base_undiscounted);
      }, */
      /* cost_item_discount_net(state) {
        return n(state.cost_item_discount_net_base)
          * n((state.quantity_multiplier), 1);
      }, */
      /* cost_item_price_net_base(state) {
        return n(state.cost_item_price_net_base)
          - n(state.cost_item_discount_net_base);
      }, */
      /* cost_item_price_net(state) {
        return n(state.cost_item_price_net_undiscounted)
          - n(state.cost_item_discount_net);
      }, */
      oTaxSums(state, parent) {
        const settings =
          (Object.keys(state.oTaxSettings || {}).length
            ? state.oTaxSettings
            : parent.oProjectTaxSettings) || {}
        const taxTypes = Object.keys(settings)

        if (!taxTypes.length) return {}

        const sums = {}

        const totalPrice = n(state.cost_item_price_net)
        const totalCost = n(state.cost_item_total_cost_net)
        const totalProfit = totalPrice - totalCost
        const laborCost = n(state.cost_item_labor_cost_net)
        const materialCost = n(state.cost_item_materials_cost_net)
        const subed = n(state.cost_type_is_subcontracted)

        // Iterate each tax category
        // iterate each named sub type
        // find its individual tax implication
        // add to sums
        taxTypes.forEach((taxType) => {
          settings[taxType].forEach((tax) => {
            // Taxes can be set to be levied on the SHARE of cost, profit or all of
            // items that constitutes different classes of costs like LABOR,
            // MATERIALS, SUBCONTRACTOR or EVERY KIND OF COST (general/All inclusive)
            let shareOfCost = 0
            if (taxType === 'ihlt' && !subed) {
              // inhouse labor
              shareOfCost = _.divide(laborCost, totalCost)
            } else if (taxType === 'mt' && !subed) {
              // materials cost
              shareOfCost = _.divide(materialCost, totalCost)
            } else if (taxType === 'slt' && subed) {
              // subcontracted cost
              shareOfCost = 1
            } else if (taxType === 'ct') {
              shareOfCost = 1
            }

            const key = `${taxType}-${tax.name}-${tax.on}`
            const name = tax.name

            sums[key] = sums[key] || {
              key,
              name,
              cost: 0,
              profit: 0,
              sum: 0,
              type: taxType,
              on: tax.on || 'all',
              pcnt: tax.pcnt
            }

            // Taxes can be set to be levied ONLY on the COST, ONLY on the PROFIT
            // or on the PRICE(all) segment of an item.  Use-taxes are levied on COST often,
            // while sales taxes/ value added taxes are levied on the entire price.
            // Sometimes profit alone is targeted for a tax.
            if (tax.on === 'cost') {
              sums[key].cost = tax.pcnt * shareOfCost * totalCost
            } else if (tax.on === 'profit') {
              sums[key].profit = tax.pcnt * shareOfCost * totalProfit
            } else {
              sums[key].cost = tax.pcnt * shareOfCost * totalCost
              sums[key].profit = tax.pcnt * shareOfCost * totalProfit
            }

            sums[key].sum = sums[key].profit + sums[key].cost
          })
        })

        return sums
      },
      cost_item_profit_tax(state) {
        return Object.values(state.oTaxSums).reduce((acc, tax) => acc + tax.profit, 0)
      },
      cost_item_cost_tax(state) {
        return Object.values(state.oTaxSums).reduce((acc, tax) => acc + tax.cost, 0)
      },
      profit_tax_percentage(state) {
        const totalPrice = n(state.cost_item_price_net)
        const totalCost = n(state.cost_item_total_cost_net)
        const totalProfit = totalPrice - totalCost
        return _.divide(
          Object.values(state.oTaxSums).reduce((acc, tax) => acc + tax.profit, 0),
          totalProfit
        )
      },
      cost_tax_percentage(state) {
        const totalCost = n(state.cost_item_total_cost_net)
        return _.divide(
          Object.values(state.oTaxSums).reduce((acc, tax) => acc + tax.cost, 0),
          totalCost
        )
      },
      cost_item_tax(state) {
        return state.cost_item_cost_tax + state.cost_item_profit_tax
      },
      cost_item_gross(state) {
        return n(state.cost_item_tax) + n(state.cost_item_price_net)
      },
      cost_item_count_addons_available(state) {
        return (state.aoAddons || []).length
      },
      cost_item_has_live_pricing(state) {
        return state.live_price_reference ? 1 : 0
      },
      live_price_country(state) {
        return state.live_price_country
      },
      asAssemblyPath(state, parent) {
        let path = parent.asAssemblyPath || []
        if (state && state.type === 'cost_item') {
          path = [...path, state.cost_type_name]
        }
        return path
      },
      // eslint-disable-next-line no-unused-vars
      aoLocation(state, parent, children = null, possibleDimensions = null, norm) {
        // eslint-disable-line
        let parentRef = state.parentRefId
        let parentObj = null
        const rooms = []

        while (parentRef) {
          parentObj = norm[parentRef] || null

          if (!parentObj) {
            parentRef = null
            break
          }

          if (
            parentObj &&
            parentObj.asRequiredDimensions &&
            parentObj.asRequiredDimensions.length
          ) {
            const name = parentObj.assembly_name || parentObj.quote_name
            rooms.push({
              name,
              refId: parentObj.refId,
              type: parentObj.type
            })
          }

          parentRef = parentObj.parentRefId
        }

        rooms.reverse()

        return rooms
      },

      version() {
        return '3.0.0'
      },

      item_count_upgrades(state) {
        const self = state.item_is_upgraded || state.addon_is_upgraded || 0

        return +self
      },

      /**
       * Adjusts non-overridden addons when a price adjustment has been observed
       */
      aoAddons(state, parent, children, possibleDimensions, latestSet, changeSet) {
        const addons = _.imm(state.aoAddons)
        const updatedPrice =
          changeSet[state.refId] && changeSet[state.refId].cost_item_price_net_base

        if (updatedPrice) {
          for (let index = 0; index < addons.length; index++) {
            const addon = { ...addons[index] }
            addon.targetPriceAdjustment =
              updatedPrice -
              (addon.targetPrice || updatedPrice) +
              (addon.targetPriceAdjustment || 0)
            addon.targetPrice = updatedPrice
            addons[index] = addon
          }
        }

        return addons
      }
    }
  },

  getFieldDependencies() {
    const {
      cost_matrix_labor_cost_net,
      cost_matrix_aggregate_cost_net,
      cost_matrix_rate_net,
      cost_item_qty_net,
      cost_item_labor_cost_net_base,
      cost_item_labor_cost_net,
      cost_item_materials_cost_net_base,
      cost_item_materials_cost_net,
      cost_item_total_hours_base,
      cost_item_total_hours,
      cost_item_total_cost_net_base,
      cost_item_total_cost_net,
      cost_item_price_net_base,
      cost_item_price_net,
      cost_item_markup_percentage_adjustment,
      cost_item_markup_net_adjustment,
      cost_item_markup_net_adjusted,
      cost_item_price_net_base_adjustment,
      cost_item_price_net_adjustment,
      cost_item_qty_net_base,
      cost_item_regular_price_net_base,
      cost_item_regular_price_net,
      cost_item_labor_cost_net_regular,
      cost_item_materials_cost_net_regular,
      cost_item_minimum_labor_net_adjustment,
      cost_item_minimum_materials_net_adjustment,
      // cost_item_unpaid_to_vendor_net,
      cost_item_profit_tax,
      cost_item_cost_tax,
      cost_item_tax,
      cost_item_gross,
      item_vendor_unpaid_net,
      cost_item_actual_labor_cost_net,
      cost_item_actual_materials_cost_net,
      cost_item_actual_total_cost_net,
      item_is_upgraded,
      asDimensionsLinked,
      asDimensionsUsed,
      cost_item_count_addons_available,
      item_count_upgrades,
      purchase_unit_of_measure_id,
      purchase_cost_item_qty_net_base,
      purchase_cost_item_qty_net,
      cost_type_materials_purchase_qty_per_unit,
      purchase_unit_of_measure_abbr,
      purchase_unit_of_measure_name,
      cost_type_material_waste_factor_net,
      cost_tax_percentage,
      profit_tax_percentage,
      dimension_measure_type,
      cost_matrix_materials_cost_net,
      oVariationTraits,
      cost_item_weight_net,
      oSiblingsVariables,
      cost_type_hours_per_unit,
      oTaxSums,
      cost_item_profit_net
    } = this.getComputedDependants()

    return {
      cost_item_is_included: {
        cost_item_qty_net_base
      },
      oTaxSettings: {
        oTaxSums
      },
      oMeta: {
        cost_matrix_materials_cost_net,
        cost_matrix_labor_cost_net
      },
      cost_type_is_fee: {
        cost_matrix_materials_cost_net,
        cost_matrix_labor_cost_net,
        cost_type_hours_per_unit,
        cost_item_qty_net_base
      },
      unit_of_measure_id: {
        asDimensionsLinked,
        asDimensionsUsed,
        cost_item_qty_net_base,
        purchase_unit_of_measure_abbr,
        purchase_unit_of_measure_id,
        purchase_unit_of_measure_name,
        dimension_measure_type
      },
      cost_type_is_subcontracted: {
        cost_matrix_materials_cost_net,
        cost_matrix_labor_cost_net,
        cost_type_material_waste_factor_net,
        purchase_unit_of_measure_id,
        purchase_unit_of_measure_abbr,
        purchase_unit_of_measure_name,
        cost_type_materials_purchase_qty_per_unit,
        purchase_cost_item_qty_net_base,
        purchase_cost_item_qty_net,
        oTaxSums
      },
      cost_type_has_materials: {
        cost_matrix_materials_cost_net,
        cost_matrix_labor_cost_net
      },
      cost_type_has_labor: {
        cost_matrix_materials_cost_net,
        cost_matrix_labor_cost_net
      },
      cost_type_qty_equation: {
        asDimensionsLinked,
        cost_item_qty_net_base
      },
      aoAddons: {
        asDimensionsUsed,
        cost_item_count_addons_available
      },
      asDimensionsLinked: {
        asDimensionsUsed
      },
      addon_is_upgraded: {
        item_is_upgraded
      },
      item_is_upgraded: {
        item_count_upgrades
      },
      // Cost matrix
      quantity_multiplier: {
        cost_item_qty_net,
        cost_item_total_cost_net,
        cost_item_labor_cost_net_regular,
        cost_item_materials_cost_net_regular,
        cost_item_labor_cost_net,
        cost_item_materials_cost_net
      },

      // A change in labor_type_rate_net necessitates an update in cost_matrix_labor_cost_net
      labor_type_rate_net: {
        cost_matrix_labor_cost_net
      },
      cost_type_hours_per_unit: {
        cost_matrix_labor_cost_net,
        cost_item_total_hours_base
      },
      cost_matrix_labor_cost_net: {
        cost_matrix_aggregate_cost_net,
        cost_item_labor_cost_net_base
      },
      cost_matrix_materials_cost_net: {
        cost_matrix_aggregate_cost_net,
        cost_item_materials_cost_net_base
      },
      cost_matrix_aggregate_cost_net: {
        cost_matrix_rate_net,
        cost_item_price_net_adjustment,
        cost_item_total_cost_net_base,
        cost_item_regular_price_net_base,
        cost_item_price_net_base_adjustment
      },
      cost_type_minimum_price_net: {
        cost_item_markup_net_adjustment
      },
      cost_matrix_markup_net: {
        cost_item_markup_net_adjustment,
        cost_item_markup_net_adjusted,
        cost_matrix_rate_net,
        cost_item_regular_price_net_base,
        cost_item_price_net_adjustment,
        cost_item_price_net_base_adjustment
      },
      cost_matrix_rate_net: {
        cost_item_price_net_base,
        cost_item_regular_price_net_base,
        cost_item_markup_net_adjustment
      },
      cost_item_markup_percentage_adjustment: {
        cost_item_price_net_base_adjustment,
        cost_item_markup_net_adjustment,
        cost_item_markup_net_adjusted,
        cost_item_price_net_adjustment
      },
      cost_item_markup_net_adjustment: {
        cost_item_markup_net_adjusted,
        cost_item_price_net_adjustment,
        cost_item_price_net_base_adjustment
      },
      cost_item_markup_net_adjusted: {
        cost_item_price_net_base_adjustment,
        cost_item_price_net_adjustment,
        oTaxSums,
        oSiblingsVariables
      },
      weight_unit_of_measure_id: {
        oSiblingsVariables
      },

      // Cost item
      cost_type_minimum_qty_net: {
        cost_item_qty_net_base
      },
      oSiblingsVariables: {
        cost_item_qty_net_base
      },
      cost_item_qty_net_base: {
        cost_item_labor_cost_net_base,
        cost_item_price_net_base_adjustment,
        cost_item_regular_price_net_base,
        cost_type_materials_purchase_qty_per_unit,
        purchase_cost_item_qty_net_base,
        cost_item_materials_cost_net_base,
        cost_item_price_net_base,
        cost_item_total_hours_base,
        cost_item_price_net_adjustment,
        cost_item_qty_net
      },

      cost_item_price_net_adjustment: {
        cost_item_price_net_base_adjustment
      },

      cost_type_material_waste_factor_net: {
        purchase_cost_item_qty_net_base,
        purchase_cost_item_qty_net,
        cost_item_materials_cost_net_base,
        cost_item_materials_cost_net
      },

      cost_type_materials_purchase_qty_per_unit: {
        purchase_cost_item_qty_net_base,
        purchase_cost_item_qty_net
      },

      cost_item_qty_net: {
        cost_item_labor_cost_net_regular,
        cost_item_materials_cost_net_regular,
        cost_item_labor_cost_net,
        cost_item_materials_cost_net,
        cost_item_price_net,
        cost_item_total_hours,
        purchase_cost_item_qty_net,
        cost_item_minimum_materials_net_adjustment,
        cost_item_minimum_labor_net_adjustment
      },
      cost_type_static_materials_cost_net: {
        cost_item_materials_cost_net_regular,
        cost_item_materials_cost_net_base
      },
      cost_type_static_labor_cost_net: {
        cost_item_materials_cost_net_regular,
        cost_item_labor_cost_net_base
      },

      cost_item_total_hours_base: {
        cost_item_total_hours
      },
      cost_item_labor_cost_net_base: {
        cost_item_labor_cost_net_regular,
        cost_item_total_cost_net_base,
        cost_item_labor_cost_net
      },
      purchase_cost_item_qty_net_base: {
        purchase_cost_item_qty_net,
        cost_item_materials_cost_net_base,
        cost_item_materials_cost_net
      },
      purchase_cost_item_qty_net: {
        cost_item_materials_cost_net,
        cost_item_weight_net
      },
      cost_type_weight_per_purchase_unit_net: {
        cost_item_weight_net
      },
      cost_item_materials_cost_net_base: {
        cost_item_materials_cost_net_regular,
        cost_item_total_cost_net_base,
        cost_item_materials_cost_net
      },
      cost_type_minimum_materials_cost_net: {
        cost_item_minimum_materials_net_adjustment
      },
      cost_item_materials_cost_net_regular: {
        cost_item_minimum_materials_net_adjustment,
        cost_item_materials_cost_net
      },
      cost_item_minimum_materials_net_adjustment: {
        cost_item_materials_cost_net
      },
      cost_item_materials_cost_net: {
        cost_item_total_cost_net,
        oTaxSums
      },
      cost_type_minimum_labor_cost_net: {
        cost_item_minimum_labor_net_adjustment
      },
      cost_item_labor_cost_net_regular: {
        cost_item_minimum_labor_net_adjustment,
        cost_item_labor_cost_net
      },
      cost_item_minimum_labor_net_adjustment: {
        cost_item_labor_cost_net
      },
      cost_item_labor_cost_net: {
        cost_item_total_cost_net,
        oTaxSums
      },
      cost_item_total_cost_net_base: {
        cost_item_total_cost_net,
        cost_item_price_net_base,
        cost_item_regular_price_net_base
      },
      cost_item_regular_price_net_base: {
        cost_item_regular_price_net,
        cost_item_price_net_base
      },
      cost_item_total_cost_net: {
        cost_item_price_net,
        cost_item_cost_tax,
        cost_item_profit_net
      },
      cost_item_actual_materials_cost_net_explicit: {
        cost_item_actual_materials_cost_net
      },
      cost_item_actual_labor_cost_net_explicit: {
        cost_item_actual_labor_cost_net
      },
      cost_item_actual_materials_cost_net: {
        cost_item_actual_total_cost_net
      },
      cost_item_actual_labor_cost_net: {
        cost_item_actual_total_cost_net
      },
      cost_item_actual_total_cost_net: {
        item_vendor_unpaid_net
      },
      item_vendor_paid_net: {
        item_vendor_unpaid_net
      },
      cost_item_price_net_base_adjustment: {
        cost_item_price_net_base
      },
      cost_item_price_net_base: {
        cost_item_markup_percentage_adjustment,
        cost_item_price_net
      },
      cost_item_price_net: {
        cost_item_price_net,
        cost_item_gross,
        oTaxSums,
        cost_item_profit_net
      },
      oTaxSums: {
        cost_item_profit_tax,
        cost_item_cost_tax
      },
      cost_item_profit_tax: {
        profit_tax_percentage,
        cost_item_tax
      },
      cost_item_cost_tax: {
        cost_tax_percentage,
        cost_item_tax
      },
      cost_item_tax: {
        cost_item_gross
      },
      aoVariationItems: {
        oVariationTraits
      }
    }
  },

  getParentDependencies() {
    const {
      quantity_multiplier,
      cost_item_qty_net_base,
      cost_item_markup_percentage_adjustment,
      asAssemblyPath,
      cost_item_show_itemized_prices,
      item_is_upgraded,
      aoLocation,
      cost_type_qty_equation,
      cost_item_qty_net,
      oSiblingsVariables,
      oTaxSums
    } = this.getComputedDependants()

    return {
      // When the parent of this object sees as change in
      //  quote_discount_percentage, update this objects
      //  quote_discount_percentage as well, or cost_item_discount_percentage
      //  if this object is a cost item.
      oDimensions: {
        cost_type_qty_equation,
        cost_item_qty_net_base,
        cost_item_qty_net,
        oSiblingsVariables
      },
      quote_markup_percentage_adjustment: {
        cost_item_markup_percentage_adjustment
      },
      aoChildren: {
        oSiblingsVariables
      },
      oProjectTaxSettings: {
        oTaxSums
      },
      item_is_upgraded: {
        item_is_upgraded
      },
      quote_show_itemized_prices: {
        cost_item_show_itemized_prices
      },
      quote_name: {
        asAssemblyPath,
        aoLocation
      },
      assembly_name: {
        asAssemblyPath,
        aoLocation
      },
      asAssemblyPath: {
        asAssemblyPath,
        aoLocation
      },
      asRequiredDimensions: {
        aoLocation
      },
      quote_qty_net: {
        quantity_multiplier,
        cost_item_qty_net_base
      },
      quote_qty_net_base: {
        quantity_multiplier,
        cost_item_qty_net_base
      },
      quantity_multiplier: {
        quantity_multiplier,
        cost_item_qty_net_base
      },
      quote_subtotal_net: {
        cost_item_markup_percentage_adjustment
      }
    }
  },

  getAllVariationPossibilities,

  generateVueActions() {
    return {
      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param float totalPrice
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setAssignees({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostItem',
          assignees,
          object = _.imm(rootState[store].normalized[refId])
        } = payload
        const explicitChanges = {
          assignee_ids: assignees
        }
        let changes = {
          ...explicitChanges
        }
        // Now set assignees
        assigneeRefIdsToFlush.push(object.refId)
        _.throttle(() => flushAssignees({ dispatch, norm: rootState[store].normalized, object }), {
          delay: 2000
        })

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      async getUnavailableCombinations({ dispatch, rootState }, payload) {
        const {
          /**
           * Parent cost_type/cost_item refId
           */
          refId = false,
          store = 'Quote',
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const variationItems = _.imm(object?.oVariations?.items)
        const all = getAllVariationPossibilities(variationItems)
        const dst = await dispatch('findPossibleVariations', {
          ...payload,
          lockedSelections: await dispatch('getLockedSelections', payload),
          chosenSelections: object?.oVariations?.selectedItem?.variations
        })

        return Object.keys(all).reduce(
          (acc, vtype) => ({
            ...acc,
            [vtype]: _.difference(all[vtype], dst[vtype])
          }),
          {}
        )
      },
      async getLockedSelections({ rootState }, payload) {
        const {
          /**
           * Parent cost_type/cost_item refId
           */
          refId = false,
          store = 'Quote',
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        return getLockedSelections(object)
      },
      async selectLinkedVariations({ dispatch, rootState }, payload) {
        const {
          variationType,
          /**
           * Parent cost_type/cost_item refId
           */
          refId = false,
          store = 'Quote',
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const norm = _.imm(rootState[store].normalized)

        const links = _.imm(object.oVariationLinks) || {}

        const costTypeIds = Object.keys(links)

        if (!costTypeIds.length) return

        const linkedItems = Object.keys(norm)
          .filter(
            (rr) =>
              norm[rr].type === 'cost_item' &&
              costTypeIds.includes(norm[rr].cost_type_id) &&
              rr !== refId && // don't try to link to itself
              norm[rr].cost_type_id in links &&
              links[norm[rr].cost_type_id][variationType]
          )
          .reduce(
            (acc, rr) => ({
              ...acc,
              [String(norm[rr].cost_type_id)]: [...(acc[String(norm[rr].cost_type_id)] || []), rr]
            }),
            {}
          )

        const linkedCostTypeIds = Object.keys(linkedItems)
        if (!linkedCostTypeIds.length) return

        let normChanges = {}
        await Promise.all(
          linkedCostTypeIds.map(async (costTypeId) => {
            const linkSettings = links[costTypeId]
            const foreignType = linkSettings[variationType]

            if (!foreignType) return

            const refIdsOftype = linkedItems[String(costTypeId)]

            const subNormChanges = await Promise.all(
              refIdsOftype.reduce(async (acc, rr) => ({
                ...acc,
                ...(
                  await dispatch('selectVariation', {
                    ...payload,
                    refId: rr,
                    variationType: foreignType,
                    selectLinks: false
                  })
                ).normalizedChanges
              }))
            )

            normChanges = {
              ...normChanges,
              ...subNormChanges
            }
          })
        )

        return normChanges
      },
      async selectVariation({ dispatch, rootState }, payload) {
        const {
          variationType,
          variationValue,
          /**
           * Parent cost_type/cost_item refId
           */
          refId = false,
          store = 'Quote',
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        let norm = _.imm(rootState[store].normalized)
        const rootRefId = getNormalizedRootRefId(norm)
        const quote = norm[rootRefId]
        const company = rootState.session.company
        const zipcode = AutoCost.getAutoCostZipcode(company, quote)

        const lockedSelections = await dispatch('getLockedSelections', payload)
        if (lockedSelections[variationType] && variationValue !== lockedSelections[variationType]) {
          return
        }

        // check if it's already selected
        const current = _.immutable(object.oVariations?.selectedItem?.variations)
        if (current?.[variationType] === variationValue) return

        const unavailableCombos = await dispatch('getUnavailableCombinations', payload)

        if (variationValue in unavailableCombos[variationType]) {
          this.$store.dispatch('alert', {
            error: true,
            message: 'That combination is not available, try another selection.'
          })
          return
        }

        // Get links, and their links
        let idsChecked = []
        const getLinks = (refId) => {
          let linkObject = norm[refId]

          const links = _.imm(linkObject?.oVariations?.links) || {}

          const costTypeIds = Object.keys(links)

          if (!costTypeIds) return {}

          if (!_.difference(costTypeIds, idsChecked).length) return {}

          idsChecked = _.uniq([...idsChecked, ...costTypeIds])

          return Object.keys(norm).reduce((acc, rr) => {
            const subLinks = getLinks(rr)

            const checkItem = norm[rr]

            // don't try to link to itself and must be cost item
            if (checkItem.type !== 'cost_item' || rr === refId) return acc

            const checkId = String(checkItem.cost_type_id)
            const parentId = String(checkItem.oVariations?.parentId)

            if (
              !(
                (costTypeIds.includes(checkId) || costTypeIds.includes(parentId)) &&
                (checkId in links || parentId in links) &&
                (links[checkId][variationType] || links[parentId][variationType])
              )
            )
              return acc

            return {
              ...acc,
              [parentId || checkId]: [
                // default to parentId
                ...(acc[parentId || checkId] || []),
                rr
              ],

              ...Object.keys(subLinks).reduce(
                (subacc, id) => ({
                  ...subacc,
                  [id]: [...(acc[id] || []), ...(subacc[id] || []), ...subLinks[id]]
                }),
                {}
              )
            }
          }, {})
        }
        const linkedItems = getLinks(object.refId)
        const links = _.imm(object?.oVariations?.links) || {}

        // Find best variation for THIS item
        const bestVariationByRef = {}

        // Find best variation for each linked refId
        const linkedRefs = _.flatten(Object.values(linkedItems))

        // Load all variants for each linked
        await Promise.all(
          linkedRefs.map((ref) =>
            dispatch('getVariants', {
              refId: ref,
              store
            })
          )
        )

        norm = _.imm(rootState[store].normalized)

        linkedRefs.forEach((linkedRef) => {
          const linkedObj = norm[linkedRef]
          const costTypeId = String(linkedObj.oVariations?.parentId ?? linkedObj.cost_type_id)
          const linkSettings = links[costTypeId]
          const foreignType = linkSettings && linkSettings[variationType]

          if (!foreignType) return

          const bestVariation = findBestVariation(linkedObj, foreignType, variationValue)
          if (!bestVariation) return
          const { id: bestCostTypeId, livePriceRef } = bestVariation
          bestVariationByRef[linkedRef] = livePriceRef
            ? `live_price:${livePriceRef}`
            : bestCostTypeId
        })

        // Set THIS item with best variation after so that it overrides any links
        const {
          id: variationId,
          livePriceRef,
          uid
        } = findBestVariation(object, variationType, variationValue)

        bestVariationByRef[object.refId] = livePriceRef
          ? `live_price:${uid}:${livePriceRef}`
          : variationId

        // Filter costtypes to full fetch all the variations
        const costTypeRefs = Object.values(bestVariationByRef).filter(
          (ref) => !ref.startsWith('live_price:')
        )
        let set = []
        if (costTypeRefs.length) {
          const res = await dispatch(
            'CostType/filter',
            {
              filters: {
                cost_type_id: _.uniq(_.flatten(costTypeRefs)).join('||')
              }
            },
            { root: true }
          )
          set = res.set
        }

        const livePriceRefs = Object.values(bestVariationByRef).filter((ref) =>
          ref.startsWith('live_price:')
        )
        const livePriceSet = await Promise.all(
          livePriceRefs.map(async (livePriceRef) => {
            const liveUidPrice = livePriceRef.split('live_price:')[1]
            const [liveUid, livePrice] = liveUidPrice.split(':')
            const res = await dispatch(
              'CostType/getDefaultLivePriceItem',
              {
                livePriceRef: livePrice,
                company,
                zipcode
              },
              { root: true }
            )

            return {
              ...res,
              uid: liveUid,
              livePriceRef: res.live_price_reference
            }
          })
        )

        if (!set.length && !livePriceSet.length) {
          return dispatch(
            'alert',
            {
              error: true,
              message: 'Variation item could not be found'
            },
            { root: true }
          )
        }

        const autocostMaterialRefs = {}
        const craftsmanMaterialRefs = {}
        const autocostLaborRefs = {}
        if (set[0]?.live_price_reference) {
          const vend = set[0].live_price_reference.split('-')[0]
          if (vend.length === 4 && vend === 'crft') {
            craftsmanMaterialRefs[set[0].live_price_reference] = {
              live_price_reference: set[0].live_price_reference
            }
          } else if (vend.length === 4) {
            autocostMaterialRefs[set[0].live_price_reference] = {
              live_price_reference: set[0].live_price_reference
            }
          }
        }
        if (set[0]?.labor_type_id) {
          if (set[0].labor_type_id.startsWith('ac-')) {
            autocostLaborRefs[set[0].labor_type_id] = { live_price_reference: set[0].labor_type_id }
          }
        }

        let livePrices
        let reverseIndex
        if (Object.keys(autocostMaterialRefs).length || Object.keys(autocostLaborRefs).length) {
          const resp = await dispatch('ajax', {
            path: 'live_price/fetchMultipleCostItemLivePrice',
            data: {
              zipcode,
              autocost_material: autocostMaterialRefs,
              craftsman_material: craftsmanMaterialRefs,
              autocost_labor: autocostLaborRefs
            }
          })
          livePrices = resp.payload

          let laborRate
          let materialCost
          let hours

          if (set[0].live_price_reference) {
            materialCost = livePrices[set[0].live_price_reference]['live_price']['material_rate']
            hours = livePrices[set[0].live_price_reference]['live_price']['hours_per_unit']
          } else {
            materialCost = set[0].cost_matrix_materials_cost_net
            hours = set[0].cost_type_hours_per_unit
          }

          if (set[0].labor_type_id && set[0].labor_type_id.startsWith('ac-')) {
            laborRate = livePrices[set[0].labor_type_id]['live_price']['labor_rate']
          } else {
            laborRate = set[0].labor_type_rate_net
          }

          const markup = set[0].cost_matrix_use_company_markup
            ? rootState.session.company.company_default_markup
            : set[0].cost_matrix_markup_net

          const laborCost = laborRate * hours
          const combinedCost = materialCost + laborCost
          const rate = combinedCost * markup

          set[0].cost_matrix_materials_cost_net = materialCost
          set[0].cost_matrix_labor_cost_net = laborCost
          set[0].cost_matrix_aggregate_cost_net = combinedCost
          set[0].cost_type_hours_per_unit = hours
          set[0].labor_type_rate_net = laborRate
          set[0].labor_type_rate_net_index = laborRate
          set[0].cost_matrix_markup_net = markup
          set[0].cost_matrix_rate_net = rate

          reverseIndex = {
            [set[0].cost_type_id]: set[0]
          }
        } else {
          // apply modification factors
          const mod =
            object.mod_labor_net && object.mod_materials_net
              ? {
                  mod_labor_net: object.mod_labor_net,
                  mod_materials_net: object.mod_materials_net,
                  mod_id: object.mod_id || null
                }
              : await dispatch(
                  'Quote/getQuoteMod',
                  {
                    store,
                    refId: object.refId
                  },
                  { root: true }
                )

          reverseIndex = [...set, ...livePriceSet].reduce(
            (acc, obj) => ({
              ...acc,
              [obj.livePriceRef
                ? `live_price:${obj.uid}:${obj.livePriceRef}`
                : String(obj.cost_type_id)]: CostType.applyMod(obj, mod, obj.cost_matrix_markup_net)
                .item
            }),
            {}
          )
        }

        // Embue each variation into normalized
        const refEmbues = {}
        for (const ref of Object.keys(bestVariationByRef)) {
          const target = norm[ref]
          const costTypeId = bestVariationByRef[ref]
          const variation = reverseIndex[costTypeId]
          if (!variation) continue

          const selectedVariationItem =
            target.oVariations?.items?.find(
              (item) =>
                item.id === costTypeId ||
                `live_price:${item.uid}:${item.livePriceRef}` === costTypeId
            ) || {}

          const markup = set[0]?.cost_matrix_use_company_markup
            ? rootState.session.company.company_default_markup
            : variation.cost_matrix_markup_net

          const labor = _.n(variation.cost_matrix_labor_cost_net)
          const materials = _.n(variation.cost_matrix_materials_cost_net)
          const currentAgg = _.n(variation.cost_matrix_aggregate_cost_net)
          const currentPrice = _.n(variation.cost_matrix_rate_net)
          const adjustedMarkup = currentAgg ? currentPrice / currentAgg : 1

          refEmbues[ref] = {
            aoProperties: variation.aoProperties,
            selected_cost_type_id: costTypeId,
            cost_type_name: variation.cost_type_name,
            cost_type_desc: variation.cost_type_desc,
            file_ids: variation.file_ids,
            aoImages: variation.aoImages,
            cost_type_production_notes: variation.cost_type_production_notes,
            cost_type_hours_per_unit: variation.cost_type_hours_per_unit,
            labor_type_id: variation.labor_type_id,
            labor_type_name: variation.labor_type_name,
            labor_type_rate_net: variation.labor_type_rate_net,
            labor_type_rate_net_index: variation.labor_type_rate_net_index,
            labor_type_is_indexed: variation.labor_type_is_indexed,
            cost_matrix_rate_net: currentPrice,
            cost_matrix_markup_net: markup,
            cost_matrix_labor_cost_net: labor,
            cost_matrix_labor_cost_net_explicit: labor,
            cost_matrix_materials_cost_net: materials,
            cost_matrix_aggregate_cost_net: currentAgg,
            cost_type_is_subcontracted: variation.cost_type_is_subcontracted,
            cost_type_has_materials: variation.cost_type_has_materials,
            cost_type_has_labor: variation.cost_type_has_labor,
            cost_type_minimum_qty_net: variation.cost_type_minimum_qty_net,
            cost_type_minimum_price_net: variation.cost_type_minimum_price_net,
            cost_type_static_materials_cost_net: variation.cost_type_static_materials_cost_net,
            cost_type_static_labor_cost_net: variation.cost_type_static_labor_cost_net,
            cost_type_minimum_labor_cost_net: variation.cost_type_minimum_labor_cost_net,
            cost_type_minimum_materials_cost_net: variation.cost_type_minimum_materials_cost_net,
            cost_type_material_waste_factor_net: variation.cost_type_material_waste_factor_net,
            purchase_unit_of_measure_abbr: variation.purchase_unit_of_measure_abbr,
            purchase_unit_of_measure_name: variation.purchase_unit_of_measure_name,
            purchase_unit_of_measure_id: variation.purchase_unit_of_measure_id,
            cost_type_materials_purchase_qty_per_unit:
              variation.cost_type_materials_purchase_qty_per_unit,
            cost_type_sku: variation.cost_type_sku,
            cost_type_manufacturer_name: variation.cost_type_manufacturer_name,
            vendor_id: variation.vendor_id,
            vendor_name: variation.vendor_name,
            stage_id: variation.stage_id,
            stage_name: variation.stage_name,
            cost_type_weight_per_purchase_unit_net:
              variation.cost_type_weight_per_purchase_unit_net,
            weight_unit_of_measure_id: variation.weight_unit_of_measure_id,
            live_price_reference: variation.live_price_reference,
            cost_item_markup_net_adjusted: adjustedMarkup,
            oVariations: {
              ...(target.oVariations || {}),
              selectedItem: {
                name: variation.cost_type_name,
                desc: variation.cost_type_desc,
                variations: selectedVariationItem.variations || {},
                type: 'cost_type',
                id: costTypeId,
                rate: currentPrice,
                cost: currentAgg
              }
            }
          }
        }

        // Set field values
        return dispatch(
          `${store}/field`,
          {
            changes: refEmbues,
            explicit: refEmbues,
            skipLocalAudit: true
          },
          { root: true }
        )
      },
      /**
       * From the locked selections determine the remaining
       * possible variations
       * @param state
       * @param dispatch
       * @param payload
       * @returns {Promise<void>}
       */
      async findPossibleVariations({ rootState }, payload) {
        const {
          lockedSelections = {},
          chosenSelections = {},
          /**
           * Parent cost_type/cost_item refId
           */
          refId = false,
          store = 'Quote',
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const variationTypes = object.oVariations?.variationTypes ?? {}
        const variationItems = object.oVariations?.items ?? []
        const lockedKeys = [...Object.keys(lockedSelections), ...Object.keys(chosenSelections)]
        const allVariations = getAllVariationPossibilities(variationItems)
        const avail = allVariations

        if (lockedKeys.length) {
          // Accumulated selections for each step
          const accumSelections = {}

          variationTypes.forEach((vtype, index) => {
            if (vtype.name in lockedSelections) {
              avail[vtype.name] = [lockedSelections[vtype.name]]
              accumSelections[vtype.name] = lockedSelections[vtype.name]
              return
            }

            // all possibilities are available for the first option,
            // as long as it is not locked
            if (index === 0) {
              avail[vtype.name] = allVariations[vtype.name]
              // But we still want to add it to accum selections so it filters
              // the subsequent results correctly
              if (vtype.name in chosenSelections)
                accumSelections[vtype.name] = chosenSelections[vtype.name]

              return
            }

            // Get a subset that is available based on the previous position
            const prevType = variationTypes[index - 1]
            const prevSelection = chosenSelections[prevType.name] || lockedSelections[prevType.name]

            // if there is no previous selection or locked value, all can be available
            if (!prevSelection) {
              avail[vtype.name] = allVariations[vtype.name]
              return
            }

            // Add to accumulation based on previous position selection
            accumSelections[prevType.name] = prevSelection

            // filter items that are possible based on PREVIOUS selections
            const poss = variationItems.filter((vi) => {
              return Object.keys(accumSelections).every(
                (key) =>
                  String(vi.variations[key]).toLowerCase() ===
                  String(accumSelections[key]).toLowerCase()
              )
            })

            const possVariations = getAllVariationPossibilities(poss)

            if (vtype.name in possVariations && possVariations[vtype.name].length) {
              avail[vtype.name] = possVariations[vtype.name]
            }
          })
        }

        if (Object.keys(chosenSelections).length) {
          Object.keys(chosenSelections).forEach((vtype) => {
            const index = avail[vtype]?.indexOf(chosenSelections[vtype])

            if (index > 0) {
              const theChosenOne = avail[vtype].splice(index, 1)[0]
              avail[vtype].unshift(theChosenOne)
            }
          })
        }

        return avail
      },

      async deselectVariation({ rootState, dispatch }, payload) {
        const {
          /**
           * Parent cost_type/cost_item refId
           */
          refId = false,
          store = 'Quote',
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const explicitChanges = {
          cost_type_hours_per_unit: 0,
          cost_matrix_materials_cost_net: 0,
          cost_type_name: object.oMeta?.optionGroupName ?? object.cost_type_name,
          oVariations: {
            ...object.oVariations,
            selectedItem: null
          }
        }

        // Set field values
        return dispatch('reportChanges', {
          ...payload,
          explicitChanges,
          changes: explicitChanges,
          refId,
          store,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float markup
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async findBestVariation({ rootState }, payload) {
        const {
          variationType,
          variationChoice,
          /**
           * Parent cost_type/cost_item refId
           */
          refId = false,
          store = 'Quote',
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        return findBestVariation(object, variationType, variationChoice)
      },

      async getVariants({ rootState, dispatch }, payload) {
        const {
          refId,
          store,
          normalized = rootState[store].normalized,
          object = normalized[refId],
          zipcode = null
        } = payload

        const parentId = object.oVariations?.parentId ?? object.variation_parent_cost_type_id

        if (!parentId) {
          console.debug('could not load variants')
        }

        if (object.oVariations?.items.length) return object.oVariations?.items

        const items = await _.throttle(
          async () =>
            await dispatch(
              'CostType/getVariants',
              {
                id: parentId,
                zipcode
              },
              { root: true }
            ),
          { delay: 100, key: parentId }
        )

        const oVariations = {
          ...object.oVariations,
          items
        }
        return dispatch(
          `${store}/field`,
          {
            changes: {
              [refId]: { oVariations }
            },
            immediate: true,
            explicit: false,
            skipLocalAudit: true,
            skipAudit: true
          },
          { root: true }
        )
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float markup
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async embueVariant({ dispatch, state }, payload) {
        const {
          /**
           * Parent cost_type/cost_item refId
           */
          refId,
          object = state.normalized[refId],
          store = 'Quote',
          variantId,
          parentId
        } = payload

        let [{ object: parent }, { object: variant = null }] = await Promise.all([
          dispatch(
            `CostType/fetch`,
            {
              id: parentId
            },
            { root: true }
          ),
          (variantId &&
            dispatch(
              `CostType/fetch`,
              {
                id: variantId
              },
              { root: true }
            )) ||
            null
        ])

        // make sure to run the defaulter on the parent
        // fetch variants
        parent.oVariations = CostType.getComputedDependants().oVariations(parent)

        // Should now trigger for most, since the fetch will not
        // generally return the variants
        // next step is to avoid storing this in the items when saving
        // the item as part of a quote, so that it can be fetched just in time
        // perhaps from the removeBulk function
        // This should now occur JIT
        // if (!parent.oVariations?.items?.length) {
        //   parent.oVariations.items = await dispatch(
        //     'CostType/getVariants',
        //     {
        //       id: parentId
        //     },
        //     { root: true }
        //   )
        // }

        variant = variant ?? parent // if nothing pre-selected

        const explicitChanges = {
          type: 'cost_item',
          cost_type_id: parent.cost_type_id, // always sit as the parent
          selected_cost_type_id: variant.cost_type_id,
          cost_type_name: variant.cost_type_name,
          cost_type_desc: variant.cost_type_desc,
          file_ids: variant.file_ids,
          cost_type_production_notes: variant.cost_type_production_notes,
          cost_type_hours_per_unit: variant.cost_type_hours_per_unit,

          labor_type_id: variant.labor_type_id,
          labor_type_name: variant.labor_type_name,
          labor_type_rate_net: variant.labor_type_rate_net,

          cost_matrix_rate_net: variant.cost_matrix_rate_net,
          cost_matrix_markup_net: variant.cost_matrix_markup_net,
          cost_matrix_labor_cost_net: variant.cost_matrix_labor_cost_net,
          cost_matrix_materials_cost_net: variant.cost_matrix_materials_cost_net,
          cost_matrix_aggregate_cost_net: variant.cost_matrix_aggregate_cost_net,
          cost_type_is_subcontracted: variant.cost_type_is_subcontracted,
          cost_type_has_materials: variant.cost_type_has_materials,
          cost_type_has_labor: variant.cost_type_has_labor,
          cost_type_minimum_qty_net: variant.cost_type_minimum_qty_net,
          cost_type_minimum_price_net: variant.cost_type_minimum_price_net,
          cost_type_static_materials_cost_net: variant.cost_type_static_materials_cost_net,
          cost_type_static_labor_cost_net: variant.cost_type_static_labor_cost_net,
          cost_type_minimum_labor_cost_net: variant.cost_type_minimum_labor_cost_net,
          cost_type_minimum_materials_cost_net: variant.cost_type_minimum_materials_cost_net,
          cost_type_material_waste_factor_net: variant.cost_type_material_waste_factor_net,
          purchase_unit_of_measure_abbr: variant.purchase_unit_of_measure_abbr,
          purchase_unit_of_measure_name: variant.purchase_unit_of_measure_name,
          purchase_unit_of_measure_id: variant.purchase_unit_of_measure_id,
          cost_type_materials_purchase_qty_per_unit:
            variant.cost_type_materials_purchase_qty_per_unit,
          cost_type_sku: variant.cost_type_sku,
          cost_type_manufacturer_name: variant.cost_type_manufacturer_name,
          vendor_id: variant.vendor_id,
          vendor_name: variant.vendor_name,
          stage_id: variant.stage_id,
          stage_name: variant.stage_name,
          cost_type_weight_per_purchase_unit_net: variant.cost_type_weight_per_purchase_unit_net,
          weight_unit_of_measure_id: variant.weight_unit_of_measure_id,
          live_price_reference: variant.live_price_reference,
          aoImages: variant.aoImages,
          csi_code_id: variant.csi_code_id,

          live_price_online_stock: variant.live_price_online_stock,
          live_price_store_stock: variant.live_price_store_stock,
          live_price_vendor_id: variant.live_price_vendor_id,
          live_price_vendor_name: variant.live_price_vendor_name,
          live_price_item_url: variant.live_price_item_url,
          live_price_store_id: variant.live_price_store_id,
          live_price_store_name: variant.live_price_store_name,

          oMeta: {
            ...(variant.oMeta ?? {}),
            optionGroupName: parent.oMeta?.optionGroupName ?? parent.cost_type_name,
            optionGroupDesc: parent.oMeta?.optionGroupDesc ?? ''
          }
        }

        const foundInParent =
          parent.oVariations?.items?.find((it) => it.id === variant.cost_type_id) ?? null
        const variantVariations = foundInParent?.variations ?? {}

        // Now set the variations
        explicitChanges.oVariations = {
          ...(parent.oVariations ?? {}),
          parentId: parent.cost_type_id,
          selectedItem: {
            ...defaultVariationItem,
            uid: v4(),
            ...(foundInParent || {}),
            // Full item name
            name: explicitChanges.cost_type_name,
            id: explicitChanges.cost_type_id,
            desc: explicitChanges.cost_type_desc,
            rate: explicitChanges.cost_matrix_rate_net,
            cost: explicitChanges.cost_matrix_aggregate_cost_net,
            fileIds: explicitChanges.file_ids,
            aoImages: explicitChanges.file_ids,
            sku: explicitChanges.cost_type_sku,
            labor_type_id: explicitChanges.labor_type_id,
            live_price_reference: explicitChanges.live_price_reference,
            livePriceRef: explicitChanges.live_price_reference,
            variations: variantVariations
          }
        }

        // Set field values
        return dispatch('reportChanges', {
          ...payload,
          explicitChanges,
          changes: explicitChanges,
          refId,
          store,
          object,
          parent,
          variant
        })
      },

      /**
       * Will reset flags that should never be set on a bradn new item. This should be done before saving
       * and before adding an item. Can be used on a cost_item or cost_type. This will eraise item_id and other ^item_
       * fields so make sure to add that back after if it is necessary, or make sure it has a refId field set before
       * saving. This method does not affect refId or parentRefId. If you need to change those use
       * Normalize.rereference() method
       * @param dispatch
       * @param payload
       * @returns {Promise<void>}
       */
      async resetFlags({ dispatch }, payload = {}) {
        const { resetAddons = true } = payload
        let { object } = await dispatch('resolveObject', payload)
        const changes = {
          aoStageTasks: (object.aoStageTasks || []).map((t) => ({
            ...t,
            task_id: null, // make sure task id always null
            task_stage_object_id: null,
            quote_id: null,
            client_id: null,
            invoice_id: null,
            // keep task_stage_object_status and _id
            // Also anything that hints at being completed
            task_completed_by: null,
            completer_id: null,
            creator_id: null,
            company_id: null,
            task_status: 'u',
            task_time_completed: null,
            task_completer: null,
            oOwner: null,
            task_owner: null,
            owner_id: null,
            aoComments: [],
            oClient: null,
            oQuote: null,
            oInvoice: null,
            task_list_id: null
          })),
          addon_is_upgraded: 0,
          addon_upgraded_by: null,
          addon_time_upgraded: null,
          upgradesOriginalKey: null,
          addon_is_saved: 0,

          cost_item_actual_materials_cost_net: null,
          cost_item_actual_labor_cost_net: null,
          cost_item_actual_total_cost_net: null,
          cost_item_actual_materials_cost_net_explicit: null,
          cost_item_actual_labor_cost_net_explicit: null,
          cost_item_actual_total_cost_net_explicit: null,
          cost_item_is_complete_according_to_general: null,
          cost_item_actual_total_hours: null,

          change_order_id: null,

          item_id: null,
          // Remove original tags from addons
          // remove bulk items
          aoAddons: resetAddons
            ? (object.aoAddons || [])
                .map((a) => ({
                  ...a,
                  ...(a.bulk &&
                  Object.keys(a.bulk).length &&
                  (a.id || (a.bulk && (a.bulk.cost_type_id || a.bulk.assembly_id)))
                    ? {
                        id: a.id || (a.bulk && (a.bulk.cost_type_id || a.bulk.assembly_id)) || null,
                        type: a.type || (a.bulk && (a.bulk.type || a.bulk.type)) || null,
                        bulk: null,
                        original: a.bulk && Object.keys(a.bulk).length ? 1 : 0
                      }
                    : {})
                }))
                .filter((a) => a.id)
            : object.aoAddons || []
        }

        const newObject = {
          ...object,
          ...changes
        }

        const whiteList = ['item_type']

        Object.keys(newObject).forEach((field) => {
          let val = newObject[field]
          // remove parent or client or item data
          if (/^(client_|parent_)?item_/.test(field) && !whiteList.includes(field)) {
            changes[field] = null
            val = null
          } else if (whiteList.includes(field)) {
            changes[field] = val
          }
          newObject[field] = val
        })
        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges: changes,
          object: newObject,
          auditLocal: payload.auditLocal || false,
          auditFull: payload.auditFull || false
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootStage
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param int vendorFullyPaid
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async markVendorFullyPaid({ dispatch }, payload) {
        const { store = 'CostItem' } = payload

        const { set: selected } = await dispatch(`${store}/resolveObject`, payload, { root: true })
        return dispatch(
          'Item/markVendorFullyPaid',
          {
            ...payload,
            selected
          },
          { root: true }
        )
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param float markup
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setMarkup({ dispatch }, payload) {
        return dispatch('setTargetMarkup', { ...payload, targetMarkup: payload.markup })
      },

      /**
       * Setting for all quantity ie: cost_item_materials_cost_net
       *   NOT cost_matrix_materials_cost_net
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param int isSubcontracted
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setIsSubcontracted({ dispatch }, payload) {
        return dispatch(
          'CostType/setIsSubcontracted',
          {
            ...payload,
            store: payload.store || 'CostItem'
          },
          { root: true }
        )
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param float materialsCost
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setMaterialsCost({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostItem',
          object = _.imm(rootState[store].normalized[refId]),
          holdPrice = false,
          materialsCost: cost
        } = payload

        let materials = _.n(cost)
        const matrix = materials / (_.n(object.cost_item_qty_net) || 1)

        const explicitChanges = {
          cost_item_materials_cost_net: materials
        }

        let { changes } = await dispatch(
          'CostType/setMaterialsCost',
          {
            store,
            object,
            materialsCost: matrix,
            auditLocal: false
          },
          { root: true }
        )

        changes = {
          ...explicitChanges,
          ...changes
        }

        if (holdPrice) {
          const laborCost = _.n(object.cost_item_labor_cost_net)
          let agg = materials + laborCost
          const qty = _.n(object.cost_item_qty_net)
          // In order to hold price there must be SOME costs
          // so we add a 0.000001 cost
          if (_.eq(agg, 0, 6) && holdPrice) {
            // We want final price to be 0.001 so it is under 1 cent
            const materialsPer = 0.0001 / qty
            agg = 0.0001
            changes = {
              ...changes,
              cost_matrix_materials_cost_net: materialsPer
            }
          }
          const targetMarkup =
            _.n(object.cost_item_price_net) / agg || _.n(object.cost_item_markup_net_adjusted)
          const { changes: markupChanges } = await dispatch('setTargetMarkup', {
            object,
            targetMarkup,
            store,
            auditLocal: false
          })
          changes = {
            ...changes,
            ...markupChanges
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param float laborCost
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setLaborCost({ dispatch, rootState }, payload) {
        const {
          refId = null,
          store = 'CostItem',
          laborCost: cost,
          holdPrice = false,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const labor = _.n(cost)
        const qty = _.n(object.cost_item_qty_net) || 1
        const staticCost = _.n(object.cost_type_static_labor_cost_net)
        // Minimum is for the entire value of full quantity_multiplier multiplied _net value,
        // NOT the _base value
        const min = _.n(object.cost_type_minimum_labor_cost_net)

        const newMinAdjustment = min - labor > 0 ? min - labor : 0
        const newLabor = labor - newMinAdjustment - staticCost
        const newLaborPer = newLabor / qty

        let { changes } = await dispatch(
          'CostType/setLaborCost',
          {
            object,
            store,
            laborCost: newLaborPer,
            auditLocal: false
          },
          { root: true }
        )

        const explicitChanges = {
          cost_item_labor_cost_net: labor
        }

        changes = {
          ...changes,
          ...explicitChanges
        }

        if (holdPrice) {
          let materialsCost = _.n(object.cost_item_materials_cost_net)
          let agg = materialsCost + labor
          const qty = _.n(object.cost_item_qty_net)
          // In order to hold price there must be SOME costs
          // so we add a 0.0001 cost
          if (_.eq(agg, 0, 6) && holdPrice) {
            // We want final price to be 0.001 so it is under 1 cent
            const materialsPer = 0.0001 / qty
            agg = 0.0001
            changes = {
              ...changes,
              cost_matrix_materials_cost_net: materialsPer
            }
          }
          const targetMarkup =
            _.n(object.cost_item_price_net) / agg || _.n(object.cost_item_markup_net_adjusted)
          const { changes: markupChanges } = await dispatch('setTargetMarkup', {
            object,
            targetMarkup,
            store,
            auditLocal: false
          })
          changes = {
            ...changes,
            ...markupChanges
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param float totalActualCost
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTotalActualCost({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostItem',
          totalActualCost: cost,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const laborPercentage =
          (_.n(object.cost_item_actual_labor_cost_net) || _.n(object.cost_item_labor_cost_net)) /
          (_.n(object.cost_item_actual_total_cost_net) || _.n(object.cost_item_total_cost_net) || 1)
        const labor = _.n(cost) * laborPercentage
        const materials = _.n(cost) - labor

        // If the actual cost change is set to MORE than what has already
        // been paid to the vendor, then the item is marked as NOT fully paid.
        // If the the actual cost change is set to LESS than what was already
        // paid to the vendor, it is marked as fully paid
        const actual = _.n(cost)
        const paid = _.n(object.item_vendor_paid_net)
        let fully = 0
        if (actual > paid) {
          fully = 0
        } else if (actual <= paid || _.eq(actual, paid, 2)) {
          fully = 1
        }

        const explicitChanges = {
          cost_item_actual_total_cost_net: _.n(cost)
        }

        let changes = {
          cost_item_actual_materials_cost_net_explicit: materials,
          cost_item_actual_labor_cost_net_explicit: labor,
          item_vendor_is_fully_paid: fully,
          ...explicitChanges
        }

        if (object.item_id) {
          await dispatch(
            'Item/saveActualCost',
            {
              id: object.item_id,
              materials,
              labor,
              object
            },
            { root: true }
          )
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param float totalCost
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTotalCost({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostItem',
          totalCost: cost,
          object = _.imm(rootState[store].normalized[refId]),
          holdPrice = false
        } = payload

        const qty = _.n(object.cost_item_qty_net) || 1
        let aggregateCost = cost / qty

        // In order to hold price there must be SOME costs
        // so we add a 0.000001 cost
        // We want final price to be 0.001 so it is under 1 cent
        if (holdPrice && _.eq(aggregateCost, 0, 6)) aggregateCost = _.divide(0.0001, qty)

        const { changes: aggChanges } = await dispatch(
          'CostType/setAggregateCost',
          {
            object,
            store,
            aggregateCost,
            holdPrice: false
          },
          { root: true }
        )

        const explicitChanges = {
          cost_item_total_cost_net: cost
        }

        let changes = {
          ...explicitChanges,
          ...aggChanges
        }

        if (holdPrice) {
          const currentMarkup =
            _.n(object.cost_item_markup_net_adjusted) || _.n(object.cost_matrix_markup_net)
          const markup = _.divide(object.cost_item_price_net, aggregateCost * qty) || currentMarkup
          const { changes: markupChanges } = await dispatch('setMarkup', {
            object,
            markup,
            store,
            auditLocal: false
          })
          changes = {
            ...changes,
            ...markupChanges
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param float totalHours         net hours, NOT base hours
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTotalHours({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostItem',
          totalHours: hours,
          object = _.imm(rootState[store].normalized[refId]),
          holdPrice = false
        } = payload

        const hoursPer = _.n(hours) / (_.n(object.cost_item_qty_net) || 1)
        const hoursBase = _.n(hours) / (_.n(object.quantity_multiplier) || 1)

        const explicitChanges = {
          cost_item_total_hours_base: hoursBase,
          cost_item_total_hours: hours
        }

        let { changes } = await dispatch(
          'CostType/setHours',
          {
            store,
            object,
            hours: hoursPer,
            auditLocal: false
          },
          { root: true }
        )

        if (holdPrice) {
          const { changes: auditedChanges } = await dispatch('reportChanges', {
            store,
            object,
            changes,
            auditLocal: false
          })
          const newObject = {
            ...object,
            ...changes,
            ...auditedChanges
          }
          let materialsCost = _.n(newObject.cost_item_materials_cost_net)
          const laborCost = _.n(newObject.cost_item_labor_cost_net)
          let agg = materialsCost + laborCost
          // In order to hold price there must be SOME costs
          // so we add a 0.000001 cost
          if (_.eq(agg, 0, 6) && holdPrice) {
            materialsCost = 0.000001
            agg = materialsCost
            const { changes: holdChanges } = await dispatch('setMaterialsCost', {
              object,
              store,
              materialsCost,
              holdPrice: true,
              auditLocal: false
            })
            changes = {
              ...changes,
              ...holdChanges
            }
          }
          if (agg) {
            const targetMarkup =
              _.n(object.cost_item_price_net) / agg || _.n(object.cost_item_markup_net_adjusted)
            const { changes: markupChanges } = await dispatch('setTargetMarkup', {
              object,
              targetMarkup,
              store,
              auditLocal: false
            })
            changes = {
              ...changes,
              ...markupChanges
            }
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param float targetMarkup
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTargetMarkup({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostItem',
          targetMarkup: rmarkup,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        let markup = rmarkup
        if (markup >= 4) {
          let marginEquiv = _.markupToMargin(markup)
          marginEquiv = _.eq(marginEquiv, 0.99, 2) ? 1 : marginEquiv
          const intendedMarkup = 1 + marginEquiv
          const intendedMargin = _.markupToMargin(intendedMarkup)
          dispatch(
            'alert',
            {
              ai: true,
              timeout: 20000,
              message: `Are you sure you want a profit margin of ${_.format(marginEquiv * 100, 'number', 0)}%? That equates to a markup of ${_.format(markup, 'number', 0)}x or ${_.format((markup - 1) * 100, 'number', 0)}% of your costs.`,
              actions: [
                {
                  title: `I actually intended to apply a ${_.format(intendedMarkup, 'number', 2)}x markup on my costs (equivalent to ${_.format(intendedMargin * 100, 'number', 0)}% profit margin)`,
                  action: () => {
                    return dispatch('setTargetMarkup', {
                      ...payload,
                      targetMarkup: intendedMarkup,
                      refId: refId ?? object.refId
                    })
                  }
                }
              ]
            },
            { root: true }
          )
        }

        const target = _.n(markup)
        const adjustment = _.n(object.cost_item_markup_percentage_adjustment)
        /**
         * Because the markup for items can be
         * adjusted based on a quote or assembly-level adjustment
         * we need to adjust for the fact that for every
         * basis point we increase markup, the markup adjustment will
         * also increase because it is based on a percentage increase.
         *
         * Because final markup is calculated like this:
         * finalMarkup = regularMarkup + (regularMarkup - 1) • percentageAdjustment
         *
         * If we specifically want to set what finalMarkup is (which is the point of this method)
         * then we need to pre-adjuste for the percentage adjustment:
         * targetMarkup = regularMarkup + (regularMarkup - 1) • percentAdjustment
         * So we need to know what to set regularMarkup to so that targetMarkup = v
         * (what we are setting here). so we need to isollate
         * regularMarkup so we know what to set it to.
         *
         * t = r + (r - 1)a
         * t = r + ra - a // simplify
         * t = r(1 + a) - a // separate a and r
         * t + a = r(1 + a) // divide by (1+a)
         * (t + a) / (1 + a) = r
         *
         * t: target markup
         * r: regular markup
         * a: existing adjustment percentage
         *
         * So:
         */
        const denom = 1 + adjustment || 1
        let final = 0
        if (!_.eq(denom, 0)) {
          final = (target + adjustment) / (1 + adjustment)
        }

        const explicitChanges = {
          cost_matrix_markup_net: final
        }
        let { changes } = await dispatch(
          'CostType/setMarkup',
          {
            store,
            object,
            markup: final,
            auditLocal: false
          },
          { root: true }
        )

        changes = {
          ...changes,
          cost_item_markup_net_adjusted: target,
          cost_item_markup_net_adjustment: target - final
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param float totalPrice
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTotalPrice({ dispatch, rootState, rootGetters: gets }, payload) {
        const {
          refId = false,
          store = 'CostItem',
          totalPrice: price,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const explicitChanges = {
          cost_item_price_net: price
        }
        let changes = {
          ...explicitChanges
        }

        const cost = _.n(object.cost_item_total_cost_net)
        const total = _.n(price)

        if (!_.eq(cost, 0)) {
          const defaultMarkup = gets.defaultMarkup
          let targetMarkup = total / (_.n(object.cost_item_total_cost_net) || total / defaultMarkup)
          if (_.eq(total, 0)) {
            targetMarkup = 0
          }

          const { changes: markupChanges } = await dispatch('setTargetMarkup', {
            ...payload,
            targetMarkup
          })
          changes = {
            ...changes,
            ...markupChanges
          }
        } else {
          const markup = _.n(object.cost_item_markup_net_adjusted)
          const totalCost = price / markup
          const { changes: priceChanges } = await dispatch('setTotalCost', {
            store,
            object,
            totalCost
          })
          changes = {
            ...changes,
            ...priceChanges,
            cost_matrix_markup_net: markup,
            cost_item_markup_net_adjusted: markup
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param int included
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setIncluded({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'Quote',
          included = 1,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const objType = object.type === 'cost_item' ? 'cost_item' : 'assembly'
        const otherObjType = object.type === 'cost_item' ? 'cost_item' : 'quote'

        let changes = {}
        let explicitChanges = {}
        let origQty = object[`${otherObjType}_qty_net_base`]

        if (!included) {
          changes[`${objType}_is_included`] = 0
          changes[`${otherObjType}_qty_net_base_original`] = origQty
          changes.assembly_orig_price_net = object.quote_price_net
        } else {
          changes[`${objType}_is_included`] = 1
          changes[`${otherObjType}_qty_net_base`] = object[`${otherObjType}_qty_net_base_original`]
        }

        explicitChanges = { ...changes }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param float qty
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setQty({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostItem',
          qty: quantity,
          equation = null,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        if (object.type !== 'cost_item') {
          return dispatch('reportChanges', {
            ...payload,
            changes: {},
            object
          })
        }

        const qty = _.n(quantity)
        const qtyBase = qty / (_.n(object.quantity_multiplier) || 1)

        const explicitChanges = {
          cost_item_qty_net: qty,
          cost_type_qty_equation: equation,
          oEquations: {
            cost_item_qty_net: null,
            cost_item_qty_net_base: equation || null
          }
        }
        let changes = {
          ...explicitChanges,
          cost_item_qty_net_base: qtyBase
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async unlinkQty({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostItem',
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        let changes = {
          cost_type_qty_equation: '',
          cost_item_qty_net_base: object.cost_item_qty_net_base
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param string|int unitOfMeasure
       *    @param bool linkQty             whether to link qty to the dimension linked to this
       *                                    unit of measure or not if possible.  default true.
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setUnitOfMeasure({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostItem',
          unitOfMeasure: id,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const { object: uom } = await dispatch(
          'UnitOfMeasure/resolveObject',
          {
            id
          },
          { root: true }
        )

        if (uom.unit_of_measure_id in _.conversionTables) {
          dispatch(
            'alert',
            {
              message: `Psst... when compatible dimensions are used for items with ${uom.unit_of_measure_name} (${uom.unit_of_measure_abbr}) units they are converted automatically, so you don't need to!`,
              ai: true
            },
            { root: true }
          )
        }

        const explicitChanges = {
          unit_of_measure_id: uom.unit_of_measure_id || null,
          unit_of_measure_name: uom.unit_of_measure_name || null,
          unit_of_measure_abbr: uom.unit_of_measure_abbr || null,
          dimension_measure_type: uom.dimension_measure_type || null
        }
        let changes = {
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param string|id vendor
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      setVendor({ dispatch }, payload) {
        return dispatch(
          'CostType/setVendor',
          {
            ...payload,
            store: payload.store || 'CostItem'
          },
          { root: true }
        )
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param string|id laborType
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      setLaborType({ dispatch }, payload) {
        return dispatch(
          'CostType/setLaborType',
          {
            ...payload,
            store: payload.store || 'CostItem'
          },
          { root: true }
        )
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param string|id tradeType
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      setTradeType({ dispatch }, payload) {
        return dispatch(
          'CostType/setTradeType',
          {
            ...payload,
            store: payload.store || 'CostItem'
          },
          { root: true }
        )
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool holdPrice           if set to true, markup will be adjusted to hold the
       *                                    price the same, if applicable and possible
       *    @param string|id stage
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      setStage({ dispatch }, payload) {
        return dispatch(
          'CostType/setStage',
          {
            ...payload,
            store: payload.store || 'CostItem'
          },
          { root: true }
        )
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param payload
       * @returns {Promise<{object: *}>}
       */
      async markPending({ dispatch }, payload) {
        const { object: resolved } = await dispatch('resolveObject', payload)
        const { object } = await dispatch('ajax', {
          path: `item/markPending/${resolved.item_id || resolved.refId}`
        })
        return { ...payload, object }
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param payload
       * @returns {Promise<{object: *}>}
       */
      async markInProgress({ dispatch }, payload) {
        const { object: resolved } = await dispatch('resolveObject', payload)
        const { object } = await dispatch('ajax', {
          path: `item/markInProgress/${resolved.item_id || resolved.refId || payload.item_id || payload.refId}`
        })
        return { ...payload, object }
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param payload
       * @returns {Promise<{object: *}>}
       */
      async markCompleted({ dispatch }, payload) {
        const { object: resolved } = await dispatch('resolveObject', payload)
        const { object } = await dispatch('ajax', {
          path: `item/markCompleted/${resolved.item_id || resolved.refId || payload.item_id || payload.refId}`
        })
        return { ...payload, object }
      }
    }
  },

  getItemAssigneeFormatFromAssignee,
  getItemAssigneeFormatFromAssignees,
  getAssigneeIdsFromItemAssignees,
  validateAssignees,
  getIncludedPrice
}
