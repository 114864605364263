// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

import { paymentTerms, defaultPaymentTerm } from '../PaymentTerms'

export default {
  type: 'payment_term',

  skipAudit: true,

  fields: {
    payment_term_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    quote_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'quote'
    },
    company_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'quote'
    },
    payment_term_type: {
      type: 'string',
      filter: true,
      save: true,
      format: false,
      mapTo: false,
      default: defaultPaymentTerm,
      set: (value) => {
        return paymentTerms.includes(value) ? value : defaultPaymentTerm
      },
      get: (value) => {
        return paymentTerms.includes(value) ? value : defaultPaymentTerm
      }
    },
    payment_term_show_details: {
      type: 'int',
      filter: true,
      save: true,
      format: false,
      mapTo: false,
      default: 1
    },
    payment_term_time_created: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    payment_term_time_last_modified: {
      type: 'string',
      filter: true,
      format: 'datetime',
      mapTo: false
    }
  },
  generateVueActions() {
    return {}
  }
}
