import { ref, computed, onMounted, watch } from 'vue'

export const useViewport = ({ viewportRef, svgRef }) => {
  // Core viewport state
  const zoom = ref(1)
  const panOffset = ref({ x: 0, y: 0 })
  const canvasSize = ref(100000)
  const viewBox = ref({ width: 1000, height: 1000 })
  const viewportRect = ref({})

  // Update viewport size based on actual dimensions
  const updateViewportSize = () => {
    if (!viewportRef.value) return
    viewportRect.value = viewportRef.value.getBoundingClientRect()
    viewBox.value = {
      width: viewportRect.value.width,
      height: viewportRect.value.height
    }
  }

  // Watch for viewport ref changes and resize
  watch(viewportRef, (newRef) => {
    if (newRef) {
      updateViewportSize()
    }
  })

  // Set up resize observer
  onMounted(() => {
    if (!viewportRef.value) return

    const resizeObserver = new ResizeObserver(() => {
      updateViewportSize()
    })

    resizeObserver.observe(viewportRef.value)

    // Initial size update
    updateViewportSize()
  })

  // Computed viewBox dimensions based on zoom and actual viewport size
  const computedViewBox = computed(() => {
    const width = viewBox.value.width / zoom.value
    const height = viewBox.value.height / zoom.value
    return {
      x: panOffset.value.x,
      y: panOffset.value.y,
      width,
      height,
      toString() {
        return `${this.x} ${this.y} ${this.width} ${this.height}`
      }
    }
  })

  // Convert screen coordinates to SVG coordinates
  const screenToViewportPoint = (x, y) => {
    if (!viewportRect.value) return null

    const pt = {}
    pt.x = x - viewportRect.value.x
    pt.y = y - viewportRect.value.y

    return pt
  }

  const screenToSVGPoint = (x, y) => {
    if (!svgRef.value) return null

    const pt = {}
    pt.x = (x - viewportRect.value.x) / zoom.value + panOffset.value.x
    pt.y = (y - viewportRect.value.y) / zoom.value + panOffset.value.y

    return pt
  }

  // Convert SVG coordinates to screen coordinates
  const svgToScreenPoint = (x, y) => {
    if (!svgRef.value) return null

    const pt = {}
    pt.x = (x - panOffset.value.x) * zoom.value + viewportRect.value.x
    pt.y = (y - panOffset.value.y) * zoom.value + viewportRect.value.y

    return pt
  }

  // Find element at point
  const findElementAtPoint = (svgPoint) => {
    if (!svgRef.value) return null

    const svg = svgRef.value
    const pt = svg.createSVGPoint()
    pt.x = svgPoint.x
    pt.y = svgPoint.y

    // Convert viewport coordinates to SVG coordinates
    const screenPoint = svgToScreenPoint(pt.x, pt.y)

    // Use elementsFromPoint() to get elements at the adjusted coordinates
    const elements = document
      .elementsFromPoint(screenPoint.x, screenPoint.y)
      .filter((el) => svg.contains(el)) // Filter out elements that are not in the SVG

    return elements?.[0] ?? null
  }

  // Find layer at point
  const findLayerAtPoint = (svgPoint) => {
    const element = findElementAtPoint(svgPoint)
    if (!element) return null

    // Traverse up the DOM to find the layer group
    let current = element
    while (current && current !== svgRef.value) {
      const layerId = current.getAttribute('data-layer-id')
      if (layerId) {
        return {
          id: layerId,
          type: current.getAttribute('data-layer-type'),
          element: current
        }
      }
      current = current.parentElement
    }
    return null
  }

  // Constrain pan offset to keep content in view
  const constrainPanOffset = (offset) => {
    return {
      x: Math.max(0, Math.min(offset.x, canvasSize.value - viewBox.value.width / zoom.value)),
      y: Math.max(0, Math.min(offset.y, canvasSize.value - viewBox.value.height / zoom.value))
    }
  }

  // Update pan offset with constraints
  const updatePanOffset = (newOffset) => {
    panOffset.value = constrainPanOffset(newOffset)
  }

  // Update zoom with constraints
  const updateZoom = (newZoom, svgPoint = null, screenPoint = null) => {
    // Constrain zoom level
    const oldZoom = zoom.value
    zoom.value = Math.min(Math.max(newZoom, 0.1), 5)

    // If both screen and SVG points are provided, adjust pan to maintain position
    if (svgPoint && screenPoint) {
      // Convert screen point to viewport coordinates
      const viewportX = screenPoint.x - viewportRect.value.x
      const viewportY = screenPoint.y - viewportRect.value.y

      // Calculate the new pan offset that keeps svgPoint at svgpoint
      updatePanOffset({
        x: svgPoint.x - viewportX / zoom.value,
        y: svgPoint.y - viewportY / zoom.value
      })
    }
  }

  // Zoom in/out by factor
  const zoomIn = () => updateZoom(zoom.value * 1.2)
  const zoomOut = () => updateZoom(zoom.value / 1.2)

  // Pan by offset
  const pan = (dx, dy) => {
    updatePanOffset({
      x: panOffset.value.x + dx / zoom.value,
      y: panOffset.value.y + dy / zoom.value
    })
  }

  // Get viewport bounds
  const getViewportBounds = () => {
    if (!viewportRef.value) return null

    const rect = viewportRef.value.getBoundingClientRect()
    return {
      x: rect.x,
      y: rect.y,
      width: rect.width,
      height: rect.height
    }
  }

  // Check if point is in viewport
  const isPointInViewport = (x, y) => {
    const bounds = getViewportBounds()
    if (!bounds) return false

    return (
      x >= bounds.x &&
      x <= bounds.x + bounds.width &&
      y >= bounds.y &&
      y <= bounds.y + bounds.height
    )
  }

  // Get the current transform matrix
  const getTransformMatrix = () => {
    if (!svgRef.value) return null
    return svgRef.value.getScreenCTM()
  }

  // Reset viewport to initial state
  const resetViewport = () => {
    zoom.value = 1
    panOffset.value = { x: 0, y: 0 }
    viewBox.value = { width: 1000, height: 1000 }
  }

  // Get content bounds
  const getContentBounds = (takeoff) => {
    if (!takeoff?.layers?.length) return null

    // Find the bounds of all layers
    let minX = Infinity
    let minY = Infinity
    let maxX = -Infinity
    let maxY = -Infinity

    takeoff.layers.forEach((layer) => {
      if (!layer.position) return

      const { x, y, width, height } = layer.position
      minX = Math.min(minX, x)
      minY = Math.min(minY, y)
      maxX = Math.max(maxX, x + width)
      maxY = Math.max(maxY, y + height)
    })

    // Add padding around the bounds (10% on each side)
    const padding = 0.1
    const totalWidth = maxX - minX
    const totalHeight = maxY - minY
    const paddingX = totalWidth * padding
    const paddingY = totalHeight * padding

    return {
      minX: minX - paddingX,
      minY: minY - paddingY,
      maxX: maxX + paddingX,
      maxY: maxY + paddingY
    }
  }

  // Modify zoomToFit to use getContentBounds
  const zoomToFit = (takeoff) => {
    const bounds = getContentBounds(takeoff)
    if (!bounds) return null

    const { minX, minY, maxX, maxY } = bounds

    // Calculate required zoom to fit
    const contentWidth = maxX - minX
    const contentHeight = maxY - minY
    const viewportWidth = viewBox.value.width
    const viewportHeight = viewBox.value.height

    // Calculate zoom needed to fit both width and height
    const zoomX = viewportWidth / contentWidth
    const zoomY = viewportHeight / contentHeight
    const requiredZoom = Math.min(zoomX, zoomY)

    // Constrain zoom between 0.1 (10%) and 5 (500%)
    const constrainedZoom = Math.min(Math.max(requiredZoom, 0.1), 1)

    // Update zoom and pan offset
    updateZoom(constrainedZoom)

    // Center the content
    const centerX = (minX + maxX) / 2
    const centerY = (minY + maxY) / 2
    const viewportCenterX = viewportWidth / (2 * constrainedZoom)
    const viewportCenterY = viewportHeight / (2 * constrainedZoom)

    updatePanOffset({
      x: centerX - viewportCenterX,
      y: centerY - viewportCenterY
    })

    return { minX, minY, maxX, maxY }
  }

  const layerIsInView = (layer) => {
    const { x, y, width, height } = layer.position

    if (
      panOffset.value.x <= x &&
      panOffset.value.y <= y &&
      viewBox.value.width >= width &&
      viewBox.value.height >= height
    ) {
      return true
    }

    return false
  }

  // Position a layer at the top-left of the viewport
  const positionLayerAtTopLeft = (layer) => {
    // Get the layer's position
    const { x, y } = layer.position

    // Update the pan offset to position the layer at top-left
    updatePanOffset({
      x: x - 200,
      y: y - 200
    })
  }

  return {
    // State
    zoom,
    panOffset,
    viewBox,
    computedViewBox,

    // Coordinate conversion
    screenToSVGPoint,
    screenToViewportPoint,
    svgToScreenPoint,
    findElementAtPoint,
    findLayerAtPoint,

    // Pan and zoom controls
    constrainPanOffset,
    updatePanOffset,
    updateZoom,
    zoomIn,
    zoomOut,
    pan,

    // Viewport utilities
    getViewportBounds,
    isPointInViewport,
    getTransformMatrix,
    updateViewportSize,
    resetViewport,
    canvasSize,
    zoomToFit,
    getContentBounds,
    positionLayerAtTopLeft,
    layerIsInView
  }
}
