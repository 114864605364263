import { ref, watch, onUnmounted, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import type { Ref } from 'vue'

/**
 * Composable for tracking user online status based on company membership
 * Automatically updates user's online status every 10 seconds when user is in a company
 * @param userId - The ID of the user to track
 * @param companyId - Ref to the current company ID the user is in (null/empty when not in a company)
 * @returns Object with tracking state and manual control functions
 */
export function useUserOnlineTracking(userId: string) {
    const store = useStore()
    const isTracking = ref(false)


    const isUserOnline = computed(() => {
        return store.state.trackOnline.usersOnline?.[userId]?.userOnline
    })
    /**
     * Starts tracking the user's online status
     */
    const startTracking = () => {
        isTracking.value = true
        return store.dispatch('subscribeUserOnline',
            userId
        )
    }

    /**
     * Stops tracking the user's online status
     */
    const stopTracking = () => {
        if (!isTracking.value) return;
        store.dispatch('unSubscribeToChannelUserOnline', userId);
        isTracking.value = false;
    }

    onMounted(() => {
        startTracking()
    })


    // Automatically clean up when component unmounts
    onUnmounted(() => {
        stopTracking()
    })

    return {
        isTracking,
        startTracking,
        stopTracking,
        isUserOnline
    }
}