import { toValue, toRefs, ref } from 'vue'
import { useCalculations } from '@/components/Takeoff/composables/useCalculations.js'

export function useSegmentPaths({ zoom }) {
  const getPointPathData = (layer, layers, previewSegment = null) => {
    const segments = getSegmentsFromPoints(layer.points, layer.metadata?.isComplete)
    return getPathData(
      {
        ...layer,
        segments
      },
      layers,
      previewSegment
    )
  }

  const getSegmentsFromPoints = (points, complete = false) => {
    return points.reduce((acc, pt, index) => {
      const nextPt = points[index + 1] ?? null
      if (nextPt || index === 0) acc.push([pt, nextPt ?? pt])
      if (!nextPt && complete && index >= 1) acc.push([pt, points[0]])
      return acc
    }, [])
  }

  // Calculate angle between two segments
  const calculateAngle = (p0, p1, p2) => {
    const v1 = { x: p1.x - p0.x, y: p1.y - p0.y }
    const v2 = { x: p2.x - p1.x, y: p2.y - p1.y }

    const angle1 = Math.atan2(v1.y, v1.x)
    const angle2 = Math.atan2(v2.y, v2.x)

    let angle = Math.abs(angle2 - angle1) * (180 / Math.PI)
    // Normalize to 0-180
    while (angle > 180) angle = 360 - angle
    return angle
  }

  // Find intersecting segments from other layers
  const findIntersectingSegments = (point, radius = 2, currentLayer, layersProvided = []) => {
    const intersectingSegments = []
    const sqRadius = radius * radius // Calculate the square of the radius once
    const pointX = point.x,
      pointY = point.y

    for (let i = 0; i < layersProvided.length; i++) {
      const lineLayer = layersProvided[i]
      if (lineLayer.id === currentLayer.id) continue

      const segments = lineLayer.segments
      if (!segments) continue // Skip if no segments

      for (let j = 0; j < segments.length; j++) {
        const segment = segments[j]
        const start = segment[0],
          end = segment[1]

        const dxStart = pointX - start.x,
          dyStart = pointY - start.y
        const dxEnd = pointX - end.x,
          dyEnd = pointY - end.y

        // Compare squared distances to avoid computing square roots
        const startDistSq = dxStart * dxStart + dyStart * dyStart
        const endDistSq = dxEnd * dxEnd + dyEnd * dyEnd

        if (startDistSq < sqRadius || endDistSq < sqRadius) {
          intersectingSegments.push(segment)
        }
      }
    }

    return intersectingSegments
  }

  // Calculate control points and adjusted points for rounded corners
  const calculateRoundedCorner = (
    p0,
    p1,
    p2,
    radius = 2 / zoom.value,
    currentLayer,
    layersProvided
  ) => {
    // Vector calculations
    const v1x = p1.x - p0.x,
      v1y = p1.y - p0.y,
      v2x = p2.x - p1.x,
      v2y = p2.y - p1.y

    // Length calculations using squared values when possible to avoid sqrt until needed
    const len1Sq = v1x * v1x + v1y * v1y,
      len2Sq = v2x * v2x + v2y * v2y

    if (len1Sq < 1 || len2Sq < 1) return null

    const len1 = Math.sqrt(len1Sq),
      len2 = Math.sqrt(len2Sq)

    // Normalize vectors
    const n1x = v1x / len1,
      n1y = v1y / len1,
      n2x = v2x / len2,
      n2y = v2y / len2

    // Parallel check (dot product)
    const dot = n1x * n2x + n1y * n2y
    if (Math.abs(Math.abs(dot) - 1) < 0.01) return null

    // Intersection check
    const hasIntersections =
      findIntersectingSegments(p1, 2, currentLayer, layersProvided).length > 0

    // Radius calculations
    const adjustedZoom = zoom.value,
      radiusMod = 1.5 / adjustedZoom,
      controlPointMod = 0.5 / adjustedZoom,
      maxRadius = Math.min(len1, len2) / (3 / adjustedZoom),
      useRadius = Math.min(hasIntersections ? radius * radiusMod : radius, maxRadius)

    const radiusRadiusMod = useRadius * radiusMod,
      radiusControlPointMod = useRadius * controlPointMod

    // Calculate endpoints and control points
    const end1 = { x: p1.x - n1x * radiusRadiusMod, y: p1.y - n1y * radiusRadiusMod },
      start2 = { x: p1.x + n2x * radiusRadiusMod, y: p1.y + n2y * radiusRadiusMod },
      c1 = { x: end1.x + n1x * radiusControlPointMod, y: end1.y + n1y * radiusControlPointMod },
      c2 = { x: start2.x - n2x * radiusControlPointMod, y: start2.y - n2y * radiusControlPointMod }

    // Angle and bisector calculations
    const angle = calculateAngle(p0, p1, p2),
      bisectorx = (n1x + n2x) / 2,
      bisectory = (n1y + n2y) / 2,
      bisectorLength = Math.sqrt(bisectorx * bisectorx + bisectory * bisectory),
      normalizedBisectorx = bisectorx / bisectorLength,
      normalizedBisectory = bisectory / bisectorLength

    // Perpendicular vector calculation
    const perpendicularx = -normalizedBisectory,
      perpendiculary = normalizedBisectorx

    // Text position calculation
    const textOffset = useRadius * (12 / adjustedZoom),
      textPosition = {
        x: p1.x + perpendicularx * textOffset,
        y: p1.y + perpendiculary * textOffset
      }

    return { end1, start2, c1, c2, angle, center: p1, textPosition }
  }

  const { getGroupedOuterPolygons } = useCalculations({ zoom })
  const getAreaPathData = (polygons) => {
    const path = polygons
      .map((polygon) => {
        if (!polygon || !polygon.length) return ''
        return `${polygon.reduce((acc, { x, y }, i) => {
          // combine points to create a closed area path
          return `${acc}${i === 0 ? 'M' : 'L'} ${x} ${y}`
        }, '')} Z`
      })
      .join(' ')

    return path
  }

  const getPathData = (layer, layersProvided, previewSegmentProvided = false) => {
    if (!layer?.segments?.length && !previewSegmentProvided) return ''

    let pathData = []
    let areaData = []
    let cornerData = []
    const drawPreview = previewSegmentProvided
    const segments = [
      ...(layer.segments ?? []),
      ...(drawPreview ? [[...previewSegmentProvided, true]] : [])
    ]
    let angleElements = ''

    // Check if shape is closed
    const groupedPolygons = getGroupedOuterPolygons(layer.segments)
    const isExplicitlyClosed = groupedPolygons.every((pg) => pg.isExplicitlyClosed)

    // Draw segments with rounded corners
    for (let i = 0; i < segments.length; i++) {
      const [start, end, preview = false] = segments[i]

      let lineStart = { x: start.x, y: start.y }
      let lineEnd = { x: end.x, y: end.y }
      let corner
      let nextCorner

      // Handle next corner
      if (i < segments.length - 1) {
        // Regular next segment
        const [nextStart, nextEnd] = segments[i + 1]
        if (nextStart.x === end.x && nextStart.y === end.y) {
          nextCorner = calculateRoundedCorner(start, end, nextEnd, 2, layer, layersProvided)
          if (nextCorner) {
            lineEnd.x = nextCorner.end1.x
            lineEnd.y = nextCorner.end1.y
          }
        }
      } else if (isExplicitlyClosed && !preview) {
        // Last segment to first segment corner for closed shapes
        const [firstStart, firstEnd] = segments[0]
        if (firstStart.x === end.x && firstStart.y === end.y) {
          nextCorner = calculateRoundedCorner(start, end, firstEnd, 2, layer, layersProvided)
          if (nextCorner) {
            lineEnd.x = nextCorner.end1.x
            lineEnd.y = nextCorner.end1.y
          }
        }
      }

      // Handle previous corner
      if (i > 0) {
        // Regular previous segment
        const [prevStart, prevEnd] = segments[i - 1]
        if (prevEnd.x === start.x && prevEnd.y === start.y) {
          corner = calculateRoundedCorner(prevStart, prevEnd, end, 2, layer, layersProvided)
          if (corner) {
            cornerData.push({
              start,
              end,
              path: `M ${corner.end1.x} ${corner.end1.y} C ${corner.c1.x} ${corner.c1.y}, ${corner.c2.x} ${corner.c2.y}, ${corner.start2.x} ${corner.start2.y}`
            })
            areaData.push({
              path: `C ${corner.c1.x} ${corner.c1.y}, ${corner.c2.x} ${corner.c2.y}, ${corner.start2.x} ${corner.start2.y}`
            })
            lineStart.x = corner.start2.x
            lineStart.y = corner.start2.y

            if (preview && corner.angle < 175) {
              const angleX = corner.textPosition.x
              const angleY = corner.textPosition.y
              angleElements = `
                <g transform="translate(${angleX - 12 / zoom.value}, ${angleY - 12 / zoom.value}) scale(${1 / zoom.value})">
                  <rect x="-12" y="-8" width="24" height="16" rx="4" fill="white" opacity="0.8"/>
                  <text x="0" y="0" class="angle-text" text-anchor="middle" dominant-baseline="middle">${Math.round(corner.angle)}°</text>
                </g>
              `
            }
          }
        }
      } else if (isExplicitlyClosed && !preview) {
        // First segment to last segment corner for closed shapes
        const lastSegment = segments[segments.length - 1]
        const [lastStart, lastEnd] = lastSegment
        if (lastEnd.x === start.x && lastEnd.y === start.y) {
          corner = calculateRoundedCorner(lastStart, lastEnd, end, 2, layer, layersProvided)
          if (corner) {
            cornerData.push({
              start,
              end,
              path: `M ${corner.end1.x} ${corner.end1.y} C ${corner.c1.x} ${corner.c1.y}, ${corner.c2.x} ${corner.c2.y}, ${corner.start2.x} ${corner.start2.y}`
            })
            areaData.push({
              path: `C ${corner.c1.x} ${corner.c1.y}, ${corner.c2.x} ${corner.c2.y}, ${corner.start2.x} ${corner.start2.y}`
            })
            lineStart.x = corner.start2.x
            lineStart.y = corner.start2.y
          }
        }
      }

      if (i === 0) areaData.push({ path: ` M ${lineStart.x} ${lineStart.y} ` })
      areaData.push({ path: ` L ${lineEnd.x} ${lineEnd.y}` })

      // Calculate midpoint and offset for length label
      const midX = (lineStart.x + lineEnd.x) / 2
      const midY = (lineStart.y + lineEnd.y) / 2

      // Calculate perpendicular offset for label (20 pixels up)
      const dx = lineEnd.x - lineStart.x
      const dy = lineEnd.y - lineStart.y
      const length = Math.sqrt(dx * dx + dy * dy)
      const offsetX = length ? (-dy / length) * 40 : 0
      const offsetY = length ? (dx / length) * 40 : 0

      pathData.push({
        path: ` M ${lineStart.x} ${lineStart.y} L ${lineEnd.x} ${lineEnd.y}`,
        start,
        end,
        preview,
        labelPosition: {
          x: midX - offsetX,
          y: midY - offsetY
        }
      })
    }

    const polygons = groupedPolygons.map((pg) => pg.polygon)
    const areaPath = layer.segments.length >= 3 ? getAreaPathData(polygons) : null

    pathData.sort((p) => (p.start.outside && p.end.outside ? 1 : -1))
    cornerData.sort((p) => (p.start.outside && p.end.outside ? 1 : -1))

    return { pathData, cornerData, angleElements, areaPath, isExplicitlyClosed }
  }

  return {
    getPointPathData,
    getPathData
  }
}
