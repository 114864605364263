<template>
  <Tag
    class="border-0 shadow-sm text-pitch-black cursor-pointer px-2 py-1.5"
    :style="nameStyle"
    @click.native="(e) => clickHandler(e)"
  >
    <div
      @click.stop.prevent="(e) => clickHandler(e)"
      class="flex flex-row items-center text-pitch-black font-normal"
    >
      <slot name="before"></slot>

      <code class="">{{ abbr }}</code>
      <template v-if="size !== 'sm'">&nbsp;•&nbsp;{{ dim.dimension_name }}</template>
      <template v-if="showSuggestedMeasure">&nbsp;•&nbsp;{{ suggestedUnitOfMeasure }}</template>

      <slot name="after" v-if="$slots.after"></slot>
    </div>

    <help headless ref="help" @click="() => $emit('click')" class="hidden">
      <template #title
        ><span class="text-pitch-black font-normal">{{ dim.dimension_name }}</span></template
      >

      <slot name="help-before"></slot>

      <strong>Dimension measure type</strong>
      <p>
        {{ dim.dimension_measure_type }}
      </p>

      <strong>Description</strong>
      <p v-if="dim.dimension_desc">
        {{ dim.dimension_desc }}
      </p>

      <slot>
        <p v-if="actions && Object.keys(actions).length">
          <Btn
            class="round info block"
            v-for="action in actions"
            :key="action.title"
            :action="action.action"
          >
            {{ action.title }}
          </Btn>
        </p>
      </slot>

      <strong>Code</strong>
      <br />
      <a
        style="display: inline-flex; align-items: center; cursor: pointer; border-radius: 0.2em"
        class="py-2 px-4 text-light bg-dark"
        @click="copyToClipboard(abbr)"
      >
        <code class="p-2" style="text-weight: bold">{{ abbr }}</code>
        <font-awesome-icon icon="clipboard-line" />
      </a>
      <div>
        <small class="text-muted">
          This is the code you can use in calculators and dimension fields to link to the related
          dimension.
        </small>
      </div>

      <slot name="help-after"></slot>
    </help>
  </Tag>
</template>

<script>
import Tag from 'primevue/tag'
Tag.compatConfig = { MODE: 3 }
import Help from '@/components/ui/Help.vue'

export default {
  name: 'DimensionBadge',
  emits: ['click'],
  components: {
    Help,
    Tag
  },
  computed: {
    nameStyle() {
      return `background-color: #${this.color};`
    },
    metric() {
      return this.$store.state.session.user.country_id > 2
    },
    abbr() {
      return this.dim.abbr || this.dim.dimension_id || this.dim.dimension_abbr
    },
    color() {
      return `${this.dim.color || this.dim.dimension_color}73`
    },
    measureType() {
      return this.dim.dimension_measure_type || this.dim.measureType
    },
    suggestedUnitOfMeasure() {
      const metric = this.metric
      const mt = this.measureType

      if (mt === 'length') {
        return metric ? 'm' : 'ft'
      } else if (mt === 'volume') {
        return metric ? 'm3' : 'yd3'
      } else if (mt === 'area') {
        return metric ? 'm2' : 'ft2'
      } else if (mt === 'weight') {
        return metric ? 'kg' : 'lbs'
      }

      return 'each'
    }
  },
  methods: {
    clickHandler() {
      if (!this.openable) {
        this.$emit('click')
        return
      }

      this.$refs.help.open()
    },
    close() {
      this.$refs.help.close()
    },
    open() {
      this.$refs.help.close()
    },
    copyToClipboard(text) {
      this.$store.dispatch('copyToClipboard', text)
    }
  },
  props: {
    dim: {
      type: Object
    },
    size: {
      type: String,
      default: 'md'
    },
    actions: {
      type: Object
    },
    showSuggestedMeasure: {
      type: Boolean,
      default: true
    },
    openable: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.dimension-label {
  border-radius: 0.25em;
  padding: 0.3em 0.7em 0.3em 0.7em;
  color: rgba($pitch-black, 0.8);
  font-size: 0.9em;
  font-weight: bold;
  line-height: 1.1;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  code {
    font-weight: bold;
    color: $pitch-black;
  }
}
</style>
