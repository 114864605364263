export default {
  type: 'quickbooks',
  skipAudit: true,
  generateVueActions() {
    return {
      async getAccounts({ dispatch }) {
        const { payload } = await dispatch('ajax', {
          path: 'quickbooks/getAccounts'
        })

        return { payload }
      }
    }
  }
}
