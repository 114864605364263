import { forceBaseValues } from '@/router/guards/beforeEnter'

export default [
  {
    path: '/booked',
    component: () => import('@/components/pages/Booked.vue'),
    meta: {
      confirmLeave: false,
      scopesAllowed: ['user', 'company', 'quote']
    }
  },
  {
    path: '/feature/autocost',
    name: 'AutoCost Feature',
    component: () => import('@/components/pages/AutoCost.vue'),
    beforeEnter: [forceBaseValues],
    meta: {
      public: true
    }
  },
  {
    path: '/feature/com',
    name: 'Cloud Office Managers Feature',
    component: () => import('@/components/pages/CloudOfficeManager.vue'),
    beforeEnter: [forceBaseValues],
    meta: {
      public: true
    }
  },
  {
    path: '/user/:id?',
    name: 'Profile',
    alias: ['/:scopeRoute/user/:id?', '/profile/:id?'],
    component: () => import('@/components/pages/Profile.vue'),
    meta: {
      confirmLeave: true,
      scopesAllowed: ['user', 'company'],
      menuContext: 'user'
    }
  },
  {
    path: '/user/reset-password',
    name: 'User Reset Password',
    component: () => import('@/components/pages/ResetPassword.vue'),
    meta: {
      confirmLeave: true,
      scopesAllowed: ['user', 'company'],
      menuContext: 'user'
    }
  },
  {
    path: '/',
    name: 'Home',
    alias: ['/:scopeRoute/home', '/home'],
    component: () => import('@/components/pages/Home.vue'),
    meta: {
      scopesAllowed: ['user']
    }
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import('@/components/pages/Notifications.vue')
  },
  {
    path: '/accounting/callback',
    name: 'Accounting Export Callback',
    component: () => import('@/components/pages/AccountingCallback.vue'),
    meta: {
      scopesAllowed: ['user', 'company']
    }
  },
  {
    path: '/abcsupply/callback',
    name: 'ABC Supply Callback',
    component: () => import('@/components/pages/ABCSupplyCallback.vue'),
    meta: {
      scopesAllowed: ['user', 'company']
    }
  },
  {
    path: '/files',
    name: 'User Files',
    component: () => import('@/components/pages/Files.vue'),
    meta: {
      scopesAllowed: ['user', 'company'],
      fixedLayout: true
    }
  },
  {
    path: '/:notFound(.*)*',
    name: 'NotFound',
    component: () => import('@/components/pages/NotFound.vue')
  }
]
